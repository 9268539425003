import React from "react";
import "./index.scss";
import useTeamTypesController from "./controller";
import CreateTeamActionUnauthorized from "./create-team-action-unauthorized";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import TeamTypesButtons from "./team-types-button";
import NeoModalDialog from "../../../common/components/neo-modal-dialog";
import Progress from "../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../common/components/error-new";
import { TeamType } from "../../create-team/redux";
import RegionalITTeamUnAuthorizedMessage from "../../../common/components/regional-it-team-unauthorized-message";

const TeamTypesSelection = createFunctionalComponentFromView<ReturnType<typeof useTeamTypesController>, unknown>(
  useTeamTypesController,
  (controller) => {
    const renderCreateRegionalITAndTechOpsTeamUnAuthorized = () => {
      if (controller.createTeamUnAuthorizedPageIsActive) {
        return controller.createTeamUnAuthorizedPageTeamType === TeamType.REGIONAL_IT ?
          <RegionalITTeamUnAuthorizedMessage
            errorMessage={"Sorry, you do not have permission to create Regional IT team!"}
          /> :
          <CreateTeamActionUnauthorized/>;
      }
      return null;
    };

    const renderTeamTypesButton = () => {
      if (!controller.createTeamUnAuthorizedPageIsActive) {
        return (
          <>
            <TeamTypesButtons/>
          </>
        );
      }
    };

    const renderTeamTypes = () => {
      if (controller.shouldRenderTeamTypes()) {
        return (
          <div className="TeamTypes">
            {renderTeamTypesButton()}
            {renderCreateRegionalITAndTechOpsTeamUnAuthorized()}
          </div>
        );
      }
    };

    const renderError = () => {
      if (controller.shouldRenderErrorMessage()) {
        return (
          <Error
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            {...mapApplicationErrorInfoToErrorComponentProps(controller.getErrorMessage()!)}
          />
        );
      }
    };

    const renderModalBody = () => (
      <>
        {renderTeamTypes()}
        <Progress text={controller.getProgressMessage()}/>
        {renderError()}
      </>
    );

    return (
      <NeoModalDialog
        dialogTitle="Choose Team Type"
        show={controller.showChooseTeamTypeForTeamCreationModal}
        onHide={controller.onClickHideChooseTeamTypeForTeamCreationModal}
        modalSize={"xl"}
        dontAutoHideOnButtonClick={true}
        dialogClassName={"team-type-dialog"}
        primaryButton={{
          show: true,
          onClick: controller.getPrimaryButtonOnClickHandler,
          label: controller.getModalPrimaryButtonLabel(),
        }}
      >
        {renderModalBody()}
      </NeoModalDialog>
    );
  });

export default TeamTypesSelection;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as service from "./service";
import { PEV, ProgressErrorValue } from "../../../utils/progress-error-value";
import { INeoErrorInfo, NeoError } from "../../../utils/errors/neo-errors";
import { Nullable } from "../../../utils/nullable";
import { createAsyncThunkWithRejectValue } from "../../../common/redux/createAsyncThunkWithRejectValue";
import { ApplicationBasicInfo } from "../../applications/application-details/redux";

export interface IApplicationFilters {
  searchText: string;
  teamTypes: string[];
  countries: string[];
  businessFunctions: string[];
  statuses: string[];
  categories: string[];
  displayInactiveApplications: boolean;
}

interface IApplicationsState {
  applicationsPEV: ProgressErrorValue<ApplicationBasicInfo[], Nullable<string>, Nullable<INeoErrorInfo>>;
  filters: IApplicationFilters;
  filteredApplications: ApplicationBasicInfo[];
}

const initialState: IApplicationsState = {
  applicationsPEV: PEV([]),
  filters: {
    searchText: "",
    teamTypes: [],
    countries: [],
    businessFunctions: [],
    statuses: [],
    categories: [],
    displayInactiveApplications: false,
  },
  filteredApplications: [],
};

const fetchAllApplications = createAsyncThunkWithRejectValue<ApplicationBasicInfo[]>(
  "catalogue/applications",
  service.getAllApplications,
);

const filterApplication = (
  applications: ApplicationBasicInfo[],
  selectedValues: string[],
  filterType: "teamType" | "country" | "businessFunction" | "status" | "categories",
) => {
  function isSelectedValuesNotEmpty() {
    return selectedValues.length > 0;
  }

  if (isSelectedValuesNotEmpty()) {
    if (filterType === "teamType") {
      return applications.filter((application) => selectedValues.includes(application.team.teamType));
    }
    if (filterType === "categories") {
      return applications.filter((application) => application.category.some((category) => selectedValues.includes(category)));
    }
    return applications.filter((application) => selectedValues.includes(application[filterType]));
  }
  return applications;
};

export const applicationsCataloguePageSlice = createSlice({
  name: "applicationsCataloguePage",
  initialState,
  reducers: {
    updateApplicationsPageState: (state: IApplicationsState, action: PayloadAction<Partial<IApplicationsState>>) => ({
      ...state,
      ...action.payload,
    }),
    applyFilters: (
      state: IApplicationsState,
      action: PayloadAction<{
        filters: Partial<IApplicationFilters>;
        activeApplicationStatuses: Array<ApplicationBasicInfo["status"]>;
      }>,
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload.filters,
      };
      const searchTextInUpperCase = state.filters.searchText.toUpperCase().trim();
      const containsSearchText = (text: string) =>
        text && text.toUpperCase().includes(searchTextInUpperCase);
      let filteredApplications = state.applicationsPEV.value;

      if (!state.filters.displayInactiveApplications) {
        filteredApplications = filteredApplications.filter((application) => action.payload.activeApplicationStatuses.includes(application.status));
      }
      if (searchTextInUpperCase) {
        filteredApplications = filteredApplications.filter((application) => (
          containsSearchText(application.name)
          || containsSearchText(application.description)
          || application.owner.some(containsSearchText)
        ));
      }
      filteredApplications = filterApplication(filteredApplications, state.filters.teamTypes, "teamType");
      filteredApplications = filterApplication(filteredApplications, state.filters.countries, "country");
      filteredApplications = filterApplication(filteredApplications, state.filters.businessFunctions, "businessFunction");
      filteredApplications = filterApplication(filteredApplications, state.filters.statuses, "status");
      filteredApplications = filterApplication(filteredApplications, state.filters.categories, "categories");
      state.filteredApplications = filteredApplications;
    },
  },
  extraReducers: {
    [fetchAllApplications.pending.type]: (state: IApplicationsState) => {
      state.applicationsPEV = PEV(state.applicationsPEV.value, "Fetching all applications.");
    },
    [fetchAllApplications.fulfilled.type]: (state: IApplicationsState, action: PayloadAction<ApplicationBasicInfo[]>) => {
      state.applicationsPEV = PEV(action.payload);
    },
    [fetchAllApplications.rejected.type]: (state: IApplicationsState, action: PayloadAction<NeoError>) => {
      state.applicationsPEV = PEV([], null, action.payload.getErrorInfo());
    },
  },
});

export const applicationsCataloguePageReducer = applicationsCataloguePageSlice.reducer;

export const ApplicationsCataloguePageActions = {
  fetchAllApplications,
  applyFilter: (filters: Partial<IApplicationFilters>, activeApplicationStatuses: Array<ApplicationBasicInfo["status"]>) =>
    applicationsCataloguePageSlice.actions.applyFilters({ filters, activeApplicationStatuses }),
  resetFilters: (activeApplicationStatuses: Array<ApplicationBasicInfo["status"]>) => {
    const { searchText, ...filtersWithoutSearchText } = initialState.filters;
    return applicationsCataloguePageSlice.actions.applyFilters({ filters: filtersWithoutSearchText, activeApplicationStatuses });
  },
  applySearch: (searchText: IApplicationFilters["searchText"], activeApplicationStatuses: Array<ApplicationBasicInfo["status"]>) =>
    applicationsCataloguePageSlice.actions.applyFilters({ filters: { searchText }, activeApplicationStatuses }),
};

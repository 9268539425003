import { INeoErrorInfo, NeoError, NeoErrorType } from "../../../utils/errors/neo-errors";
import { IRequest } from "../types/requests";
import { ProgressErrorValue } from "../../../utils/progress-error-value";
import { Nullable } from "../../../utils/nullable";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { RequestsPageActions } from "../redux";

export type RequestDetailsProps = {
  requestId: string;
  requestsPEV: ProgressErrorValue<IRequest[], Nullable<string>, Nullable<INeoErrorInfo>>;
  showActions: boolean;
  taskDetailTitle: string;
};

export default function useRequestDetailsController({ requestsPEV, requestId, showActions, taskDetailTitle }: RequestDetailsProps) {
  const selectedRequestDetails = requestsPEV.value.find((request) => request.id === requestId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RequestsPageActions.fetchRequestEvents({ requestId }));
  }, []);

  return ({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    selectedRequestDetails: selectedRequestDetails!,
    showActions,
    taskDetailTitle,
    shouldRenderErrorMessage: () => requestsPEV.error || !selectedRequestDetails,
    shouldRenderProgressMessage: () => (requestsPEV.progress && !selectedRequestDetails),
    shouldRenderRequestDetails: () => !!selectedRequestDetails,
    getProgressMessage: () => showActions ? "Fetching task details" : "Fetching request details",
    getErrorMessage: () =>
      requestsPEV.error
        ? requestsPEV.error
        : new NeoError(NeoErrorType.RESOURCE_NOT_FOUND, "Request details not found").getErrorInfo(),
  });
}

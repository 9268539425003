import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { eventsDiscoveryPageActions, ICategory, INamespace } from "./redux";
import EventsAnalytics from "../common/services/events-analytics";
import { HEADER_HEIGHT, NAMESPACE_HEADER_HEIGHT, NAMESPACE_TILE_HEIGHT, PAGE_SIZE, TILE_HEIGHT } from "./data/constants";
import { useGetFeatureToggleState } from "../../../common/hooks/feature-toggles";
import { useHistory, useParams } from "react-router-dom";
import { useSelectorTyped } from "../../../common/redux/store";

interface UrlParam {
  nsViewNamespaceName: string;
}

export default function useEventsDiscoveryController() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { nsViewNamespaceName } = useParams<UrlParam>();
  const eventRegistrations = useSelectorTyped((state) => state.events.eventsDiscoveryPage.categoryEventRegistrationsMap);
  const nsEventRegistrations = useSelectorTyped((state) => state.events.eventsDiscoveryPage.namespaceEventRegistrationsMap);
  const hasMore = useSelectorTyped((state) => state.events.eventsDiscoveryPage.hasMore);
  const hasMoreNamespace = useSelectorTyped((state) => state.events.eventsDiscoveryPage.hasMoreNamespace);
  const isLoading = useSelectorTyped((state) => state.events.eventsDiscoveryPage.isLoading);
  const isLoadingNamespace = useSelectorTyped((state) => state.events.eventsDiscoveryPage.isLoadingNamespace);
  const pageNos = useSelectorTyped((state) => state.events.eventsDiscoveryPage.currentPage);
  const pageSize = PAGE_SIZE;
  const tileHeight = TILE_HEIGHT;
  const namespaceTileHeight = NAMESPACE_TILE_HEIGHT;
  const categoryNameHeaderHeight = HEADER_HEIGHT;
  const namespaceNameHeaderHeight = NAMESPACE_HEADER_HEIGHT;
  const [categorySectionHeights, setCategoryHeights] = useState<{ [key in string]: number }>({});
  const [namespaceSectionHeights, setNamespaceHeights] = useState<{ [key in string]: number }>({});
  const categoryMetadataPEV = useSelectorTyped((state) => state.events.eventsDiscoveryPage.categoryMetadata);
  const namespaceMetadataPEV = useSelectorTyped((state) => state.events.eventsDiscoveryPage.namespacesMetadata);
  const categoryMetadata = useSelectorTyped((state) => state.events.eventsDiscoveryPage.categoryMetadata.value);
  const selectedCategory = useSelectorTyped((state) => state.events.eventsDiscoveryPage.selectedCategory);
  const selectedNamespaceName = useSelectorTyped((state) => state.events.eventsDiscoveryPage.selectedNamespaceName);
  // const selectedEvent = useSelectorTyped((state) => state.events.eventsDiscoveryPage.selectedEvent);
  const namespacesMetadata = useSelectorTyped((state) => state.events.eventsDiscoveryPage.namespacesMetadata.value);
  const [clickScrollInProgress, setClickScrollInProgress] = useState(false);
  const [sortedCategoryMetadata, setSortedCategoryMetadata] = useState<ICategory[]>([]);
  const [sortedNamespaceMetadata, setSortedNamespaceMetadata] = useState<INamespace[]>([]);
  const isEventTagsEnabled = useGetFeatureToggleState("Events_EventsDiscovery_Tags");
  const isNamespacesViewEnabled = useGetFeatureToggleState("Events_EventsDiscovery_Namespaces_View");
  const isNamespacesActive = useSelectorTyped((state) => state.events.eventsDiscoveryPage.isNamespacesActive);
  const [isNotFoundError, setIsNotFoundError] = useState(false);

  useEffect(() => {
    EventsAnalytics.trackPageEventDiscovery();
    if (!categoryMetadata) {
      dispatch(eventsDiscoveryPageActions.fetchCategories());
      dispatch(eventsDiscoveryPageActions.fetchMasterDataCategories());
    }
    if (!namespacesMetadata) {
      dispatch(eventsDiscoveryPageActions.fetchNamespaces());
    }
  }, []);

  useEffect(() => {
    if (categoryMetadata && categoryMetadata.length > 0) {
      setSortedCategoryMetadata(sortAndFilterCategories());
      const heights: { [key in string]: number } = {};
      categoryMetadata.forEach((agg) => {
        if (agg.totalEventRegistrations > 0) {
          const calculatedHeight = categoryNameHeaderHeight + tileHeight * Math.ceil(agg.totalEventRegistrations / 3);
          heights[agg.name] = tileHeight > calculatedHeight ? tileHeight : calculatedHeight;
        } else {
          heights[agg.name] = categoryNameHeaderHeight + 24;
        }
      });
      setCategoryHeights(heights);
    }
  }, [categoryMetadata]);

  useEffect(() => {
    if (!isNamespacesActive && sortedCategoryMetadata && sortedCategoryMetadata.length > 0) {
      if (selectedCategory) {
        handleItemClick(selectedCategory);
      } else {
        dispatch(eventsDiscoveryPageActions.setSelectedCategory(sortedCategoryMetadata[0].name));
      }
      return;
    }
    // document.getElementById(selectedEvent)?.scrollIntoView({block: "center"});
  }, [sortedCategoryMetadata]);

  useEffect(() => {
    if (namespacesMetadata && namespacesMetadata.length > 0) {
      setSortedNamespaceMetadata(sortMetadata(namespacesMetadata) as INamespace[]);
      const heights: { [key in string]: number } = {};
      namespacesMetadata.forEach((namespace) => {
        if (namespace.totalEventRegistrations > 0) {
          const calculatedHeight = namespaceNameHeaderHeight + namespaceTileHeight * Math.ceil(namespace.totalEventRegistrations / 3);
          heights[namespace.name] = tileHeight > calculatedHeight ? tileHeight : calculatedHeight;
        } else {
          heights[namespace.name] = namespaceNameHeaderHeight + 24;
        }
      });
      setNamespaceHeights(heights);
    }
  }, [namespacesMetadata]);

  useEffect(() => {
    if (sortedNamespaceMetadata && sortedNamespaceMetadata.length > 0) {
      if (isNamespacesActive && selectedNamespaceName && selectedCategory) {
        handleItemClick(selectedNamespaceName);
      } else if (isNamespacesActive && selectedNamespaceName) {
        handleItemClick(selectedNamespaceName, false);
      } else if (!selectedNamespaceName) {
        dispatch(eventsDiscoveryPageActions.setSelectedNamespaceName(sortedNamespaceMetadata[0].name));
      }
      return;
    }
    // document.getElementById(selectedEvent)?.scrollIntoView({block: "center"});
  }, [sortedNamespaceMetadata]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    if (!isNamespacesActive && nsViewNamespaceName !== "discovery") {
      dispatch(eventsDiscoveryPageActions.setNamespacesActive(true));
      dispatch(eventsDiscoveryPageActions.setSelectedNamespaceName(nsViewNamespaceName));
      fetchEventRegistrations(nsViewNamespaceName);
    }
  }, [nsViewNamespaceName]);

  useEffect(() => {
    if (!isNamespacesActive) {
      window.scrollTo({ top: 0 });
      handleItemClick(selectedCategory);
    }
    if (isNamespacesActive && sortedNamespaceMetadata) {
      window.scrollTo({ top: 0 });
      handleItemClick(selectedNamespaceName);
    }
  }, [isNamespacesActive]);

  const switchToNamespaceView = (isNamespaceViewActive: boolean) => {
    dispatch(eventsDiscoveryPageActions.setNamespacesActive(isNamespaceViewActive));
    if (!isNamespaceViewActive) {
      history.push("/events/discovery");
    }
  };

  const getOffsetPositionOf = (domain: string) => {
    const bodyRect = document.body.getBoundingClientRect().top;
    const sectionRect = document.getElementById(domain);
    // @ts-ignore
    const elementPosition = sectionRect?.getBoundingClientRect().top ?? 0;
    const headerOffset = 145;
    const sectionPosition = elementPosition - bodyRect;
    return sectionPosition - headerOffset;
  };

  const handleItemClick = (domain: string, scrollSmooth: boolean = true) => {
    if (domain !== "") {
      EventsAnalytics.trackPageCategory(domain);
      setClickScrollInProgress(true);
      if (!isNamespacesActive) {
        // setHighlightedCategory(domain);
        dispatch(eventsDiscoveryPageActions.setSelectedCategory(domain));
        history.push("/events/discovery");
      }
      if (isNamespacesActive) {
        // setHighlightedNamespace(domain);
        dispatch(eventsDiscoveryPageActions.setSelectedNamespaceName(domain));
        fetchEventRegistrations(domain);
        history.push(`/events/${domain}`);
      }
      const newOffsetPosition = getOffsetPositionOf(domain);
      window.scrollTo({
        top: newOffsetPosition,
        behavior: scrollSmooth ? "smooth" : "auto",
      });
    }
  };

  // @ts-ignore
  const fetchEventRegistrations = (item: string) => {
    if (isLoading[item] || isLoadingNamespace[item]) {
      return;
    }
    // eslint-disable-next-line unicorn/prefer-array-some
    if (isNamespacesActive && sortedNamespaceMetadata.length > 0 && sortedNamespaceMetadata.find((ns) => ns.name === item) === undefined) {
      setIsNotFoundError(true);
      dispatch(eventsDiscoveryPageActions.setSelectedNamespaceName(sortedNamespaceMetadata[0].name));
      return;
    }
    const pageNo = pageNos[item];
    if (!isNamespacesActive && hasMore[item]) {
      dispatch(eventsDiscoveryPageActions.fetchEventRegistrationsV3({ aggregate: item, pageNo, pageSize }));
    } else if (isNamespacesActive && hasMoreNamespace[item]) {
      dispatch(eventsDiscoveryPageActions.fetchEventRegistrations({ namespaceName: item }));
    }
  };

  // function handleSectionContentInView(inviewItem: string, isInView: boolean) {
  //   if (isInView) {
  //     if(!isNamespacesActive){
  //       dispatch(eventsDiscoveryPageActions.setSelectedCategory(inviewItem));
  //     }
  //     if(isNamespacesActive){
  //       dispatch(eventsDiscoveryPageActions.setSelectedNamespaceName(inviewItem));
  //     }
  //     fetchEventRegistrations(inviewItem);
  //   }
  // }

  function handleSectionTitleInView(item: string, isInView: boolean) {
    if (isInView) {
      fetchEventRegistrations(item);
    }
    if (!isNamespacesActive && selectedCategory === item) {
      setClickScrollInProgress(false);
    }
    if (isNamespacesActive && selectedNamespaceName === item) {
      setClickScrollInProgress(false);
    }
    if (!isNamespacesActive && isInView && !clickScrollInProgress) {
      dispatch(eventsDiscoveryPageActions.setSelectedCategory(item));
      // fetchEventRegistrations(item);
      // setHighlightedCategory(item);
      document.getElementById(`cat-${item}`)?.scrollIntoView(false);
    }
    if (isNamespacesActive && isInView && !clickScrollInProgress) {
      dispatch(eventsDiscoveryPageActions.setSelectedNamespaceName(item));
      // fetchEventRegistrations(item);
      // setHighlightedNamespace(item);
      document.getElementById(`ns-${item}`)?.scrollIntoView(false);
      history.push(`/events/${item}`);
    }
  }

  const onEventTileClick = (namespaceName: string, eventName: string) => {
    dispatch(eventsDiscoveryPageActions.setSelectedEvent(`namespace_${namespaceName}_event_${eventName}`));
    EventsAnalytics.trackPageEventDetails(selectedCategory, `${namespaceName} > ${eventName}`);
  };

  const sortAndFilterCategories = (): ICategory[] => {
    const sortedCategories = categoryMetadata && sortMetadata(categoryMetadata) as ICategory[];
    // @ts-ignore
    const final = sortedCategories && sortedCategories.length > 0 ? [
      ...sortedCategories.filter(({ name }) => name !== "Others"),
      ...sortedCategories.filter(({ name }) => name === "Others"),
    ] : [];
    return final;
  };

  const sortMetadata = (items: ICategory[] | INamespace[]) => items.map((e) => e).sort(function(a, b) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  });

  const shouldShowEventTags = () => isEventTagsEnabled;

  const filterTags = (tags: string[]): string[] | undefined => {
    if (shouldShowEventTags() && tags && tags.length > 0) {
      const tagLengths: number[] = tags.map((tag) => tag.length);
      const sum = tagLengths.reduce((len1, len2) => len1 + len2);
      if (isValidTagSize(sum, tagLengths)) {
        return tags;
      }
      const filteredTags = tags.slice(0, -1);
      return filterTags(filteredTags);
    }
  };

  function isValidTagSize(sum: number, tagLengths: number[]) {
    return sum <= 19 || (tagLengths.length === 2 && sum <= 24) || tagLengths.length === 1;
  }

  return ({
    eventRegistrations,
    nsEventRegistrations,
    categorySectionHeights,
    namespaceSectionHeights,
    categoryMetadata,
    namespacesMetadata,
    sortAndFilterCategories,
    categoryMetadataPEV,
    namespaceMetadataPEV,
    hasMore,
    hasMoreNamespace,
    isLoading,
    isLoadingNamespace,
    pageNos,
    pageSize,
    tileHeight,
    selectedCategory,
    selectedNamespaceName,
    handleItemClick,
    fetchEventRegistrations,
    onEventTileClick,
    handleSectionTitleInView,
    sortedCategoryMetadata,
    shouldShowEventTags,
    filterTags,
    isNamespacesViewEnabled,
    isNamespacesActive,
    sortedNamespaceMetadata,
    switchToNamespaceView,
    // highlightedCategory,
    // highlightedNamespace,
    isNotFoundError,
  });
}

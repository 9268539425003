import { IApplicationStatuses } from "../redux/slices/application-status";
import { callBffApi } from "./axios";

type ApplicationsStatusResponse = {
  data: IApplicationStatuses;
};

export const applicationStatusService = {
  getApplicationsStatuses: async (): Promise<IApplicationStatuses> => {
    const result = await callBffApi<ApplicationsStatusResponse>("get", "/application-status");
    return result.data;
  },
};

export const EVENT_REGISTRATIONS_NOT_FETCHED = null;
export const FETCHING_NAMESPACES_TEXT = "Fetching namespaces ...";
export const FETCHING_EVENT_REGISTRATIONS_TEXT = "Fetching event registrations ...";
export const FETCHING_CATEGORIES_TEXT = "Fetching categories ...";
export const FETCHING_CATEGORIES_ERROR_TEXT = "Error while fetching categories ...";
export const FETCH_NAMESPACES_ERROR_TEXT = "Error while fetching namespaces !";
export const FETCH_EVENT_REGISTRATIONS_ERROR_TEXT = "Error while fetching event registrations !";
export const FETCHING_EVENT_METRICS_TEXT = "Fetching event metrics ...";
export const FETCHING_EVENT_METRICS_ERROR_TEXT = "Error while fetching event metrics ...";
export const PAGE_SIZE = 200;
export const TILE_HEIGHT = 224;
export const HEADER_HEIGHT = 64;
export const NAMESPACE_HEADER_HEIGHT = 84;
export const NAMESPACE_TILE_HEIGHT = 200;

import { useSelectorTyped } from "../../redux/store";


export default function useFeedbackButtonController() {
  const surveyLink = useSelectorTyped((state) => state.header.uiConfigsPEV.value.externalLinks.surveyLink);

  return {
    surveyLink,
  };
}

import React, { FunctionComponent } from "react";
import PageTitle from "../../../../common/components/page-title";
import TeamListTable from "../../../../common/components/team-list-table";
import useTeamInformationController from "./controller";
import Progress from "../../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../../common/components/error-new";
import ImpactXButton from "../../../../common/components/impactx-button";
import "./index.scss";
import NeoModalDialog from "../../../../common/components/neo-modal-dialog";
import StepBar from "../../../../common/components/step-bar";
import { CreateTeamForm } from "../../../../common/components/create-team";
import { TeamType } from "../../../create-team/redux";
import { PageTitleWithRightElement } from "../../../../common/components/page-title-with-right-column-element";
import NeoLink from "../../../../common/components/neo-link";

export const SelectTeam: FunctionComponent = () => {
  const controller = useTeamInformationController();

  const renderNoTeamsMessage = () => (
    <span>
      You are not associated with any team. Click&nbsp;
      <b>
        <NeoLink
          className={"create-team-link"}
          onClick={controller.onClickCreateButton}
          link={"/get-started"}
        >here
        </NeoLink>
      </b> to create a new team.
    </span>
  );

  const renderContent = () => {
    if (controller.shouldRenderProgressMessage()) {
      return (<Progress text={controller.teamListProgress}/>);
    }
    if (controller.shouldRenderErrorMessage()) {
      return (
        <Error
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          {...mapApplicationErrorInfoToErrorComponentProps(controller.teamListError!)}
        />);
    }
    if (controller.shouldRenderNoTeamsMessage()) {
      return renderNoTeamsMessage();
    }
    return (
      <TeamListTable
        teams={controller.getTeamList()}
        onRowClickedHandler={controller.rowClickHandler}
        hideStatus={true}
      />
    );
  };

  const renderTeamCreationModal = () => (
    <NeoModalDialog
      dialogTitle="Create Experiment & Innovation Team"
      bodyText={""}
      dialogClassName={"create-team-modal"}
      show={controller.createTeamModalShow}
      onHide={() => controller.setCreateTeamModalShow(false)}
    >
      <div className={"create-team-modal-content"}>
        <CreateTeamForm
          onCancel={controller.onCancel}
          showLoggedInUserBePartOfTeamErrorWithinForm={true}
          teamType={TeamType.EXPERIMENT_AND_INNOVATION}
        />
      </div>
    </NeoModalDialog>
  );

  return (
    <div className="SelectTeam">
      <StepBar
        stepLabels={["Select Team", "Application Information", "Infrastructure Configuration", "Review and Submit"]}
        currentCompletedStep={1}
      />
      <div>
        <PageTitle
          title="Experiments and Innovations"
          displayTitleLighter={true}
        />
        <div className={"select-team-title"}>
          <PageTitleWithRightElement
            title={"Select Team"}
            rightColumnElement={
              <ImpactXButton
                label="Create Team"
                onClickHandler={controller.setTeamProjectClassificationAndShowCreateTeamModal}
              />
            }
          />
        </div>
        {renderTeamCreationModal()}
      </div>
      <div className={"content"}>
        {renderContent()}
      </div>
      <ImpactXButton
        className={"previous-button"}
        label="PREVIOUS"
        outline
        onClickHandler={controller.onPrevious}
      />
    </div>
  );
};

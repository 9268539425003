import { ActionCreatorWithPayload, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EventsDiscoveryPageService } from "./service";
import { PEV, ProgressErrorValue } from "../../../utils/progress-error-value";
import { Nullable } from "../../../utils/nullable";
import {
  FETCH_EVENT_REGISTRATIONS_ERROR_TEXT,
  FETCH_NAMESPACES_ERROR_TEXT,
  FETCHING_CATEGORIES_TEXT,
  FETCHING_EVENT_REGISTRATIONS_TEXT,
  FETCHING_NAMESPACES_TEXT,
} from "./data/constants";
import { Metadata } from "showdown";
import { MasterDataCategory } from "../events-overview/redux";

export type IEventRegistrationNotFetched = null;

export interface INamespace {
  name: string;
  description: string;
  eventRegistrations: ProgressErrorValue<IEventRegistration[] | IEventRegistrationNotFetched>;
  totalEventRegistrations: number;
}

export interface IEventRegistration {
  description: string;
  name: string;
  tags: string[];
  namespace: { name: string };
}

export interface IEventRegistrationV3 {
  name: string;
  description: string;
  category: string;
  tags: string[];
  namespace: { name: string; description: string };
}

export interface ICategory {
  name: string;
  masterId: number;
  totalEventRegistrations: number;
}

export interface IEventsDiscoveryPageState {
  namespacesMetadata: ProgressErrorValue<Nullable<INamespace[]>>;
  selectedNamespace: Nullable<INamespace>;
  categoryEventRegistrationsMap: { [key in string]: IEventRegistrationV3[] };
  namespaceEventRegistrationsMap: { [key in string]: IEventRegistration[] };
  isLoading: { [key in string]: boolean };
  isLoadingNamespace: { [key in string]: boolean };
  hasMore: { [key in string]: boolean };
  hasMoreNamespace: { [key in string]: boolean };
  categoryMetadata: ProgressErrorValue<Nullable<ICategory[]>>;
  currentPage: { [key in string]: number };
  selectedCategory: string;
  selectedEvent: string;
  selectedNamespaceName: string;
  isNamespacesActive: boolean;
  // hasRenderedNamespaceView: boolean;
}

const initialState: IEventsDiscoveryPageState = {
  namespacesMetadata: PEV(null, FETCHING_NAMESPACES_TEXT),
  selectedNamespace: null,
  categoryEventRegistrationsMap: {},
  namespaceEventRegistrationsMap: {},
  isLoading: {},
  isLoadingNamespace: {},
  currentPage: {},
  hasMore: {},
  hasMoreNamespace: {},
  categoryMetadata: PEV(null, FETCHING_CATEGORIES_TEXT),
  selectedCategory: "",
  selectedEvent: "",
  selectedNamespaceName: "",
  isNamespacesActive: false,
  // hasRenderedNamespaceView: false,
};

const fetchNamespaces = createAsyncThunk("fetch-namespaces", async () =>
  EventsDiscoveryPageService.fetchNamespaces(),
);

const fetchEventRegistrations = createAsyncThunk(
  "fetch-event-registrations",
  async (payload: { namespaceName: string }) =>
    EventsDiscoveryPageService.fetchEventRegistrations(payload.namespaceName),
);

// eslint-disable-next-line max-len
const fetchEventRegistrationsV3 = createAsyncThunk(
  "fetch-event-registrationsv3",
  async (payload: { aggregate: string; pageNo: number; pageSize: number }) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    EventsDiscoveryPageService.fetchEventRegistrationsV3(
      payload.aggregate,
      payload.pageNo,
      payload.pageSize,
    ),
);

const fetchCategories = createAsyncThunk("fetch-categories", async () =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  EventsDiscoveryPageService.fetchCategories(),
);

const fetchMasterDataCategories = createAsyncThunk(
  "fetch-master-data-categories",
  async () =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    // eslint-disable-next-line @typescript-eslint/require-await
    EventsDiscoveryPageService.fetchMasterDataCategories(),
);

const eventsDiscoveryPageSlice = createSlice({
  name: "eventsDiscoveryPage",
  initialState,
  reducers: {
    updateEventsDiscoveryPage: (
      state: IEventsDiscoveryPageState,
      action: PayloadAction<Partial<IEventsDiscoveryPageState>>,
    ) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: {
    [fetchNamespaces.pending.type]: (state: IEventsDiscoveryPageState) => {
      state.namespacesMetadata = PEV([], FETCHING_NAMESPACES_TEXT);
    },

    [fetchNamespaces.fulfilled.type]: (
      state: IEventsDiscoveryPageState,
      action: PayloadAction<INamespace[]>,
    ) => {
      state.namespacesMetadata = PEV(action.payload);

      if (!state.selectedNamespace) {
        const [firstNamespace] = action.payload;
        state.selectedNamespace = firstNamespace;
      }

      const loadStatus: { [key: string]: boolean } = {};
      action.payload.forEach((ns) => {
        loadStatus[ns.name] = false;
      });
      state.isLoadingNamespace = loadStatus;

      const events: { [key: string]: [] } = {};
      action.payload.forEach((ns) => {
        events[ns.name] = [];
      });
      state.namespaceEventRegistrationsMap = events;

      const hasMore: { [key: string]: boolean } = {};
      action.payload.forEach((ns) => {
        hasMore[ns.name] = ns.totalEventRegistrations > 0;
      });
      state.hasMoreNamespace = hasMore;
    },

    [fetchNamespaces.rejected.type]: (
      state: IEventsDiscoveryPageState,
      // action: PayloadAction<never, never, Metadata, Error>,
    ) => {
      state.namespacesMetadata = PEV(null, null, FETCH_NAMESPACES_ERROR_TEXT);
    },

    [fetchEventRegistrations.pending.type]: (
      state: IEventsDiscoveryPageState,
      action,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state.selectedNamespace!.eventRegistrations = PEV(
        null,
        FETCHING_EVENT_REGISTRATIONS_TEXT,
        null,
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      state.isLoadingNamespace[action.meta?.arg.namespaceName] = true;
    },

    [fetchEventRegistrations.fulfilled.type]: (
      state: IEventsDiscoveryPageState,
      action: PayloadAction<{
        namespaceName: string;
        eventRegistrations: IEventRegistration[];
      }>,
    ) => {
      const namespace = action.payload.namespaceName;
      state.isLoadingNamespace[namespace] = false;

      if (action.payload.eventRegistrations && action.payload.eventRegistrations.length > 0) {
        state.namespaceEventRegistrationsMap[namespace] =
          action.payload.eventRegistrations;
        state.hasMoreNamespace[namespace] = false;
      }
    },

    [fetchEventRegistrations.rejected.type]: (
      state: IEventsDiscoveryPageState,
      action: PayloadAction<never, never, Metadata, Error>,
    ) => {
      if (!action.error.message || action.error.message === "Unknown Error") {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.selectedNamespace!.eventRegistrations = PEV(
          null,
          null,
          FETCH_EVENT_REGISTRATIONS_ERROR_TEXT,
        );
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.selectedNamespace!.eventRegistrations = PEV(
          null,
          null,
          action.error.message,
        );
      }
    },
    [fetchCategories.pending.type]: (state: IEventsDiscoveryPageState) => {
      state.categoryMetadata = PEV(null, FETCHING_CATEGORIES_TEXT, null);
    },
    [fetchCategories.fulfilled.type]: (
      state: IEventsDiscoveryPageState,
      action: PayloadAction<{ categories: ICategory[] }>,
    ) => {
      state.categoryMetadata = PEV(action.payload.categories, null, null);

      const currentPage: { [key: string]: number } = {};
      action.payload.categories.forEach((cat) => {
        currentPage[cat.name] = 1;
      });
      state.currentPage = currentPage;

      const hasMore: { [key: string]: boolean } = {};
      action.payload.categories.forEach((cat) => {
        hasMore[cat.name] = cat.totalEventRegistrations > 0;
      });
      state.hasMore = hasMore;

      const loadStatus: { [key: string]: boolean } = {};
      action.payload.categories.forEach((cat) => {
        loadStatus[cat.name] = false;
      });
      state.isLoading = loadStatus;

      const events: { [key: string]: [] } = {};
      action.payload.categories.forEach((cat) => {
        events[cat.name] = [];
      });
      state.categoryEventRegistrationsMap = events;
    },
    [fetchCategories.rejected.type]: (
      state: IEventsDiscoveryPageState,
      action: PayloadAction<never, never, Metadata, Error>,
    ) => {
      state.categoryMetadata = PEV(null, null, action.error.message);
    },
    [fetchEventRegistrationsV3.pending.type]: (
      state: IEventsDiscoveryPageState,
      action,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      state.isLoading[action.meta?.arg.aggregate] = true;
    },
    [fetchEventRegistrationsV3.fulfilled.type]: (
      state: IEventsDiscoveryPageState,
      action: PayloadAction<{
        eventRegistrations: IEventRegistrationV3[];
        aggregate: string;
      }>,
    ) => {
      const category = action.payload.aggregate;
      state.isLoading[category] = false;

      if (action.payload.eventRegistrations && action.payload.eventRegistrations.length > 0) {
        if (category in state.categoryEventRegistrationsMap) {
          // eslint-disable-next-line max-len
          state.categoryEventRegistrationsMap[category] =
            // eslint-disable-next-line unicorn/prefer-spread
            state.categoryEventRegistrationsMap[category].concat(action.payload.eventRegistrations);
        } else {
          state.categoryEventRegistrationsMap[category] =
            action.payload.eventRegistrations;
        }
      } else {
        state.hasMore[category] = false;
      }
      // @ts-ignore
      // eslint-disable-next-line max-len
      if (state.categoryEventRegistrationsMap[category].length < state.categoryMetadata.value.find((kv) => kv.name === category).totalEventRegistrations) {
        state.hasMore[category] = true;
      } else {
        state.hasMore[category] = false;
      }
      state.currentPage[category] += 1;
    },

    [fetchMasterDataCategories.fulfilled.type]: (state: IEventsDiscoveryPageState, action: PayloadAction<MasterDataCategory[]>) => {
      const categories =
        state.categoryMetadata.value !== null
          ? state.categoryMetadata.value
          : new Array<ICategory>();
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      state.categoryMetadata.value = action.payload.reduce(merge, categories);
    },

    [fetchMasterDataCategories.rejected.type]: (
      state: IEventsDiscoveryPageState,
      action: PayloadAction<never, never, Metadata, Error>,
    ) => {
      state.categoryMetadata = PEV(null, null, action.error.message);
    },
  },
});

function merge(
  categories: ICategory[],
  masterDataCategory: MasterDataCategory,
) {
  const index = categories?.findIndex(
    (category: ICategory) =>
      category.masterId === Number.parseInt(masterDataCategory.id, 10),
  );
  if (index === -1) {
    const mergedCategory: ICategory = {
      name: masterDataCategory.name,
      masterId: Number.parseInt(masterDataCategory.id, 10),
      totalEventRegistrations: 0,
    };
    categories?.push(mergedCategory);
  }
  return categories;
}

const updateEventDiscoveryPageState: ActionCreatorWithPayload<Partial<IEventsDiscoveryPageState>> =
  eventsDiscoveryPageSlice.actions.updateEventsDiscoveryPage;

const setSelectedNamespace = (selectedNamespace: INamespace) =>
  updateEventDiscoveryPageState({
    selectedNamespace,
  });

const setSelectedCategory = (selectedCategory: string) =>
  updateEventDiscoveryPageState({
    selectedCategory,
  });

const setSelectedEvent = (selectedEvent: string) =>
  updateEventDiscoveryPageState({
    selectedEvent,
  });

const setSelectedNamespaceName = (selectedNamespaceName: string) =>
  updateEventDiscoveryPageState({
    selectedNamespaceName,
  });

const setNamespacesActive = (isNamespacesActive: boolean) =>
  updateEventDiscoveryPageState({
    isNamespacesActive,
  });

export const eventsDiscoveryPageReducer = eventsDiscoveryPageSlice.reducer;

export const eventsDiscoveryPageActions = {
  fetchNamespaces,
  setSelectedNamespace,
  fetchEventRegistrations,
  fetchEventRegistrationsV3,
  fetchCategories,
  setSelectedCategory,
  setSelectedEvent,
  fetchMasterDataCategories,
  setSelectedNamespaceName,
  setNamespacesActive,
};

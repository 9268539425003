import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "./index.scss";
import SubHeader from "../../common/components/sub-header";
import { CataloguePages } from "./data/pages";
import { createFunctionalComponentFromView } from "../../utils/view-controller";
import useCatalogueController from "./controller";

const Catalogue = createFunctionalComponentFromView(useCatalogueController, (controller) => (
  <div className="Catalogue">
    <SubHeader
      headerTitle="Catalogue"
      backgroundColor="#634F7D"
      menuItems={controller.getMenuItems()}
    />
    <Switch>
      <Route
        path={CataloguePages.APPLICATIONS.path}
        component={CataloguePages.APPLICATIONS.component}
      />
      <Route
        path={CataloguePages.MY_APPS_AND_SERVICES.path}
        component={CataloguePages.MY_APPS_AND_SERVICES.component}
      />
      <Route
        path={CataloguePages.DEVELOPER_TOOL_DETAILS.path}
        component={CataloguePages.DEVELOPER_TOOL_DETAILS.component}
      />
      <Route
        path={CataloguePages.DEVELOPER_TOOLS.path}
        component={CataloguePages.DEVELOPER_TOOLS.component}
      />
      <Route
        path={CataloguePages.APPLICATION_DETAILS.path}
        component={CataloguePages.APPLICATION_DETAILS.component}
      />
      <Redirect to={CataloguePages.APPLICATIONS.path}/>
    </Switch>
  </div>
));

export default Catalogue;

import React, { FunctionComponent, ReactElement } from "react";
import "./index.scss";
import { Accordion } from "react-bootstrap";
import { AccordionProps } from "react-bootstrap/Accordion";

export type NeoAccordionProps = AccordionProps & {
  items: Array<{
    heading: string | ReactElement;
    body: ReactElement;
    eventKey: string;
  }>;
};

const NeoAccordion: FunctionComponent<NeoAccordionProps> = (props: NeoAccordionProps) => {
  const renderItems = () => props.items.map((item, index) => (
    <div
      className="accordion-item"
      key={index}
    >
      <Accordion.Collapse
        className="accordion-body"
        eventKey={item.eventKey}
      >
        {item.body}
      </Accordion.Collapse>
      <Accordion.Toggle
        className="accordion-header"
        as={"button"}
        eventKey={item.eventKey}
      >
        {item.heading}
        <i className="ri-arrow-down-s-line"/>
      </Accordion.Toggle>
    </div>
  ));

  return (
    <Accordion
      className="NeoAccordion"
      defaultActiveKey={props.defaultActiveKey}
      onSelect={props.onSelect}
      activeKey={props.activeKey}
    >
      {renderItems()}
    </Accordion>
  );
};

export default NeoAccordion;

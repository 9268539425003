import { useHistory } from "react-router-dom";
import { convertSpaceToUnderscore } from "../../../utils/string";
import { DeveloperTool } from "../../../pages/catalogue/developer-tools/redux";

type Props = {
  developerTool: DeveloperTool;
  onClick: () => void;
};

export default function useDeveloperToolCardController(props: Props) {
  const history = useHistory();

  return {
    ...props,
    currentPathname: history.location.pathname,
    redirectionPathname: `/catalogue/developer-tools/${props.developerTool.id}/${convertSpaceToUnderscore(props.developerTool.name)}`,
  };
}

import { callBffApi } from "../../../common/services/axios";
import { DeveloperTool, DownloadDeveloperToolMetrics } from "./redux";

export const fetchDeveloperTools = async () => {
  const response = await callBffApi<{ data: DeveloperTool[] }>("get", "/applications/developer-tools");
  return response.data;
};

export const captureDownloadDeveloperToolMetrics = async (payload: DownloadDeveloperToolMetrics) =>
  callBffApi<void>(
    "post",
    "/applications/metrics/download-developer-tool",
    payload,
  );

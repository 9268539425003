import { PEV, ProgressErrorValue } from "../../../utils/progress-error-value";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { ApplicationPurpose } from "../../data/application-purpose";
import { applicationPurposeService } from "../../services/application-purpose";
import { INeoErrorInfo, NeoError } from "../../../utils/errors/neo-errors";
import { createAsyncThunkWithRejectValue } from "../createAsyncThunkWithRejectValue";
import { Nullable } from "../../../utils/nullable";

export type IApplicationPurposeState = ProgressErrorValue<ApplicationPurpose[], Nullable<string>, Nullable<INeoErrorInfo>>;

const initialState: IApplicationPurposeState = PEV([]);

const fetchApplicationPurposes = createAsyncThunkWithRejectValue<ApplicationPurpose[]>(
  "application-purpose",
  applicationPurposeService.getApplicationPurposes,
);


export const applicationPurposesSlices = createSlice({
  name: "applicationPurposes",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchApplicationPurposes.pending.type]: (state) => {
      state.progress = "Fetching application purposes";
      state.error = null;
      state.value = [];
    },
    [fetchApplicationPurposes.fulfilled.type]: (state: IApplicationPurposeState, action: PayloadAction<ApplicationPurpose[]>) => {
      state.value = action.payload;
      state.progress = null;
      state.error = null;
    },
    [fetchApplicationPurposes.rejected.type]: (state: IApplicationPurposeState, action: PayloadAction<NeoError>) => {
      state.error = action.payload.getErrorInfo();
      state.progress = null;
      state.value = [];
    },
  },
});

export const dispatchFetchPurposesIfEmpty = (
  dispatch: Dispatch<unknown>,
  applicationPurposesPEV: IApplicationPurposeState,
) => {
  if (applicationPurposesPEV.value.length === 0 && !applicationPurposesPEV.progress && !applicationPurposesPEV.error) {
    dispatch(fetchApplicationPurposes());
  }
};

export const applicationPurposesReducer = applicationPurposesSlices.reducer;

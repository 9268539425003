import YesNoChoices from "../../../data/yes-no-choice";
import { thoughtworksApiUserGuideUrl } from "../../../data/urls";
import { getGcpProjectNameValidatorConfig, getGcpValidatorConfig } from "../../../utils/validator";

const getInfraConfigQuestions = (applicationName: string) => [
  {
    type: "panel",
    title: "Code Repository",
    elements: [
      {
        type: "radiogroup",
        name: "isCodeRepositoryNeeded",
        title: "Do you need GitHub repository?",
        isRequired: true,
        colCount: 1,
        choices: [
          YesNoChoices.YES,
          YesNoChoices.NO,
        ],
      },
      {
        type: "text",
        name: "githubUsername",
        title: "GitHub username",
        visibleIf: `{isCodeRepositoryNeeded}='${YesNoChoices.YES}'`,
        isRequired: true,
      },
      {
        type: "text",
        name: "githubOrganisation",
        title: "GitHub organisation",
        visibleIf: `{isCodeRepositoryNeeded}='${YesNoChoices.YES}'`,
        isRequired: true,
      },
      {
        type: "radiogroup",
        name: "isCiCdPipelineNeeded",
        title: "Do you need CI/CD pipeline (CircleCI)?",
        colCount: 1,
        choices: [
          YesNoChoices.YES,
          YesNoChoices.NO,
        ],
        visibleIf: `{isCodeRepositoryNeeded}='${YesNoChoices.YES}'`,
        isRequired: true,
      },
    ],
  },
  {
    type: "panel",
    title: "Cloud Provider",
    elements: [
      {
        type: "radiogroup",
        name: "isHostingServiceNeeded",
        title: "Do you need GCP project?",
        colCount: 1,
        choices: [
          YesNoChoices.YES,
          YesNoChoices.NO,
        ],
        isRequired: true,
      },
      {
        type: "matrixdynamic",
        minRowCount: 1,
        maxRowCount: 5,
        rowCount: 1,
        width: "max-content",
        name: "gcpProjects",
        title: "GCP project names (upto 5 projects)",
        description: "Use the word \"prod\" if the project is for a production " +
          "environment. E.g. app-name-prod.",
        addRowText: "+ Add Project",
        removeRowText: "\t",
        visibleIf: `{isHostingServiceNeeded}='${YesNoChoices.YES}'`,
        columns: [
          {
            name: "name",
            width: 600,
            cellType: "text",
            isRequired: true,
            defaultValue: applicationName,
            requiredErrorText: "Required",
            validators: getGcpProjectNameValidatorConfig(),
          },
        ],
        validators: getGcpValidatorConfig(),
      },
    ],
  },
  {
    type: "panel",
    title: "Other requirements",
    elements: [
      {
        type: "comment",
        name: "comment",
        title: "Describe your requirement, if you didn't find what you are looking for.",
      },
    ],
    visibleIf: `{isCodeRepositoryNeeded}='${YesNoChoices.NO}' and {isHostingServiceNeeded}='${YesNoChoices.NO}'`,
  },
  {
    type: "html",
    html: "<div>Please follow the instructions on the "
      + `<a class="tw-api-url" href="${thoughtworksApiUserGuideUrl}" target="_blank">API User Guide</a>, `
      + "if you are looking for APIs of Thoughtworks systems.</div>",
    visibleIf: `{isCodeRepositoryNeeded}='${YesNoChoices.NO}' and {isHostingServiceNeeded}='${YesNoChoices.NO}'`,
  },
];

export default getInfraConfigQuestions;

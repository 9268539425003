import { useDispatch } from "react-redux";
import { ApplicationDetailsPageActions } from "../../redux";
import React, { useEffect } from "react";
import { useSelectorTyped } from "../../../../../common/redux/store";
import Analytics from "../../../../../common/services/analytics";
import { ApplicationStatus } from "../../../../../common/data/application-status";
import castTo from "../../../../../utils/cast-to";

export type IEditApplicationStatusController = ReturnType<typeof useEditApplicationStatusController>;

export function useEditApplicationStatusController() {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const applicationInfo = useSelectorTyped((state) => state.applicationDetailsPage.applicationBasicInfoPEV.value!);
  const applicationStatusList = useSelectorTyped((state) => state.applicationStatusListPEV.value);
  const applicationStatusEditedValue = useSelectorTyped((state) => state.applicationDetailsPage.applicationStatusEditedValue);
  const editApplicationStatusPE = useSelectorTyped((state) => state.applicationDetailsPage.editApplicationStatusPE);

  useEffect(() => {
    dispatch(ApplicationDetailsPageActions.updateApplicationStatusEditedValue(applicationInfo.status));
  }, []);

  const isUpdateApplicationStatusInProgress = () => !!editApplicationStatusPE.progress;

  function onClickCancelButton() {
    dispatch(ApplicationDetailsPageActions.updateApplicationStatusEditState(false));
  }

  function onClickConfirmButton() {
    if (applicationStatusEditedValue) {
      dispatch(ApplicationDetailsPageActions.updateApplicationStatus({
        status: applicationStatusEditedValue,
        applicationId: applicationInfo.id,
      }));
      Analytics.trackEventEditApplicationStatus();
    }
  }

  const onApplicationStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(ApplicationDetailsPageActions.updateApplicationStatusEditedValue(
      castTo<ApplicationStatus>(event.target.value)),
    );
  };

  return {
    applicationInfo,
    applicationStatusList,
    onClickCancelButton,
    onClickConfirmButton,
    onApplicationStatusChange,
    isUpdateApplicationStatusInProgress,
  };
}

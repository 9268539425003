import { ActionCreatorWithPayload, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "../../../utils/nullable";

export interface IBasicInfo {
  projectName: string;
  projectDescription: string;
  purpose: string;
  country: string;
  countryCode: string;
  targetAudience: string;
  businessFunction: string;
  projectCategory: string[];
  "projectCategory-Comment": string;
  projectOwner: string[];
  techStack: string[];
  "techStack-Comment": string;
  teamId: string;
  teamName: string;
  hasMnpi: Nullable<boolean>;
  hasPii: Nullable<boolean>;
  hasSensitivePii: Nullable<boolean>;
  billingProjectId?: Nullable<string>;
  billingProjectName?: Nullable<string>;
}

export interface IInfraConfig {
  isCodeRepositoryNeeded: string;
  isCiCdPipelineNeeded: string;
  githubOrganisation: string;
  githubUsername: string;
  isHostingServiceNeeded: string;
  gcpProjectName: string;
  gcpProjects: Array<{ name: string }>;
  comment: string;
}

export type IRegionalItFormData = {
  basicInfo: IBasicInfo;
  infraConfig: IInfraConfig;
};

export const regionalITInitialState: IRegionalItFormData = {
  basicInfo: {
    projectName: "",
    projectDescription: "",
    country: "",
    countryCode: "",
    targetAudience: "",
    businessFunction: "",
    projectCategory: [],
    "projectCategory-Comment": "",
    projectOwner: [],
    techStack: [],
    "techStack-Comment": "",
    teamId: "",
    teamName: "",
    purpose: "",
    hasMnpi: null,
    hasPii: null,
    hasSensitivePii: null,
  },
  infraConfig: {
    isCodeRepositoryNeeded: "",
    githubOrganisation: "",
    githubUsername: "",
    isCiCdPipelineNeeded: "",
    isHostingServiceNeeded: "",
    gcpProjectName: "",
    gcpProjects: [],
    comment: "",
  },
};


const regionalITSlice = createSlice({
  name: "getStartedPage/regionalIT",
  initialState: regionalITInitialState,
  reducers: {
    updateInfraConfig: (state: IRegionalItFormData, action: PayloadAction<Partial<IInfraConfig>>) => {
      state.infraConfig = {
        ...state.infraConfig,
        ...action.payload,
      };
    },
    updateBasicInfo: (state: IRegionalItFormData, action: PayloadAction<Partial<IBasicInfo>>) => {
      state.basicInfo = {
        ...state.basicInfo,
        ...action.payload,
      };
    },
  },
});

export const regionalITReducer = regionalITSlice.reducer;

const updateInfraConfig: ActionCreatorWithPayload<Partial<IInfraConfig>> =
  regionalITSlice.actions.updateInfraConfig;

const updateBasicInfo: ActionCreatorWithPayload<Partial<IBasicInfo>> =
  regionalITSlice.actions.updateBasicInfo;

export const RegionalITCategoryActions = {
  updateInfraConfig,
  updateBasicInfo,
};

import { useState } from "react";
import { DropdownMenuProps } from "../dropdown-menu";


export type Props = Pick<DropdownMenuProps, "dropdownItems" | "children"> & {
  tooltipContent: string;
  ariaLabel?: string;
};

export default function useDropdownMenuWithTooltipController(props: Props) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);

  const toggleDropdownOpen = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return {
    isDropdownOpen,
    toggleDropdownOpen,
    ...props,
  };
}

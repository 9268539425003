import React, { FunctionComponent } from "react";
import "./index.scss";

type Props = {
  stepLabels: string[];
  currentCompletedStep: number;
};

const isStepCompleted = (currentStep: number, currentCompletedStep: number) => currentStep <= currentCompletedStep;

const getCircleStyle = (currentCompletedStep: number, currentStep: number) =>
  (isStepCompleted(currentStep, currentCompletedStep) ? "active" : "");

const renderStep = (step: string, currentCompletedStep: number, currentStep: number) => (
  <li
    className={getCircleStyle(currentCompletedStep, currentStep)}
    key={currentStep}
  >
    {step}
  </li>
);

const StepBar: FunctionComponent<Props> = (props: Props) => (
  <div className={"StepBar"}>
    <div className="step-bar-container">
      <ul className="progressbar">
        {props.stepLabels.map((step, idx) => {
          const currentStep = idx + 1;
          return renderStep(step, props.currentCompletedStep, currentStep);
        })}
      </ul>
    </div>
  </div>
);

export default StepBar;

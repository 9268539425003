import { useGetFeatureToggleState } from "../../hooks/feature-toggles";
import { getEnvVars } from "../../config/env";
import { useEffect } from "react";
import { invokeImmediately } from "../../../utils/functions";
import { useLocation } from "react-router-dom";
import { useSelectorTyped } from "../../redux/store";

export default function useQualarooController() {
  const isViewQualarooNudgeToggleEnabled = useGetFeatureToggleState("Neo_Survey_ViewQualarooNudge");
  const { isApplicationNewlyCreated } = useSelectorTyped((state) => state.applicationDetailsPage);
  const userEmailPEV = useSelectorTyped((state) => state.header.userInfo.email);
  const { pathname } = useLocation();

  useEffect(() => {
    if (isViewQualarooNudgeToggleEnabled) {
      invokeImmediately(function setupAndLoadQualarooSurveyNudge() {
        const scriptId = "qualaroo-nudge-script";
        if (!document.getElementById(scriptId)) {
          const scriptElement = document.createElement("script");
          scriptElement.type = "text/javascript";
          scriptElement.async = true;
          scriptElement.id = scriptId;
          scriptElement.src = getEnvVars().surveyNudgeUrl;
          document.body.append(scriptElement);
        }
      });
    }
  }, [isViewQualarooNudgeToggleEnabled]);

  useEffect(() => {
    if (isViewQualarooNudgeToggleEnabled) {
      /* eslint-disable no-underscore-dangle */
      const qualarooSurvey = window._kiq = window._kiq || [];
      // noinspection TypeScriptValidateJSTypes
      qualarooSurvey.push([
        "identify",
        userEmailPEV.value,
      ]);
    }
  }, [isViewQualarooNudgeToggleEnabled, userEmailPEV]);

  useEffect(() => {
    if (isViewQualarooNudgeToggleEnabled) {
      /* eslint-disable no-underscore-dangle */
      const qualarooSurvey = window._kiq = window._kiq || [];
      // noinspection TypeScriptValidateJSTypes
      qualarooSurvey.push([
        "set",
        { isApplicationNewlyCreated },
      ]);
    }
  }, [isViewQualarooNudgeToggleEnabled, pathname]);


  return {
    isViewQualarooNudgeToggleEnabled,
  };
}

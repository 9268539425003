import React from "react";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import useTaskDetailsController, { TaskDetailsProps } from "./controller";
import BackButton from "../../../common/components/back-button";
import ContainerBox from "../../../common/components/container-box";
import RequestAndTasks from "../request-and-tasks";

const TaskDetails = createFunctionalComponentFromView<ReturnType<typeof useTaskDetailsController>, TaskDetailsProps>(
  useTaskDetailsController,
  (controller) => (
    <div className={"TaskDetails"}>
      <ContainerBox
        componentToRenderInEmptySpace={(
          <BackButton/>
        )}
        isDisplayingBackButton={true}
      >
        <RequestAndTasks
          requestId={controller.requestId}
          requestsPEV={controller.assignedRequestsPEV}
          showActions={true}
          taskDetailTitle={"Task details"}
        />
      </ContainerBox>
    </div>
  ),
);

export default TaskDetails;



import React, { FunctionComponent } from "react";
import "./index.scss";
import BannerImage from "../assets/LandingPageBanner.png";
import NeoLink from "../../../common/components/neo-link";
import Analytics from "../../../common/services/analytics";
import { CarouselConfig } from "../carousel-config";


export type IBannerProps = CarouselConfig["items"][number];

const Banner: FunctionComponent<IBannerProps> = (props: IBannerProps) => {
  const renderLinks = () => {
    if (props.links) {
      return (
        <div className="banner-links">
          {props.links.map(({ url, label, isExternalLink }) =>
            <NeoLink
              key={label}
              link={url}
              label={label}
              external={isExternalLink}
              onClick={() => Analytics.trackEventClickHomePageBannerLinks(props.heading, label)}
            />)}
        </div>
      );
    }
  };

  return (
    <div className="PageBanner">
      <div
        className="banner-container"
        style={{
          color: props.color ?? "white",
          backgroundColor: props.backgroundColor ?? "#634F7D",
        }}
      >
        <div
          className="banner-content"
        >
          <div className="banner-message">
            <h1>{props.heading}</h1>
            <p>
              {props.description}
            </p>
          </div>
          {renderLinks()}
        </div>
        <div
          className="banner-image"
          style={{
            backgroundImage: `url('${props.imageSource ?? BannerImage}')`,
          }}
        />
      </div>
    </div>
  );
};

export default Banner;

import { useSelectorTyped } from "../../redux/store";
import { ITeam, Role } from "../../../pages/team-details/redux";
import { getJoinTeamQuestions } from "./data/questions";
import { getUserEmployeeIdFromUserEmail } from "../../../utils/teams/surveyjs-helper";
import Analytics from "../../services/analytics";

type JoinTeamModelProps = {
  teamName: ITeam["name"];
  pageTitle: string;
  isJoinTeamInProgress: boolean;
  show: boolean;
  onCloseModal: () => void;
  onClickJoinButton: (employeeId: string, role: Role) => void;
};

export default function useJoinTeamController(props: JoinTeamModelProps) {
  const loggedInUserEmailIdPEV = useSelectorTyped((state) => state.header.userInfo.email);
  const potentialMembersPEV = useSelectorTyped((state) => state.teamsPage.potentialMembersPEV);

  const onCloseModal = () => {
    props.onCloseModal();
  };

  const getProgressMessage = () => {
    const shouldDisplayProgress = potentialMembersPEV.progress !== null
      || loggedInUserEmailIdPEV.progress !== null;
    return shouldDisplayProgress ? "Fetching details" : null;
  };

  const getErrorMessage = () => potentialMembersPEV.error || loggedInUserEmailIdPEV.error;

  const shouldRenderErrorMessage = () => !!getErrorMessage();

  return {
    showModal: props.show,
    questions: getJoinTeamQuestions(props.teamName),
    isJoinTeamInProgress: props.isJoinTeamInProgress,
    onCancel: onCloseModal,
    getProgressMessage,
    getErrorMessage,
    shouldRenderForm: () => !getProgressMessage() && !shouldRenderErrorMessage(),
    shouldRenderErrorMessage,
    onCloseModal,
    getInitialFormData: () => ({
      role: "",
    }),
    getJoinButtonLabel: () => props.isJoinTeamInProgress ? "Joining" : "Join",
    onClickJoinButton: (formData: { role: Role }) => {
      props.onClickJoinButton(
        getUserEmployeeIdFromUserEmail(
          loggedInUserEmailIdPEV.value,
          potentialMembersPEV.value,
        ),
        formData.role,
      );
      Analytics.trackEventClickJoinTeamButton(props.pageTitle, formData.role);
    },
  };
}

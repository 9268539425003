import {useEffect, useState} from "react";
import {MENU_IDS} from "./data/constants";
import {useHistory} from "react-router-dom";
import {EventsPages} from "../data/pages";
import {useGetFeatureToggleState} from "../../../common/hooks/feature-toggles";

interface MenuChange {
  detail: { id: string };
}

export default function useEventsSubHeaderController() {
  const [activeMenuId, setActiveMenuId] = useState("");
  const history = useHistory();
  const isOverviewFeatureEnabled = useGetFeatureToggleState("Events_EventsDiscovery_Overview");

  useEffect(() => {
    const path = history.location.pathname;
    const isPathUserGuide = path.includes(EventsPages.EVENTS_USER_GUIDE.path);
    if (isPathUserGuide) {
      setActiveMenuId(MENU_IDS.USER_GUIDE);
      return;
    }
    const isPathEvents = path.includes(EventsPages.EVENTS_OVERVIEW.path);
    const isPathEventDetails = path.includes(EventsPages.EVENTS_OVERVIEW.path) && path.length > EventsPages.EVENTS_OVERVIEW.path.length;
    const isPathDiscovery = path.includes(EventsPages.EVENTS_DISCOVERY.path);
    if ((isOverviewFeatureEnabled && isPathDiscovery) ||
        (isOverviewFeatureEnabled && isPathEventDetails) ||
        (!isOverviewFeatureEnabled && isPathEvents))  {
      setActiveMenuId(MENU_IDS.DISCOVER_EVENTS);
      return;
    }

    if(isOverviewFeatureEnabled && isPathEvents){
      setActiveMenuId(MENU_IDS.EVENTS_OVERVIEW);
      return;
    }

  }, [history.location, isOverviewFeatureEnabled]);

  const eventRoutes = {
    [MENU_IDS.EVENTS_OVERVIEW]: EventsPages.EVENTS_OVERVIEW.path,
    [MENU_IDS.DISCOVER_EVENTS]: isOverviewFeatureEnabled ? EventsPages.EVENTS_DISCOVERY.path : EventsPages.EVENTS_OVERVIEW.path,
    [MENU_IDS.USER_GUIDE]: EventsPages.EVENTS_USER_GUIDE.path,
  };
  const handleEventTabClick = (event: MenuChange) => {
    const clickedMenuId = event.detail.id;
    setActiveMenuId(clickedMenuId);
    // @ts-ignore
    history.push(eventRoutes[clickedMenuId]);
  };

  return ({
    handleEventTabClick,
    activeMenuId,
  });
}

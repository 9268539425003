import { useSelectorTyped } from "../../redux/store";

type RegionalITTeamUnAuthorizedMessageProps = {
  errorMessage: string;
};

export default function useRegionalITTeamUnAuthorizedMessageController(props: RegionalITTeamUnAuthorizedMessageProps) {
  const uiConfig = useSelectorTyped((state) => state.header.uiConfigsPEV.value);

  return ({
    errorMessage: props.errorMessage,
    neoSupportEmail: uiConfig.emails.neoSupportEmail,
    regionalItLeadsMasterDataLink: uiConfig.internalLinks.regionalItLeadsMasterDataLink,
  });
}

import { useDispatch } from "react-redux";
import { experimentsAndInnovationsCategoryActions, IInfraConfig } from "../redux";
import { useSelectorTyped } from "../../../../common/redux/store";
import { stepLabels } from "../../utils/step-labels";
import getQuestions from "./data/questions";
import { useGetFeatureToggleState } from "../../../../common/hooks/feature-toggles";

export default function useInfraConfigController() {
  const dispatch = useDispatch();
  const infraConfigFormData = useSelectorTyped((state) => state.getStartedPage.experimentsAndInnovations.infraConfig);
  const { projectName, teamName, teamId, billingProjectId } = useSelectorTyped((state) => state.getStartedPage.experimentsAndInnovations.basicInfo);
  const isDisplayGCPBudgetAlertToggleEnabled = useGetFeatureToggleState("Neo_Applications_DisplayBudgetAlert");

  return {
    questions: getQuestions(projectName, !!billingProjectId, isDisplayGCPBudgetAlertToggleEnabled),
    teamDetails: { teamName: teamName ?? "", teamId: teamId ?? "" },
    infraConfigFormData,
    shouldGoToNextStep: (formData: Partial<IInfraConfig>) => {
      dispatch(experimentsAndInnovationsCategoryActions.updateInfraConfig(formData));
      return true;
    },
    getStepLabelsForPage: () => stepLabels,
    shouldGoToPreviousStep: (formData: Partial<IInfraConfig>) => {
      dispatch(experimentsAndInnovationsCategoryActions.updateInfraConfig(formData));
      return true;
    },
  };
}

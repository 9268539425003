import { Placement } from "react-bootstrap/Overlay";
import { ReactNode, useState } from "react";

export type TooltipProps = {
  id: string;
  placement?: Placement;
  content: ReactNode;
  enabled?: boolean;
  className?: string;
};

export default function useTooltipController(props: TooltipProps) {
  const [show, setShow] = useState(false);
  const tooltipEnabled = props.enabled ?? true;

  const onToggle = () => {
    setShow(!show);
  };

  const shouldRenderTooltip = () => tooltipEnabled && show;

  return {
    shouldRenderTooltip,
    onToggle,
    ...props,
  };
}

import React from "react";
import "./index.scss";
import ContainerBox from "../../../common/components/container-box";
import useDeveloperToolsController from "./controller";
import Progress from "../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../common/components/error-new";
import { Col, Row } from "react-bootstrap";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import DeveloperToolCard from "../../../common/components/developer-tool-card";
import { DeveloperTool } from "./redux";
import NeoLink from "../../../common/components/neo-link";

const DeveloperTools = createFunctionalComponentFromView(useDeveloperToolsController, (controller) => {
  const renderDeveloperToolsList = (developerTools: DeveloperTool[]) => (
    <Row className="developer-tools-list">
      {developerTools
        .map((developerTool, index) => (
          <Col
            className="developer-tools-column"
            key={index}
            xs={3}
            md={3}
          >
            <DeveloperToolCard
              developerTool={developerTool}
              onClick={() => controller.onClickDeveloperToolCard(developerTool)}
            />
          </Col>
        ))}
    </Row>
  );

  const renderDeveloperToolsByCategory = () =>
    Object.entries(controller.developerToolsByCategory).map(([category, developerTools], index) => {
      if (developerTools.length > 0) {
        return (
          <div
            className="developer-tools-by-category"
            key={index}
          >
            <h5 className={"category"}>{category}</h5>
            {renderDeveloperToolsList(developerTools)}
          </div>
        );
      }
    });

  const renderDeveloperToolsContent = () => {
    if (controller.shouldRenderProgressMessage()) {
      return <Progress text={controller.fetchDeveloperToolsProgress}/>;
    }
    if (controller.shouldRenderErrorMessage()) {
      return (
        <Error
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          {...mapApplicationErrorInfoToErrorComponentProps(controller.fetchDeveloperToolsError!)}
          onClick={controller.retryFetchDeveloperTools}
        />
      );
    }
    if (controller.shouldRenderNoDeveloperToolsExistMessage()) {
      return (
        <p>
          There are no developer tools published yet. Go to API Platform to gain access to the relevant API and then publish your tools.
        </p>
      );
    }
    return renderDeveloperToolsByCategory();
  };

  return (
    <div className="DeveloperTools">
      <ContainerBox>
        <h4 className="developer-tools-title">Developer Tools</h4>
        <p className="developer-tools-description">
          {controller.description}
          <div>
            <NeoLink
              link={controller.helpDocumentLink}
              label="Link to help document"
              external
            />
          </div>
        </p>
        <div className="developer-tools-content">
          {renderDeveloperToolsContent()}
        </div>
      </ContainerBox>
    </div>
  );
});

export default DeveloperTools;


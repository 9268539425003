import { ActionCreatorWithPayload, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PEV, ProgressErrorValue } from "../../../utils/progress-error-value";
import * as service from "./service";
import * as teamService from "../../team-details/service";
import { PE, ProgressError } from "../../../utils/progress-error";
import { Nullable } from "../../../utils/nullable";
import { PES, ProgressErrorSuccess } from "../../../utils/progress-error-success";
import { ApplicationStatus } from "../../../common/data/application-status";
import { ApplicationPurpose } from "../../../common/data/application-purpose";
import { INeoErrorInfo, NeoError } from "../../../utils/errors/neo-errors";
import { createAsyncThunkWithRejectValue } from "../../../common/redux/createAsyncThunkWithRejectValue";
import { IAddOneTeamMemberRequest, ITeam } from "../../team-details/redux";
import { IProject } from "../../../common/redux/slices/projects";
import { RequestClosedEventPayload, RequestEventTypeLabelMap, RequestInProgressEventPayload, RequestRaisedEventPayload } from "../../requests/redux";
import { iif } from "../../../utils/functions";
import { IRequest } from "../../requests/types/requests"; // TODO Change country to countryCode

// TODO Change country to countryCode
export type ApplicationBasicInfo = {
  id: string;
  name: string;
  description: string;
  country: string;
  purpose: ApplicationPurpose | string;
  targetAudience: string;
  businessFunction: string;
  category: string[];
  owner: string[];
  techStack: string[];
  status: ApplicationStatus;
  countryName: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  isEditable: boolean;
  isDataClassificationEditable: boolean;
  isStatusEditable: boolean;
  isActive: boolean;
  canDecommissionApplication: boolean;
  canRequestAdditionalSupport: boolean;
  hasMnpi: boolean;
  hasPii: boolean;
  hasSensitivePii: boolean;
  decommissionReason: Nullable<string>;
  /**
   * @deprecated The field should not be used
   */
  teamId: ITeam["id"];
  /**
   * @deprecated The field should not be used
   */
  teamType: ITeam["teamType"];
  team: {
    id: ITeam["id"];
    name: ITeam["name"];
    description: ITeam["description"];
    status: ITeam["status"];
    supportEmail: ITeam["supportEmail"];
    teamType: ITeam["teamType"];
    contactEmail: ITeam["contactEmail"];
    isOpen: ITeam["isOpen"];
    canJoinTeam: boolean;
    canExitTeam: boolean;
    members: ITeam["members"];
  };
  lifespan: {
    canShowApplicationLifespan: boolean;
    canShowIndicator: boolean;
    canShowExtendOption: boolean;
    canShowApplicationLifespanInEditPage: boolean;
    canEditApplicationLifespan: boolean;
  };
  endDate: Nullable<DateOnly>;
  project: Nullable<IProject>;
};

export enum OktaChicletType {
  OIDC = "OIDC",
  SPA = "SPA",
  SAML = "SAML",
}

export interface IRequestedAdditionalSupport {
  zendeskResourceRequests: IRequest[];
  decommissionRequests: Array<Pick<IRequest, "id">>;
}

export interface IDecommissionApplicationResponse {
  decommissionRequests: Array<Pick<IRequest, "id">>;
  application: Pick<ApplicationBasicInfo, "decommissionReason" | "status" | "isActive">;
}

export interface ICreateTicketResponseData {
  id: string;
  subject: string;
  description: string;
  status: string;
  cc: Email[];
  tags: string[];
}

export interface IRequestZendeskResourceResponse {
  request: IRequest;
  ticket: ICreateTicketResponseData;
}

export interface EditApplicationInfoForm extends ApplicationBasicInfo {
  "techStack-Comment": string;
  "category-Comment": string;
}

export type UpdateApplicationData = {
  name?: ApplicationBasicInfo["name"];
  description?: ApplicationBasicInfo["description"];
  targetAudience?: ApplicationBasicInfo["targetAudience"];
  country?: ApplicationBasicInfo["country"];
  businessFunction?: ApplicationBasicInfo["name"];
  category?: ApplicationBasicInfo["category"];
  owner?: ApplicationBasicInfo["owner"];
  techStack?: ApplicationBasicInfo["techStack"];
  purpose?: ApplicationBasicInfo["purpose"];
  status?: ApplicationBasicInfo["status"];
  hasMnpi?: ApplicationBasicInfo["hasMnpi"];
  hasPii?: ApplicationBasicInfo["hasPii"];
  hasSensitivePii?: ApplicationBasicInfo["hasSensitivePii"];
  endDate?: ApplicationBasicInfo["endDate"];
};

export type SamlOktaChicletRequest = {
  chicletName: string;
  chicletType: OktaChicletType.SAML;
  ssoUrl: string;
  audienceUrl: string;
};

export type OidcOktaChicletRequest = {
  chicletName: string;
  chicletType: OktaChicletType.OIDC;
  initiateLoginUrl: string;
  signInRedirectUrl: string;
  signOutRedirectUrl: string;
};

export type SpaOktaChicletRequest = {
  chicletName: string;
  chicletType: OktaChicletType.SPA;
  initiateLoginUrl: string;
  signInRedirectUrl: string;
  signOutRedirectUrl: string;
};

export type OktaChicletRequest = SamlOktaChicletRequest | OidcOktaChicletRequest | SpaOktaChicletRequest;

export interface RequestAdditionalInfrastructureData {
  repositories?: string[];
  cloudProjects?: string[];
  oktaChiclet?: OktaChicletRequest;
}

export interface RequestDecommissionApplicationData {
  reason: string;
}

export interface RequestZendeskResourceData {
  resourceType: string;
  resourceDetails: Record<string, string>;
}

export type InfrastructureDetails = {
  id: string;
  githubRepositories: string[];
  ciCdProvider: string;
  cloudProviderType: string;
  cloudAccount: string[];
  githubOrg: string;
  pipelines: string[];
  createdBy: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  isEditable: boolean;
  canRequestAdditionalInfra: boolean;
  canShowBillingProjectNotAssociatedAlert: boolean;
  oktaChiclets: Array<{
    authenticationType: string;
    url: string;
    chicletLabel: string;
  }>;
  requestedInfra: {
    showTaskDetailsPageUrl: boolean;
    gcpProjects: Array<{
      requestId: string;
      name: string;
    }>;
    repositories: Array<{
      requestId: string;
      name: string;
    }>;
    oktaChiclets: Array<{
      name: string;
      type: OktaChicletType;
      requestId: string;
    }>;
  };
};

export type ApplicationInformation = {
  application: ApplicationBasicInfo;
  infrastructure: InfrastructureDetails;
};

export enum ApplicationEventTypeLabelMap {
  APPLICATION_CREATED = "application-created",
  APPLICATION_DETAILS_UPDATED = "application-details-updated",
  APPLICATION_LIFESPAN_END = "application-lifespan-end",
  APPLICATION_DECOMMISSIONED = "application-decommissioned",
  INFRASTRUCTURE_REQUESTED_FOR_APPLICATION = "infrastructure-requested-for-application",
  APPLICATION_PREPARED_FOR_DEVELOPMENT = "application-prepared-for-development",
  APPLICATION_ENTERED_DEVELOPMENT = "application-entered-development",
  APPLICATION_SECURITY_REVIEW_REQUESTED = "application-security-review-requested",
  APPLICATION_ENTERED_SECURITY_REVIEW = "application-entered-security-review",
  APPLICATION_MOVED_TO_PRODUCTION = "application-moved-to-production",
  APPLICATION_PUT_ON_HOLD = "application-put-on-hold",
  APPLICATION_DISAPPROVED = "application-disapproved"
}

export type EventTypeLabelMap = ApplicationEventTypeLabelMap | RequestEventTypeLabelMap;

export type ApplicationCreatedEventPayload = {
  id: string;
  name: string;
  description: string;
  country: {
    code: string;
    name: string;
  };
  targetAudience: string;
  businessFunction: string;
  category: string[];
  owner: string[];
  techStack: string[];
  hasMnpi: boolean;
  hasPii: boolean;
  hasSensitivePii: boolean;
  endDate?: Nullable<DateOnly>;
  status: ApplicationStatus;
  purpose: ApplicationPurpose;
  team: ITeam;
  project: IProject & { isActive: boolean; isDeleted: boolean };
  createdBy: string;
  createdAt: Date;
};

export type ApplicationDetailsUpdatedEventPayload = {
  id: string;
  name?: string;
  description?: string;
  country?: {
    code: string;
    name: string;
  };
  targetAudience?: string;
  businessFunction?: string;
  category: string[];
  owner: string[];
  techStack: string[];
  hasMnpi?: boolean;
  hasPii?: boolean;
  hasSensitivePii?: boolean;
  endDate?: Nullable<DateOnly>;
  status?: ApplicationStatus;
  purpose?: ApplicationPurpose;
  project: IProject & { isActive: boolean; isDeleted: boolean };
  updatedBy: string;
  updatedAt: Date;
};

export type ApplicationDeletedEventPayload = {
  id: string;
  name: string;
  description: string;
  country: {
    code: string;
    name: string;
  };
  targetAudience: string;
  businessFunction: string;
  category: string[];
  owner: string[];
  techStack: string[];
  hasMnpi: boolean;
  hasPii: boolean;
  hasSensitivePii: boolean;
  endDate?: Nullable<DateOnly>;
  status: ApplicationStatus;
  purpose: ApplicationPurpose;
  team: ITeam;
  project: IProject & { isActive: boolean; isDeleted: boolean };
  createdBy: string;
  createdAt: Date;
  updatedBy: string;
  updatedAt: Date;
};
export type NotificationRecipients = Array<"$TEAM_MEMBERS" | "$REGIONAL_IT_LEADS" | string>;

export type ApplicationLifespanEndEventPayload = {
  id: string;
  name: string;
  numberOfDaysForApplicationEnd: number;
  notifiers: {
    to: NotificationRecipients;
    cc: NotificationRecipients;
  };
  endDate: string;
  triggeredBy: string;
};

export type ApplicationDecommissionedEventPayload = {
  id: string;
  status: ApplicationStatus;
  decommissionReason: string;
  updatedBy: string;
  updatedAt: Date;
};

export type ApplicationStatusChangeEventPayload = {
  id: string;
  status: ApplicationStatus;
  updatedBy: string;
  updatedAt: Date;
};

export type ApplicationEventPayload =
  ApplicationCreatedEventPayload
  | ApplicationDetailsUpdatedEventPayload
  | ApplicationDeletedEventPayload
  | ApplicationLifespanEndEventPayload
  | ApplicationDecommissionedEventPayload
  | RequestRaisedEventPayload
  | RequestInProgressEventPayload
  | RequestClosedEventPayload
  | ApplicationStatusChangeEventPayload;

export interface IApplicationEvent {
  id: Uuid;
  groupingKey: string;
  payload: ApplicationEventPayload;
  type: EventTypeLabelMap;
  retry: number;
  createdDateTime: string;
  updatedDateTime: string;
  isPublished?: boolean;
  publishedDateTime?: string;
}

interface IApplicationDetailsPageState {
  applicationBasicInfoPEV: ProgressErrorValue<Nullable<ApplicationBasicInfo>, Nullable<string>, Nullable<INeoErrorInfo>>;
  infrastructureInfoPEV: ProgressErrorValue<Nullable<InfrastructureDetails>, Nullable<string>, Nullable<INeoErrorInfo>>;
  activitiesPEV: ProgressErrorValue<IApplicationEvent[], Nullable<string>, Nullable<INeoErrorInfo>>;
  requestedAdditionalSupportPEV: ProgressErrorValue<Nullable<IRequestedAdditionalSupport>, Nullable<string>, Nullable<INeoErrorInfo>>;
  editApplicationDetailsPE: ProgressError;
  editApplicationNamePE: ProgressError;
  editApplicationStatusPE: ProgressError;
  isApplicationDetailsInEditMode: boolean;
  idOfApplicationBeingFetched: string;
  gcpProjectCreationPES: ProgressErrorSuccess;
  githubRepoCreationPES: ProgressErrorSuccess;
  applicationStatusEditedValue: Nullable<ApplicationBasicInfo["status"]>;
  isApplicationNameInEditMode: boolean;
  isApplicationStatusInEditMode: boolean;
  joinTeamPE: ProgressError;
  exitTeamPE: ProgressError;
  showJoinTeamModal: boolean;
  requestAdditionalInfraPES: ProgressErrorSuccess;
  requestDecommissionApplicationPES: ProgressErrorSuccess;
  requestZendeskResourcePES: ProgressErrorSuccess;
  idOfApplicationActivitiesDisplayed: string;
  idOfApplicationAdditionalSupportBeingFetched: string;
  isApplicationNewlyCreated: boolean;
}

const initialState: IApplicationDetailsPageState = {
  applicationBasicInfoPEV: PEV(null),
  infrastructureInfoPEV: PEV(null),
  activitiesPEV: PEV([]),
  requestedAdditionalSupportPEV: PEV(null),
  editApplicationDetailsPE: PE(),
  editApplicationNamePE: PE(),
  editApplicationStatusPE: PE(),
  isApplicationDetailsInEditMode: false,
  isApplicationNameInEditMode: false,
  isApplicationStatusInEditMode: false,
  gcpProjectCreationPES: PES(),
  githubRepoCreationPES: PES(),
  applicationStatusEditedValue: null,
  idOfApplicationBeingFetched: "",
  idOfApplicationActivitiesDisplayed: "",
  idOfApplicationAdditionalSupportBeingFetched: "",
  joinTeamPE: PE(),
  showJoinTeamModal: false,
  requestAdditionalInfraPES: PES(),
  requestDecommissionApplicationPES: PES(),
  requestZendeskResourcePES: PES(),
  exitTeamPE: PE(),
  isApplicationNewlyCreated: false,
};

const fetchApplicationDetails = createAsyncThunkWithRejectValue(
  "applicationDetails/fetch",
  (payload: { applicationId: string }) => service.fetchApplication(payload.applicationId),
);

const fetchInfrastructureInfo = createAsyncThunkWithRejectValue(
  "infrastructureDetails/fetch",
  (payload: { applicationId: string }) => service.fetchInfrastructure(payload.applicationId),
);

const fetchActivitiesOfApplication = createAsyncThunkWithRejectValue(
  "/activities/application/:applicationId",
  (applicationId: string) => service.fetchActivitiesOfApplication(applicationId),
);

const fetchRequestedAdditionalSupport = createAsyncThunkWithRejectValue(
  "/additional-support/application/:applicationId",
  (payload: {
    applicationId: string;
    requestTypes?: string[];
  }) => service.fetchRequestedAdditionalSupportForApplication(payload.applicationId, payload.requestTypes),
);

const updateApplicationInformation = createAsyncThunk(
  "applicationDetails/update",
  (payload: {
    updatedApplicationInformation: UpdateApplicationData;
    applicationId: string;
    existingApplicationData?: ApplicationBasicInfo;
  }) => {
    const { updatedApplicationInformation, applicationId, existingApplicationData } = payload;
    return Object.keys(updatedApplicationInformation).length > 0
      ? service.updateApplicationInformation(updatedApplicationInformation, applicationId)
      : {
        application: existingApplicationData,
      };
  },
);

const requestAdditionalInfrastructure = createAsyncThunk(
  "infrastructureDetails/update",
  (payload: {
    data: RequestAdditionalInfrastructureData;
    applicationId: string;
  }) => {
    const { data, applicationId } = payload;
    return service.requestAdditionalInfra(applicationId, data);
  },
);

const requestDecommissionApplication = createAsyncThunk(
  "application/:applicationId/decommission",
  (payload: {
    data: RequestDecommissionApplicationData;
    applicationId: string;
  }) => {
    const { data, applicationId } = payload;
    return service.requestDecommissionApplication(applicationId, data);
  },
);

const requestZendeskResource = createAsyncThunk(
  "application/:applicationId/request-zendesk-resource",
  (payload: {
    data: RequestZendeskResourceData;
    applicationId: string;
  }) => {
    const { data, applicationId } = payload;
    return service.requestZendeskResource(applicationId, data);
  },
);

const updateApplicationName = createAsyncThunk(
  "applicationDetails/name/update",
  async (payload: { name: UpdateApplicationData["name"]; applicationId: string }) => {
    const { name, applicationId } = payload;
    return service.updateApplicationInformation({ name }, applicationId);
  },
);

const updateApplicationStatus = createAsyncThunk(
  "applicationDetails/status/update",
  (payload: { status: UpdateApplicationData["status"]; applicationId: string }) => {
    const { status, applicationId } = payload;
    return service.updateApplicationInformation({ status }, applicationId);
  },
);

const triggerGCPProjectCreation = createAsyncThunk(
  "application/:applicationId/infrastructure/gcp-project",
  (applicationId: string) => service.triggerGCPProjectCreation(applicationId),
);

const triggerGithubRepoCreation = createAsyncThunk(
  "application/:applicationId/infrastructure/github-repository",
  (applicationId: string) => service.triggerGithubRepositoryCreation(applicationId),
);

const joinTeam = createAsyncThunk(
  "join-team",
  async (payload: { teamId: string; memberData: IAddOneTeamMemberRequest; applicationId: string }) => {
    const { memberData, teamId, applicationId } = payload;
    await teamService.joinTeam(teamId, memberData);
    return service.fetchApplication(applicationId);
  },
);

const exitTeam = createAsyncThunk(
  "exit-team",
  async (payload: { teamId: string; employeeId: string; applicationId: string }) => {
    const { employeeId, teamId, applicationId } = payload;
    await teamService.exitTeam(teamId, employeeId);
    return service.fetchApplication(applicationId);
  },
);

const isFetchingApplicationDetailsActionCompleted = <ActionPayload>(
  idOfApplicationInProgress: string,
  action: PayloadAction<ActionPayload | ApplicationBasicInfo | NeoError | InfrastructureDetails | never, never, { arg: { applicationId: string } }>,
) => {
  const applicationIdFromResponse = action.meta.arg.applicationId;
  return idOfApplicationInProgress === applicationIdFromResponse;
};

export const applicationDetailsPageSlice = createSlice({
  name: "applicationDetailsPage",
  initialState,
  reducers: {
    updateApplicationDetailsPage:
      (state: IApplicationDetailsPageState, action: PayloadAction<Partial<IApplicationDetailsPageState>>) => ({
        ...state,
        ...action.payload,
      }),
  },
  extraReducers: {
    [fetchApplicationDetails.pending.type]: (state, action: PayloadAction<ApplicationBasicInfo, string, { arg: { applicationId: string } }>) => {
      function isCurrentFetchAppRequestSameAsExistingApp() {
        return action.meta.arg.applicationId === state.applicationBasicInfoPEV?.value?.id;
      }

      if (isCurrentFetchAppRequestSameAsExistingApp()) {
        state.applicationBasicInfoPEV = PEV(state.applicationBasicInfoPEV.value);
      } else {
        state.applicationBasicInfoPEV = PEV(null, "Fetching application details.");
      }
      state.idOfApplicationBeingFetched = action.meta.arg.applicationId;
    },
    [fetchApplicationDetails.fulfilled.type]: (
      state,
      action: PayloadAction<ApplicationBasicInfo, never, { arg: { applicationId: string } }>,
    ) => {
      if (isFetchingApplicationDetailsActionCompleted(state.idOfApplicationBeingFetched, action)) {
        state.applicationBasicInfoPEV = PEV(action.payload);
      }
    },
    [fetchApplicationDetails.rejected.type]: (
      state,
      action: PayloadAction<NeoError, never, { arg: { applicationId: string } }>,
    ) => {
      if (isFetchingApplicationDetailsActionCompleted(state.idOfApplicationBeingFetched, action)) {
        state.applicationBasicInfoPEV.progress = null;
        state.applicationBasicInfoPEV.error = action.payload.getErrorInfo();
        state.applicationBasicInfoPEV.value = null;
      }
    },
    [fetchInfrastructureInfo.pending.type]: (
      state,
      action: PayloadAction<never, never, { arg: { applicationId: string } }>,
    ) => {
      state.infrastructureInfoPEV = PEV(null, "Fetching infrastructure details.");
      state.idOfApplicationBeingFetched = action.meta.arg.applicationId;
    },
    [fetchInfrastructureInfo.fulfilled.type]: (
      state,
      action: PayloadAction<InfrastructureDetails, never, { arg: { applicationId: string } }>,
    ) => {
      if (isFetchingApplicationDetailsActionCompleted(state.idOfApplicationBeingFetched, action)) {
        state.infrastructureInfoPEV = PEV(action.payload);
      }
    },
    [fetchInfrastructureInfo.rejected.type]: (state, action: PayloadAction<NeoError, never, { arg: { applicationId: string } }>) => {
      if (isFetchingApplicationDetailsActionCompleted(state.idOfApplicationBeingFetched, action)) {
        state.infrastructureInfoPEV.progress = null;
        state.infrastructureInfoPEV.error = action.payload.getErrorInfo();
        state.infrastructureInfoPEV.value = null;
      }
    },
    [fetchActivitiesOfApplication.pending.type]: (state, action: PayloadAction<IApplicationEvent[], never, { arg: string }>) => {
      if (iif(function isCurrentFetchAppActivitiesRequestDifferentThanExistingApp() {
        const currentApplicationId = state.applicationBasicInfoPEV.value?.id;
        return currentApplicationId === action.meta.arg && currentApplicationId !== state.idOfApplicationActivitiesDisplayed;
      })) {
        state.activitiesPEV = PEV([], "Fetching activities.");
        state.idOfApplicationActivitiesDisplayed = action.meta.arg;
      }
    },
    [fetchActivitiesOfApplication.fulfilled.type]: (state, action: PayloadAction<IApplicationEvent[], never, { arg: string }>) => {
      if (iif(function isCurrentCompletedAppActivitiesRequestSameAsExistingApp() {
        return action.meta.arg === state.idOfApplicationActivitiesDisplayed;
      })) {
        state.activitiesPEV = PEV(action.payload);
      }
    },
    [fetchActivitiesOfApplication.rejected.type]: (state, action: PayloadAction<NeoError, never, { arg: string }>) => {
      if (iif(function isCurrentCompletedAppActivitiesRequestSameAsExistingApp() {
        return action.meta.arg === state.idOfApplicationActivitiesDisplayed;
      })) {
        state.activitiesPEV = PEV([], null, action.payload.getErrorInfo());
      }
    },
    [fetchRequestedAdditionalSupport.pending.type]: (
      state,
      action: PayloadAction<IRequestedAdditionalSupport, never, { arg: { applicationId: string } }>,
    ) => {
      if (iif(function isCurrentFetchAdditionalSupportSameAsExistingApp() {
        return action.meta.arg.applicationId === state.applicationBasicInfoPEV?.value?.id && state.requestedAdditionalSupportPEV.value;
      })) {
        state.requestedAdditionalSupportPEV = PEV(state.requestedAdditionalSupportPEV.value);
      } else {
        state.requestedAdditionalSupportPEV = PEV(
          state.requestedAdditionalSupportPEV.value,
          "Fetching additional support.",
        );
      }
      state.idOfApplicationAdditionalSupportBeingFetched = action.meta.arg.applicationId;
    },
    [fetchRequestedAdditionalSupport.fulfilled.type]: (
      state,
      action: PayloadAction<IRequestedAdditionalSupport, never, { arg: { applicationId: string } }>,
    ) => {
      if (iif(function isCurrentCompletedAppAdditionalSupportRequestSameAsExistingApp() {
        return action.meta.arg.applicationId === state.idOfApplicationAdditionalSupportBeingFetched;
      })) {
        state.requestedAdditionalSupportPEV = PEV(action.payload);
      }
    },
    [fetchRequestedAdditionalSupport.rejected.type]: (
      state,
      action: PayloadAction<NeoError, never, { arg: { applicationId: string } }>,
    ) => {
      if (iif(function isCurrentCompletedAppAdditionalSupportRequestSameAsExistingApp() {
        return action.meta.arg.applicationId === state.idOfApplicationAdditionalSupportBeingFetched;
      })) {
        state.requestedAdditionalSupportPEV = PEV(
          state.requestedAdditionalSupportPEV.value,
          null,
          action.payload.getErrorInfo(),
        );
      }
    },
    [updateApplicationInformation.pending.type]: (state) => {
      state.editApplicationDetailsPE = PE("Update application information is in progress", null);
    },
    [updateApplicationInformation.fulfilled.type]: (state, action: PayloadAction<ApplicationInformation, never>) => {
      state.editApplicationDetailsPE = PE();
      // isEditable state will not be present in the response
      state.applicationBasicInfoPEV.value = {
        ...state.applicationBasicInfoPEV.value,
        ...action.payload.application,
      };
      state.infrastructureInfoPEV.value = action.payload.infrastructure ?? state.infrastructureInfoPEV.value;
      state.isApplicationDetailsInEditMode = false;
    },
    [updateApplicationInformation.rejected.type]: (state) => {
      state.editApplicationDetailsPE = PE(null, "Error while updating application details");
    },
    [updateApplicationName.pending.type]: (state) => {
      state.editApplicationNamePE = PE("Update application name is in progress", null);
    },
    [updateApplicationName.fulfilled.type]: (state, action: PayloadAction<ApplicationInformation, never>) => {
      state.editApplicationNamePE = PE();
      // isEditable state will not be present in the response
      state.applicationBasicInfoPEV.value = {
        ...state.applicationBasicInfoPEV.value,
        ...action.payload.application,
      };
      state.infrastructureInfoPEV.value = action.payload.infrastructure;
      state.isApplicationNameInEditMode = false;
    },
    [updateApplicationName.rejected.type]: (state) => {
      state.editApplicationNamePE = PE(null, "Error while updating application name");
    },
    [updateApplicationStatus.pending.type]: (state) => {
      state.editApplicationStatusPE = PE("Update application status is in progress", null);
    },
    [updateApplicationStatus.fulfilled.type]: (state, action: PayloadAction<ApplicationInformation, never>) => {
      state.editApplicationStatusPE = PE();
      // isEditable state will not be present in the response
      state.applicationBasicInfoPEV.value = {
        ...state.applicationBasicInfoPEV.value,
        ...action.payload.application,
      };
      state.infrastructureInfoPEV.value = action.payload.infrastructure;
      state.isApplicationStatusInEditMode = false;
    },
    [updateApplicationStatus.rejected.type]: (state) => {
      state.editApplicationStatusPE = PE(null, "Error while updating application status");
    },
    [triggerGCPProjectCreation.pending.type]: (state) => {
      state.gcpProjectCreationPES = PES("GCP project creation is in progress");
    },
    [triggerGCPProjectCreation.fulfilled.type]: (state) => {
      state.gcpProjectCreationPES = PES(null, null, true);
    },
    [triggerGCPProjectCreation.rejected.type]: (state) => {
      state.gcpProjectCreationPES = PES(null, "Error while triggering GCP project creation");
    },
    [triggerGithubRepoCreation.pending.type]: (state) => {
      state.githubRepoCreationPES = PES("Github repository creation is in progress");
    },
    [triggerGithubRepoCreation.fulfilled.type]: (state) => {
      state.githubRepoCreationPES = PES(null, null, true);
    },
    [triggerGithubRepoCreation.rejected.type]: (state) => {
      state.githubRepoCreationPES = PES(null, "Error while triggering github repository creation");
    },
    [joinTeam.pending.type]: (state) => {
      state.joinTeamPE = PE("Joining to the team.", null);
    },
    [joinTeam.fulfilled.type]: (state, action: PayloadAction<ApplicationBasicInfo>) => {
      state.applicationBasicInfoPEV = PEV({
        ...state.applicationBasicInfoPEV.value,
        ...action.payload,
      });
      state.joinTeamPE = PE();
      state.showJoinTeamModal = false;
    },
    [joinTeam.rejected.type]: (state, action: PayloadAction<never, never, never, Error>) => {
      state.joinTeamPE = PE(null, `Error occurred while joining the team (${action.error.message})`);
      state.showJoinTeamModal = false;
    },
    [exitTeam.pending.type]: (state) => {
      state.exitTeamPE = PE("Exiting team.", null);
    },
    [exitTeam.fulfilled.type]: (state, action: PayloadAction<ApplicationBasicInfo>) => {
      state.applicationBasicInfoPEV = PEV({
        ...state.applicationBasicInfoPEV.value,
        ...action.payload,
      });
      state.exitTeamPE = PE(null, null);
    },
    [exitTeam.rejected.type]: (state, action: PayloadAction<never, never, never, Error>) => {
      state.exitTeamPE = PE(null, `Error occurred while exiting team (${action.error.message})`);
    },
    [requestAdditionalInfrastructure.pending.type]: (state) => {
      state.requestAdditionalInfraPES = PES("Request additional infra is in progress", null);
    },
    [requestAdditionalInfrastructure.fulfilled.type]: (state, action: PayloadAction<InfrastructureDetails, never>) => {
      state.requestAdditionalInfraPES = PES(null, null, true);
      state.infrastructureInfoPEV.value = action.payload;
    },
    [requestAdditionalInfrastructure.rejected.type]: (state) => {
      state.requestAdditionalInfraPES = PES(null, "Error while requesting additional infra");
    },
    [requestDecommissionApplication.pending.type]: (state) => {
      state.requestDecommissionApplicationPES = PES("Raising decommission request is in progress", null);
    },
    [requestDecommissionApplication.fulfilled.type]: (state, action: PayloadAction<IDecommissionApplicationResponse, never>) => {
      const { application, decommissionRequests } = action.payload;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state.requestedAdditionalSupportPEV.value!.decommissionRequests = decommissionRequests;
      state.applicationBasicInfoPEV = PEV({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...state.applicationBasicInfoPEV.value!,
        ...application,
      });
      state.requestDecommissionApplicationPES = PES(null, null, true);
    },
    [requestDecommissionApplication.rejected.type]: (state) => {
      state.requestDecommissionApplicationPES = PES(null, "Error while raising decommission request");
    },
    [requestZendeskResource.pending.type]: (state) => {
      state.requestZendeskResourcePES = PES("Raising additional resource request is in progress", null);
    },
    [requestZendeskResource.fulfilled.type]: (state, action: PayloadAction<IRequestZendeskResourceResponse, never>) => {
      state.requestedAdditionalSupportPEV.value?.zendeskResourceRequests.push(action.payload.request);
      state.requestZendeskResourcePES = PES(null, null, true);
    },
    [requestZendeskResource.rejected.type]: (state) => {
      state.requestZendeskResourcePES = PES(null, "Error while raising additional resource request");
    },
  },
});

export const applicationDetailsPageReducer = applicationDetailsPageSlice.reducer;

const updateApplicationDetailsPageState: ActionCreatorWithPayload<Partial<IApplicationDetailsPageState>> =
  applicationDetailsPageSlice.actions.updateApplicationDetailsPage;

const updateApplicationDetailsEditState = (editing: boolean) => updateApplicationDetailsPageState({ isApplicationDetailsInEditMode: editing });

const updateApplicationNameEditState = (editing: boolean) => updateApplicationDetailsPageState({ isApplicationNameInEditMode: editing });

const updateApplicationStatusEditState = (editing: boolean) => updateApplicationDetailsPageState({ isApplicationStatusInEditMode: editing });

const resetGCPProjectCreationPES = () => updateApplicationDetailsPageState({ gcpProjectCreationPES: PES() });

const resetRequestAdditionalInfraPES = () => updateApplicationDetailsPageState({ requestAdditionalInfraPES: PES() });

const resetRequestDecommissionApplicationPES = () => updateApplicationDetailsPageState({ requestDecommissionApplicationPES: PES() });

const resetRequestZendeskResourcePES = () => updateApplicationDetailsPageState({ requestZendeskResourcePES: PES() });

const updateApplicationStatusEditedValue = (status: ApplicationStatus) => updateApplicationDetailsPageState({ applicationStatusEditedValue: status });

const updateApplicationBasicInfo = (applicationBasicInfo: ApplicationBasicInfo) =>
  updateApplicationDetailsPageState({ applicationBasicInfoPEV: PEV(applicationBasicInfo, null, null) });

const updateApplicationCreatedState = (isApplicationNewlyCreated: boolean) =>
  updateApplicationDetailsPageState({ isApplicationNewlyCreated });

const displayJoinTeamModal = (value: boolean) =>
  updateApplicationDetailsPageState({ showJoinTeamModal: value });

export const ApplicationDetailsPageActions = {
  fetchApplicationDetails,
  fetchInfrastructureInfo,
  fetchActivitiesOfApplication,
  fetchRequestedAdditionalSupport,
  updateApplicationDetailsPageState,
  updateApplicationCreatedState,
  updateApplicationDetailsEditState,
  updateApplicationInformation,
  triggerGCPProjectCreation,
  resetGCPProjectCreationPES,
  updateApplicationStatusEditedValue,
  triggerGithubRepoCreation,
  updateApplicationNameEditState,
  updateApplicationStatusEditState,
  updateApplicationName,
  updateApplicationStatus,
  updateApplicationBasicInfo,
  joinTeam,
  displayJoinTeamModal,
  requestAdditionalInfrastructure,
  resetRequestAdditionalInfraPES,
  requestDecommissionApplication,
  requestZendeskResource,
  resetRequestDecommissionApplicationPES,
  resetRequestZendeskResourcePES,
  exitTeam,
};

import { useSelectorTyped } from "../../../common/redux/store";
import { useEffect } from "react";
import Analytics from "../../../common/services/analytics";

export default function useRegionalITController() {
  useEffect(() => {
    Analytics.trackPageGetStartedRegionalIT();
  }, []);

  const currentStep = useSelectorTyped((state) => state.getStartedPage.currentStep);

  function shouldRenderBasicInfo() {
    return currentStep === "BASIC_INFO";
  }

  function shouldRenderInfraConfig() {
    return currentStep === "INFRA_CONFIG";
  }

  function shouldRenderReviewSubmit() {
    return currentStep === "REVIEW_SUBMIT";
  }

  function shouldRenderTeamSelection() {
    return currentStep === "TEAM_SELECTION";
  }

  return {
    shouldRenderBasicInfo,
    shouldRenderInfraConfig,
    shouldRenderReviewSubmit,
    shouldRenderTeamSelection,
  };
}

import React, { FunctionComponent } from "react";

type Props = {
  name: string;
  className: string;
};

const EventTag: FunctionComponent<Props> = (props: Props) => (
  <div className={props.className}>
    {props.name}
  </div>
);

export default EventTag;

import React from "react";
import "./index.scss";
import PageTitle from "../../../../common/components/page-title";
import useInfraConfigController from "./controller";
import NavigationButtons from "../../navigation-buttons";
import FormComponent, { INavigationButtonsProps } from "../../../../common/components/forms";
import StepBar from "../../../../common/components/step-bar";
import TeamLink from "../../../../common/components/team-link";
import { createFunctionalComponentFromView } from "../../../../utils/view-controller";

const InfraConfig = createFunctionalComponentFromView<ReturnType<typeof useInfraConfigController>>(
  useInfraConfigController,
  (controller) => {

    function renderNavigationButtons({ getFormData, doesFormHasErrors }: INavigationButtonsProps) {
      return (
        <NavigationButtons
          doesFormHasErrors={doesFormHasErrors}
          getFormData={getFormData}
          shouldGoToNextStep={controller.shouldGoToNextStep}
          shouldGoToPreviousStep={controller.shouldGoToPreviousStep}
        />
      );
    }

    const renderStepBar = () => (
      <StepBar
        stepLabels={controller.getStepLabelsForPage()}
        currentCompletedStep={3}
      />
    );

    const renderPageTitle = () => (
      <PageTitle
        title="Regional IT"
        subText={
          <TeamLink
            id={controller.teamDetails.teamId}
            name={controller.teamDetails.teamName}
          />}
        subtitle="Infrastructure Configuration"
        displayTitleLighter={true}
      />
    );

    return (
      <div className="InfraConfig">
        {renderStepBar()}
        {renderPageTitle()}
        <FormComponent
          questions={controller.questions}
          data={controller.infraConfigFormData}
          renderNavigationButtons={renderNavigationButtons}
        />
      </div>
    );
  },
);


export default InfraConfig;

import React, { FunctionComponent } from "react";
import gitHubLogo from "../assets/github_PNG20.webp";
import gcpLogo from "../assets/lockup_cloud_main.webp";
import circleciLogo from "../assets/circleci.webp";
import oktaLogo from "../assets/Okta_Logo_BrightBlue_Medium.webp";
import matomoLogo from "../assets/matomo_logo.webp";
import sumoLogicLogo from "../assets/Sumo_Logic-Logo.wine.webp";

const renderHeaderTitle = (title: string) => {
  const lastSpaceIndex = title.lastIndexOf(" ");
  const firstLine = title.slice(0, lastSpaceIndex);
  const SecondLine = title.slice(lastSpaceIndex, title.length);
  return (
    <div className="row-title">
      <div>{firstLine}</div>
      <div>{SecondLine}</div>
    </div>
  );
};

const HeaderRow: FunctionComponent = () => (
  <>
    <tr className="header-row">
      <th
        className="header-column"
        aria-label="EmptyHeader"
        rowSpan={2}
      />
      <td className="split-cell-top">{renderHeaderTitle("VERSION CONTROL SYSTEM")}</td>
      <td>{renderHeaderTitle("CLOUD PROVIDERS")}</td>
      <td className="split-cell-top">{renderHeaderTitle("CI/CD TOOL")}</td>
      <td className="split-cell-top">{renderHeaderTitle("SINGLE SIGN-ON")}</td>
      <td className="split-cell-top">{renderHeaderTitle("MONITORING TOOL")}</td>
      <td className="split-cell-top">{renderHeaderTitle("ANALYTICS TOOL")}</td>
    </tr>
    <tr>
      <td className="split-cell-bottom logo">
        <img
          width="74"
          src={gitHubLogo}
          alt="gitHubLogo"
        />
      </td>
      <td className="split-cell-bottom logo">
        <img
          width="90"
          src={gcpLogo}
          alt="gcpLogo"
        />
      </td>
      <td className="split-cell-bottom logo">
        <img
          width="100"
          src={circleciLogo}
          alt="circleCILogo"
        />
      </td>
      <td className="split-cell-bottom logo">
        <img
          width="55"
          src={oktaLogo}
          alt="oktaLogo"
        />
      </td>
      <td className="split-cell-bottom logo">
        <img
          width="110"
          src={sumoLogicLogo}
          alt="sumoLogicLogo"
        />
      </td>
      <td className="split-cell-bottom logo">
        <img
          width="100"
          src={matomoLogo}
          alt="matomoLogo"
        />
      </td>
    </tr>
  </>
);

export default HeaderRow;

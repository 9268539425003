import { Hash } from "../../utils/hash";

export const applicationTypeLabelMap: Hash<string> = {
  EXPERIMENT_AND_INNOVATION: "Experiment and Innovation",
  REGIONAL_IT: "Regional IT",
  TECHOPS: "TechOps",
};

export const applicationTypeDropDownOptions = [
  { label: applicationTypeLabelMap.EXPERIMENT_AND_INNOVATION, value: "EXPERIMENT_AND_INNOVATION" },
  { label: applicationTypeLabelMap.REGIONAL_IT, value: "REGIONAL_IT" },
  { label: applicationTypeLabelMap.TECHOPS, value: "TECHOPS" },
];

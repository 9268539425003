import React, {FunctionComponent} from "react";
import "./index.scss";
import CheckBox from "../check-box";
import {ProjectClassification} from "../../../pages/get-started/redux";
import {ApplicationPurpose} from "../../data/application-purpose";
import {useGetFeatureToggleState} from "../../hooks/feature-toggles";

type Props = {
  handleOnChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: ProjectClassification;
  purpose?: ApplicationPurpose | string;
};
const ConsentCheckBox: FunctionComponent<Props> = (props: Props) => {

  const isDisplayGCPBudgetAlertToggleEnabled = useGetFeatureToggleState("Neo_Applications_DisplayBudgetAlert");

  function renderBudgetAlertMessage() {
    if (isDisplayGCPBudgetAlertToggleEnabled && props.type === ProjectClassification.EXPERIMENT_AND_INNOVATION
            && props.purpose !== ApplicationPurpose.SPONSORED_CLIENT_PRE_SALES_OR_POC) {
      return (
        <div className="budget-alert-message">
                    All GCP projects (Production & Non-production) associated to this application will be disabled when the sum of all
                    GCP projects exceeds the $100 monthly budget.
        </div>
      );
    }
  }

  return (
    <div className="ConsentCheckBox">
      <div className="check-box">
        <CheckBox onChange={props.handleOnChanged}/>
        <span/>
      </div>
      <div>
        <div className="heading">Acknowledgement</div>
        <div className="message">
                    This application is only for Thoughtworks internal usage, should only be accessed by its employees and contractors,
                    and will not contain any client’s sensitive data.
          <br/>
          {renderBudgetAlertMessage()}
        </div>
      </div>
    </div>
  );
};

export default ConsentCheckBox;

import { getDecommissionApplicationQuestions } from "./data/questions";
import { useSelectorTyped } from "../../../../../common/redux/store";
import Analytics from "../../../../../common/services/analytics";
import { ApplicationDetailsPageActions } from "../../redux";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { FormRef } from "../../../../../common/components/forms";

type FormData = { decommissionReason: string };

export default function useDecommissionApplicationFormController(props: { onClose: () => void }) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const application = useSelectorTyped((state) => state.applicationDetailsPage.applicationBasicInfoPEV.value!);
  const { requestDecommissionApplicationPES, requestedAdditionalSupportPEV } = useSelectorTyped((state) => state.applicationDetailsPage);
  const { value: uiConfig } = useSelectorTyped((state) => state.header.uiConfigsPEV);
  const decommissionRequests = requestedAdditionalSupportPEV.value?.decommissionRequests;
  const isDecommissionAlreadyRequested = !!decommissionRequests && decommissionRequests.length > 0;
  const dispatch = useDispatch();
  const formRef = useRef<FormRef>(null);
  const [formKey, setFormKey] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [decommissionFormData, setDecommissionFormData] = useState<FormData>({ decommissionReason: "" });

  const closeAccordionAndResetFormData = () => {
    props.onClose();
    formRef.current?.reset();
    dispatch(ApplicationDetailsPageActions.resetRequestDecommissionApplicationPES());
  };

  useEffect(() => {
    if (requestDecommissionApplicationPES.success) {
      setShowConfirmationModal(false);
      closeAccordionAndResetFormData();
    }
  }, [requestDecommissionApplicationPES]);

  useEffect(() => {
    setFormKey((value) => value + 1);
  }, [uiConfig, application, decommissionRequests]);

  const isRequestDecommissionInProgress = () => !!requestDecommissionApplicationPES.progress;

  const onHideConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  return {
    formRef,
    formKey,
    showConfirmationModal,
    onClickDecommissionInConfirmationModal: () => {
      Analytics.trackEventDecommissionApplication();
      dispatch(ApplicationDetailsPageActions.requestDecommissionApplication({
        applicationId: application.id,
        data: {
          reason: decommissionFormData.decommissionReason,
        },
      }));
    },
    onClickCancelInConfirmationModal: onHideConfirmationModal,
    onHideConfirmationModal,
    getQuestions: () => getDecommissionApplicationQuestions({
      isDecommissionAlreadyRequested,
      decommissionReasons: uiConfig.decommissionReasons,
      canUserDecommissionApplication: application.canDecommissionApplication,
    }),
    getFormData: () => ({
      decommissionReason: application.decommissionReason,
    }),
    shouldRenderNavigationButtons: () => application.canDecommissionApplication,
    getDecommissionButtonLabel: () => isRequestDecommissionInProgress() ? "Decommissioning" : "Decommission",
    isFormButtonDisabled: () => isDecommissionAlreadyRequested,
    isConfirmationModalButtonDisabled: isRequestDecommissionInProgress,
    onSubmit: (formData: FormData) => {
      setDecommissionFormData(formData);
      setShowConfirmationModal(true);
    },
    onCancel: closeAccordionAndResetFormData,
  };
}

import React, { FunctionComponent } from "react";
import {  RedocStandalone } from "redoc";
import Progress from "../progress";
import useEventsUserGuideController from "./controller";
import "./index.scss";

export interface UserGuideProps{
  docs: string[];
  title: string;
  version: string;
  imageUrlMap: { [key in string]: string};
}

export interface OpenApiJson{
  openapi: string;
  info: Info;
  paths: Record<string, unknown>;
}

export interface Info{
  description: string;
  version: string;
  title: string;
}

const UserGuide : FunctionComponent<UserGuideProps> = (props:UserGuideProps) => {
  const controller = useEventsUserGuideController(props);
  const openApiJson = controller.openApiJson;

  return openApiJson ? (
    <div className="UserGuide">
      <RedocStandalone
        spec={openApiJson}
        options={{
          hideDownloadButton: true,
          disableSearch: true,
          scrollYOffset: 248,
          hideLoading: true,
          nativeScrollbars: true,
          theme: {
            sidebar: {backgroundColor: "white", activeTextColor: "black" },
            rightPanel: {width: "0%"},
          }}}
      />
    </div>) :
    <div><Progress text={"Loading user guide."}/></div>;
};

export default UserGuide;

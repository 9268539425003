export const secondInMilliseconds = 1000;
export const minuteInMilliseconds = 60 * secondInMilliseconds;
export const hourInMilliseconds = 60 * minuteInMilliseconds;
export const minuteInSeconds = 60;
export const hourInSeconds = 60 * minuteInMilliseconds;

export function timeInMilliseconds(hour: number = 0, minutes: number = 0, seconds: number = 0) {
  return hour * hourInMilliseconds + minutes * minuteInMilliseconds + seconds * secondInMilliseconds;
}

export function timeInSeconds(hour: number = 0, minutes: number = 0, seconds: number = 0) {
  return hour * hourInSeconds + minutes * minuteInSeconds + seconds;
}

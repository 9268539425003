import { useDispatch } from "react-redux";
import { ApplicationDetailsPageActions } from "../../redux";
import { ChangeEvent, useState } from "react";
import { useSelectorTyped } from "../../../../../common/redux/store";
import Analytics from "../../../../../common/services/analytics";
import { getApplicationNameRegExPattern } from "../../../../get-started/utils/validator";

export type IEditApplicationNameController = ReturnType<typeof useEditApplicationNameController>;

export function useEditApplicationNameController() {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const applicationInfo = useSelectorTyped((state) => state.applicationDetailsPage.applicationBasicInfoPEV.value!);
  const editApplicationNamePE = useSelectorTyped((state) => state.applicationDetailsPage.editApplicationNamePE);
  const [applicationName, setApplicationName] = useState(applicationInfo.name);
  const [isApplicationNameValid, setIsApplicationNameValid] = useState(true);

  function onClickCancelButton() {
    dispatch(ApplicationDetailsPageActions.updateApplicationNameEditState(false));
  }

  function onClickConfirmButton() {
    // eslint-disable-next-line security/detect-non-literal-regexp
    const applicationNameRegEx = new RegExp(getApplicationNameRegExPattern());
    const isPatternMatched = applicationNameRegEx.test(applicationName);
    setIsApplicationNameValid(isPatternMatched);
    if (isPatternMatched) {
      dispatch(ApplicationDetailsPageActions.updateApplicationName(
        { name: applicationName, applicationId: applicationInfo.id },
      ));
      Analytics.trackEventEditApplicationName();
    }
  }

  const isUpdateApplicationNameInProgress = () => !!editApplicationNamePE.progress;

  function onInputValueChange(event: ChangeEvent<HTMLInputElement>) {
    setApplicationName(event.target.value);
  }

  return {
    applicationName,
    onClickCancelButton,
    onClickConfirmButton,
    onInputValueChange,
    isApplicationNameValid,
    isUpdateApplicationNameInProgress,
  };
}

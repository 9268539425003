import React from "react";
import SectionHeader from "../../../review-and-submit/section-header";
import ReviewDetails from "../../../review-and-submit/review-details";
import useInfraConfigReviewController from "./controller";
import { createFunctionalComponentFromView } from "../../../../../utils/view-controller";
import ReviewDetailsList from "../../../review-and-submit/review-details-list";

const InfrastructureConfigurationReview = createFunctionalComponentFromView(
  useInfraConfigReviewController,
  (controller) => {

    const renderComments = () => {
      if (controller.shouldRenderComments()) {
        return (
          <ReviewDetails
            title="Comments"
            details={controller.infraConfig.comment}
          />
        );
      }
    };

    const renderGCPProjectNames = () => {
      if (controller.isGcpProjectRequested()) {
        return (
          <ReviewDetailsList
            title="GCP project names"
            detailsList={controller.getGcpProjectNames()}
          />
        );
      }
    };


    function renderGithubRepositoryDetails() {
      if (controller.isGithubRepoRequested()) {
        return (
          <>
            <ReviewDetails
              title="GitHub username"
              details={controller.infraConfig.githubUsername}
            />
            <ReviewDetails
              title="GitHub organisation"
              details={controller.infraConfig.githubOrganisation}
            />
            <ReviewDetails
              title="CircleCI"
              details={controller.getCircleCiRequestedStatus()}
            />
          </>
        );
      }
    }

    return (
      <div>
        <SectionHeader
          title="Infrastructure Configuration"
          step="INFRA_CONFIG"
        />
        <ReviewDetails
          title="GitHub repositories"
          details={controller.getGithubRepoRequestedStatus()}
        />
        {renderGithubRepositoryDetails()}
        <ReviewDetails
          title="GCP project"
          details={controller.getGcpProjectRequestedStatus()}
        />
        {renderGCPProjectNames()}
        {renderComments()}
      </div>
    );
  },
);

export default InfrastructureConfigurationReview;

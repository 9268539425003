import { useSelectorTyped } from "../../../../common/redux/store";
import getManageInfraQuestions from "./data/questions";
import { SurveyModel } from "survey-react";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ApplicationDetailsPageActions, OktaChicletRequest } from "../redux";
import { FormRef } from "../../../../common/components/forms";
import Analytics from "../../../../common/services/analytics";
import { prefixApplicationNameToGitHubRepos } from "../../../get-started/data/common-functions";
import { useGetFeatureToggleState } from "../../../../common/hooks/feature-toggles";
import moment from "moment";
import { TeamType } from "../../../create-team/redux";

type FormData = {
  githubRepos: Array<{ name: string }>;
  gcpProjects: Array<{ name: string }>;
  oktaChiclets: OktaChicletRequest[];
};

export default function useManageInfrastructureController() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const application = useSelectorTyped(((state) => state.applicationDetailsPage.applicationBasicInfoPEV.value!));
  const infrastructureInfoPEV = useSelectorTyped(((state) => state.applicationDetailsPage.infrastructureInfoPEV));
  const requestAdditionalInfraPES = useSelectorTyped(((state) => state.applicationDetailsPage.requestAdditionalInfraPES));
  const neoSupportEmail = useSelectorTyped((state) => state.header.uiConfigsPEV.value.emails.neoSupportEmail);
  const oktaChicletHelpDocumentLink = useSelectorTyped((state) => state.header.uiConfigsPEV.value.externalLinks.oktaChicletHelpDocumentLink);
  const {
    productionGCPProjectDisablingDate,
    defaultProjectIdForExperimentAndInnovationApp,
  } = useSelectorTyped((state) => state.header.uiConfigsPEV.value);
  const diSupportEmail = useSelectorTyped((state) => state.header.uiConfigsPEV.value.emails.diSupportEmail);
  const isRequestOktaPreviewToggleEnabled = useGetFeatureToggleState("Neo_Applications_RequestOktaPreview");
  const [showNavigationButtons, setShowNavigationButtons] = useState(false);
  const dispatch = useDispatch();
  const formRef = useRef<FormRef>(null);
  const [formKey, setFormKey] = useState(0);
  const resetFormData = () => {
    formRef.current?.reset();
    dispatch(ApplicationDetailsPageActions.resetRequestAdditionalInfraPES());
  };
  const isDisplayGCPBudgetAlertToggleEnabled = useGetFeatureToggleState("Neo_Applications_DisplayBudgetAlert");
  useEffect(() => {
    if (requestAdditionalInfraPES.success) {
      resetFormData();
    }
  }, [requestAdditionalInfraPES]);

  useEffect(() => {
    Analytics.trackPageAppManageInfrastructureDetails();
  }, []);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [infrastructureInfoPEV, isRequestOktaPreviewToggleEnabled, oktaChicletHelpDocumentLink, diSupportEmail]);

  const isRequestAdditionalInfraInProgress = () => !!requestAdditionalInfraPES.progress;

  const shouldRenderError = () => !!infrastructureInfoPEV.error;

  const isInfraDetailsFetchedSuccessfully = () => !shouldRenderError() && !infrastructureInfoPEV.progress;

  return {
    neoSupportEmail,
    infrastructureDetailProgress: infrastructureInfoPEV.progress,
    infrastructureDetailError: infrastructureInfoPEV.error,
    shouldRenderError,
    shouldRenderForm: isInfraDetailsFetchedSuccessfully,
    getQuestions: () => getManageInfraQuestions({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      infrastructureInfo: infrastructureInfoPEV.value!,
      teamType: application.team.teamType,
      applicationName: application.name,
      isRequestOktaPreviewToggleEnabled,
      oktaChicletHelpDocumentLink,
      diSupportEmail,
      isApplicationCreatedAfterProductionGCPDisablingDate: moment(application.createdAt).format("YYYY-MM-DD") >= productionGCPProjectDisablingDate,
      isDisplayGCPBudgetAlertToggleEnabled,
      shouldDisplayBudgetAlert: application.team.teamType === TeamType.EXPERIMENT_AND_INNOVATION
        && application.project?.id === defaultProjectIdForExperimentAndInnovationApp,
    }),
    shouldRenderNavigationButtons: () => showNavigationButtons,
    onFormValueChanged: (form: SurveyModel) => {
      const hasAnyValue = (fieldName: string) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const data = form.getValue(fieldName);
        return data ? (data as unknown[]).length > 0 : false;
      };
      setShowNavigationButtons(
        hasAnyValue("githubRepos")
        || hasAnyValue("gcpProjects")
        || hasAnyValue("oktaChiclets"),
      );
    },
    onSubmit: (formData: FormData) => {
      Analytics.trackEventRequestAdditionalInfra();
      dispatch(ApplicationDetailsPageActions.requestAdditionalInfrastructure({
        applicationId: application.id,
        data: {
          repositories: formData.githubRepos
            ? prefixApplicationNameToGitHubRepos(formData.githubRepos, application.name)
            : undefined,
          cloudProjects: formData.gcpProjects?.map(({ name }) => name),
          oktaChiclet: formData.oktaChiclets?.[0],
        },
      }));
    },
    onCancel: () => {
      resetFormData();
    },
    isRequestAdditionalInfraInProgress,
    getSubmitButtonLabel: () => isRequestAdditionalInfraInProgress() ? "Submitting" : "Submit",
    formRef,
    formKey,
    shouldRenderBillingProjectNotAssociatedAlert: () => isInfraDetailsFetchedSuccessfully()
      && infrastructureInfoPEV.value?.canShowBillingProjectNotAssociatedAlert,
  };
}

import React, { ReactElement } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./index.scss";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import useTooltipController, { TooltipProps } from "./controller";

const ToolTipWrapped = createFunctionalComponentFromView<ReturnType<typeof useTooltipController>, TooltipProps>(
  useTooltipController,
  (controller, children) => {
    const renderTooltipContent = () => (
      <Tooltip
        id={controller.id}
        className={`Tooltip${controller.className ?? ""}`}
      >
        <div className="content">{controller.content}</div>
      </Tooltip>
    );

    return (
      <OverlayTrigger
        placement={controller.placement}
        overlay={renderTooltipContent()}
        onToggle={controller.onToggle}
        show={controller.shouldRenderTooltip()}
      >
        {children as ReactElement}
      </OverlayTrigger>
    );
  },
);

ToolTipWrapped.defaultProps = {
  placement: "auto",
  enabled: true,
};

export default ToolTipWrapped;

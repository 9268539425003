import { useSelectorTyped } from "../../../../common/redux/store";
import {
  ApplicationCreatedEventPayload,
  ApplicationDecommissionedEventPayload,
  ApplicationDetailsUpdatedEventPayload,
  ApplicationEventTypeLabelMap,
  ApplicationLifespanEndEventPayload,
  ApplicationStatusChangeEventPayload,
} from "../redux";
import { ActivityHistory } from "../../../team-details/redux";
import { iif } from "../../../../utils/functions";
import { ACTIVITY_HISTORY_TIME_FORMAT, formatDate } from "../../../../utils/date-format";
import {
  RequestClosedEventPayload,
  RequestEventTypeLabelMap,
  RequestInProgressEventPayload,
  RequestRaisedEventPayload,
} from "../../../requests/redux";
import { omit } from "lodash";

export type ApplicationAction = {
  text: string;
};

export type RequestAction = {
  requestId: string;
  hyperLinkLabel: string;
  hyperLinkLabelSuffixText: string;
};
export type ApplicationActivityTableData = Omit<ActivityHistory, "action"> & {
  action: ApplicationAction | RequestAction;
};

export default function useApplicationActivitiesController() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const application = useSelectorTyped(((state) => state.applicationDetailsPage.applicationBasicInfoPEV.value!));
  const activitiesPEV = useSelectorTyped((state) => state.applicationDetailsPage.activitiesPEV);

  const sortActivityByOccurredTime = (
    value1: ApplicationActivityTableData,
    value2: ApplicationActivityTableData,
  ) => new Date(value2.occurredAt).getTime() - new Date(value1.occurredAt).getTime();

  const applicationActivitiesTableData: ApplicationActivityTableData[] = iif(function buildApplicationActivityTableData() {
    if (activitiesPEV.value) {
      return activitiesPEV.value.reduce<ApplicationActivityTableData[]>((result, event) => {
        let activityTypeAndTableDataMap: Record<string, () => ApplicationActivityTableData | ApplicationActivityTableData[]> = {
          [ApplicationEventTypeLabelMap.APPLICATION_CREATED]: () => ({
            action: { text: "Application has been created" },
            eventType: event.type,
            occurredAt: event.createdDateTime,
            performedBy: (event.payload as ApplicationCreatedEventPayload)?.createdBy,
          }),
          [ApplicationEventTypeLabelMap.APPLICATION_DETAILS_UPDATED]: () => {
            const payload = event.payload as ApplicationDetailsUpdatedEventPayload;
            const returnValue = [];
            if ((payload.status && Object.keys(omit(payload, ["id", "updatedBy", "updatedAt"])).length > 1) || !payload.status) {
              returnValue.push({
                action: { text: "Application information updated" },
                eventType: event.type,
                occurredAt: event.createdDateTime,
                performedBy: (event.payload as ApplicationDetailsUpdatedEventPayload)?.updatedBy,
              });
            }
            return returnValue;
          },
          [RequestEventTypeLabelMap.REQUEST_RAISED]: () => {
            const payload = (event.payload as RequestRaisedEventPayload);
            return {
              action: {
                hyperLinkLabel: `Request (${payload.request.type})`,
                hyperLinkLabelSuffixText: "has been raised",
                requestId: payload.request.id,
              },
              eventType: event.type,
              occurredAt: event.createdDateTime,
              performedBy: payload.request.requester.employeeEmail,
            };
          },
          [RequestEventTypeLabelMap.REQUEST_IN_PROGRESS]: () => {
            const payload = (event.payload as RequestInProgressEventPayload);
            return {
              action: {
                hyperLinkLabel: `Request (${payload.request.type})`,
                hyperLinkLabelSuffixText: "is in progress",
                requestId: payload.request.id,
              },
              eventType: event.type,
              occurredAt: event.createdDateTime,
              performedBy: payload.request.tasks[0].taskOwner.teamSupportEmail,
            };
          },
          [RequestEventTypeLabelMap.REQUEST_CLOSED]: () => {
            const payload = (event.payload as RequestClosedEventPayload);
            return {
              action: {
                hyperLinkLabel: `Request (${payload.request.type})`,
                // eslint-disable-next-line max-len
                hyperLinkLabelSuffixText: `has been Closed ${payload.request.aggregatedTaskStatus ? `(${payload.request.aggregatedTaskStatus})` : ""}`,
                requestId: payload.request.id,
              },
              eventType: event.type,
              occurredAt: event.createdDateTime,
              performedBy: payload.request.tasks[0].taskOwner.teamSupportEmail,
            };
          },
          [ApplicationEventTypeLabelMap.APPLICATION_LIFESPAN_END]: () => {
            const payload = (event.payload as ApplicationLifespanEndEventPayload);
            let actionText: string;
            if (payload.numberOfDaysForApplicationEnd === 15) {
              actionText = "Notified team members and regional IT lead 15 days prior to the application lifespan end date";
            } else if (payload.numberOfDaysForApplicationEnd === 30) {
              actionText = "Notified team members 30 days prior to the application lifespan end date";
            } else {
              actionText = "Notified NEO team on the application lifespan end date";
            }
            return {
              action: {
                text: actionText,
              },
              eventType: event.type,
              occurredAt: event.createdDateTime,
              performedBy: payload.triggeredBy,
            };
          },
          [ApplicationEventTypeLabelMap.APPLICATION_DECOMMISSIONED]: () => {
            const payload = (event.payload as ApplicationDecommissionedEventPayload);
            return {
              action: {
                text: payload.decommissionReason
                  ? `Application has been decommissioned for the reason "${payload.decommissionReason}"`
                  : "Application has been decommissioned by NEO Team",
              },
              eventType: event.type,
              occurredAt: event.createdDateTime,
              performedBy: payload.updatedBy,
            };
          },
        };
        const getApplicationStatusChangeActivity = () => {
          const payload = (event.payload as ApplicationStatusChangeEventPayload);
          return {
            action: {
              text: `Application status changed to "${payload.status}"`,
            },
            eventType: event.type,
            occurredAt: event.createdDateTime,
            performedBy: payload.updatedBy,
          };
        };
        activityTypeAndTableDataMap = {
          ...activityTypeAndTableDataMap,
          [ApplicationEventTypeLabelMap.INFRASTRUCTURE_REQUESTED_FOR_APPLICATION]: getApplicationStatusChangeActivity,
          [ApplicationEventTypeLabelMap.APPLICATION_PREPARED_FOR_DEVELOPMENT]: getApplicationStatusChangeActivity,
          [ApplicationEventTypeLabelMap.APPLICATION_ENTERED_DEVELOPMENT]: getApplicationStatusChangeActivity,
          [ApplicationEventTypeLabelMap.APPLICATION_SECURITY_REVIEW_REQUESTED]: getApplicationStatusChangeActivity,
          [ApplicationEventTypeLabelMap.APPLICATION_ENTERED_SECURITY_REVIEW]: getApplicationStatusChangeActivity,
          [ApplicationEventTypeLabelMap.APPLICATION_MOVED_TO_PRODUCTION]: getApplicationStatusChangeActivity,
          [ApplicationEventTypeLabelMap.APPLICATION_PUT_ON_HOLD]: getApplicationStatusChangeActivity,
          [ApplicationEventTypeLabelMap.APPLICATION_DISAPPROVED]: getApplicationStatusChangeActivity,
        };
        if (activityTypeAndTableDataMap[event.type]) {
          const activity = activityTypeAndTableDataMap[event.type]();
          if (Array.isArray(activity)) {
            result = [...result, ...activity];
          } else {
            result.push(activity);
          }
        }
        return result;
      }, []).sort(sortActivityByOccurredTime);
    } else {
      return [];
    }
  });
  const getApplicationActivitiesTableData = () => applicationActivitiesTableData;

  const isApplicationListEmpty = () => activitiesPEV.value?.length === 0;

  return ({
    getApplicationActivitiesTableData,
    shouldRenderProgressMessage: () => activitiesPEV.progress !== null,
    shouldRenderErrorMessage: () => !!activitiesPEV.error,
    shouldRenderNoActivitiesMessage: () => isApplicationListEmpty(),
    progressMessage: activitiesPEV.progress,
    errorMessage: activitiesPEV.error,
    getCSVFileName: () => `Activity_History_${application.name}`,
    getExportCSVData: () => {
      const escapeDoubleQuote = (textWithDoubleQuote: string) =>
        textWithDoubleQuote.replace(/"/g, "\"\"");
      const getDescription = (applicationActivity: ApplicationActivityTableData) => {
        if ((applicationActivity.action as RequestAction).hyperLinkLabel) {
          const action = applicationActivity.action as RequestAction;
          return escapeDoubleQuote(`${action.hyperLinkLabel} ${action.hyperLinkLabelSuffixText}`);
        } else {
          return escapeDoubleQuote((applicationActivity.action as ApplicationAction).text);
        }
      };
      return applicationActivitiesTableData.map((activityHistory) => ({
        Date: formatDate(activityHistory.occurredAt, ACTIVITY_HISTORY_TIME_FORMAT),
        Description: getDescription(activityHistory), // escapeDoubleQuote(activityHistory.action.text),
        User: activityHistory.performedBy ? escapeDoubleQuote(activityHistory.performedBy) : "",
      }));
    },
    getPaginationRowsPerPageOptions: () => {
      const rowsPerPage: number[] = [];
      let init = 10;
      const maxRow = getApplicationActivitiesTableData().length;
      let multiple = 1;
      while (init < maxRow) {
        rowsPerPage.push(init);
        init = 25 * multiple++;
      }
      rowsPerPage.push(maxRow);
      return rowsPerPage;
    },
    shouldRenderExportCSVLink: () => !isApplicationListEmpty(),
    sortActivityByOccurredTime,
  });
}

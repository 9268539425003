import React, { FunctionComponent } from "react";
import useExperimentsAndInnovationsController from "./controller";
import BasicInformation from "./basic-info";
import InfraConfig from "./infra-config";
import ReviewAndSubmit from "./review-and-submit";
import { SelectTeam } from "./select-team";

const ExperimentsAndInnovations: FunctionComponent = () => {
  const controller = useExperimentsAndInnovationsController();

  function renderCurrentStep() {
    if (controller.shouldRenderTeamInfo()) {
      return (
        <SelectTeam/>
      );
    }
    if (controller.shouldRenderBasicInfo()) {
      return (
        <BasicInformation/>
      );
    }
    if (controller.shouldRenderInfraConfig()) {
      return (
        <InfraConfig/>
      );
    }
    if (controller.shouldRenderReviewSubmit()) {
      return (
        <ReviewAndSubmit/>
      );
    }
    return null;
  }

  return (
    <div>
      {renderCurrentStep()}
    </div>
  );
};

export default ExperimentsAndInnovations;

import { RouteComponentProps } from "react-router-dom";
import { useEffect } from "react";
import { useSelectorTyped } from "../../../common/redux/store";
import { RequestsPageActions } from "../redux";
import { useDispatch } from "react-redux";
import Analytics from "../../../common/services/analytics";

export type TaskDetailsProps = RouteComponentProps<{ requestId: string }>;

export default function useTaskDetailsController(props: TaskDetailsProps) {
  const requestId = props.match.params.requestId;
  const dispatch = useDispatch();
  const assignedRequestsPEV = useSelectorTyped((state) => state.requestsPage.assignedRequestsPEV);
  useEffect(() => {
    Analytics.trackPageTaskDetails();
    dispatch(RequestsPageActions.fetchAssignedRequests());
  }, []);

  return ({
    requestId,
    assignedRequestsPEV,
  });
}

import { useDispatch } from "react-redux";
import { ApplicationDetailsPageActions } from "./redux";
import { useSelectorTyped } from "../../../common/redux/store";
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { dispatchFetchCountryRegionalItLeadsIfEmpty } from "../../../common/redux/slices/country-regional-it-leads";
import { dispatchFetchProjectsIfEmpty } from "../../../common/redux/slices/projects";
import { dispatchFetchApplicationStatusesIfEmpty } from "../../../common/redux/slices/application-status";
import { convertUnderscoreToSpace } from "../../../utils/string";
import { TeamsPageActions } from "../../teams/redux";
import { getNumberOfDays } from "../../../utils/date-format";
import { dispatchFetchBusinessFunctionLeadsIfEmpty } from "../../../common/redux/slices/business-functions";


export enum ApplicationTabKey {
  APPLICATION_INFO = "application-information",
  MANAGE_INFRASTRUCTURE = "manage-infrastructure",
  ACTIVITY_HISTORY = "activity-history",
  ADDITIONAL_SUPPORT = "additional-support",
}

export type TabInfo = {
  key: ApplicationTabKey;
  text: string;
  isVisible: boolean;
};

export type ApplicationDetailsProps = RouteComponentProps<{
  applicationId: string;
}>;

export default function useApplicationDetailsController(props: ApplicationDetailsProps) {
  const applicationId = convertUnderscoreToSpace(props.match.params.applicationId);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    value: applicationBasicInfo,
    error: applicationBasicInfoError,
    progress: applicationBasicInfoProgress,
  } = useSelectorTyped(((state) => state.applicationDetailsPage.applicationBasicInfoPEV));
  const { isApplicationDetailsInEditMode, isApplicationNameInEditMode, isApplicationStatusInEditMode }
    = useSelectorTyped((state) => state.applicationDetailsPage);
  const projectsPEV = useSelectorTyped(((state) => state.projectsPEV));
  const businessFunctionLeadsPEV = useSelectorTyped(((state) => state.businessFunctionLeadsPEV));
  const countryRegionalItLeadsPEV = useSelectorTyped(((state) => state.countryRegionalItLeadsPEV));
  const applicationStatusListPEV = useSelectorTyped((state) => state.applicationStatusListPEV);
  const [activeTab, setActiveTab] = useState(ApplicationTabKey.APPLICATION_INFO);
  useEffect(() => {
    dispatchFetchCountryRegionalItLeadsIfEmpty(dispatch, countryRegionalItLeadsPEV);
    dispatchFetchBusinessFunctionLeadsIfEmpty(dispatch, businessFunctionLeadsPEV);
    dispatchFetchProjectsIfEmpty(dispatch, projectsPEV);
    dispatchFetchApplicationStatusesIfEmpty(dispatch, applicationStatusListPEV);
    dispatch(ApplicationDetailsPageActions.fetchApplicationDetails({ applicationId }));
    dispatch(ApplicationDetailsPageActions.fetchInfrastructureInfo({ applicationId }));
    dispatch(ApplicationDetailsPageActions.updateApplicationDetailsEditState(false));
    dispatch(ApplicationDetailsPageActions.updateApplicationNameEditState(false));
    dispatch(ApplicationDetailsPageActions.updateApplicationStatusEditState(false));
    dispatch(TeamsPageActions.fetchAllPotentialMembers());
    return (function onComponentUnMount() {
      dispatch(ApplicationDetailsPageActions.updateApplicationCreatedState(false));
    });
  }, []);

  useEffect(() => {
    dispatch(ApplicationDetailsPageActions.fetchActivitiesOfApplication(applicationId));
  }, [applicationBasicInfo]);

  useEffect(function scrollWindowToTop() {
    window.scrollTo(0, 0);
  }, [isApplicationDetailsInEditMode]);

  function shouldRenderApplicationInfo() {
    return (!getProgressText() && !getErrorInfo()) && applicationBasicInfo;
  }

  function canUserEditApplicationDetail() {
    return applicationBasicInfo?.isEditable ?? false;
  }

  function canViewInfrastructureInfo() {
    return !applicationBasicInfoError &&
      canUserEditApplicationDetail();
  }

  function navigateBack() {
    history.go(-1);
  }

  function disableApplicationDetailsEdit() {
    dispatch(ApplicationDetailsPageActions.updateApplicationDetailsEditState(false));
  }

  function disableApplicationNameEdit() {
    dispatch(ApplicationDetailsPageActions.updateApplicationNameEditState(false));
  }

  function disableApplicationStatusEdit() {
    dispatch(ApplicationDetailsPageActions.updateApplicationStatusEditState(false));
  }

  function backToApplicationDetailPageFromEdit() {
    disableApplicationDetailsEdit();
    disableApplicationNameEdit();
    disableApplicationStatusEdit();
  }

  function shouldRenderApplicationEditPage() {
    return isApplicationDetailsInEditMode;
  }

  const onClickBackButton = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if (shouldRenderApplicationEditPage() || isApplicationNameInEditMode || isApplicationStatusInEditMode) {
      backToApplicationDetailPageFromEdit();
    } else {
      navigateBack();
    }
  };

  function getProgressText() {
    const isSilentLoading = applicationBasicInfo && applicationBasicInfoProgress;
    const displayAppProgress = isSilentLoading ? null : applicationBasicInfoProgress;
    const progressMsg = displayAppProgress
      || countryRegionalItLeadsPEV.progress
      || applicationStatusListPEV.progress;
    if (shouldRenderApplicationEditPage()) {
      return progressMsg || projectsPEV.progress;
    }
    return progressMsg;
  }

  function getErrorInfo() {
    if (!getProgressText()) {
      return applicationBasicInfoError || countryRegionalItLeadsPEV.error || applicationStatusListPEV.error;
    }
  }

  function shouldRenderError() {
    return !!getErrorInfo();
  }

  function onRetryButtonClick() {
    if (applicationBasicInfoError) {
      dispatch(ApplicationDetailsPageActions.fetchApplicationDetails({ applicationId }));
    }
    if (countryRegionalItLeadsPEV.error) {
      dispatchFetchCountryRegionalItLeadsIfEmpty(dispatch, countryRegionalItLeadsPEV);
    }
    if (businessFunctionLeadsPEV.error) {
      dispatchFetchBusinessFunctionLeadsIfEmpty(dispatch, businessFunctionLeadsPEV);
    }
    if (applicationStatusListPEV.error) {
      dispatchFetchApplicationStatusesIfEmpty(dispatch, applicationStatusListPEV);
    }
  }

  const getRemainingLifeSpanDays = () => getNumberOfDays(new Date().toISOString(), applicationBasicInfo?.endDate);

  const getLifespanLabelText = () => {
    const remainingDays = getRemainingLifeSpanDays();
    return remainingDays <= 0 ? "Lifespan ended" : `${remainingDays}  days left`;
  };

  const shouldRenderManageInfraTab = () => canViewInfrastructureInfo();

  const shouldRenderAdditionalSupportTab = () => !!applicationBasicInfo
    && applicationBasicInfo.canRequestAdditionalSupport;

  return ({
    tabs: [
      { key: ApplicationTabKey.APPLICATION_INFO, text: "Application Information", isVisible: true },
      { key: ApplicationTabKey.MANAGE_INFRASTRUCTURE, text: "Manage Infrastructure", isVisible: shouldRenderManageInfraTab() },
      { key: ApplicationTabKey.ADDITIONAL_SUPPORT, text: "Need Additional Support", isVisible: shouldRenderAdditionalSupportTab() },
      { key: ApplicationTabKey.ACTIVITY_HISTORY, text: "Activity", isVisible: true },
    ],
    activeTab,
    applicationDetails: applicationBasicInfo,
    applicationStatusListPEV,
    setActiveTab,
    shouldRenderManageInfraTab,
    shouldRenderAdditionalSupportTab,
    shouldRenderApplicationInfo,
    getErrorInfo,
    shouldRenderError,
    getProgressText,
    shouldRenderApplicationEditPage,
    onClickBackButton,
    canUserEditApplicationDetail,
    onRetryButtonClick,
    getTotalLifeSpanDays: () => getNumberOfDays(applicationBasicInfo?.createdAt, applicationBasicInfo?.endDate),
    getRemainingLifeSpanDays,
    shouldRenderApplicationLifespan: () => applicationBasicInfo?.lifespan.canShowIndicator && !isApplicationDetailsInEditMode,
    hasApplicationLifeSpanReachedThreshold: () => applicationBasicInfo?.lifespan.canShowExtendOption,
    getLifespanLabelText,
  });
}

import React from "react";
import "./index.scss";
import NeoAccordion, { NeoAccordionProps } from "../../../../common/components/neo-accordion";
import DecommissionApplication from "./decommission-application";
import { createFunctionalComponentFromView } from "../../../../utils/view-controller";
import useAdditionalSupportController from "./controller";
import { Alert } from "react-bootstrap";
import NeoLink from "../../../../common/components/neo-link";
import Progress from "../../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../../common/components/error-new";
import RequestZendeskResource from "./request-zendesk-resource";
import { IResourceConfig } from "../../../../common/components/header/redux";

const AdditionalSupport = createFunctionalComponentFromView(
  useAdditionalSupportController,
  (controller) => {
    const renderNoAdditionalSupportMessage = () => (
      <Alert className="no-additional-support-message">
        You cannot request for any additional support for an inactive
        application ({controller.application.status}). Please contact&nbsp;
        <NeoLink
          label={controller.neoSupportMail}
          link={`mailto:${controller.neoSupportMail}`}
          external
        />
        &nbsp;for further details.
      </Alert>
    );

    const renderDecommissionRequestUrl = () => {
      if (controller.shouldRenderDecommissionRequestUrl()) {
        return (
          <NeoLink link={controller.decommissionRequestUrl}>
            (Requested)
          </NeoLink>
        );
      }
    };

    const renderRequestZendeskResourceUrl = (config: IResourceConfig) => {
      if (controller.shouldRenderRequestZendeskResourceUrl(config)) {
        return (
          <NeoLink link={controller.requestZendeskResourceUrl(config)}>
            (Requested)
          </NeoLink>
        );
      }
    };

    const renderDecommissionApplicationAccordionHeading = () => (
      <div className="decommission-request-url">
        I need to decommission this application. {renderDecommissionRequestUrl()}
      </div>
    );

    const renderRequestZendeskResourceAccordionHeading = (config: IResourceConfig) => (
      <div className="request-zendesk-resource-url">
        {config.accordionTitle}{renderRequestZendeskResourceUrl(config)}
      </div>
    );

    const renderAccordionItems = () => {
      const items: NeoAccordionProps["items"] = [{
        heading: renderDecommissionApplicationAccordionHeading(),
        body: <DecommissionApplication onClose={controller.closeAccordion}/>,
        eventKey: "decommission-application",
      }];
      return [
        ...items,
        ...(
          controller.isRequestZendeskResourceToggleEnabled
            ? controller.resourceConfig.map((config) => ({
              heading: renderRequestZendeskResourceAccordionHeading(config),
              body:
                <RequestZendeskResource
                  resourceConfig={config}
                  onClose={controller.closeAccordion}
                />,
              eventKey: config.resourceTypeReadableLabel.replace(" ", "-").toLowerCase(),
            }))
            : []
        ),
      ];
    };

    const renderAdditionalSupportForms = () => (
      <>
        <NeoAccordion
          onSelect={controller.onSelectAccordion}
          activeKey={controller.activeAccordion}
          items={renderAccordionItems()}
        />
        <b>Not seeing what you need?</b>
        <p>
          For any other needs, please send a mail to&nbsp;
          <NeoLink
            link={`mailto:${controller.neoSupportMail}`}
            label={controller.neoSupportMail}
            external
          />
        </p>
      </>
    );

    const renderProgress = () => <Progress text={controller.getProgressText()}/>;

    const renderError = () => {
      if (controller.shouldRenderError()) {
        return (
          <Error
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            {...mapApplicationErrorInfoToErrorComponentProps(controller.getErrorInfo()!)}
            onClick={controller.onRetryButtonClick}
          />
        );
      }
    };

    const renderContent = () => {
      if (controller.shouldRenderContent()) {
        return controller.isApplicationActive
          ? renderAdditionalSupportForms()
          : renderNoAdditionalSupportMessage();
      }
    };

    return (
      <div className="AdditionalSupport">
        {renderProgress()}
        {renderContent()}
        {renderError()}
      </div>
    );
  },
);

export default AdditionalSupport;

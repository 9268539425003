import React from "react";
import { ApplicationBasicInfo } from "../redux";
import useApplicationInformationController, { Props } from "./controller";
import "./index.scss";
import castTo from "../../../../utils/cast-to";
import Details from "../details";
import { createFunctionalComponentFromView } from "../../../../utils/view-controller";
import DetailsList from "../details-list";
import Panel from "../../../../common/components/panel";
import EditButton from "../../../../common/components/edit-button";
import NeoLink from "../../../../common/components/neo-link";

const ApplicationInformation = createFunctionalComponentFromView<ReturnType<typeof useApplicationInformationController>, Props>(
  useApplicationInformationController,
  (controller) => {
    function renderLifespan(field: keyof ApplicationBasicInfo) {
      if (controller.shouldRenderEndDateField()) {
        return (
          <Details
            key={field}
            fieldName={controller.headingsMap[field]}
          >
            <div className="lifespan-extend">
              <div className="lifespan">
                {
                  controller.getFieldValue(field)
                    ? castTo<string>(controller.getFieldValue(field))
                    : "---"
                }
              </div>
              {
                controller.shouldRenderExtendButton() &&
                <div className="extend-option">
                  <a
                    onClick={controller.onClickExtendOption}
                  >
                    Extend?
                  </a>
                </div>
              }
            </div>
          </Details>
        );
      }
    }

    function renderBillingProject() {
      return (
        <Details
          fieldName="Billing project"
          value={controller.getBillingProject()}
        />
      );
    }

    function renderFields(fields: Array<keyof ApplicationBasicInfo>) {
      return (
        <>
          {
            fields.map((field) => {
              if (controller.renderAsList(field)) {
                return (
                  <DetailsList
                    key={field}
                    fieldName={controller.headingsMap[field]}
                    detailsList={castTo<string[]>(controller.getFieldValue(field))}
                  />
                );
              } else if (controller.isEndDateField(field)) {
                return renderLifespan(field);
              } else if (controller.isProjectField(field)) {
                return renderBillingProject();
              } else {
                return (
                  <Details
                    key={field}
                    fieldName={controller.headingsMap[field]}
                    value={castTo<string>(controller.getFieldValue(field))}
                  />
                );
              }
            })
          }
        </>
      );
    }

    function renderTitleInfo() {
      return (
        <NeoLink
          link={controller.informationHandlingDocumentLink}
          external
        >
          View document
        </NeoLink>
      );
    }

    if (controller.shouldRenderApplicationInformation()) {
      return (
        <div className="ApplicationInformation">
          <EditButton
            id={"edit-application-details-button"}
            showButton={controller.shouldRenderEditButton()}
            onClickHandler={controller.onClickEditButton}
            ariaLabel="Edit application"
          />
          <Panel
            titleText="General Details"
            content={renderFields(controller.getGeneralDetailsFields())}
          />
          <Panel
            titleText="Data Classification"
            titleInfo={renderTitleInfo()}
            content={renderFields(controller.dataClassificationFields)}
          />
        </div>
      );
    }
    return null;
  });

export default ApplicationInformation;

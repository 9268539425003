import React, { FunctionComponent, PropsWithChildren, ReactElement, ReactNode } from "react";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import ImpactXButton from "../impactx-button";
import "./index.scss";
import _ from "lodash";
import castTo from "../../../utils/cast-to";

type Props = {
  dialogTitle?: string;
  headerContent?: ReactNode;
  subTitle?: string;
  highlightSubTitle?: boolean;
  bodyText?: string | ReactElement;
  bodyIcon?: ReactNode;
  show: boolean;
  onHide: () => void;
  dontAutoHideOnButtonClick?: boolean;
  dialogClassName?: string;
  modalSize?: ModalProps["size"];
  primaryButton?: {
    show: boolean;
    onClick?: () => void;
    label?: string;
    disabled?: boolean;
  };
  secondaryButton?: {
    show: boolean;
    onClick?: () => void;
    label?: string;
    disabled?: boolean;
  };
  footerContent?: ReactNode;
};

const defaultProps = {
  secondaryButton: {
    show: false,
    label: "No",
  },
  primaryButton: {
    show: false,
    label: "Yes",
  },
  bodyText: "",
  dontAutoHideOnButtonClick: false,
};

const NeoModalDialog: FunctionComponent<Props> = (props: PropsWithChildren<Props>) => {
  const allProps = castTo<PropsWithChildren<Props>>(_.defaultsDeep({ ...props }, defaultProps));

  const onClickHandler = (buttonProps: Props["primaryButton"] | Props["secondaryButton"]) => () => {
    if (!allProps.dontAutoHideOnButtonClick) {
      allProps.onHide();
    }
    buttonProps?.onClick?.();
  };

  const renderFooterButton = (
    buttonProps: Props["primaryButton"] | Props["secondaryButton"],
    isButtonStyleOutline: boolean,
  ) => {
    if (buttonProps?.show) {
      return (
        <ImpactXButton
          className="modal-button"
          outline={isButtonStyleOutline}
          label={buttonProps.label}
          onClickHandler={onClickHandler(buttonProps)}
          disabled={buttonProps.disabled ?? false}
        />
      );
    }
    return null;
  };

  const displayFooterContent = () => {
    if (props.footerContent) {
      return props.footerContent;
    }
    if (allProps.secondaryButton?.show || allProps.primaryButton?.show) {
      return (
        <div className={"footer"}>
          {renderFooterButton(allProps.secondaryButton, true)}
          {renderFooterButton(allProps.primaryButton, false)}
        </div>
      );
    }
    return null;
  };

  const renderModalBody = () => {
    if (allProps.children) {
      return allProps.children;
    } else {
      return (
        <div className={"dialogue-body"}>
          {allProps.bodyIcon}
          {allProps.bodyText}
        </div>
      );
    }
  };

  const renderSubTitle = () => {
    if (allProps.subTitle) {
      return (
        <div className={`dialogue-sub-title ${props.highlightSubTitle ? "dialogue-sub-title-highlighted" : ""}`}>
          <b>{allProps.subTitle}</b>
        </div>
      );
    }
  };

  const renderModalHeader = () => props.headerContent ?? <h3>{allProps.dialogTitle}</h3>;

  return (
    <>
      <Modal
        show={allProps.show}
        onHide={allProps.onHide}
        backdrop="static"
        dialogClassName={`${allProps.dialogClassName ?? ""} NeoModalDialog`}
        centered
        size={allProps.modalSize}
      >
        <Modal.Header
          closeButton={true}
          className="header"
        >
          {renderModalHeader()}
        </Modal.Header>
        {renderSubTitle()}
        {renderModalBody()}
        {displayFooterContent()}
      </Modal>
    </>
  );
};

export default NeoModalDialog;

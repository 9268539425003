import { useSelectorTyped } from "../../common/redux/store";
import { getApplicationSectionCardConfigs } from "./data/application-section-card-configs";

export default function useHomeController() {
  const enableConfetti = useSelectorTyped((state) => state.header.uiConfigsPEV.value.enableConfetti);
  const whatsRecent = useSelectorTyped((state) => state.header.uiConfigsPEV.value.whatsRecent);

  return ({
    shouldRenderConfetti: () => enableConfetti,
    applicationSectionCardConfigs: getApplicationSectionCardConfigs(whatsRecent),
  });
}

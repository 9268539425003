import React, { FunctionComponent } from "react";
import ReactMarkdown from "react-markdown";
import "./index.scss";
import remarkGfm from "remark-gfm";
import reHypeSlug from "rehype-slug";
import reHypeAutolinkHeadings from "rehype-autolink-headings";

export type MarkdownProps = {
  content: string;
};

const Markdown: FunctionComponent<MarkdownProps> = (props: MarkdownProps) => (
  <div className="Markdown markdown-body">
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[reHypeSlug, reHypeAutolinkHeadings]}
    >
      {props.content}
    </ReactMarkdown>
  </div>
);

export default Markdown;

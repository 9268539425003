import { callBffApi } from "../../common/services/axios";
import { IRequest } from "./types/requests";
import { IDisapproveTaskRequest, IRequestEvent } from "./redux";
import { toast } from "react-toastify";

type MyRequestsResponseData = {
  data: IRequest[];
};

export const getMyRequests = async () => {
  const response = await callBffApi<MyRequestsResponseData>("get", "/requests/my-requests");
  return response.data;
};

export const getRequestEvents = async (requestId: string) => {
  const response = await callBffApi<{ data: IRequestEvent[] }>("get", `/requests/${requestId}/events`);
  return response.data;
};

export const getAssignedRequests = async () => {
  const response = await callBffApi<MyRequestsResponseData>("get", "/requests/assigned-requests");
  return response.data;
};

export const approveTask = async (requestId: string, taskId: string) => {
  try {
    const response = await callBffApi<IRequest>("post", `/requests/${requestId}/tasks/${taskId}/approve`);
    toast.success("Task approved successfully");
    return response;
  } catch (error) {
    toast.error(`Error while approving the task. (${(error as Error).message})`);
    throw error;
  }
};

export const reTriggerTask = async (requestId: string, taskId: string) => {
  try {
    const response = await callBffApi<IRequest>("post", `/requests/${requestId}/tasks/${taskId}/re-trigger`);
    toast.success("Task re-triggered successfully");
    return response;
  } catch (error) {
    toast.error(`Error while re-triggering the task. (${(error as Error).message})`);
    throw error;
  }
};

export const completeTask = async (requestId: string, taskId: string) => {
  try {
    const response = await callBffApi<IRequest>("post", `/requests/${requestId}/tasks/${taskId}/complete`);
    toast.success("Task completed successfully");
    return response;
  } catch (error) {
    toast.error(`Error while completing the task. (${(error as Error).message})`);
    throw error;
  }
};

export const disapproveTask = async (
  requestId: string,
  taskId: string,
  requestBody: IDisapproveTaskRequest,
) => {
  try {
    const response = await callBffApi<IRequest>(
      "post",
      `/requests/${requestId}/tasks/${taskId}/disapprove`,
      requestBody,
    );
    toast.success("Task disapproved successfully");
    return response;
  } catch (error) {
    toast.error(`Error while disapproving the task. (${(error as Error).message})`);
    throw error;
  }
};

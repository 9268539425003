import { Nullable } from "./nullable";
import { INeoErrorInfo } from "./errors/neo-errors";

export type ProgressErrorValue<Value, Progress = Nullable<string>, Error = Nullable<INeoErrorInfo | string>> = {
  progress: Progress;
  error: Error;
  value: Value;
};

export function PEV<Value, Progress = Nullable<string>, Error = Nullable<INeoErrorInfo | string>>(
  value: Value,
  progress?: Progress,
  error?: Error,
): ProgressErrorValue<Value, Progress, Error> {
  return {
    progress: (progress ?? null) as Progress,
    error: (error ?? null) as Error,
    value,
  };
}

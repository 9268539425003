import { useDispatch } from "react-redux";
import { useSelectorTyped } from "../../../common/redux/store";
import { useEffect, useMemo } from "react";
import Analytics from "../../../common/services/analytics";
import { DeveloperTool, DeveloperToolsPageActions } from "./redux";
import { TaxonomyCategoryReadableLabel, UiConfig } from "../../../common/components/header/redux";

const groupDeveloperToolsByCategory = (
  developerTools: DeveloperTool[],
  taxonomyCategoryLabelMap: UiConfig["labelMaps"]["taxonomyCategoryLabelMap"],
  getTaxonomyCategoryLabel: (category: DeveloperTool["taxonomyCategory"]) => TaxonomyCategoryReadableLabel,
) => {
  const developerToolsMap = Object.fromEntries(
    Object.values(taxonomyCategoryLabelMap).map((label) => ([label, []])),
  ) as Record<TaxonomyCategoryReadableLabel, DeveloperTool[]>;
  developerTools.forEach((developerTool) => {
    developerToolsMap[getTaxonomyCategoryLabel(developerTool.taxonomyCategory)].push(developerTool);
  });
  return developerToolsMap;
};

export default function useDeveloperToolsController() {
  const dispatch = useDispatch();
  const {
    error: fetchDeveloperToolsError,
    progress: fetchDeveloperToolsProgress,
    value: developerTools,
  } = useSelectorTyped((state) => state.developerToolsPage.developerToolsPEV);
  const { labelMaps, developerToolConfig, externalLinks } = useSelectorTyped((state) => state.header.uiConfigsPEV.value);
  const { taxonomyCategoryLabelMap } = labelMaps;

  const getTaxonomyCategoryLabel = (category: DeveloperTool["taxonomyCategory"]) =>
    taxonomyCategoryLabelMap[category ?? "OTHER_TOOLS"] as TaxonomyCategoryReadableLabel ?? "Other tools";

  const developerToolsByCategory = useMemo(
    () => groupDeveloperToolsByCategory(developerTools, taxonomyCategoryLabelMap, getTaxonomyCategoryLabel),
    [developerTools, taxonomyCategoryLabelMap],
  );

  useEffect(() => {
    Analytics.trackPageDeveloperTools();
    dispatch(DeveloperToolsPageActions.fetchDeveloperTools());
  }, []);

  const isDeveloperToolsListEmpty = () => developerTools.length === 0;

  const getDeveloperToolDescription = () => developerToolConfig.description;

  const getDeveloperToolHelpDocumentLink = () => externalLinks.developerToolHelpDocumentLink;

  return ({
    developerToolsByCategory,
    fetchDeveloperToolsError,
    fetchDeveloperToolsProgress,
    description: getDeveloperToolDescription(),
    helpDocumentLink: getDeveloperToolHelpDocumentLink(),
    onClickDeveloperToolCard: (developerTool: DeveloperTool) => {
      Analytics.trackEventClickOnCardFromDeveloperToolsPage(
        developerTool.name,
        getTaxonomyCategoryLabel(developerTool.taxonomyCategory),
      );
    },
    retryFetchDeveloperTools: () => {
      dispatch(DeveloperToolsPageActions.fetchDeveloperTools());
    },
    shouldRenderNoDeveloperToolsExistMessage: () => isDeveloperToolsListEmpty(),
    shouldRenderProgressMessage: () => fetchDeveloperToolsProgress !== null && isDeveloperToolsListEmpty(),
    shouldRenderErrorMessage: () => !!fetchDeveloperToolsError,
  });
}


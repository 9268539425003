export const getDisapproveTaskQuestions = () => ([
  {
    name: "disapprovalReason",
    type: "comment",
    title: "Disapproval Reason",
    placeHolder: "Please specify a reason for disapproving the task.",
    description: "Accepts upto 1000 characters",
    descriptionLocation: "underTitle",
    maxLength: 1000,
    isRequired: true,
    rows: 3,
  },
]);


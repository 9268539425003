import {callBffEventsAPI} from "../../../common/services/axios";

const fetchEventMetrics = async () => {
  const eventMetricsResponse = await callBffEventsAPI("get", "/event-metrics");
  return eventMetricsResponse;
};

const fetchCategories = async () => {
  const eventMetricsResponse = await callBffEventsAPI("get", "/master-data/categories");
  return eventMetricsResponse;
};

export const EventsOverviewService = {
  fetchEventMetrics,
  fetchCategories,
};

import React, { FunctionComponent } from "react";
import gitHubLogo from "../assets/GitHubLogo.webp";
import googleCloudLogo from "../assets/GoogleCloudLogo.webp";
import oktaLogo from "../assets/OktaLogo.webp";
import circleCILogo from "../assets/CircleCILogo.webp";
import matomoLogo from "../assets/MotomaLogo.webp";
import "./index.scss";
import ImpactXLinkButton from "../../../common/components/impactx-link-button";

const InfraStructureOfferings: FunctionComponent = () => {
  const renderHeader = () => (
    <header>
      <h1>Infrastructure offerings</h1>
      <ImpactXLinkButton
        href={"/infra-offerings"}
        isInternalLink={true}
        className="know-more-link-button"
      >
        Know More
      </ImpactXLinkButton>
    </header>
  );

  const renderLogos = () => (
    <section>
      <img
        src={gitHubLogo}
        alt="GitHub"
      />
      <img
        src={googleCloudLogo}
        alt="Google Cloud"
      />
      <img
        src={oktaLogo}
        alt="Okta"
      />
      <img
        src={circleCILogo}
        alt={"Circle CI"}
      />
      <img
        src={matomoLogo}
        alt="Matomo"
      />
    </section>
  );


  return (
    <div className="InfraStructureOfferings">
      <div className="infra-offerings-content">
        {renderHeader()}
        {renderLogos()}
      </div>
    </div>
  );
};


export default InfraStructureOfferings;

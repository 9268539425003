import React, { FunctionComponent } from "react";
import useEventsUserGuideController from "./controller";
import "./index.scss";
import UserGuide from "../../../common/components/user-guide";
import introduction from "./assets/markdown/Introduction.md";
import quickStart from"./assets/markdown/quickStart.md";
import subscribeEvents from"./assets/markdown/subscribeEvents.md";
import publishEvents from"./assets/markdown/publishEvents.md";
import eventsPlatformCI from "./assets/markdown/eventsPlatformCLI.md";
import authorizationMatrix from "./assets/markdown/authorizationMatrix.md";

const images = function() {
  const importAll = (r: __WebpackModuleApi.RequireContext): string[] => r.keys().map((k) => (r(k) as { default: string }).default);
  return importAll(require.context("./assets/images", false, /\.(png)$/));
}();

const EventsUserGuide: FunctionComponent = () => {

  const controller = useEventsUserGuideController();
  return (
    <div className="EventsUserGuide">
      <div className="events-user-guide-container">
        <UserGuide
          docs={[introduction, quickStart, subscribeEvents, publishEvents, eventsPlatformCI, authorizationMatrix]}
          // @ts-ignore
          imageUrlMap={controller.getImageMap(images)}
          title={"User Guide"}
          version={""}
        />
      </div>
    </div>);
};

export default EventsUserGuide;

import React, { FunctionComponent, ReactNode } from "react";
import { ProjectClassification } from "../redux";
import useProjectClassificationInfoController from "./project-classification-info-controller";

export type Props = {
  heading: string;
  content: ReactNode;
  projectClassification: ProjectClassification;
  id: string;
};

const ProjectClassificationInfo: FunctionComponent<Props> = (props: Props) => {
  const controller = useProjectClassificationInfoController(props);
  return (
    <button
      className="project-classification-info"
      onClick={controller.onProjectClassificationSelected}
      id={controller.id}
    >
      <h6>{controller.heading}</h6>
      <div>{controller.content}</div>
    </button>
  );
};

export default ProjectClassificationInfo;

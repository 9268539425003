import React from "react";
import "./index.scss";
import SearchBar from "../../../../common/components/search-bar";
import { useApplicationSearchController } from "./controller";
import { createFunctionalComponentFromView } from "../../../../utils/view-controller";

const MyApplicationSearch = createFunctionalComponentFromView(useApplicationSearchController, (controller) => (
  <SearchBar
    label="My Applications"
    className="MyApplicationsSearch"
    placeholder="Search by keyword"
    searchText={controller.searchText}
    onChangeSearchText={controller.onChangeSearchText}
    onClickClearSearchText={controller.onClickClearSearchText}
  />
));

export default MyApplicationSearch;

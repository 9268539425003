import { callBffEventsAPI } from "../../../common/services/axios";
import { IEventRegistration, INamespace } from "./redux";
import { PEV } from "../../../utils/progress-error-value";
import { EVENT_REGISTRATIONS_NOT_FETCHED } from "./data/constants";


interface NamespacesResponse {
  namespaces: INamespace[];
}

interface EventRegistrationsResponse {
  eventRegistrations: IEventRegistration[];
  namespaceName: string;
}

interface INamespaceMetadata {
  namespaceName: string;
  totalEventRegistrations: number;
}

const fetchNamespaces = async (): Promise<INamespace[]> => {
  const namespacesResponse = await callBffEventsAPI<NamespacesResponse>("get", "/namespaces");
  const namespaceMetadataResponse = await fetchNamespacesMetadata();

  return namespacesResponse.namespaces.map<INamespace>((namespacesWithoutEventRegistrationsPEV) => ({
    ...namespacesWithoutEventRegistrationsPEV,
    eventRegistrations: PEV(EVENT_REGISTRATIONS_NOT_FETCHED),
    totalEventRegistrations: namespaceMetadataResponse
      .find((ns) => ns.namespaceName === namespacesWithoutEventRegistrationsPEV.name)?.totalEventRegistrations ?? 0,
  }));
};

const fetchNamespacesMetadata = async (): Promise<INamespaceMetadata[]> => {
  const namespaceMetadataResponse = await callBffEventsAPI<INamespaceMetadata[]>("get", "/event-registrations/allEvents");
  return namespaceMetadataResponse;
};

const fetchEventRegistrations = async (namespaceName: string): Promise<EventRegistrationsResponse> => {
  const eventRegistrationsResponse = await callBffEventsAPI<EventRegistrationsResponse>("get", `/namespaces/${namespaceName}/event-registrations`);
  return eventRegistrationsResponse;
};

// eslint-disable-next-line @typescript-eslint/require-await
const fetchEventRegistrationsV3 = async (categoryName: string, pageNo: number, pageSize: number) => {
  // eslint-disable-next-line max-len
  const eventRegistrationsResponse = await callBffEventsAPI("get", `/event-registrations?categoryName=${categoryName}&pageNo=${pageNo}&pageSize=${pageSize}`);
  return eventRegistrationsResponse;
};

// eslint-disable-next-line @typescript-eslint/require-await
const fetchCategories = async () => {
  // eslint-disable-next-line max-len
  const categoriesResponse = await callBffEventsAPI("get", "/categories");
  return categoriesResponse;
};

const fetchMasterDataCategories = async () => {
  const masterDataCategoriesResponse = await callBffEventsAPI("get", "/master-data/categories");
  return masterDataCategoriesResponse;
};

export const EventsDiscoveryPageService = {
  fetchNamespaces,
  fetchEventRegistrations,
  fetchEventRegistrationsV3,
  fetchCategories,
  fetchMasterDataCategories,
  fetchNamespacesMetadata,
};


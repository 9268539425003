import { IProject } from "../../../../../common/redux/slices/projects";

export const getAssociateProjectQuestions = (projects: IProject[]) => ([
  {
    type: "tagbox",
    name: "projectName",
    title: "Select project",
    isRequired: true,
    choices: projects.map((project) => project.name),
  },
]);


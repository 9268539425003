import { IResourceConfig } from "../../../../../../common/components/header/redux";

export const getRequestResourceQuestions = (resourceConfig: IResourceConfig) => [
  {
    type: "panel",
    title: "",
    elements: resourceConfig.resourceDetails.map((resource) => (
      {
        type: resource.type,
        name: resource.fieldName,
        title: resource.fieldLabelInUi,
        isRequired: true,
        readOnly: false,
        choices: resource.options,
        storeOthersAsComment: false,
      })),
  },
];

import {useEffect, useState} from "react";
import {OpenApiJson, UserGuideProps} from ".";

export default function useEventUserGuideController(props: UserGuideProps) {
  const [openApiJson, setOpenApiJson] = useState<OpenApiJson>();

  useEffect(() => {
    void Promise.all(props.docs.map((doc) => fetch(doc).then((response) => response.text()))).then((texts) => {
      let docData = texts.join("");
      Object.keys(props.imageUrlMap).forEach((key) => {
        docData = docData.replace(key, props.imageUrlMap[key]);
      });
      const json = {
        openapi: "3.0.0",
        info: {
          description: docData,
          version: props.version,
          title: props.title,
        },
        paths: {},
      };
      setOpenApiJson(json);
    });
  }, []);

  return ({
    openApiJson,
  });
}

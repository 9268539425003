import React, { FunctionComponent } from "react";
import RegionalITInfo from "../../../common/components/project-classification-contents/regional-it-info";

const RegionalITTooltipInfo: FunctionComponent = () => (
  <div className="tooltip-content">
    <RegionalITInfo />
  </div>
);

export default RegionalITTooltipInfo;

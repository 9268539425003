import { mnpiInfoUrl, piiInfoUrl } from "./urls";
import YesNoChoices from "./yes-no-choice";
import { renderLink } from "../../../utils/forms";


const getDataClassificationQuestions = () => ({
  type: "panel",
  title: "Data Classification",
  elements: [
    {
      type: "radiogroup",
      name: "hasMnpi",
      title: "Does your application expect to handle Thoughtworks financial information?",
      description: `Eg. Revenue, Profit, Salary, etc. Find more about ${renderLink(mnpiInfoUrl, "MNPI information")}.`,
      descriptionLocation: "underTitle",
      choices: [
        { text: YesNoChoices.YES, value: true },
        { text: YesNoChoices.NO, value: false },
      ],
      isRequired: true,
    },
    {
      type: "radiogroup",
      name: "hasPii",
      title: "Does your application expect to handle any personally identifiable information of Thoughtworks employees?",
      description: `Eg. Legal Name, Email address, Phone number, etc. Find more about ${renderLink(piiInfoUrl, "Personal information")}.`,
      descriptionLocation: "underTitle",
      choices: [
        { text: YesNoChoices.YES, value: true },
        { text: YesNoChoices.NO, value: false },
      ],
      isRequired: true,
    },
    {
      type: "radiogroup",
      name: "hasSensitivePii",
      title: "Does your application expect to handle any sensitive personally identifiable information of Thoughtworks employees?",
      description: "Eg. SSN, medical, anything revealing or concerning racial or ethnic origin, political opinions, " +
        "religious or philosophical beliefs, trade union membership, genetic data, biometric data (where used for identification purposes), " +
        "sex life, and sexual orientation, etc.",
      descriptionLocation: "underTitle",
      choices: [
        { text: YesNoChoices.YES, value: true },
        { text: YesNoChoices.NO, value: false },
      ],
      isRequired: true,
    },
  ],
});

export default getDataClassificationQuestions;

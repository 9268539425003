import React from "react";
import "./index.scss";
import { createFunctionalComponentFromView } from "../../../../utils/view-controller";
import { useApplicationStatusController } from "./controller";
import EditButton from "../../../../common/components/edit-button";
import EditApplicationStatus from "./edit-application-status";


const ApplicationStatus = createFunctionalComponentFromView(
  useApplicationStatusController,
  (controller) => {
    const renderApplicationStatus = () => (
      <>
        <div className="status">
          <span className="title">Status:&nbsp;</span>
          <span>{controller.applicationStatus}</span>
        </div>
        <EditButton
          ariaLabel="Edit application status"
          data-testid="edit-application-status-button"
          onClickHandler={controller.onClickEditButton}
          showButton={controller.isUserAuthorizedToEditApplicationStatus}
        />
      </>
    );

    return (
      <div className={"ApplicationStatus"}>
        {
          controller.isApplicationStatusInEditMode
            ? <EditApplicationStatus/>
            : renderApplicationStatus()
        }
      </div>
    );
  });

export default ApplicationStatus;

import React, { FunctionComponent, ReactNode } from "react";
import useTeamTypesInfoController from "./controller";
import { TeamType } from "../../../create-team/redux";
import "./index.scss";

export type Props = {
  heading: string;
  content: ReactNode;
  teamType: TeamType;
  id: string;
};

const TeamTypesInfo: FunctionComponent<Props> = (props: Props) => {
  const controller = useTeamTypesInfoController(props);
  return (
    <button
      className="team-types-info"
      onClick={controller.onTeamTypeInfoSelected}
      id={controller.id}
    >
      <h6>{controller.heading}</h6>
      <div>{controller.content}</div>
    </button>
  );
};

export default TeamTypesInfo;

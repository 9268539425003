import React from "react";
import "./index.scss";
import { createFunctionalComponentFromView } from "../../../../utils/view-controller";
import { IApplicationNameController, Props, useApplicationNameController } from "./controller";
import EditApplicationName from "./edit-application-name";
import EditButton from "../../../../common/components/edit-button";

const ApplicationName = createFunctionalComponentFromView<IApplicationNameController, Props>(
  useApplicationNameController,
  (controller) => {
    const renderEditButton = () => (
      <div className={"edit-icon"}>
        <EditButton
          ariaLabel="Edit application name"
          data-testid="edit-application-name-button"
          onClickHandler={controller.onClickEditButton}
          showButton={controller.isUserAuthorizedToEditApplicationName}
        />
      </div>
    );

    const renderApplicationName = () => (
      <div className={"ApplicationName"}>
        <div className={"name"}>
          <h3>{controller.name}</h3>
        </div>
        {renderEditButton()}
      </div>
    );

    return (
      controller.isApplicationNameInEditMode ? <EditApplicationName/> : renderApplicationName()
    );
  });

export default ApplicationName;

import React, { FunctionComponent } from "react";
import "./index.scss";
import { useHistory } from "react-router-dom";
import NeoLink from "../neo-link";

type Props = {
  onClickHandler?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const BackButton: FunctionComponent<Props> = (props: Props) => {
  const history = useHistory();

  const onClickBackButton = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    history.goBack();
  };

  return (
    <div className="BackButton">
      <NeoLink
        link="/"
        onClick={props.onClickHandler ?? onClickBackButton}
        className="d-flex flex-row"
        clearDefaultStyles
      >
        <i className="ri-arrow-left-s-line left-arrow"/> Back
      </NeoLink>
    </div>
  );
};

export default BackButton;

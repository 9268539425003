import { useEffect, useState } from "react";
import { hasOktaTokenExpired } from "./data";
import { NeoError, NeoErrorType } from "../../../utils/errors/neo-errors";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { useHistory } from "react-router-dom";
import { getEnvVars } from "../../config/env";

export default function useAuthenticationController(oktaAuthClient: OktaAuth) {
  const [isSessionTimedOut, setIsSessionTimedOut] = useState(false);
  const { oktaAuth: auth } = getEnvVars();
  const history = useHistory();

  const onComponentMount = () => {
    void oktaAuthClient.start();
    window.addEventListener("focus", onFocus);

    return function onComponentUnMount() {
      void oktaAuthClient.stop();
      window.removeEventListener("focus", onFocus);
    };
  };

  useEffect(onComponentMount, []);

  const onFocus = () => {
    setIsSessionTimedOut(hasOktaTokenExpired());
  };

  const getSessionTimeOutErrorInfo = () => {
    const error = new NeoError(NeoErrorType.AUTH_SESSION_EXPIRED, "");
    return error.getErrorInfo();
  };

  const onClickLoginButton = () => window.location.reload();

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return {
    isSessionTimedOut,
    onClickLoginButton,
    getSessionTimeOutErrorInfo,
    restoreOriginalUri,
    callbackPath: auth.callbackPath,
  };
}

import { IPotentialMember } from "../../../pages/teams/redux";
import { useSelectorTyped } from "../../redux/store";

export const useSelectedNonTechOpsMembers = (selectedMembersToBeAddedToTeam: Array<IPotentialMember["employeeId"]>) => {
  const potentialMembersPEV = useSelectorTyped((state) => state.teamsPage.potentialMembersPEV);
  const isNonTechOpsMember = (member: IPotentialMember) => !(member.isTechOpsMember || member.isRegionalITMember);
  let selectedNonTechOpsMembers: IPotentialMember[] = [];
  if (selectedMembersToBeAddedToTeam.length > 0) {
    selectedNonTechOpsMembers = potentialMembersPEV.value.filter((member) =>
      isNonTechOpsMember(member) && selectedMembersToBeAddedToTeam.includes(member.employeeId),
    );
  }
  return selectedNonTechOpsMembers;
};

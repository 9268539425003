import { useDispatch } from "react-redux";
import { ReactNode } from "react";
import { Props } from "./project-classification-info";
import { GetStartedPageActions } from "../redux";

type ProjectClassificationInfoController = {
  onProjectClassificationSelected: () => void;
  heading: string;
  content: ReactNode;
  id: string;
};

export default function useProjectClassificationInfoController(props: Props): ProjectClassificationInfoController {
  const dispatch = useDispatch();
  const onProjectClassificationSelected = () => dispatch(
    GetStartedPageActions.updateSelectedProjectClassification(
      props.projectClassification,
      "TEAM_SELECTION",
    ));

  return {
    onProjectClassificationSelected,
    heading: props.heading,
    content: props.content,
    id: props.id,
  };
}

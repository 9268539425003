import React from "react";
import ReactDOM from "react-dom";
import App from "./common/components/app";
import "remixicon/fonts/remixicon.css";
import { Provider } from "react-redux";
import { store } from "./common/redux/store";
import { toast, ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { Authentication } from "./common/components/authentication";
import ErrorBoundary from "./common/components/error-boundary";

window.toast = toast;

const MILLISECONDS_10SEC = 10 * 1000;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Authentication>
          <ErrorBoundary>
            <App/>
          </ErrorBoundary>
        </Authentication>
      </BrowserRouter>
    </Provider>
    <ToastContainer
      autoClose={MILLISECONDS_10SEC}
      position="top-center"
    />
  </React.StrictMode>,
  document.getElementById("root"),
);

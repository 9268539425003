import { iif } from "../functions";
import * as Survey from "survey-react";

export const isValidUrl = (urlString: string) => {
  try {
    const url = new URL(urlString);
    return urlString.length <= 2048
      && urlString.toLowerCase().startsWith("https://")
      && !urlString.includes(",")
      && !urlString.includes(" ")
      && url.protocol === "https:"
      && url.hostname !== "localhost"
      && !url.search
      && iif(function validateDomainNameIsNotIpAddress() {
        return new RegExp(/[a-z]/i).test(url.hostname);
      });
  } catch {
    return false;
  }
};

export const getUrlValidatorConfig = (fieldName: string) => {
  Survey
    .FunctionFactory
    .Instance
    .register("isValidUrl", (params: string[]) => isValidUrl(params[0]?.trim()), false);

  return [
    {
      type: "expression",
      expression: `isValidUrl({${fieldName}}) = true`,
      text: "Please enter valid url (should start with https:// and should not contain ip address or localhost).",
    },
  ];
};

export const getOktaChicletNameValidatorConfig = () => [
  {
    type: "regexvalidator",
    text: "Please enter only 3 - 85 characters.",
    regex: "^.{3,85}$",
  },
];

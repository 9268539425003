import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import useExitTeamController from "./controller";
import ImpactXButton from "../impactx-button";
import React from "react";
import NeoModalDialog from "../neo-modal-dialog";
import AlertIcon from "../neo-modal-dialog/alert-icon";

export const ExitTeamButton = createFunctionalComponentFromView(useExitTeamController, (controller) => {

  function renderExitTeamModal() {
    return (
      <NeoModalDialog
        dialogClassName="exit-team-modal"
        dialogTitle="Exit Team"
        primaryButton={{
          show: true,
          onClick: controller.onClickConfirmExitButton,
          label: controller.getExitTeamConfirmButtonLabel(),
          disabled: controller.shouldExitTeamModalButtonBeDisabled(),
        }}
        secondaryButton={{
          show: true,
          label: "Cancel",
          disabled: controller.shouldExitTeamModalButtonBeDisabled(),
        }}
        show={controller.showExitTeamModal}
        onHide={controller.onHideExitTeamModal}
        subTitle={"Are you sure you want to exit this team?"}
        bodyText={"On exiting all access will be revoked from the team’s initiatives and the resources associated with it."}
      />
    );
  }

  const renderExitTeamLastManagerWarningModal = () => (
    <NeoModalDialog
      dialogClassName="exit-team-last-manager-warning-modal"
      dialogTitle="Exit Team"
      bodyIcon={<AlertIcon/>}
      bodyText="You are the only manager in this team.The team must always have atleast one manager.
      To exit the team kindly assign another manager and then exit team"
      show={controller.showExitTeamLastManagerWarningModal}
      onHide={controller.onHideExitTeamLastManagerWarningModal}
      primaryButton={{ show: true, label: "OK" }}
      secondaryButton={{ show: false }}
    />
  );

  return (
    <>
      <ImpactXButton
        label="Exit Team"
        disabled={false}
        onClickHandler={controller.onClickExitTeamButton}
        outline={controller.isButtonOutline()}
        className={"exit-team"}
      />
      {renderExitTeamLastManagerWarningModal()}
      {renderExitTeamModal()}
    </>
  );
});

import { useDispatch } from "react-redux";
import { useSelectorTyped } from "../../../../common/redux/store";
import { ChangeEvent } from "react";
import Analytics from "../../../../common/services/analytics";
import { MyAppsAndServicesPageActions } from "../redux";

export function useApplicationSearchController() {
  const dispatch = useDispatch();
  const { searchText } = useSelectorTyped((state) => state.myAppsAndServicesPage.filters);
  const activeApplicationStatuses = useSelectorTyped((state) => state.header.uiConfigsPEV.value.activeApplicationStatuses);

  return ({
    searchText,
    onChangeSearchText: (event: ChangeEvent<HTMLInputElement>) => {
      Analytics.trackEventSearchFromMyAppsAndServicesPage();
      dispatch(MyAppsAndServicesPageActions.applySearch(event.target.value, activeApplicationStatuses));
    },
    onClickClearSearchText: () => {
      dispatch(MyAppsAndServicesPageActions.applySearch("", activeApplicationStatuses));
    },
  });
}

import React from "react";
import SubHeader from "../../common/components/sub-header";
import { Route, Switch } from "react-router-dom";
import { RequestsPages } from "./data/pages";
import "./index.scss";
import { createFunctionalComponentFromView } from "../../utils/view-controller";
import useRequestsController, { IRequestController } from "./controller";

const Requests = createFunctionalComponentFromView<IRequestController>(
  useRequestsController,
  (controller) => (
    <div className="Requests">
      <SubHeader
        {...controller.requestsSubHeaderProps}
      />
      <Switch>
        <Route
          path={RequestsPages.MY_REQUESTS.path}
          component={RequestsPages.MY_REQUESTS.component}
        />
        <Route
          path={RequestsPages.MY_TASKS.path}
          component={RequestsPages.MY_TASKS.component}
        />
        <Route
          path={RequestsPages.TASK_DETAILS.path}
          component={RequestsPages.TASK_DETAILS.component}
        />
        <Route
          path={RequestsPages.REQUEST_DETAILS.path}
          component={RequestsPages.REQUEST_DETAILS.component}
        />
      </Switch>
    </div>
  ),
);

export default Requests;

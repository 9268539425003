import React from "react";
import GetStartedBtn from "../get-started-button";
import neoLogo from "./assets/neo_logo.svg";
import neoSandboxLogo from "./assets/neo_sandbox.svg";
import useHeaderController from "./controller";
import HeaderMenus from "./header-menus";
import "./index.scss";
import DropdownMenuWithToolTip from "../dropdown-menu-with-tooltip";
import Analytics from "../../services/analytics";
import ToolTipWrapped from "../tooltip";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import NeoLink from "../neo-link";
import { Switch } from "antd";

const Header = createFunctionalComponentFromView(
  useHeaderController,
  (controller) => {

    function renderLogo() {
      if (controller.environmentConfig.isSandbox && controller.isApiUnificationToggleEnabled) {
        return (
          <div className="header-logo">
            <img
              src={neoSandboxLogo}
              alt="logo"
              height={38}
              style={{ pointerEvents: "none" }}
            />
          </div>
        );
      }
      return (
        <div className="header-logo">
          <NeoLink
            id="logo-link"
            link="/"
            ariaLabel="NEO logo"
            onClick={() => Analytics.trackEventClickOnHeaderItems("Logo")}
            clearDefaultStyles
          >
            <img
              src={neoLogo}
              alt="logo"
              height={38}
            />
          </NeoLink>
        </div>
      );
    }

    function renderUserBadge() {
      return (
        <div className="user-badge">
          <span className="user-name-initials">{controller.getUserNameInitials()}</span>
        </div>
      );
    }

    function renderSandboxMessage() {
      return (
        <div className="sandbox-message">
          {controller.message}
        </div>
      );
    }

    function renderManageMenu() {
      return (
        <div className="manage-menu">
          <DropdownMenuWithToolTip
            dropdownItems={controller.getManageMenuItems()}
            tooltipContent="Manage"
            ariaLabel="Manage"
          >
            <i className="ri-list-settings-line"/>
          </DropdownMenuWithToolTip>
        </div>
      );
    }

    function renderUncheckedChildrenContent(content: string) {
      return (
        <div className={"toggle-icon"}>
          <div className="toggle-handle">
            <i className="ri-code-s-slash-line"/>
          </div>
          <div className="unchecked-content">{content}</div>
        </div>
      );
    }

    function renderCheckedChildrenContent(content: string) {
      return (
        <div className={"toggle-icon"}>
          <div className="checked-content">{content}</div>
          <div className="toggle-handle">
            <i className="ri-code-s-slash-line"/>
          </div>
        </div>
      );
    }

    function renderToggleButton() {
      return (
        <ToolTipWrapped
          content={controller.unificationConfig.tooltipContent}
          id={"toggle-tool-tip"}
          className="-toggle-button"
          placement={"bottom"}
        >
          <Switch
            className="custom-switch"
            defaultChecked={controller.environmentConfig.isSandbox}
            checkedChildren={controller.environmentConfig.isSandbox &&
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            renderCheckedChildrenContent(controller.unificationConfig.toggleCheckedContent!)
            }
            unCheckedChildren={!controller.environmentConfig.isSandbox &&
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            renderUncheckedChildrenContent(controller.unificationConfig.toggleUnCheckedContent!)
            }
            onChange={controller.onToggleClick}
          />
        </ToolTipWrapped>
      );
    }

    function renderGetStarted() {
      return (
        <GetStartedBtn
          onTrackAnalytics={() =>
            Analytics.trackEventClickOnHeaderItems("Get Started")
          }
        />
      );
    }

    function renderHelpIcon() {
      return (
        <ToolTipWrapped
          id="help-icon-tooltip"
          content="Help"
          placement="bottom"
        >
          <NeoLink
            className="help-icon"
            link={controller.helpDocumentLink}
            onClick={() => Analytics.trackEventClickOnHeaderItems("Help")}
            external
            clearDefaultStyles
            ariaLabel="Help"
          >
            <i className="ri-question-line"/>
          </NeoLink>
        </ToolTipWrapped>
      );
    }

    return (
      <div className="Header">
        <div className="Header-Inner">
          {renderLogo()}
          <HeaderMenus/>
          <div className="header-right">
            {!(controller.isApiUnificationToggleEnabled && controller.environmentConfig.isSandbox) && renderGetStarted()}
            {!(controller.isApiUnificationToggleEnabled && controller.environmentConfig.isSandbox) && renderHelpIcon()}
            {controller.isApiUnificationToggleEnabled && renderManageMenu()}
            {controller.isApiUnificationToggleEnabled && renderToggleButton()}
            {renderUserBadge()}
          </div>
        </div>
        {(controller.isApiUnificationToggleEnabled && controller.environmentConfig.isSandbox) && renderSandboxMessage()}
      </div>
    );
  },
);

export default Header;

import { Nullable } from "./nullable";

export type ProgressError<Progress = string, Error = string> = {
  progress: Nullable<Progress>;
  error: Nullable<Error>;
};

export function PE<Progress = string, Error = string>(
  progress?: Progress,
  error?: Error,
): ProgressError<Progress, Error> {
  return {
    progress: progress || null,
    error: error || null,
  };
}

export type UnificationConfig = {
  toggleUrl: string;
  tooltipContent: string;
  toggleCheckedContent?: string;
  toggleUnCheckedContent?: string;
  environmentSpecificConfig: EnvironmentSpecificConfig;
};

export type EnvironmentSpecificConfig = {
  isSandbox: boolean;
  backgroundColor: string;
};

const environmentConfig: Record<"sandbox" | "production", EnvironmentSpecificConfig> = {
  sandbox: {
    isSandbox: true,
    backgroundColor: "#F3F1F5",
  },
  production: {
    isSandbox: false,
    backgroundColor: "#FFFFFF",
  },
};

export const environmentConfigForUnification: Record<string, UnificationConfig> = {
  local: {
    toggleUrl: "http://localhost:3000",
    tooltipContent: "Click to switch to sandbox environment",
    toggleUnCheckedContent: "Go to Sandbox",
    environmentSpecificConfig: environmentConfig.production,
  },
  test: {
    toggleUrl: "https://dev.neo.thoughtworks.net",
    tooltipContent: "Click to switch to sandbox environment",
    toggleUnCheckedContent: "Go to Sandbox",
    environmentSpecificConfig: environmentConfig.production,
  },
  dev: {
    toggleUrl: "https://qa.neo.thoughtworks.net",
    tooltipContent: "Click to switch to qa environment",
    toggleCheckedContent: "Go to QA",
    environmentSpecificConfig: environmentConfig.sandbox,
  },
  qa: {
    toggleUrl: "https://dev.neo.thoughtworks.net/api-gateway/api-list",
    tooltipContent: "Click to switch to dev environment",
    toggleUnCheckedContent: "Go to Dev",
    environmentSpecificConfig: environmentConfig.production,
  },
  sandbox: {
    toggleUrl: "https://neo.thoughtworks.net",
    tooltipContent: "Click to switch to production environment",
    toggleCheckedContent: "Go to Production",
    environmentSpecificConfig: environmentConfig.sandbox,
  },
  production: {
    toggleUrl: "https://sandbox.neo.thoughtworks.net/api-gateway/api-list",
    tooltipContent: "Click to switch to sandbox environment",
    toggleUnCheckedContent: "Go to Sandbox",
    environmentSpecificConfig: environmentConfig.production,
  },
};

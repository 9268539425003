import { useDispatch } from "react-redux";
import { useSelectorTyped } from "../../../../common/redux/store";
import { ApplicationsCataloguePageActions, IApplicationFilters } from "../redux";
import Analytics from "../../../../common/services/analytics";
import applicationCategories from "../../../get-started/data/application-category";
import React, { useEffect } from "react";
import { ApplicationBasicInfo } from "../../../applications/application-details/redux";
import { dispatchFetchCountryRegionalItLeadsIfEmpty } from "../../../../common/redux/slices/country-regional-it-leads";
import { dispatchFetchBusinessFunctionLeadsIfEmpty } from "../../../../common/redux/slices/business-functions";
import { dispatchFetchApplicationStatusesIfEmpty } from "../../../../common/redux/slices/application-status";
import { applicationTypeDropDownOptions } from "../../../../common/data/application-types";

export function useApplicationFiltersController() {
  const dispatch = useDispatch();
  const filters = useSelectorTyped((state) => state.applicationsCataloguePage.filters);
  const countryRegionalItLeadsPEV = useSelectorTyped((state) => state.countryRegionalItLeadsPEV);
  const businessFunctionLeadsPEV = useSelectorTyped((state) => state.businessFunctionLeadsPEV);
  const applicationStatusListPEV = useSelectorTyped((state) => state.applicationStatusListPEV);
  const activeApplicationStatuses = useSelectorTyped((state) => state.header.uiConfigsPEV.value.activeApplicationStatuses);
  const allApplications = useSelectorTyped((state) => state.applicationsCataloguePage.applicationsPEV.value);

  useEffect(() => {
    dispatchFetchCountryRegionalItLeadsIfEmpty(dispatch, countryRegionalItLeadsPEV);
    dispatchFetchBusinessFunctionLeadsIfEmpty(dispatch, businessFunctionLeadsPEV);
    dispatchFetchApplicationStatusesIfEmpty(dispatch, applicationStatusListPEV);
  }, []);

  useEffect(() => {
    dispatch(ApplicationsCataloguePageActions.applyFilter(filters, activeApplicationStatuses));
  }, [allApplications, activeApplicationStatuses]);

  function onChangeFilters(updatedFilters: Partial<IApplicationFilters>) {
    Analytics.trackEventFilterApplicationsFromApplicationsPage();
    dispatch(ApplicationsCataloguePageActions.applyFilter(updatedFilters, activeApplicationStatuses));
  }

  return ({
    filters,
    getApplicationTypeDropdownOptions: () => applicationTypeDropDownOptions,
    getCountryDropdownOptions: () =>
      countryRegionalItLeadsPEV.value.map((country) => ({ label: country.name, value: country.businessUnit })),
    getBusinessFunctionDropdownOptions: () =>
      businessFunctionLeadsPEV.value.map(({ businessFunction }) => ({ label: businessFunction, value: businessFunction })),
    getApplicationCategoryDropdownOptions: () =>
      applicationCategories.map((category) => ({ label: category, value: category })),
    getApplicationStatusDropdownOptions: () =>
      applicationStatusListPEV.value.map((status) => ({
        label: status,
        value: status,
        disabled: !filters.displayInactiveApplications && !activeApplicationStatuses.includes(status),
      })),
    onChangeCountryFilter: (countries: string[]) => onChangeFilters({ countries }),
    onChangeBusinessFilter: (businessFunctions: string[]) => onChangeFilters({ businessFunctions }),
    onChangeTeamTypeFilter: (teamTypes: string[]) => onChangeFilters({ teamTypes }),
    onChangeStatusFilter: (statuses: string[]) => onChangeFilters({ statuses }),
    onChangeCategoriesFilter: (categories: string[]) => onChangeFilters({ categories }),
    onChangeDisplayInactiveApplicationsCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => {
      const displayInactiveApplications = event.target.checked;
      const statuses = filters.statuses.filter((status) =>
        displayInactiveApplications || activeApplicationStatuses.includes(status as ApplicationBasicInfo["status"]),
      );
      onChangeFilters({ displayInactiveApplications, statuses });
    },
    onClickResetButton: () => {
      Analytics.trackEventResetFiltersFromApplicationsPage();
      dispatch(ApplicationsCataloguePageActions.resetFilters(activeApplicationStatuses));
    },
  });
}

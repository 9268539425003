import React, { useEffect, useRef } from "react";
import { invokeImmediately } from "../../../utils/functions";
import { getOktaAccessToken } from "../authentication/data";
import { toast } from "react-toastify";

type Props = {
  containerId: string;
  applicationName: string;
  applicationId: string;
};

const AuthZMicroFrontend = (props: Props) => {
  const rootElementRef = useRef<HTMLElement | null>(null);

  useEffect(() => function onComponentUnMount() {
    if (window.unmountAccessRequest && document.getElementById(props.containerId)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.unmountAccessRequest({ containerId: props.containerId });
    }
  }, []);

  return (
    <div
      ref={(htmlInstance) => {
        rootElementRef.current = htmlInstance;
        if (!htmlInstance) {
          return;
        }

        invokeImmediately(function renderMff() {
          if (window.renderAccessRequest) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            window.renderAccessRequest({
              containerId: props.containerId,
              application: {
                name: props.applicationName,
                id: props.applicationId,
              },
              // @ts-ignore
              accessToken: getOktaAccessToken(),
              styles: {
                themeColor: "#F2617A",
                fontSize: "16px",
                disabledColor: "#9A9C9E",
                modalHeader: {
                  backgroundColor: "white",
                  color: "black",
                  fontSize: "1.125rem",
                },
              },
              hideButtonIcon: true,
              alert: {
                success: toast.success,
                error: toast.error,
              },
            });
          }
        });
      }}
    />
  );
};

export default React.memo(AuthZMicroFrontend);

import { IPage } from "../../../common/components/app/pages";
import Applications from "../applications";
import ApplicationDetails from "../../applications/application-details";
import MyAppsAndServices from "../my-apps-and-services";
import DeveloperTools from "../developer-tools";
import DeveloperToolDetails from "../developer-tools/developer-tool-details";

export class CataloguePages {
  public static readonly APPLICATIONS: IPage = {
    title: "Applications",
    path: "/catalogue/applications",
    component: Applications,
  };
  public static readonly MY_APPS_AND_SERVICES: IPage = {
    title: "My Apps & Services",
    path: "/catalogue/my-apps-and-services",
    component: MyAppsAndServices,
  };
  public static readonly APPLICATION_DETAILS: IPage = {
    title: "Application Details",
    path: "/catalogue/:applicationId/:applicationName?",
    component: ApplicationDetails,
  };
  public static readonly DEVELOPER_TOOL_DETAILS: IPage = {
    title: "Developer Tool Details",
    path: "/catalogue/developer-tools/:developerToolId/:developerToolName?",
    component: DeveloperToolDetails,
  };
  public static readonly DEVELOPER_TOOLS: IPage = {
    title: "Developer Tools",
    path: "/catalogue/developer-tools",
    component: DeveloperTools,
  };
}

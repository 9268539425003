import { useDispatch } from "react-redux";
import { IInfraConfig, RegionalITCategoryActions } from "../redux";
import { useSelectorTyped } from "../../../../common/redux/store";
import getInfraConfigQuestions from "./data/questions";
import { stepLabels } from "../../utils/step-labels";

export default function useInfraConfigController() {
  const dispatch = useDispatch();
  const infraConfigFormData = useSelectorTyped((state) => state.getStartedPage.regionalIT.infraConfig);
  const { projectName, teamName, teamId } = useSelectorTyped((state) => state.getStartedPage.regionalIT.basicInfo);

  return {
    questions: getInfraConfigQuestions(projectName),
    teamDetails: { teamId: teamId ?? "", teamName: teamName ?? "" },
    infraConfigFormData,
    shouldGoToNextStep: (formData: Partial<IInfraConfig>) => {
      dispatch(RegionalITCategoryActions.updateInfraConfig(formData));
      return true;
    },
    getStepLabelsForPage: () => stepLabels,
    shouldGoToPreviousStep: (formData: Partial<IInfraConfig>) => {
      dispatch(RegionalITCategoryActions.updateInfraConfig(formData));
      return true;
    },
  };
}

import { useSelectorTyped } from "../../../common/redux/store";

export default function useTechOpsController() {
  const currentStep = useSelectorTyped((state) => state.getStartedPage.currentStep);

  function shouldRenderBasicInfo() {
    return currentStep === "BASIC_INFO";
  }

  function shouldRenderInfraConfig() {
    return currentStep === "INFRA_CONFIG";
  }

  function shouldRenderReviewSubmit() {
    return currentStep === "REVIEW_SUBMIT";
  }

  function shouldRenderTeamSelection() {
    return currentStep === "TEAM_SELECTION";
  }

  return {
    shouldRenderBasicInfo,
    shouldRenderInfraConfig,
    shouldRenderReviewSubmit,
    shouldRenderTeamSelection,
  };
}

import { useDispatch } from "react-redux";
import { GetStartedPageActions, ProjectClassification } from "./redux";
import { useSelectorTyped } from "../../common/redux/store";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Analytics from "../../common/services/analytics";
import { dispatchFetchCountryRegionalItLeadsIfEmpty } from "../../common/redux/slices/country-regional-it-leads";
import { dispatchFetchProjectsIfEmpty } from "../../common/redux/slices/projects";
import { ApplicationDetailsPageActions } from "../applications/application-details/redux";
import { dispatchFetchBusinessFunctionLeadsIfEmpty } from "../../common/redux/slices/business-functions";
import { buildApplicationDetailsPageLink } from "../../utils/navigation";
import { ApplicationsCataloguePageActions } from "../catalogue/applications/redux";

export default function useProjectClassificationController() {
  const currentStep = useSelectorTyped((state) => state.getStartedPage.currentStep);
  const selectedProjectClassification = useSelectorTyped((state) => state.getStartedPage.selectedProjectClassification);
  const createApplicationPEV = useSelectorTyped((state) => state.getStartedPage.createApplication);
  const countryRegionalItLeadsPEV = useSelectorTyped((state) => state.countryRegionalItLeadsPEV);
  const businessFunctionsLeadsPEV = useSelectorTyped((state) => state.businessFunctionLeadsPEV);
  const projectsPEV = useSelectorTyped((state) => state.projectsPEV);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    Analytics.trackPageGetStarted();
    dispatch(ApplicationsCataloguePageActions.fetchAllApplications());
    dispatchFetchCountryRegionalItLeadsIfEmpty(dispatch, countryRegionalItLeadsPEV);
    dispatchFetchBusinessFunctionLeadsIfEmpty(dispatch, businessFunctionsLeadsPEV);
    dispatchFetchProjectsIfEmpty(dispatch, projectsPEV);
  }, [dispatch]);

  useEffect(function scrollWindowToTop() {
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(
    function resetReduxStateAndNavigateToAppDetailsPageWhenAppIsCreated() {
      const applicationBasicInfo = createApplicationPEV.value;
      if (applicationBasicInfo) {
        dispatch(GetStartedPageActions.resetState());
        dispatch(ApplicationDetailsPageActions.updateApplicationBasicInfo(applicationBasicInfo));
        dispatch(ApplicationDetailsPageActions.updateApplicationCreatedState(true));
        history.push(buildApplicationDetailsPageLink(applicationBasicInfo));
      }
    },
    [createApplicationPEV.value],
  );

  function shouldRenderProjectClassification() {
    return currentStep === "PROJECT_CLASSIFICATION";
  }

  function shouldRenderExperimentsAndInnovations() {
    return (
      !shouldRenderProjectClassification()
      && selectedProjectClassification === ProjectClassification.EXPERIMENT_AND_INNOVATION
    );
  }

  function shouldRenderRegionalIt() {
    return (
      !shouldRenderProjectClassification()
      && selectedProjectClassification === ProjectClassification.REGIONAL_IT
    );
  }

  function shouldRenderTechOps() {
    return (
      !shouldRenderProjectClassification()
      && selectedProjectClassification === ProjectClassification.TECHOPS
    );
  }

  return ({
    shouldRenderProjectClassification,
    shouldRenderExperimentsAndInnovations,
    shouldRenderRegionalIt,
    shouldRenderTechOps,
  });
}

import { Nullable } from "./nullable";

export type ProgressErrorSuccess<Progress = string, Error = string, Success = boolean> = {
  progress: Nullable<Progress>;
  error: Nullable<Error>;
  success: Success;
};

export function PES<Progress = string, Error = string>(
  progress?: Nullable<Progress>,
  error?: Nullable<Error>,
  success?: boolean,
): ProgressErrorSuccess<Progress, Error> {
  return {
    progress: progress || null,
    error: error || null,
    success: success || false,
  };
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as service from "./service";
import { PEV, ProgressErrorValue } from "../../../utils/progress-error-value";
import { INeoErrorInfo, NeoError } from "../../../utils/errors/neo-errors";
import { Nullable } from "../../../utils/nullable";
import { createAsyncThunkWithRejectValue } from "../../../common/redux/createAsyncThunkWithRejectValue";
import { ITeam } from "../../team-details/redux";


export enum DeveloperToolStatus {
  REGISTERED = "REGISTERED",
  PUBLISHED = "PUBLISHED"
}

export type DownloadDeveloperToolMetrics = {
  developerToolId: DeveloperTool["id"];
  purpose: string;
  accountName: string;
};

export interface DeveloperTool {
  id: NanoId;
  name: string;
  description: string;
  taxonomyCategory: Nullable<string>;
  status: DeveloperToolStatus;
  purpose: string;
  recommendedFor: string;
  techStack: string[];
  owners: Email[];
  repoZipFileUrl: string;
  readmeFileContent: string;
  version: Nullable<string>;
  team: {
    id: ITeam["id"];
    name: ITeam["name"];
    status: ITeam["status"];
    description: ITeam["description"];
    supportEmail: ITeam["supportEmail"];
    members?: ITeam["members"];
    teamType: ITeam["teamType"];
    contactEmail: ITeam["contactEmail"];
    isOpen: ITeam["isOpen"];
  };
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
}

interface IDeveloperToolsState {
  developerToolsPEV: ProgressErrorValue<DeveloperTool[], Nullable<string>, Nullable<INeoErrorInfo>>;
  showDownloadDeveloperToolModal: boolean;
}

const initialState: IDeveloperToolsState = {
  developerToolsPEV: PEV([]),
  showDownloadDeveloperToolModal: false,
};

const fetchDeveloperTools = createAsyncThunkWithRejectValue<DeveloperTool[]>(
  "catalogue/fetch-developer-tools",
  service.fetchDeveloperTools,
);

const captureDownloadDeveloperToolMetrics = createAsyncThunkWithRejectValue<void, DownloadDeveloperToolMetrics>(
  "catalogue/capture-download-developer-tool-metrics",
  (payload) => service.captureDownloadDeveloperToolMetrics(payload),
);

export const developerToolsPageSlice = createSlice({
  name: "developerToolsPage",
  initialState,
  reducers: {
    updateDeveloperToolsPageState: (state: IDeveloperToolsState, action: PayloadAction<Partial<IDeveloperToolsState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: {
    [fetchDeveloperTools.pending.type]: (state: IDeveloperToolsState) => {
      state.developerToolsPEV = PEV(state.developerToolsPEV.value, "Fetching developer tools.");
    },
    [fetchDeveloperTools.fulfilled.type]: (state: IDeveloperToolsState, action: PayloadAction<DeveloperTool[]>) => {
      state.developerToolsPEV = PEV(action.payload);
    },
    [fetchDeveloperTools.rejected.type]: (state: IDeveloperToolsState, action: PayloadAction<NeoError>) => {
      state.developerToolsPEV = PEV([], null, action.payload.getErrorInfo());
    },
  },
});

export const developerToolsPageReducer = developerToolsPageSlice.reducer;

export const DeveloperToolsPageActions = {
  updateDeveloperToolsPageState: developerToolsPageSlice.actions.updateDeveloperToolsPageState,
  fetchDeveloperTools,
  captureDownloadDeveloperToolMetrics,
};

import "./index.scss";
import React from "react";
import useRegionalITTeamUnAuthorizedMessageController from "./controller";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import NeoLink from "../neo-link";

const RegionalITTeamUnAuthorizedMessage = createFunctionalComponentFromView(
  useRegionalITTeamUnAuthorizedMessageController,
  (controller) => (
    <div className="RegionalITTeamUnAuthorizedMessage">
      <div>
        {controller.errorMessage}
        <br/>
        The creation of Regional IT teams need approvals from the Regional IT leads of respective regions.
      </div>
      <div>
        If you still feel your team qualifies to be a Regional IT team, please send an email to&nbsp;
        <NeoLink
          link={`mailto:${controller.neoSupportEmail}`}
          className={"contact-email"}
          external
        >
          {controller.neoSupportEmail}
        </NeoLink>
        <br/>
        and copy the relevant Regional IT lead in the request for approval. Check your Regional IT lead&nbsp;
        <NeoLink link={controller.regionalItLeadsMasterDataLink}>
          here.
        </NeoLink>
      </div>
    </div>
  ),
);

export default RegionalITTeamUnAuthorizedMessage;

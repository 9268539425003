import React, { FunctionComponent } from "react";
import { Dropdown } from "react-bootstrap";
import "./index.scss";
import { Link } from "react-router-dom";

export type DropdownMenuProps = {
  dropdownItems: Array<{
    label: string;
    url?: string;
    onClick?: () => unknown;
  }>;
  onToggle?: () => void;
  children: JSX.Element | string;
  ariaLabel?: string;
};

const DropdownMenu: FunctionComponent<DropdownMenuProps> = (props: DropdownMenuProps) => {
  function renderMenuItem(item: DropdownMenuProps["dropdownItems"][number]) {
    if (item.url) {
      return (
        <Dropdown.Item
          as={Link}
          to={item.url}
          onClick={item.onClick}
        >
          {item.label}
        </Dropdown.Item>
      );
    }
    return (
      <Dropdown.Item
        as={"button"}
        onClick={item.onClick}
      >
        {item.label}
      </Dropdown.Item>
    );
  }

  function renderMenuItems() {
    return props.dropdownItems.map((item, index) => (
      <div key={index}>
        {renderMenuItem(item)}
        <div className="hidden-dropdown-item">
          {renderMenuItem(item)}
        </div>
      </div>
    ));
  }

  return (
    <Dropdown
      className="DropdownMenu"
      onToggle={props.onToggle}
    >
      <Dropdown.Toggle
        id="dropdown-toggle-button"
        bsPrefix="p-0"
        aria-label={props.ariaLabel}
      >
        {props.children}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {renderMenuItems()}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownMenu;

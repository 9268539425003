import { DeveloperTool, DeveloperToolsPageActions } from "../../redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { teamTypeLabelMap } from "../../../../../common/data/team-types";

export function useDeveloperToolDetailsPageTitleControllerProvider(props: { developerTool: DeveloperTool }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(DeveloperToolsPageActions.updateDeveloperToolsPageState({ showDownloadDeveloperToolModal: false }));
  }, []);

  return ({
    developerTool: props.developerTool,
    getTeamTypeLabelMap: () => teamTypeLabelMap[props.developerTool.team.teamType],
    onClickDownload: () => dispatch(DeveloperToolsPageActions.updateDeveloperToolsPageState({ showDownloadDeveloperToolModal: true })),
  });
}

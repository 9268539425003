import { PEV, ProgressErrorValue } from "../../../utils/progress-error-value";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { countryRegionalItLeadsService } from "../../services/country-regional-it-leads";
import { INeoErrorInfo, NeoError } from "../../../utils/errors/neo-errors";
import { Nullable } from "../../../utils/nullable";
import { createAsyncThunkWithRejectValue } from "../createAsyncThunkWithRejectValue";

export interface ICountryRegionalItLead {
  name: string;
  iso3Code: string;
  countryCode: string;
  status: string;
  regionalItLeadEmployeeId: string;
  regionalItLeadName: string;
  regionalItLeadEmailId: string;
  businessUnit: string;
}

type ICountryRegionalItLeadsState = ProgressErrorValue<ICountryRegionalItLead[], Nullable<string>, Nullable<INeoErrorInfo>>;

const initialState: ICountryRegionalItLeadsState = PEV([]);

const fetchCountryRegionalITLeads = createAsyncThunkWithRejectValue<ICountryRegionalItLead[]>(
  "country-regional-it-leads",
  countryRegionalItLeadsService.fetchCountryRegionalITLeads,
);

export const countryRegionalItLeadsSlices = createSlice({
  name: "countryRegionalItLeads",
  initialState,
  reducers: {},
  extraReducers: ((builder) => {
    builder
      .addCase(fetchCountryRegionalITLeads.pending, () =>
        PEV([], "Fetching country Regional IT leads"),
      )
      .addCase(fetchCountryRegionalITLeads.fulfilled, (state, action) =>
        PEV(action.payload, null, null),
      )
      .addCase(fetchCountryRegionalITLeads.rejected.type, (state, action: PayloadAction<NeoError>) =>
        PEV([], null, action.payload.getErrorInfo()),
      );
  }),
});

export const dispatchFetchCountryRegionalItLeadsIfEmpty = (
  dispatch: Dispatch<ReturnType<typeof fetchCountryRegionalITLeads>>,
  countryRegionalItLeadsPEV: ICountryRegionalItLeadsState,
) => {
  if (countryRegionalItLeadsPEV.value.length === 0 && !countryRegionalItLeadsPEV.progress && !countryRegionalItLeadsPEV.error) {
    dispatch(fetchCountryRegionalITLeads());
  }
};

export const countryRegionalItLeadsReducer = countryRegionalItLeadsSlices.reducer;

import React from "react";
import "./index.scss";
import OfferingsTable from "./offerings-table";
import GetStartedBtn from "../../common/components/get-started-button";
import ContainerBox from "../../common/components/container-box";
import PageTitle from "../../common/components/page-title";
import useInfrastructureOfferingsController from "./controller";
import BackButton from "../../common/components/back-button";
import { createFunctionalComponentFromView } from "../../utils/view-controller";

const InfrastructureOfferings = createFunctionalComponentFromView<ReturnType<typeof useInfrastructureOfferingsController>>(
  useInfrastructureOfferingsController,
  () =>
    <div
      className="InfrastructureOfferings"
      data-testid="offerings"
    >
      <ContainerBox
        componentToRenderInEmptySpace={<BackButton/>}
        isDisplayingBackButton={true}
      >
        <PageTitle title="Infrastructure Offers"/>
        <OfferingsTable/>
        <div className="get-started-button">
          <GetStartedBtn/>
        </div>
      </ContainerBox>
    </div>,
);

export default InfrastructureOfferings;

import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import React from "react";
import "./index.scss";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import useDeveloperToolCardController from "./controller";


const DeveloperToolCard = createFunctionalComponentFromView(useDeveloperToolCardController, (controller) => (
  <Link
    to={{
      pathname: controller.redirectionPathname,
      state: { from: controller.currentPathname },
    }}
    className={"DeveloperToolCard"}
    onClick={controller.onClick}
  >
    <Card>
      <Card.Body>
        <h6
          className="card-title"
          title={controller.developerTool.name}
        >
          {controller.developerTool.name}
        </h6>
        <div className="description">{controller.developerTool.description}</div>
      </Card.Body>
      <div
        className="team-name"
        title={controller.developerTool.team.name}
      >
        Team: {controller.developerTool.team.name}
      </div>
    </Card>
  </Link>
));

export default DeveloperToolCard;

import React, { FunctionComponent } from "react";
import "./index.scss";

const NotPartOfTechOpsLegend: FunctionComponent = () => (
  <div className="NotPartOfTechOpsLegend">
    <span className="legend-title">** </span>
    Not part of TechOps
  </div>
);

export default NotPartOfTechOpsLegend;

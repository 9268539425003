import React, { FunctionComponent } from "react";
import "./index.scss";
import RightArrowIcon from "../../../common/components/icons/arrow-icon";
import NeoLink from "../../../common/components/neo-link";

export type ImageNavigationCardProps = {
  title: string;
  description: string;
  link: string;
  image: string;
  imageAltText: string;
  isExternalLink?: boolean;
  onTrackAnalytics?: () => void;
};

const ImageNavigationCard: FunctionComponent<ImageNavigationCardProps> = (props: ImageNavigationCardProps) => {
  const renderCard = () => (
    <div className="card">
      <div
        style={{ backgroundImage: `url(${props.image})` }}
        className="image"
      />
      <section>
        <h6>{props.title}</h6>
        <p>{props.description}</p>
        <RightArrowIcon/>
      </section>
    </div>
  );

  const renderCardsWithExternalLink = () => (
    <NeoLink
      link={props.link}
      onClick={props.onTrackAnalytics}
      external
    >
      {renderCard()}
    </NeoLink>
  );

  const renderCardsWithInternalLink = () => (
    <NeoLink
      link={props.link}
      onClick={props.onTrackAnalytics}
    >
      {renderCard()}
    </NeoLink>
  );

  return (
    <div
      className="ImageNavigationCard"
      data-testid="ImageNavigationCard"
    >
      {props.isExternalLink ? renderCardsWithExternalLink() : renderCardsWithInternalLink()}
    </div>
  );
};

export default ImageNavigationCard;

import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { MyAppsAndServicesPageActions } from "./redux";
import Analytics from "../../../common/services/analytics";
import { useSelectorTyped } from "../../../common/redux/store";
import moment from "moment/moment";
import { ApplicationBasicInfo } from "../../applications/application-details/redux";
import { omit } from "lodash";
import { teamTypeLabelMap } from "../../../common/data/team-types";
import { getYesNoChoiceFromBoolean } from "../../../utils/forms";
import { formatDate } from "../../../utils/date-format";

export default function useMyAppsAndServicesController() {
  const dispatch = useDispatch();
  const {
    value: myApplications,
    progress: fetchMyApplicationsProgress,
    error: fetchMyApplicationsError,
  } = useSelectorTyped((state) => state.myAppsAndServicesPage.myApplicationsPEV);
  const filteredMyApplications = useSelectorTyped((state) => state.myAppsAndServicesPage.filteredMyApplications);

  useEffect(() => {
    Analytics.trackPageMyAppsAndServices();
    dispatch(MyAppsAndServicesPageActions.fetchMyApplications());
  }, []);

  const isUserAssociatedToAnyApplication = () => myApplications.length > 0;

  const isFilteredApplicationsListEmpty = () => filteredMyApplications.length === 0;

  return ({
    filteredMyApplications,
    filteredAppsAndServicesCount: filteredMyApplications.length,
    totalAppsAndServicesCount: myApplications.length,
    shouldRenderMyApplications: () => !fetchMyApplicationsError && !fetchMyApplicationsProgress && isUserAssociatedToAnyApplication(),
    shouldRenderNoApplicationsMessage: () => !fetchMyApplicationsError && !fetchMyApplicationsProgress && !isUserAssociatedToAnyApplication(),
    fetchMyApplicationsProgress,
    fetchMyApplicationsError,
    shouldRenderError: () => !!fetchMyApplicationsError,
    onClickRetryButton: () => {
      dispatch(MyAppsAndServicesPageActions.fetchMyApplications());
    },
    shouldRenderProgressMessage: () => fetchMyApplicationsProgress !== null && myApplications.length === 0,
    shouldRenderNoRecordsToDisplayMessage: () => filteredMyApplications.length === 0,
    shouldRenderExportCsvLink: () => !isFilteredApplicationsListEmpty(),
    getCSVFileName: () => `My_Apps_And_Services_${moment().format("YYYY-MM-DDTHH-mm-ss")}`,
    getCSVDataForDownload: () => {
      const escapeDoubleQuote = (textWithDoubleQuote: string) =>
        textWithDoubleQuote.replace(/"/g, "\"\"");

      return filteredMyApplications.map((application) => {
        const fieldNames: Array<keyof ApplicationBasicInfo> = [
          "name", "countryName", "country", "businessFunction", "category", "createdAt", "createdBy", "description",
          "endDate", "hasMnpi", "hasPii", "hasSensitivePii", "isDataClassificationEditable", "isEditable", "isStatusEditable",
          "lifespan", "name", "owner", "project", "purpose", "targetAudience", "team", "teamId", "teamType", "techStack", "updatedAt", "updatedBy",
          "id", "status", "decommissionReason",
        ];
        const dynamicFields: Record<string, unknown> = omit(application, fieldNames);
        Object.keys(dynamicFields).forEach((field) => {
          dynamicFields[field] = String(dynamicFields[field]);
        });

        return {
          "Team ID": application.team.id,
          "Team Name": escapeDoubleQuote(application.team.name),
          "Application ID": application.id,
          "Application Name": escapeDoubleQuote(application.name),
          Type: teamTypeLabelMap[application.team.teamType],
          Status: application.status,
          Description: escapeDoubleQuote(application.description),
          Purpose: application.purpose ?? "",
          "Target Audience": escapeDoubleQuote(application.targetAudience),
          "Business Function": application.businessFunction ?? "",
          Category: application.category,
          "Tech Stack": application.techStack,
          Owner: application.owner,
          Country: `${application.countryName} - ${application.country}`,
          MNPI: getYesNoChoiceFromBoolean(application.hasMnpi),
          PII: getYesNoChoiceFromBoolean(application.hasPii),
          "Sensitive PII": getYesNoChoiceFromBoolean(application.hasSensitivePii),
          "Open Team": getYesNoChoiceFromBoolean(application.team.isOpen),
          "End Date": formatDate(application.endDate),
          "Billing Project Name": application.project?.name,
          "Billing Project Code": application.project?.accountProjectCode,
          "Decommission Reason": application.decommissionReason ?? "",
          "Created By": application.createdBy,
          "Created At": formatDate(application.createdAt),
          "Updated By": application.updatedBy,
          "Updated At": formatDate(application.updatedAt),
          ...dynamicFields,
        };
      });
    },
  });
}

import { callBffApi } from "./axios";
import { ApplicationPurpose } from "../data/application-purpose";

type ApplicationPurposeResponse = {
  data: ApplicationPurpose[];
};

export const applicationPurposeService = {
  getApplicationPurposes: async (): Promise<ApplicationPurpose[]> => {
    const applicationPurpose = await callBffApi<ApplicationPurposeResponse>("get", "/application-purposes");
    return applicationPurpose.data;
  },
};

import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { HeaderDropdownMenuProps } from ".";

export default function useDropdownMenuController(props: HeaderDropdownMenuProps) {
  const history = useHistory();
  const [currentPath, setCurrentPath] = useState(history.location.pathname);
  useEffect(() => {
    history.listen((historyObj: { pathname: string }) => {
      setCurrentPath(historyObj.pathname);
    });
  }, [history]);

  const getHighlightClassForCurrentPath = () => {
    const url = props.url;
    return (url && (currentPath === url || currentPath.startsWith(`${url}/`))) ? "highlight" : "";
  };

  const shouldRenderDropDown = () => props.dropdownItems.length > 0;

  return {
    getHighlightClassForCurrentPath,
    shouldRenderDropDown,
  };
}

import React, { FunctionComponent, ReactNode } from "react";
import "./index.scss";

type Props = {
  label?: string;
  onClickHandler?: () => void;
  className?: string;
  disabled?: boolean;
  outline?: boolean;
  children?: ReactNode;
  size?: "s" | "m" | "l";
};

const ImpactXButton: FunctionComponent<Props> = (props: Props) => (
  <button
    className={`impact-x-button ${props.className || ""} `}
    onClick={() => {
      if (!props.disabled && props.onClickHandler) {
        props.onClickHandler();
      }
    }}
    aria-disabled={props.disabled}
    type="button"
    // eslint-disable-next-line unicorn/explicit-length-check
    ix-styles={`${props.size ? props.size : "m"} ${props.outline ? "outline" : ""}`}
  >
    {props.label || props.children}
  </button>
);

export default ImpactXButton;

import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { getStartedPageReducer } from "../../pages/get-started/redux";
import { headerReducer } from "../components/header/redux";
import { featureTogglesReducer } from "./slices/feature-toggles";
import { eventsReducer } from "../../pages/events/redux";
import { countryRegionalItLeadsReducer } from "./slices/country-regional-it-leads";
import { projectsReducer } from "./slices/projects";
import { applicationStatusReducer } from "./slices/application-status";
import { useSelector } from "react-redux";
import { teamCreationPageReducer } from "../../pages/create-team/redux";
import { teamDetailsPageReducer } from "../../pages/team-details/redux";
import { applicationPurposesReducer } from "./slices/application-purpose";
import { teamsPageReducer } from "../../pages/teams/redux";
import { applicationDetailsPageReducer } from "../../pages/applications/application-details/redux";
import { requestsPageReducer } from "../../pages/requests/redux";
import { businessFunctionLeadsReducer } from "./slices/business-functions";
import { applicationsCataloguePageReducer } from "../../pages/catalogue/applications/redux";
import { myAppsAndServicesPageReducer } from "../../pages/catalogue/my-apps-and-services/redux";
import { developerToolsPageReducer } from "../../pages/catalogue/developer-tools/redux";

const shouldEnableDevTools = () => process.env.NODE_ENV === "development";

export const store = configureStore({
  reducer: {
    getStartedPage: getStartedPageReducer,
    applicationDetailsPage: applicationDetailsPageReducer,
    header: headerReducer,
    countryRegionalItLeadsPEV: countryRegionalItLeadsReducer,
    businessFunctionLeadsPEV: businessFunctionLeadsReducer,
    projectsPEV: projectsReducer,
    applicationStatusListPEV: applicationStatusReducer,
    applicationPurposesPEV: applicationPurposesReducer,
    events: eventsReducer,
    featureTogglesPEV: featureTogglesReducer,
    teamCreationPage: teamCreationPageReducer,
    teamDetailsPage: teamDetailsPageReducer,
    teamsPage: teamsPageReducer,
    requestsPage: requestsPageReducer,
    applicationsCataloguePage: applicationsCataloguePageReducer,
    myAppsAndServicesPage: myAppsAndServicesPageReducer,
    developerToolsPage: developerToolsPageReducer,
  },
  devTools: shouldEnableDevTools(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

// TODO Replace all useSelector with our custom function below
export function useSelectorTyped<TSelected = unknown>(
  selector: (state: RootState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean,
): TSelected {
  return useSelector<RootState, TSelected>(selector, equalityFn);
}

export function createScriptElement(src: string, crossOrigin: string = "anonymous") {
  const element = document.createElement<"script">("script");
  element.setAttribute("src", src);
  element.setAttribute("crossOrigin", crossOrigin);
  return element;
}

export function createLinkElement(href: string) {
  const linkElement = document.createElement<"link">("link");
  linkElement.setAttribute("rel", "stylesheet");
  linkElement.setAttribute("href", href);
  return linkElement;
}

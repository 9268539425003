import { ActionCreatorWithPayload, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  experimentsAndInnovationsCategoryActions,
  experimentsAndInnovationsInitialState,
  experimentsAndInnovationsReducer,
  IExperimentsAndInnovationsFormData,
} from "./experiments-and-innovations/redux";
import { IRegionalItFormData, RegionalITCategoryActions, regionalITInitialState, regionalITReducer } from "./regional-it/redux";
import { ITechOpsFormData, TechOpsCategoryActions, techOpsInitialState, techOpsReducer } from "./techops/redux";
import { PEV, ProgressErrorValue } from "../../utils/progress-error-value";
import * as service from "./service";
import { Nullable } from "../../utils/nullable";
import { ITeam } from "../team-details/redux";
import { INeoErrorInfo, NeoError } from "../../utils/errors/neo-errors";
import { createAsyncThunkWithRejectValue } from "../../common/redux/createAsyncThunkWithRejectValue";
import { ApplicationBasicInfo } from "../applications/application-details/redux";
import castTo, { identity } from "../../utils/cast-to";
import {
  getApplicationCategoryIfOthers,
  getGcpProjectNamesIfNeeded,
  getGithubOrganisationIfNeeded,
  getGitHubRepoIfNeeded,
  getGithubUsernameIfNeeded,
  getTechStackIfOthers,
  isCiCdPipelineNeeded,
  isCodeRepositoryNeeded,
  isHostingServiceNeeded,
} from "./data/common-functions";
import { TeamType } from "../create-team/redux";
import { ApplicationPurpose } from "../../common/data/application-purpose";

export enum ProjectClassification {
  NONE = "NONE",
  EXPERIMENT_AND_INNOVATION = "EXPERIMENT_AND_INNOVATION",
  REGIONAL_IT = "REGIONAL_IT",
  TECHOPS = "TECHOPS",
}

export enum TeamStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum TeamStatusLabel {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}

export type CurrentStep = "PROJECT_CLASSIFICATION" | "TEAM_SELECTION" | "BASIC_INFO" | "INFRA_CONFIG" | "REVIEW_SUBMIT";

export interface ICreateApplication {
  applicationId: string;
  applicationName: string;
}

export interface IGetStartedState {
  selectedProjectClassification: ProjectClassification;
  currentStep: CurrentStep;
  experimentsAndInnovations: IExperimentsAndInnovationsFormData;
  regionalIT: IRegionalItFormData;
  techOps: ITechOpsFormData;
  createApplication: ProgressErrorValue<Nullable<ApplicationBasicInfo>>;
  teamListForSelectedCategoryPEV: ProgressErrorValue<ITeam[], Nullable<string>, Nullable<INeoErrorInfo>>;
  teamListForRegionalITPEV: ProgressErrorValue<ITeam[], Nullable<string>, Nullable<INeoErrorInfo>>;
  teamListForTechOpsPEV: ProgressErrorValue<ITeam[], Nullable<string>, Nullable<INeoErrorInfo>>;
  showCreateApplicationNotApplicablePage: boolean;
}

const initialState: IGetStartedState = {
  selectedProjectClassification: ProjectClassification.NONE,
  currentStep: "PROJECT_CLASSIFICATION",
  experimentsAndInnovations: experimentsAndInnovationsInitialState,
  regionalIT: regionalITInitialState,
  techOps: techOpsInitialState,
  createApplication: PEV(null),
  teamListForSelectedCategoryPEV: PEV([]),
  teamListForRegionalITPEV: PEV([]),
  teamListForTechOpsPEV: PEV([]),
  showCreateApplicationNotApplicablePage: false,
};

export type ApplicationFormData = IExperimentsAndInnovationsFormData | ITechOpsFormData | IRegionalItFormData;

export type ApplicationCreationRequest = {
  basicInfo: {
    applicationName: string;
    applicationDescription: string;
    country: string;
    targetAudience: string;
    businessFunction?: string;
    applicationCategory: string[];
    applicationOwner: string[];
    techStack?: string[];
    teamId: string;
    purpose: ApplicationPurpose | string;
    hasMnpi: boolean;
    hasPii: boolean;
    hasSensitivePii: boolean;
    endDate?: DateOnly;
    projectId?: string;
  };
  infraConfig: {
    isCodeRepositoryNeeded: boolean;
    githubRepo: string[];
    isCiCdPipelineNeeded: boolean;
    isHostingServiceNeeded: boolean;
    cloudProjectNames?: string[];
    billingProjectId?: string;
    comment: string;
    githubUsername: string;
    githubOrganisation: string;
  };
};

const createApplication = createAsyncThunk(
  "application/create",
  async ({ application }: { application: ApplicationFormData; teamType: TeamType }) => {
    const { endDate } = castTo<IExperimentsAndInnovationsFormData>(application).basicInfo;
    const applicationCreationRequestData = identity<ApplicationCreationRequest>({
      basicInfo: {
        applicationName: application.basicInfo.projectName,
        applicationDescription: application.basicInfo.projectDescription,
        purpose: application.basicInfo.purpose,
        country: application.basicInfo.countryCode,
        targetAudience: application.basicInfo.targetAudience,
        businessFunction: application.basicInfo.businessFunction ? application.basicInfo.businessFunction : undefined,
        applicationCategory: getApplicationCategoryIfOthers(application),
        applicationOwner: application.basicInfo.projectOwner,
        techStack: getTechStackIfOthers(application),
        teamId: application.basicInfo.teamId,
        hasMnpi: application.basicInfo.hasMnpi ?? false,
        hasPii: application.basicInfo.hasPii ?? false,
        hasSensitivePii: application.basicInfo.hasSensitivePii ?? false,
        endDate,
        ...application.basicInfo.billingProjectId &&
        { projectId: application.basicInfo.billingProjectId },
      },
      infraConfig: {
        isCodeRepositoryNeeded: isCodeRepositoryNeeded(application),
        githubRepo: getGitHubRepoIfNeeded(application),
        isCiCdPipelineNeeded: isCiCdPipelineNeeded(application),
        isHostingServiceNeeded: isHostingServiceNeeded(application),
        cloudProjectNames: getGcpProjectNamesIfNeeded(application),
        comment: application.infraConfig.comment,
        githubUsername: getGithubUsernameIfNeeded(application),
        githubOrganisation: getGithubOrganisationIfNeeded(application),
      },
    });
    return service.createApplication(applicationCreationRequestData);
  },
);

const fetchMyActiveTeamsWithCategory = createAsyncThunkWithRejectValue<ITeam[], ProjectClassification>(
  "/my-teams-with-category",
  async (teamType: ProjectClassification) => service.fetchMyTeams(teamType, TeamStatus.ACTIVE),
);

const fetchMyActiveTeamsForRegionalIT = createAsyncThunkWithRejectValue<ITeam[]>(
  "/my-teams-for-regional-it",
  async () => service.fetchMyTeams(ProjectClassification.REGIONAL_IT, TeamStatus.ACTIVE),
);

const fetchMyActiveTeamsForTechOps = createAsyncThunkWithRejectValue<ITeam[]>(
  "/my-teams-for-tech-ops",
  async () => service.fetchMyTeams(ProjectClassification.TECHOPS, TeamStatus.ACTIVE),
);

export const getStartedPageSlice = createSlice({
  name: "getStartedPage",
  initialState,
  reducers: {
    updateGetStartedPageState: (state: IGetStartedState, action: PayloadAction<Partial<IGetStartedState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: {
    [experimentsAndInnovationsCategoryActions.updateInfraConfig.type]: (state, action) => {
      state.experimentsAndInnovations = experimentsAndInnovationsReducer(state.experimentsAndInnovations, action);
    },
    [experimentsAndInnovationsCategoryActions.updateBasicInfo.type]: (state, action) => {
      state.experimentsAndInnovations = experimentsAndInnovationsReducer(state.experimentsAndInnovations, action);
    },
    [RegionalITCategoryActions.updateBasicInfo.type]: (state, action) => {
      state.regionalIT = regionalITReducer(state.regionalIT, action);
    },
    [RegionalITCategoryActions.updateInfraConfig.type]: (state, action) => {
      state.regionalIT = regionalITReducer(state.regionalIT, action);
    },
    [TechOpsCategoryActions.updateBasicInfo.type]: (state, action) => {
      state.techOps = techOpsReducer(state.techOps, action);
    },
    [TechOpsCategoryActions.updateInfraConfig.type]: (state, action) => {
      state.techOps = techOpsReducer(state.techOps, action);
    },
    [createApplication.pending.type]: (state) => {
      state.createApplication = PEV(null, "create application is in progress");
    },
    [createApplication.fulfilled.type]: (state, action: PayloadAction<ApplicationBasicInfo, never>) => {
      state.createApplication = PEV(action.payload);
    },
    [createApplication.rejected.type]: (state, action: PayloadAction<never, never, never, Error>) => {
      state.createApplication = PEV(null, null, `Error happened in application creation ${action.error.message}`);
    },
    [fetchMyActiveTeamsWithCategory.pending.type]: (state) => {
      state.teamListForSelectedCategoryPEV = PEV(state.teamListForSelectedCategoryPEV.value, "Fetching Associated teams");
    },
    [fetchMyActiveTeamsWithCategory.fulfilled.type]: (state, action: PayloadAction<ITeam[], never>) => {
      state.teamListForSelectedCategoryPEV = PEV(action.payload);
    },
    [fetchMyActiveTeamsWithCategory.rejected.type]: (state, action: PayloadAction<NeoError>) => {
      state.teamListForSelectedCategoryPEV = PEV([], null, action.payload.getErrorInfo());
    },
    [fetchMyActiveTeamsForRegionalIT.pending.type]: (state) => {
      state.teamListForRegionalITPEV = PEV(state.teamListForRegionalITPEV.value, "Fetching Associated teams");
    },
    [fetchMyActiveTeamsForRegionalIT.fulfilled.type]: (state, action: PayloadAction<ITeam[], never>) => {
      state.teamListForRegionalITPEV = PEV(action.payload);
    },
    [fetchMyActiveTeamsForRegionalIT.rejected.type]: (state, action: PayloadAction<NeoError>) => {
      state.teamListForRegionalITPEV = PEV([], null, action.payload.getErrorInfo());
    },
    [fetchMyActiveTeamsForTechOps.pending.type]: (state) => {
      state.teamListForTechOpsPEV = PEV(state.teamListForTechOpsPEV.value, "Fetching Associated teams");
    },
    [fetchMyActiveTeamsForTechOps.fulfilled.type]: (state, action: PayloadAction<ITeam[], never>) => {
      state.teamListForTechOpsPEV = PEV(action.payload);
    },
    [fetchMyActiveTeamsForTechOps.rejected.type]: (state, action: PayloadAction<NeoError>) => {
      state.teamListForTechOpsPEV = PEV([], null, action.payload.getErrorInfo());
    },
  },
});

export const getStartedPageReducer = getStartedPageSlice.reducer;

const updateGetStartedPageState: ActionCreatorWithPayload<Partial<IGetStartedState>> =
  getStartedPageSlice.actions.updateGetStartedPageState;

const updateCurrentStep = (currentStep: CurrentStep) => updateGetStartedPageState({
  currentStep,
});

const updateShowCreateApplicationNotApplicablePage = (showCreateApplicationNotApplicablePage: boolean) => updateGetStartedPageState({
  showCreateApplicationNotApplicablePage,
});

const updateSelectedProjectClassification =
  (selectedProjectClassification: ProjectClassification, nextStep: CurrentStep = "BASIC_INFO") =>
    updateGetStartedPageState({
      selectedProjectClassification,
      currentStep: nextStep,
    });

const resetState = () => updateGetStartedPageState({
  experimentsAndInnovations: experimentsAndInnovationsInitialState,
  regionalIT: regionalITInitialState,
  techOps: techOpsInitialState,
  createApplication: PEV(null),
  selectedProjectClassification: ProjectClassification.NONE,
  currentStep: "PROJECT_CLASSIFICATION",
});

export const GetStartedPageActions = {
  updateCurrentStep,
  updateSelectedProjectClassification,
  resetState,
  createApplication,
  fetchMyActiveTeamsWithCategory,
  updateShowCreateApplicationNotApplicablePage,
  fetchMyActiveTeamsForRegionalIT,
  fetchMyActiveTeamsForTechOps,
};

import { useRerenderComponentWhenMicroFrontendIsLoaded } from "../../common/hooks/mff-loaded";
import { useHistory } from "react-router-dom";
import { IMicroFrontendData } from "../../common/data/microfrontend-types";

export type IMicroFrontedProps = {
  microFrontedData: IMicroFrontendData;
};

export const useMicroFrontendControllerProvider = (props: IMicroFrontedProps) => {
  useRerenderComponentWhenMicroFrontendIsLoaded(props.microFrontedData);
  return {
    microFrontendData: props.microFrontedData,
    history: useHistory(),
  };
};




import React, { FunctionComponent } from "react";
import "./details-list.scss";
import NeoLink from "../../../common/components/neo-link";

type Props = {
  fieldName?: string;
  detailsList: string[];
  renderUrlsAsHyperLink?: boolean;
};


const DetailsList: FunctionComponent<Props> = ({ fieldName, detailsList, renderUrlsAsHyperLink }: Props) => {
  function shouldRenderDetailsList() {
    return !!detailsList && detailsList.length > 0;
  }

  function isHyperLink(detail: string) {
    return !!renderUrlsAsHyperLink && (detail.startsWith("http://") || detail.startsWith("https://"));
  }

  const renderTitle = () => {
    if (fieldName) {
      return (
        <div className="info-title">
          {fieldName}
        </div>
      );
    }
  };

  return (
    <div
      className={"DetailsList"}
      key={fieldName}
    >
      {renderTitle()}
      <div className="details-list">
        {
          shouldRenderDetailsList() ?
            detailsList.map((detail, idx) => (
              <div key={idx}>
                {
                  isHyperLink(detail)
                    ? (
                      <NeoLink
                        link={detail}
                        className="details-link"
                        external
                      />
                    )
                    : detail
                }
              </div>
            ))
            : "---"
        }
      </div>
    </div>
  );
};

export default DetailsList;

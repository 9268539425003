import { getDisapproveTaskQuestions } from "./data/questions";
import { useDispatch } from "react-redux";
import { ITask } from "../../types/requests";
import { RequestsPageActions } from "../../redux";
import { useSelectorTyped } from "../../../../common/redux/store";

type DisapproveTaskModalProps = {
  taskDetails: ITask;
};

export default function useDisapproveTaskController(props: DisapproveTaskModalProps) {
  const dispatch = useDispatch();
  const disapproveTaskPE = useSelectorTyped((state) => state.requestsPage.disapproveTaskPE);
  return ({
    ...props,
    showModal: useSelectorTyped((state) => state.requestsPage.showDisapproveTaskModal),
    isDisapprovingTaskInProgress: !!disapproveTaskPE.progress,
    questions: getDisapproveTaskQuestions,
    onCancel: () => dispatch(RequestsPageActions.updateRequestDetailsPageState({ showDisapproveTaskModal: false })),
    onClickDisapproveButton: (formData: { disapprovalReason: string }) => {
      dispatch(RequestsPageActions.disapproveTask({
        requestId: props.taskDetails.requestId,
        taskId: props.taskDetails.id,
        requestBody: {
          disapprovalReason: formData.disapprovalReason,
        },
      }));
    },
    getInitialFormData: () => ({
      disapprovalReason: "",
    }),
    getDisapproveButtonLabel: () => disapproveTaskPE.progress ? "Disapproving" : "Disapprove",
  });
}

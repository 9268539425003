import { getEnvVars } from "../../../common/config/env";
import { TextNavigationCardProps } from "../text-navigation";

const { diSupportMailId, feedbackEmailId } = getEnvVars();

export const startBuildingData: TextNavigationCardProps[] = [
  {
    text: "Create teams",
    link: "/teams",
  },
  {
    text: "Request for additional infra",
    link: `mailto:${diSupportMailId}`,
    isExternalLink: true,
  },
  {
    text: "Explore applications",
    link: "/applications",
  },
  {
    text: "Consume API",
    link: "/api-gateway/api-user-guide#section/Consume-API",
  },
  {
    text: "Subscribe to Events",
    link: "/events/user-guide#section/Subscribe-to-Events",
  },
  {
    text: "Looking for something else?",
    link: `mailto:${feedbackEmailId}`,
    isExternalLink: true,
  },
];

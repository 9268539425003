import { getDownloadDeveloperToolQuestions } from "./data/questions";
import { DeveloperTool, DeveloperToolsPageActions, DownloadDeveloperToolMetrics } from "../../redux";
import { useSelectorTyped } from "../../../../../common/redux/store";
import { useDispatch } from "react-redux";
import Analytics from "../../../../../common/services/analytics";

export type Props = {
  developerTool: DeveloperTool;
};


export default function useDownloadDeveloperToolController(props: Props) {
  const dispatch = useDispatch();

  const closeDownloadDeveloperToolModal = () =>
    dispatch(DeveloperToolsPageActions.updateDeveloperToolsPageState({ showDownloadDeveloperToolModal: false }));

  return ({
    developerToolName: props.developerTool.name,
    showModal: useSelectorTyped((state) => state.developerToolsPage.showDownloadDeveloperToolModal),
    questions: getDownloadDeveloperToolQuestions,
    onCancel: closeDownloadDeveloperToolModal,
    onClickDownloadButton: (formData: DownloadDeveloperToolMetrics) => {
      dispatch(DeveloperToolsPageActions.captureDownloadDeveloperToolMetrics({
        developerToolId: props.developerTool.id,
        purpose: formData.purpose,
        accountName: formData.accountName,
      }));
      Analytics.trackEventClickOnDownloadDeveloperToolButton(props.developerTool.name);
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(props.developerTool.repoZipFileUrl, "_blank");
      closeDownloadDeveloperToolModal();
    },
    getInitialFormData: () => ({
      purpose: "",
      accountName: "",
    }),
  });
}

import { callFeatureToggleApi } from "./axios";
import { IFeatureToggle } from "../redux/slices/feature-toggles";

type FeatureTogglesResponse = {
  "version": string;
  features: IFeatureToggle[];
};

export const getFeatureToggles = async (): Promise<FeatureTogglesResponse> => {
  const result = await callFeatureToggleApi<FeatureTogglesResponse>("get", "/api/client/features");
  return result;
};

import React from "react";
import "./index.scss";
import useApplicationActivitiesController, { ApplicationAction, ApplicationActivityTableData, RequestAction } from "./controller";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import Progress from "../../../../common/components/progress";
import TableSortIcon from "../../../../common/components/data-table-sort-icon";
import { ExportCSVLink } from "../../../../common/components/export-csv";
import Analytics from "../../../../common/services/analytics";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../../common/components/error-new";
import { ACTIVITY_HISTORY_TIME_FORMAT, formatDate } from "../../../../utils/date-format";
import NeoLink from "../../../../common/components/neo-link";

export const ApplicationActivitiesTable = () => {
  const controller = useApplicationActivitiesController();

  const renderDescription = (row: ApplicationActivityTableData) => {
    const action = (row.action as RequestAction);
    if (action.hyperLinkLabel && action.requestId) {
      return (
        <div>
          <NeoLink
            className={"link"}
            link={`/requests/${action.requestId}`}
          >
            {action.hyperLinkLabel}
          </NeoLink> {action.hyperLinkLabelSuffixText}
        </div>
      );
    }
    return (row.action as ApplicationAction).text;
  };

  const columns: Array<IDataTableColumn<ApplicationActivityTableData>> = [
    {
      name: "Date",
      selector: "occurredAt",
      cell: (row) => formatDate(row.occurredAt, ACTIVITY_HISTORY_TIME_FORMAT),
      sortable: true,
      sortFunction: (row1, row2) => controller.sortActivityByOccurredTime(row2, row1),
      grow: 1,
    },
    {
      name: "Description",
      selector: "action.text",
      sortable: true,
      grow: 3,
      cell: renderDescription,
    },
    {
      name: "User",
      selector: "performedBy",
      cell: (row) => row.performedBy ?? "--",
      sortable: true,
      grow: 1.5,
    },
  ];

  const renderActivityHistoryTable = () => (
    <DataTable
      className={"ApplicationActivitiesTable"}
      columns={columns}
      data={controller.getApplicationActivitiesTableData()}
      noHeader={true}
      sortIcon={<TableSortIcon/>}
      persistTableHead={true}
      defaultSortField="occurredAt"
      defaultSortAsc={false}
      pagination={true}
      paginationPerPage={10}
      paginationRowsPerPageOptions={controller.getPaginationRowsPerPageOptions()}
    />
  );

  function renderExportCSVLink() {
    if (controller.shouldRenderExportCSVLink()) {
      return (
        <ExportCSVLink
          data={controller.getExportCSVData()}
          filename={controller.getCSVFileName()}
          onClick={() => Analytics.trackEventExportCSVActivityHistoryOfApplication()}
        />
      );
    }
  }

  const renderContent = () => {
    if (controller.shouldRenderProgressMessage()) {
      return (
        <Progress text={controller.progressMessage}/>
      );
    }
    if (controller.shouldRenderErrorMessage()) {
      return (
        <Error
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          {...mapApplicationErrorInfoToErrorComponentProps(controller.errorMessage!)}
        />
      );
    }
    if (controller.shouldRenderNoActivitiesMessage()) {
      return (
        <span>
          There is no activity performed on the application.
        </span>
      );
    }
    return (
      <div>
        {renderActivityHistoryTable()}
      </div>
    );
  };

  return (
    <div className="ApplicationActivities">
      <div className="Heading">
        <div className="title">Activity History</div>
        <div className={"export-csv"}>
          {renderExportCSVLink()}
        </div>
      </div>
      {renderContent()}
    </div>
  );
};

import React, { FunctionComponent } from "react";
import { Nullable } from "../../../utils/nullable";
import "./review-and-submit.scss";

type Props = {
  title: string;
  details: Nullable<string>;
  className?: string;
};

const getDetails = (details: Props["details"]) => details ? details : "---";

const ReviewDetails: FunctionComponent<Props> = (props: Props) => (
  <div className={props.className}>
    <div className="details-title">{props.title}</div>
    <div className="details">{getDetails(props.details)}</div>
  </div>
);

export default ReviewDetails;

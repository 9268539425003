import useDownloadDeveloperToolController from "./controller";
import React from "react";
import "./index.scss";
import { createFunctionalComponentFromView } from "../../../../../utils/view-controller";
import NeoModalDialog from "../../../../../common/components/neo-modal-dialog";
import { NavigationButtons } from "../../../../../common/components/forms/navigation-buttons";
import FormComponent, { INavigationButtonsProps } from "../../../../../common/components/forms";

export const DownloadDeveloperToolModal = createFunctionalComponentFromView(useDownloadDeveloperToolController, (controller) => {
  function renderNavigationButtons({ getFormData, doesFormHasErrors }: INavigationButtonsProps) {
    return (
      <NavigationButtons
        nextButtonLabel="Download"
        previousButtonLabel="Cancel"
        getFormData={getFormData}
        doesFormHasErrors={doesFormHasErrors}
        onClickNext={controller.onClickDownloadButton}
        onClickPrevious={controller.onCancel}
      />
    );
  }

  return (
    <NeoModalDialog
      dialogClassName="DownloadDeveloperToolModal"
      dialogTitle={`Download ${controller.developerToolName}`}
      show={controller.showModal}
      onHide={controller.onCancel}
    >
      <FormComponent
        questions={controller.questions()}
        data={controller.getInitialFormData()}
        renderNavigationButtons={renderNavigationButtons}
      />
    </NeoModalDialog>
  );
},
);

import React, { FunctionComponent } from "react";
import useAddTeamMembersController from "./controller";
import NeoModalDialog from "../../../common/components/neo-modal-dialog";
import FormComponent, { INavigationButtonsProps } from "../../../common/components/forms";
import { NavigationButtons } from "../../../common/components/forms/navigation-buttons";
import "./index.scss";
import Progress from "../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../common/components/error-new";
import NonTechOpsMembersAlert from "../../../common/components/non-techops-members-alert";
import NotPartOfTechOpsLegend from "../../../common/components/not-part-of-techops-legend";

// TODO change folder name to add-team-members-form/dialog
const AddTeamMembersForm: FunctionComponent = () => {
  const controller = useAddTeamMembersController();

  function renderNavigationButtons({ getFormData, doesFormHasErrors }: INavigationButtonsProps) {
    return (
      <NavigationButtons
        nextButtonLabel={controller.getAddButtonLabel()}
        previousButtonLabel="Cancel"
        getFormData={getFormData}
        doesFormHasErrors={doesFormHasErrors}
        onClickNext={controller.onAddButtonClick}
        onClickPrevious={controller.onCancel}
        isNextButtonDisabled={controller.isAddMembersInProgress}
        isPreviousButtonDisabled={controller.isAddMembersInProgress}
      />
    );
  }

  function renderNotPartOfTechopsLegend() {
    if (controller.shouldDifferentiateNonTechOpsMembers) {
      return <NotPartOfTechOpsLegend/>;
    }
  }

  function renderNonTechOpsMembersAlert() {
    if (controller.shouldRenderNonTechOpsMembersAlert()) {
      return (
        <NonTechOpsMembersAlert
          selectedMembers={controller.selectedNonTechOpsMembers}
          message={controller.NonTechOpsMembersAlertMessage}
        />
      );
    }
  }

  function renderAdditionalFormContent() {
    return (
      <>
        {renderNotPartOfTechopsLegend()}
        {renderNonTechOpsMembersAlert()}
      </>
    );
  }

  const renderForm = () => (
    <FormComponent
      questions={controller.questions}
      data={controller.getFormData()}
      renderNavigationButtons={renderNavigationButtons}
      handleValueChanged={controller.handleFormValueChangedEvent}
      renderAdditionalContent={renderAdditionalFormContent}
    />
  );


  function renderModalContent() {
    return (
      <div className="AddMembers">
        {controller.shouldRenderProgress() && <Progress text={controller.progressText}/>}
        {controller.shouldRenderError() &&
          <Error
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            {...mapApplicationErrorInfoToErrorComponentProps(controller.errorText!)}
          />}
        {controller.shouldRenderForm() && renderForm()}
      </div>
    );
  }

  const renderAddMembersModal = () => (
    <NeoModalDialog
      dialogTitle="Add Members"
      dialogClassName={"add-members-modal"}
      show={controller.addMembersModalShow}
      onHide={controller.onCancel}
    >
      {renderModalContent()}
    </NeoModalDialog>
  );

  return renderAddMembersModal();
};

export default AddTeamMembersForm;

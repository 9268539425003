import { ActionCreatorWithPayload, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PEV, ProgressErrorValue } from "../../../utils/progress-error-value";
import { UserClaims } from "@okta/okta-auth-js";
import { INeoErrorInfo, NeoError } from "../../../utils/errors/neo-errors";
import { Nullable } from "../../../utils/nullable";
import { createAsyncThunkWithRejectValue } from "../../redux/createAsyncThunkWithRejectValue";
import * as service from "./service";
import { ApplicationStatus } from "../../data/application-status";
import { DeveloperTool } from "../../../pages/catalogue/developer-tools/redux";

export type IUserAdditionalInfo = {
  employeeId: string;
  emailId: string;
  firstName: string;
  lastName: string;
  thoughtworksRole: string;
  grade: string;
  departmentCode: string;
  departmentName: string;
  homeOffice: string;
  country: string;
  employmentType: string;
  isTechOpsMember: boolean;
  isRegionalITMember: boolean;
  shouldTrackAnalytics: boolean;
  isTechOpsAdmin: boolean;
};

export type IResourceConfig = {
  resourceType: string;
  resourceTypeReadableLabel: string;
  accordionTitle: string;
  requestType: string;
  resourceDetails: Array<{
    fieldName: string;
    type: "text" | "dropdown";
    fieldLabelInUi: string;
    options?: string[];
    notes?: string;
  }>;
};

export type IDeveloperToolConfig = {
  description: string;
};

export type User = {
  name: ProgressErrorValue<string, Nullable<string>, Nullable<INeoErrorInfo>>;
  email: ProgressErrorValue<string, Nullable<string>, Nullable<INeoErrorInfo>>;
  additionalInfo: ProgressErrorValue<Nullable<IUserAdditionalInfo>, Nullable<string>, Nullable<INeoErrorInfo>>;
};

export type TaxonomyCategoryReadableLabel = string;

export type UiConfig = {
  externalLinks: {
    helpDocumentLink: string;
    surveyLink: string;
    developerToolHelpDocumentLink: string;
    oktaChicletHelpDocumentLink: string;
  };
  decommissionReasons: string[];
  internalLinks: {
    regionalItLeadsMasterDataLink: string;
  };
  emails: {
    neoSupportEmail: string;
    diSupportEmail: string;
  };
  activeApplicationStatuses: ApplicationStatus[];
  enableConfetti: boolean;
  whatsRecent: {
    title: string;
    description: string;
    buttonName: string;
    link: string;
    isExternalLink?: boolean;
  };
  labelMaps: {
    requestTypeLabelMap: Record<string, string>;
    taskTypeLabelMap: Record<string, string>;
    taxonomyCategoryLabelMap: Record<TaxonomyCategoryReadableLabel, DeveloperTool["taxonomyCategory"]>;
  };
  resourceConfig: IResourceConfig[];
  developerToolConfig: IDeveloperToolConfig;
  productionGCPProjectDisablingDate: string;
  defaultProjectIdForExperimentAndInnovationApp: string;
};

export type HeaderState = {
  userInfo: User;
  uiConfigsPEV: ProgressErrorValue<UiConfig, Nullable<string>, Nullable<INeoErrorInfo>>;
};

const UserInitialState: User = {
  name: PEV(""),
  email: PEV(""),
  additionalInfo: PEV(null),
};

const initialState: HeaderState = {
  userInfo: UserInitialState,
  uiConfigsPEV: PEV({
    activeApplicationStatuses: [],
    decommissionReasons: [],
    enableConfetti: false,
    externalLinks: {
      helpDocumentLink: "",
      surveyLink: "https://idea-widget.ideanote.io/idea-submission?config=06411c40",
      developerToolHelpDocumentLink: "",
      oktaChicletHelpDocumentLink: "",
    },
    internalLinks: {
      regionalItLeadsMasterDataLink: "",
    },
    emails: {
      neoSupportEmail: "",
      diSupportEmail: "",
    },
    whatsRecent: {
      title: "4 Key Metrics",
      description: "Let's visualize the software delivery performance for your team automatically!",
      buttonName: "View reports",
      link: "/reports/4km",
      isExternalLink: false,
    },
    labelMaps: {
      requestTypeLabelMap: {},
      taskTypeLabelMap: {},
      taxonomyCategoryLabelMap: { OTHER_TOOLS: "Other tools" },
    },
    resourceConfig: [],
    developerToolConfig: {
      description: "",
    },
    productionGCPProjectDisablingDate: "",
    defaultProjectIdForExperimentAndInnovationApp: "",
  }),
};

const fetchUserInfo = createAsyncThunkWithRejectValue(
  "/fetch-user-info",
  async (getUser: () => Promise<{ name: string; email: string } | UserClaims>) => getUser(),
);

const fetchAdditionalUserInfo = createAsyncThunkWithRejectValue(
  "/fetch-additional-user-info",
  service.fetchUserAdditionalInfo,
);

const fetchUiConfigs = createAsyncThunkWithRejectValue(
  "/fetch-ui-configs",
  service.fetchUiConfigs,
);

export const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    updateHomePageState: (state: HeaderState, action: PayloadAction<Partial<HeaderState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: ((builder) => {
    builder
      .addCase(fetchUserInfo.pending, (state) => {
        state.userInfo.name = PEV("", "Fetching User Info");
        state.userInfo.email = PEV("", "Fetching User Info");
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.userInfo.name = PEV(action.payload.name ?? "");
        state.userInfo.email = PEV(action.payload.email ?? "");
      })
      .addCase(fetchUserInfo.rejected.type, (state, action: PayloadAction<NeoError>) => {
        state.userInfo.name = PEV("", null, action.payload.getErrorInfo());
        state.userInfo.email = PEV("", null, action.payload.getErrorInfo());
      })
      .addCase(fetchAdditionalUserInfo.pending, (state) => {
        state.userInfo.additionalInfo = PEV(null, "Fetching user additional info");
      })
      .addCase(fetchAdditionalUserInfo.fulfilled, (state, action) => {
        state.userInfo.additionalInfo = PEV(action.payload);
      })
      .addCase(fetchAdditionalUserInfo.rejected.type, (state, action: PayloadAction<NeoError>) => {
        state.userInfo.additionalInfo = PEV(null, null, action.payload.getErrorInfo());
      })
      .addCase(fetchUiConfigs.pending, (state) => {
        state.uiConfigsPEV = PEV(state.uiConfigsPEV.value, "Fetching ui configurations");
      })
      .addCase(fetchUiConfigs.fulfilled, (state, action) => {
        state.uiConfigsPEV = PEV({
          ...state.uiConfigsPEV.value,
          ...action.payload,
        });
      })
      .addCase(fetchUiConfigs.rejected.type, (state, action: PayloadAction<NeoError>) => {
        state.uiConfigsPEV = PEV(state.uiConfigsPEV.value, null, action.payload.getErrorInfo());
      });
  }),
});

export const headerReducer = headerSlice.reducer;
const updateHeaderState: ActionCreatorWithPayload<Partial<HeaderState>> = headerSlice.actions.updateHomePageState;

export const headerActions = {
  updateHeaderState,
  fetchUserInfo,
  fetchAdditionalUserInfo,
  fetchUiConfigs,
};

import axios, { AxiosError, AxiosInstance, AxiosResponse, Method } from "axios";
import { getEnvVars } from "../config/env";
import { getOktaIdToken } from "../components/authentication/data";
import { NeoError, NeoErrorType } from "../../utils/errors/neo-errors";

function getBffHostUrl() {
  const { bffHostUrl } = getEnvVars();
  return bffHostUrl;
}

function getBffEventsHostURL() {
  const { bffEventsHostURL } = getEnvVars();
  return bffEventsHostURL;
}

function getFeatureToggleHostUrl() {
  const { featureToggleHostUrl } = getEnvVars();
  return featureToggleHostUrl;
}

const bffAxios = axios.create({
  baseURL: getBffHostUrl(),
  timeout: 0,
});


const bffEventsAxios = axios.create({
  baseURL: getBffEventsHostURL(),
});

const featureToggleAxios = axios.create({
  baseURL: getFeatureToggleHostUrl(),
  timeout: 0,
});


export const extractErrorMsgFromAxiosError =
  (e: AxiosError<{ errorMessage: string }>, defaultMessage: string = "Unknown Error"): string => (
    e.response?.data?.errorMessage || defaultMessage
  );


function getErrorType(status: (AxiosResponse["status"]) | undefined, code: AxiosError["code"]) {
  let errorType: NeoError["errorType"] = NeoErrorType.SERVER_ERROR;
  if (!window.navigator.onLine) {
    errorType = NeoErrorType.INTERNET_ERROR;
  } else if (code === "ECONNABORTED") {
    errorType = NeoErrorType.REQUEST_TIMEOUT;
  } else if (status === 404) {
    errorType = NeoErrorType.RESOURCE_NOT_FOUND;
  } else if (status === 422) {
    errorType = NeoErrorType.UNPROCESSABLE_ENTITY;
  }
  return errorType;
}

const callAPI = async <ResponseType, PayloadType = unknown>(
  axiosInstance: AxiosInstance,
  method: Method,
  url: string,
  data?: PayloadType,
  params?: unknown,
): Promise<ResponseType> => {
  try {
    const result = await axiosInstance({
      method,
      url,
      data,
      params,
      headers: {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        Authorization: `Bearer ${getOktaIdToken()}`,
      },
    });
    return result.data as unknown as ResponseType;
  } catch (error) {
    const axiosError = error as AxiosError<{ errorMessage: string }>;
    const errorMsg = extractErrorMsgFromAxiosError(axiosError);
    const errorType = getErrorType(axiosError.response?.status, axiosError.code);
    throw new NeoError(errorType, errorMsg);
  }
};

export const callBffApi = async <ResponseType, PayloadType = unknown>
(method: Method, url: string, data?: PayloadType, params?: unknown): Promise<ResponseType> =>
  (callAPI(bffAxios, method, url, data, params));

export const callBffEventsAPI = async <ResponseType, PayloadType = unknown>
(method: Method, url: string, data?: PayloadType): Promise<ResponseType> =>
  (callAPI(bffEventsAxios, method, url, data));

export const callFeatureToggleApi = async <ResponseType, PayloadType = unknown>(
  method: Method,
  url: string,
  data?: PayloadType,
): Promise<ResponseType> => callAPI(featureToggleAxios, method, url, data);


export default bffAxios;

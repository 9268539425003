/**
 * Formats date in format month date,year
 * Ex: Oct 21,2021
 * @param {string} date - Date string to be formatted
 */
import { Nullable } from "./nullable";
import moment from "moment";

export const ACTIVITY_HISTORY_TIME_FORMAT = "MMM DD, YYYY HH:mm:ss";

export const formatDate = (date: Nullable<string> | undefined, format = "MMM DD, YYYY") => {
  if (date) {
    return moment(date).format(format);
  }
  return "";
};

export const getNumberOfDays = (startDateString: string | undefined, endDateString: Nullable<DateOnly> | undefined) => {
  if (startDateString && endDateString) {
    const date1 = new Date(startDateString.split("T")[0]);
    const date2 = new Date(endDateString.split("T")[0]);
    const oneDay = 1000 * 60 * 60 * 24;
    const diffInTime = date2.getTime() - date1.getTime();
    return Math.round(diffInTime / oneDay);
  }
  return 0;
};

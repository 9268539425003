import { callBffApi } from "../../../common/services/axios";
import { ApplicationBasicInfo } from "../../applications/application-details/redux";

type MyApplicationsResponse = {
  data: ApplicationBasicInfo[];
};

export const getMyApplications = async (): Promise<ApplicationBasicInfo[]> => {
  const response = await callBffApi<MyApplicationsResponse>("get", "/applications/my-applications");
  return response.data;
};

import { ITeam } from "../../team-details/redux";
import { teamTypeLabelMap } from "../../../common/data/team-types";
import moment from "moment";
import { getYesNoChoiceFromBoolean } from "../../../utils/forms";
import { capitalize } from "lodash";

export type Props = {
  title: string;
  teams: ITeam[];
  trackAnalytics: () => void;
};

export default function useExportTeamsController(props: Props) {

  const getCSVFileName = () => `${props.title}_${moment().format("YYYY-MM-DDTHH-mm-ss")}`;

  const getCSVDataForDownload = () => props.teams.map((team) => {
    const managerEmailIds: string[] = [];
    const developerEmailIds: string[] = [];
    const nonDeveloperEmailIds: string[] = [];
    const projectsNames: string[] = (team.projects) ? team.projects.map((project) => project.name) : [];
    team.members.forEach((member) => {
      if (member.role === "manager") {
        managerEmailIds.push(member.emailId);
      } else if (member.role === "developer") {
        developerEmailIds.push(member.emailId);
      } else {
        nonDeveloperEmailIds.push(member.emailId);
      }
    });
    return {
      "Team ID": team.id,
      "Team Name": team.name,
      "Team Description": team.description,
      "Team Category": teamTypeLabelMap[team.teamType],
      Projects: projectsNames.join(",\n"),
      "Contact Email": team.contactEmail,
      "Support Email": team.supportEmail,
      "Internal Open Source": getYesNoChoiceFromBoolean(team.isOpen),
      Managers: managerEmailIds.join(",\n"),
      Developers: developerEmailIds.join(",\n"),
      "Non Developers": nonDeveloperEmailIds.join(",\n"),
      Status: capitalize(team.status),
      "Member Count": team.members.length,
    };
  });

  return {
    getCSVDataForDownload,
    getCSVFileName,
    trackAnalytics: () => props.trackAnalytics(),
  };
}

export const getJoinTeamQuestions = (teamName: string) => ([
  {
    type: "radiogroup",
    name: "role",
    title: `Please select how do you want to contribute by joining the ${teamName} team.`,
    isRequired: true,
    choices: [
      {
        text: "<strong>Join as developer:</strong> Get access to the cloud infrastructure, codebase, and CI/CD pipeline.",
        value: "developer",
      },
      {
        text: "<strong>Join as non-developer:</strong> Get access to the cloud infrastructure but not to the codebase or CI/CD pipeline.",
        value: "non-developer",
      },
    ],
  },
]);


import { TeamType, TeamTypeReadableLabel } from "../../create-team/redux";

export const getTeamTypeReadableLabel = (teamType: TeamType) => {
  const teamTypeMap: Record<TeamType, TeamTypeReadableLabel> = {
    [TeamType.EXPERIMENT_AND_INNOVATION]: TeamTypeReadableLabel.EXPERIMENT_AND_INNOVATION,
    [TeamType.REGIONAL_IT]: TeamTypeReadableLabel.REGIONAL_IT,
    [TeamType.TECHOPS]: TeamTypeReadableLabel.TECHOPS,
    [TeamType.SOFTWARE_MANAGEMENT]: TeamTypeReadableLabel.SOFTWARE_MANAGEMENT,
    [TeamType.NONE]: TeamTypeReadableLabel.EXPERIMENT_AND_INNOVATION, // TODO Find a way to remove this none field
  };

  return teamTypeMap[teamType];
};

import { ApplicationDetailsPageActions } from "../redux";
import { useDispatch } from "react-redux";
import { useSelectorTyped } from "../../../../common/redux/store";

export function useApplicationStatusController() {
  const dispatch = useDispatch();
  const isApplicationStatusInEditMode = useSelectorTyped((state) => state.applicationDetailsPage.isApplicationStatusInEditMode);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const applicationBasicInfo = useSelectorTyped((state) => state.applicationDetailsPage.applicationBasicInfoPEV.value!);

  const onClickEditButton = () => {
    dispatch(ApplicationDetailsPageActions.updateApplicationStatusEditState(true));
  };

  const isUserAuthorizedToEditApplicationStatus = applicationBasicInfo.isStatusEditable;

  return {
    onClickEditButton,
    isApplicationStatusInEditMode,
    applicationStatus: applicationBasicInfo.status,
    isUserAuthorizedToEditApplicationStatus,
  };
}

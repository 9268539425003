import { createAsyncThunk } from "@reduxjs/toolkit";

export const createAsyncThunkWithRejectValue: typeof createAsyncThunk =
  // eslint-disable-next-line @typescript-eslint/ban-types
  // @ts-ignore
  (typePrefix: string, payloadCreator) => (
    createAsyncThunk(typePrefix, async (arg, thunkAPI) => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call
        return await payloadCreator(arg, thunkAPI);
      } catch (error) {
        throw thunkAPI.rejectWithValue(error);
      }
    })
  );

import {PEV, ProgressErrorValue} from "../../../utils/progress-error-value";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {EventsOverviewService} from "../events-overview/service";
import {
  FETCHING_CATEGORIES_ERROR_TEXT,
  FETCHING_CATEGORIES_TEXT,
  FETCHING_EVENT_METRICS_ERROR_TEXT,
  FETCHING_EVENT_METRICS_TEXT,
} from "../events-discovery/data/constants";
import {Metadata} from "showdown";
import { Nullable } from "../../../utils/nullable";

export interface IEventMetrics{
  totalPublishedEventsForLastDay: number;
  totalEventRegistrations: number;
  totalSubscriptions: number;
}

export interface MasterDataCategory{
  _id: string;
  id: string;
  name: string;
  description: string;
  icon: string;
}

export interface IEventsOverviewState{
  eventMetricsPEV: ProgressErrorValue<Nullable<IEventMetrics>>;
  categoriesPEV: ProgressErrorValue<MasterDataCategory[]>;
}
const initialState: IEventsOverviewState = {
  eventMetricsPEV: PEV({ totalPublishedEventsForLastDay: 0, totalEventRegistrations: 0, totalSubscriptions: 0}, FETCHING_EVENT_METRICS_TEXT),
  categoriesPEV: PEV([], FETCHING_CATEGORIES_TEXT),
};

const fetchEventMetrics = createAsyncThunk("fetch-event-metrics", async () =>
// eslint-disable-next-line @typescript-eslint/no-unsafe-return
  EventsOverviewService.fetchEventMetrics(),
);

const fetchMasterDataCategories = createAsyncThunk("fetch-master-data-categories", async () =>
// eslint-disable-next-line @typescript-eslint/no-unsafe-return
  EventsOverviewService.fetchCategories(),
);

const eventsOverviewPageSlice = createSlice({
  name: "eventsOverviewPage",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchEventMetrics.pending.type]: (state: IEventsOverviewState) => {
      state.eventMetricsPEV = PEV(null, FETCHING_EVENT_METRICS_TEXT);
    },
    [fetchEventMetrics.fulfilled.type]: (state: IEventsOverviewState, action: PayloadAction<IEventMetrics>) => {
      state.eventMetricsPEV = PEV(action.payload, null, null);
    },
    [fetchEventMetrics.rejected.type]: (state: IEventsOverviewState, action: PayloadAction<never, never, Metadata, Error>) => {
      if (!action.error.message) {
        state.eventMetricsPEV = PEV(null, null, action.error.message);
      } else {
        state.eventMetricsPEV = PEV(null, null, FETCHING_EVENT_METRICS_ERROR_TEXT);
      }
    },
    [fetchMasterDataCategories.pending.type]: (state: IEventsOverviewState) => {
      state.categoriesPEV = PEV([], FETCHING_CATEGORIES_TEXT);
    },
    [fetchMasterDataCategories.fulfilled.type]: (state: IEventsOverviewState, action: PayloadAction<MasterDataCategory[]>) => {
      state.categoriesPEV = PEV(action.payload, null, null);
    },
    [fetchMasterDataCategories.rejected.type]: (state: IEventsOverviewState, action: PayloadAction<never, never, Metadata, Error>) => {
      if (!action.error.message) {
        state.categoriesPEV = PEV([], null, action.error.message);
      } else {
        state.categoriesPEV = PEV([], null, FETCHING_CATEGORIES_ERROR_TEXT);
      }
    },
  },
});

export const eventsOverviewPageReducer = eventsOverviewPageSlice.reducer;

export const eventsOverviewPageActions = {
  fetchEventMetrics,
  fetchCategories: fetchMasterDataCategories,
};

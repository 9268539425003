import React, { FunctionComponent } from "react";
import BasicInformation from "./basic-info";
import useTechOpsController from "./controller";
import InfraConfig from "./infra-config";
import ReviewAndSubmit from "./review-and-submit";
import { SelectTeam } from "./select-team";

const TechOps: FunctionComponent = () => {
  const controller = useTechOpsController();

  function renderCurrentStep() {
    if (controller.shouldRenderTeamSelection()) {
      return (
        <SelectTeam/>
      );
    }
    if (controller.shouldRenderBasicInfo()) {
      return (
        <BasicInformation/>
      );
    }
    if (controller.shouldRenderInfraConfig()) {
      return (
        <InfraConfig/>
      );
    }
    if (controller.shouldRenderReviewSubmit()) {
      return (
        <ReviewAndSubmit/>
      );
    }
    return (
      <div/>
    );
  }

  return (
    <div>
      {renderCurrentStep()}
    </div>
  );
};

export default TechOps;

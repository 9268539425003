import { PEV, ProgressErrorValue } from "../../utils/progress-error-value";
import { Nullable } from "../../utils/nullable";
import { ActionCreatorWithPayload, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as service from "./service";
import { ITeam, ITeamProject } from "../team-details/redux";
import { INeoErrorInfo, NeoError } from "../../utils/errors/neo-errors";
import { createAsyncThunkWithRejectValue } from "../../common/redux/createAsyncThunkWithRejectValue";


export enum TeamType {
  NONE = "NONE",
  EXPERIMENT_AND_INNOVATION = "EXPERIMENT_AND_INNOVATION",
  REGIONAL_IT = "REGIONAL_IT",
  TECHOPS = "TECHOPS",
  SOFTWARE_MANAGEMENT = "SOFTWARE_MANAGEMENT"
}

export enum TeamTypeReadableLabel {
  EXPERIMENT_AND_INNOVATION = "Experiment and Innovation",
  REGIONAL_IT = "Regional IT",
  TECHOPS = "TechOps",
  SOFTWARE_MANAGEMENT = "Software Management"
}

export interface ICreateTeam {
  name: string;
  description: string;
  contactEmail: string;
  supportEmail: string;
  teamType: TeamType;
  managers: string[];
  developers: string[];
  "non-developers": string[];
  projectIds?: EmployeeId[];
  isOpen?: boolean;
}

export interface ITeamCreateForm extends ICreateTeam {
  isSupportEmailSameAsContact: string[];
}

interface ITeamCreationState {
  selectedTeamType: TeamType;
  createTeamPEV: ProgressErrorValue<Nullable<ITeam>>;
  projectsPEV: ProgressErrorValue<ITeamProject[], Nullable<string>, Nullable<INeoErrorInfo>>;
  createTeamUnAuthorizedPageIsActive: boolean;
  createTeamUnAuthorizedPageTeamType: TeamType;
}

const initialState: ITeamCreationState = {
  selectedTeamType: TeamType.NONE,
  createTeamPEV: PEV(null),
  projectsPEV: PEV([]),
  createTeamUnAuthorizedPageIsActive: false,
  createTeamUnAuthorizedPageTeamType: TeamType.NONE,
};

const createTeam = createAsyncThunk(
  "/create-team",
  async (payload: ICreateTeam) => service.createTeam(payload),
);

const getProjects = createAsyncThunkWithRejectValue(
  "/get-projects",
  async (query?: { isEmployeeIdsNotRequired: boolean }) => service.getProjects(query),
);

export const teamCreationPageSlice = createSlice({
  name: "teamCreationPage",
  initialState,
  reducers: {
    updateTeamCreationPageState: (state: ITeamCreationState, action: PayloadAction<Partial<ITeamCreationState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: {    // todo use builder method
    [createTeam.pending.type]: (state) => {
      state.createTeamPEV = PEV(null, "Create team is in progress");
    },
    [createTeam.fulfilled.type]: (state, action: PayloadAction<ITeam, never>) => {
      state.createTeamPEV = PEV(action.payload);
    },
    [createTeam.rejected.type]: (state, action: PayloadAction<never, never, never, Error>) => {
      state.createTeamPEV = PEV(null, null, `Error happened in team creation ${action.error.message}`);
    },
    [getProjects.pending.type]: (state) => {
      state.projectsPEV = PEV(state.projectsPEV.value, "Fetch projects is in progress");
    },
    [getProjects.fulfilled.type]: (state, action: PayloadAction<ITeamProject[], never>) => {
      state.projectsPEV = PEV(action.payload, null, null);
    },
    [getProjects.rejected.type]: (state, action: PayloadAction<NeoError>) => {
      state.projectsPEV = PEV(state.projectsPEV.value, null, action.payload.getErrorInfo());
    },
  },
});

export const teamCreationPageReducer = teamCreationPageSlice.reducer;

const updateTeamCreationPageState: ActionCreatorWithPayload<Partial<ITeamCreationState>, string> =
  teamCreationPageSlice.actions.updateTeamCreationPageState;

const updateTeamSelection = (selectedTeamClassification: TeamType) => updateTeamCreationPageState({
  selectedTeamType: selectedTeamClassification,
});

const resetState = () => updateTeamCreationPageState({
  ...initialState,
});

const updateCreateTeamUnAuthorizedPageIsActive = (isActive: boolean) => updateTeamCreationPageState({
  createTeamUnAuthorizedPageIsActive: isActive,
});

const updateCreateTeamUnAuthorizedPageTeamType = (teamType: TeamType) => updateTeamCreationPageState({
  createTeamUnAuthorizedPageTeamType: teamType,
});

export const TeamCreationPageActions = {
  updateTeamSelection,
  createTeam,
  getProjects,
  resetState,
  updateCreateTeamUnAuthorizedPageIsActive,
  updateCreateTeamUnAuthorizedPageTeamType,
};

import React from "react";
import "./index.scss";
import useRequestActivitiesController from "./controller";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import TableSortIcon from "../../../common/components/data-table-sort-icon";
import Progress from "../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../common/components/error-new";
import { ActivityHistory } from "../../team-details/redux";
import { ExportCSVLink } from "../../../common/components/export-csv";
import Analytics from "../../../common/services/analytics";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import { ACTIVITY_HISTORY_TIME_FORMAT, formatDate } from "../../../utils/date-format";

export const RequestActivitiesTable = createFunctionalComponentFromView(
  useRequestActivitiesController,
  (controller) => {
    const columns: Array<IDataTableColumn<ActivityHistory>> = [
      {
        name: "Date",
        selector: "occurredAt",
        cell: (row) => formatDate(row.occurredAt, ACTIVITY_HISTORY_TIME_FORMAT),
        sortable: true,
        sortFunction: (row1, row2) => new Date(row1.occurredAt).getTime() - new Date(row2.occurredAt).getTime(),
        grow: 1,
      },
      {
        name: "Description",
        selector: "action",
        sortable: true,
        grow: 3,
      },
      {
        name: "User",
        selector: "performedBy",
        cell: (row) => row.performedBy ?? "--",
        sortable: true,
        grow: 1,
      },
    ];

    function renderExportCSVLink() {
      if (controller.shouldRenderExportCSVLink()) {
        return (
          <ExportCSVLink
            data={controller.getExportCSVData()}
            filename={controller.getCSVFileName()}
            onClick={() => Analytics.trackEventExportCSVActivityHistoryOfTeamFromTeamDetailsPage()}
          />
        );
      }
    }

    const renderActivityHistoryTable = () => (
      <DataTable
        className={"RequestActivitiesTable"}
        columns={columns}
        data={controller.getActivities()}
        noHeader={true}
        sortIcon={<TableSortIcon/>}
        persistTableHead={true}
        defaultSortField="occurredAt"
        defaultSortAsc={false}
        pagination={true}
        paginationPerPage={10}
        paginationRowsPerPageOptions={controller.getPaginationRowsPerPageOptions()}
      />
    );

    const renderContent = () => {
      if (controller.shouldRenderProgressMessage()) {
        return (
          <Progress text={controller.progressMessage}/>
        );
      }
      if (controller.shouldRenderErrorMessage()) {
        return (
          <Error
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            {...mapApplicationErrorInfoToErrorComponentProps(controller.errorMessage!)}
          />
        );
      }
      if (controller.shouldRenderNoActivitiesMessage()) {
        return (
          <span>
          There is no activity performed on the request.
          </span>
        );
      }
      return (
        <div>
          {renderActivityHistoryTable()}
        </div>
      );
    };

    return (
      <div className="RequestActivities">
        <div>
          <div className="Heading">
            <div className={"export-csv"}>
              {renderExportCSVLink()}
            </div>
          </div>
        </div>
        {renderContent()}
      </div>
    );
  },
);

import React, { FunctionComponent } from "react";
import "./index.scss";

type Props = {
  message: string;
  retryLink?: string;
};

const EventsPlatformError: FunctionComponent<Props> = (props: Props) => {
  const errorMessage = props.message;

  const renderRecoverFromErrorMessages = () => (
    <div className="possible-ways-list">
      Please try the following:
      <div>- Try again after some time.</div>
      <div>- Refresh the page.</div>
    </div>
  );

  return (
    <div className="Events-Platform-Error">
      <div className="error-message-container">
        <div className="error-message">
          {errorMessage}
        </div>
        {renderRecoverFromErrorMessages()}
      </div>
    </div>
  );
};

export default EventsPlatformError;

import React, { FunctionComponent } from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import "./index.scss";
import TableSortIcon from "../data-table-sort-icon";
import { ITeam, ITeamMember } from "../../../pages/team-details/redux";
import { teamTypeLabelMap } from "../../data/team-types";
import { extractFieldValues } from "../../../utils/extractor";
import { TeamStatus } from "../../../pages/get-started/redux";

export type Props = {
  teams: ITeam[];
  onRowClickedHandler: (row: ITeam, event: MouseEvent) => void;
  hideStatus?: boolean;
};

const renderStatus = (status: TeamStatus) => (
  <div className={`team-status-${status}`}>{status}</div>
);

const TeamListTable: FunctionComponent<Props> = (props: Props) => {
  const columns: IDataTableColumn[] = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      sortFunction: (row1: ITeam, row2: ITeam) =>
        row1.name.localeCompare(row2.name),
    },
    {
      name: "Type",
      selector: (row: ITeam) => teamTypeLabelMap[row.teamType],
      sortable: true,
      grow: 1.5,
    },
    {
      name: "Internal Open Source",
      sortable: true,
      selector: (row: ITeam) => row.isOpen ? "Yes" : "No",
      grow: 1.1,
    },
    {
      name: "Managers",
      sortable: true,
      selector: (row: ITeam) => {
        const managers = row.members.filter((teamMember) => teamMember.role === "manager");
        const managerEmailIds = extractFieldValues<ITeamMember, string>("emailId", managers);
        return managerEmailIds.join(",\n");
      },
      grow: !props.hideStatus ? 2 : 1.5,
    },
    {
      name: "Status",
      sortable: true,
      selector: "status",
      cell: (row: ITeam) => renderStatus(row.status),
      omit: props.hideStatus,
    },
  ];

  return (
    <DataTable
      className={"TeamListTable"}
      columns={columns}
      data={props.teams}
      noHeader={true}
      defaultSortField="name"
      sortIcon={<TableSortIcon/>}
      persistTableHead={true}
      onRowClicked={props.onRowClickedHandler}
    />);
};

export default TeamListTable;

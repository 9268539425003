import React from "react";
import FormComponent, { INavigationButtonsProps } from "../forms";
import { NavigationButtons } from "../forms/navigation-buttons";
import Progress from "../progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../error-new";
import { CreateTeamProps, useCreateTeamFormController } from "./controller";
import NeoModalDialog from "../neo-modal-dialog";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import "./index.scss";
import NonTechOpsMembersAlert from "../non-techops-members-alert";
import NotPartOfTechOpsLegend from "../not-part-of-techops-legend";

export const CreateTeamForm = createFunctionalComponentFromView<ReturnType<typeof useCreateTeamFormController>, CreateTeamProps>(
  useCreateTeamFormController,
  (controller) => {
    function renderMemberNotPartOfTheTeamError() {
      if (controller.showLoggedInUserNotPartOfTheTeamError) {
        return (
          <div className="member-validation-error">
            You should be a member of this team
          </div>
        );
      }
    }

    function renderNonTechOpsMembersAlert() {
      if (controller.shouldRenderNonTechOpsMembersAlert()) {
        return (
          <NonTechOpsMembersAlert
            selectedMembers={controller.selectedNonTechOpsMembers}
            message={controller.NonTechOpsMembersAlertMessage}
          />
        );
      }
    }

    function renderNotPartOfTechOpsLegend() {
      if (controller.shouldDifferentiateNonTechOpsMembers) {
        return <NotPartOfTechOpsLegend/>;
      }
    }

    function renderAdditionalFormContent() {
      return (
        <>
          {renderNotPartOfTechOpsLegend()}
          {renderMemberNotPartOfTheTeamError()}
          {renderNonTechOpsMembersAlert()}
        </>
      );
    }

    const renderNavigationButtons = ({ getFormData, doesFormHasErrors }: INavigationButtonsProps) => (
      <NavigationButtons
        nextButtonLabel={controller.getCreateButtonLabel()}
        previousButtonLabel="Cancel"
        getFormData={getFormData}
        doesFormHasErrors={doesFormHasErrors}
        validateBusinessLogic={controller.validateLoggedInUserPartOfTheTeam}
        onClickNext={controller.onSave}
        onClickPrevious={controller.onCancel}
        isNextButtonDisabled={controller.isTeamCreationInProgress()}
        isPreviousButtonDisabled={controller.isTeamCreationInProgress()}
      />
    );

    const renderConfirmationModal = () => (
      <NeoModalDialog
        dialogTitle="Are you not a member?"
        bodyText="You are not a member/manager of this team. Are you sure you want to create this team without you?"
        dialogClassName="create-team-confirmation-modal"
        show={controller.showCreateTeamConfirmationModal}
        onHide={controller.onHideConfirmationModal}
        primaryButton={{ show: true, label: "Create", onClick: controller.onClickCreateInConfirmationModal }}
        secondaryButton={{ show: true, label: "Cancel", onClick: controller.onClickCancelInConfirmationModal }}
      />
    );

    const renderForm = () => {
      if (controller.shouldRenderForm()) {
        return (
          <FormComponent
            questions={controller.questions}
            data={controller.getInitialFormData()}
            renderNavigationButtons={renderNavigationButtons}
            handleValueChanged={controller.handleFormValueChangedEvent}
            renderAdditionalContent={renderAdditionalFormContent}
            key={controller.formKey}
          />
        );
      }
    };

    function renderError() {
      if (controller.shouldRenderErrorMsg()) {
        return (
          <Error
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            {...mapApplicationErrorInfoToErrorComponentProps(controller.getErrorMsg()!)}
          />
        );
      }
    }

    return (
      <div className={"CreateTeam"}>
        {renderForm()}
        {renderConfirmationModal()}
        <Progress text={controller.getProgressMsg()}/>
        {renderError()}
      </div>
    );
  },
);

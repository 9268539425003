import React, { FunctionComponent } from "react";
import { Col, Row } from "react-bootstrap";
import ProjectClassificationInfo from "./project-classification-info";
import "./index.scss";
import ExperimentAndInnovationInfo from "../../../common/components/project-classification-contents/experiment-innovation-info";
import RegionalITInfo from "../../../common/components/project-classification-contents/regional-it-info";
import TechOpsInfo from "../../../common/components/project-classification-contents/techops-info";
import { ProjectClassification } from "../redux";
import ApplicationsLink from "./applications-link";
import PageTitle from "../../../common/components/page-title";

const ProjectClassificationButtons: FunctionComponent = () => (
  <Row>
    <Col>
      <ProjectClassificationInfo
        heading="Experiment and Innovation"
        content={<ExperimentAndInnovationInfo/>}
        projectClassification={ProjectClassification.EXPERIMENT_AND_INNOVATION}
        id="experiment-and-innovation-btn"
      />
    </Col>
    <Col>
      <ProjectClassificationInfo
        heading="Regional IT"
        content={<RegionalITInfo/>}
        projectClassification={ProjectClassification.REGIONAL_IT}
        id="regional-it-btn"
      />
    </Col>
    <Col>
      <ProjectClassificationInfo
        heading="TechOps"
        content={<TechOpsInfo/>}
        projectClassification={ProjectClassification.TECHOPS}
        id="techops-info-btn"
      />
    </Col>
  </Row>
);

const ProjectClassifications: FunctionComponent = () => (
  <div className="ProjectClassification">
    <PageTitle
      title="Get Started"
      subtitle="How do you classify your project/initiative?"
      displayTitleLighter={true}
    />
    <ApplicationsLink/>
    <ProjectClassificationButtons/>
  </div>
);

export default ProjectClassifications;

import * as Survey from "survey-react";

// TODO Can this function be refactored out of utils
const teamNameRegExValidation = {
  type: "regexvalidator",
  text: "Please enter only lower/upper case alphabets, numbers, hyphen(-), space with 4 - 50 characters.",
  regex: "^[A-Za-z0-9- ]{4,50}$",
};

function isTeamNameAlreadyExist(teamNames: string[]) {
  return (params: string[]) => {
    const teamNameProvidedByUser = params[0]?.trim();
    return teamNames.some((team) => team.toLowerCase() === teamNameProvidedByUser?.toLowerCase());
  };
}

function isEditedTeamNameAlreadyExits(teamNames: string[], currentTeamName: string) {
  return (params: string[]) => {
    const teamNameProvidedByUser = params[0]?.trim();
    if (currentTeamName === teamNameProvidedByUser) {
      return false;
    } else {
      return teamNames.some((team) => team.toLowerCase() === teamNameProvidedByUser?.toLowerCase());
    }
  };
}

export const getTeamNameValidatorConfig = (teamNames: string[]) => {
  Survey
    .FunctionFactory
    .Instance
    .register("isTeamNameAlreadyExist", isTeamNameAlreadyExist(teamNames), false);

  return [
    teamNameRegExValidation,
    {
      type: "expression",
      expression: "isTeamNameAlreadyExist({name}) = false",
      text: "A team with the same name already exists. Please provide a different name.",
    },
  ];
};

export const getTeamNameValidatorConfigForEditTeamPage = (teamNames: string[], currentTeamName: string) => {
  Survey
    .FunctionFactory
    .Instance
    .register(
      "isTeamNameAlreadyExistForEditTeam",
      isEditedTeamNameAlreadyExits(teamNames, currentTeamName),
      false,
    );

  return [
    teamNameRegExValidation,
    {
      type: "expression",
      expression: "isTeamNameAlreadyExistForEditTeam({name}) = false",
      text: "A team with the same name already exists. Please provide a different name.",
    },
  ];
};

function noUserAssignedToRoles() {
  return (params: string[][]) => {
    const managers = params[0];
    const developers = params[1];
    const nonDevelopers = params[2];
    const noUsersInBothRoles = !managers && !developers && !nonDevelopers;
    return noUsersInBothRoles;
  };
}

export const addTeamMembersValidatorConfigForRoles = () => {
  Survey
    .FunctionFactory
    .Instance
    .register("noUserAssignedToRoles", noUserAssignedToRoles(), false);

  return [
    {
      type: "expression",
      expression: "noUserAssignedToRoles({managers},{developers},{non-developers}) = false",
      text: "At least one member is required to add to the team.",
    },
  ];
};

export const getEmailIdValidator = () => ([
  {
    type: "regexvalidator",
    text: "Email address is not in accepted format",
    regex: "^[a-zA-Z0-9]+([_.-]?[a-zA-Z0-9]+)*$",
  },
]);

import React from "react";
import ImpactXIconButton from "../impactx-icon-button";
import "./index.scss";

type Props = {
  id?: string;
  ariaLabel: string;
  onClickHandler: () => void;
  showButton?: boolean;
  className?: string;
  "data-testid"?: string;
};

const EditButton = (props: Props) => {
  if (props.showButton) {
    return (
      <ImpactXIconButton
        id={props.id}
        onClick={props.onClickHandler}
        className={`EditButton ${props.className ?? ""}`}
        data-testid={props["data-testid"]}
        ariaLabel={props.ariaLabel}
      >
        <i className="ri-pencil-line"/>
      </ImpactXIconButton>
    );
  }
  return null;
};

EditButton.defaultProps = {
  showButton: false,
};


export default EditButton;

import { RouteComponentProps } from "react-router-dom";
import { useEffect } from "react";
import { useSelectorTyped } from "../../../common/redux/store";
import { RequestsPageActions } from "../redux";
import { useDispatch } from "react-redux";
import Analytics from "../../../common/services/analytics";

export type RequestDetailsPageProps = RouteComponentProps<{ requestId: string }>;

export default function useRequestDetailsPageController(props: RequestDetailsPageProps) {
  const requestId = props.match.params.requestId;
  const dispatch = useDispatch();
  const myRequestDetailsPEV = useSelectorTyped((state) => state.requestsPage.myRequestsPEV);

  useEffect(() => {
    Analytics.trackPageRequestDetails();
    dispatch(RequestsPageActions.fetchMyRequests());
  }, []);

  return ({
    requestId,
    myRequestDetailsPEV,
  });
}

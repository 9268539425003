import { PEV, ProgressErrorValue } from "../../../utils/progress-error-value";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { RootState } from "../store";
import { projectsService } from "../../services/projects";
import { INeoErrorInfo, NeoError } from "../../../utils/errors/neo-errors";
import { Nullable } from "../../../utils/nullable";
import { createAsyncThunkWithRejectValue } from "../createAsyncThunkWithRejectValue";

export interface IProject {
  id: string;
  name: string;
  code: string;
  type: string;
  accountId: string;
  accountProjectCode: string;
  opportunityId: string;
  startDate: DateOnly;
  endDate: DateOnly;
}

type IProjectsState = ProgressErrorValue<IProject[], Nullable<string>, Nullable<INeoErrorInfo>>;

const initialState: IProjectsState = PEV([]);

const fetchProjects = createAsyncThunkWithRejectValue<IProject[]>(
  "fetchProjects",
  projectsService.getProjects,
);

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: ((builder) => {
    builder
      .addCase(fetchProjects.pending, (state) =>
        PEV(state.value, "Fetching the projects. Please wait for few seconds."),
      )
      .addCase(fetchProjects.fulfilled, (state, action) =>
        PEV(action.payload),
      )
      .addCase(fetchProjects.rejected.type, (state, action: PayloadAction<NeoError>) =>
        PEV(state.value, null, action.payload.getErrorInfo()),
      );
  }),
});

export const dispatchFetchProjectsIfEmpty = (
  dispatch: Dispatch<ReturnType<typeof fetchProjects>>,
  projectsPEV: RootState["projectsPEV"],
) => {
  if (projectsPEV.value.length === 0 && !projectsPEV.progress && !projectsPEV.error) {
    dispatch(fetchProjects());
  }
};

export const projectsReducer = projectsSlice.reducer;

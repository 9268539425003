export const getDecommissionApplicationQuestions = (options: {
  isDecommissionAlreadyRequested: boolean;
  canUserDecommissionApplication: boolean;
  decommissionReasons: string[];
}) => [
  {
    type: "html",
    html: `
      <div>
        NOTE: All GCP Projects, GitHub team, CI/CD pipelines, Okta preview / Okta production chiclets and groups, Clients will be deleted. 
        GitHub repo will be temporarily archived.<br>
        Only managers can request for decommissioning the application.
      </div>
    `,
  },
  {
    type: "dropdown",
    name: "decommissionReason",
    title: "Please tell us why",
    placeHolder: "Select a suitable reason",
    isRequired: true,
    hasOther: true,
    visible: options.canUserDecommissionApplication,
    readOnly: options.isDecommissionAlreadyRequested,
    optionsCaption: "Select a suitable reason",
    otherText: "Others (Give reasons)",
    choices: options.decommissionReasons,
    storeOthersAsComment: false,
  },
];

import { useEffect } from "react";
import Analytics from "../../../common/services/analytics";
import { useSelectorTyped } from "../../../common/redux/store";

export default function useExperimentsInnovationsController() {
  useEffect(() => {
    Analytics.trackPageGetStartedExperimentAndInnovation();
  }, []);

  const currentStep = useSelectorTyped((state) => state.getStartedPage.currentStep);

  function shouldRenderTeamInfo() {
    return currentStep === "TEAM_SELECTION";
  }

  function shouldRenderBasicInfo() {
    return currentStep === "BASIC_INFO";
  }

  function shouldRenderInfraConfig() {
    return currentStep === "INFRA_CONFIG";
  }

  function shouldRenderReviewSubmit() {
    return currentStep === "REVIEW_SUBMIT";
  }

  return {
    shouldRenderTeamInfo,
    shouldRenderBasicInfo,
    shouldRenderInfraConfig,
    shouldRenderReviewSubmit,
  };
}

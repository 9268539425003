const techStack = [
  ".NET 4.5",
  ".Net",
  "Actions on Google",
  "ActiveAdmin",
  "ActiveAndroid",
  "ActiveMQ",
  "Activiti",
  "ADFS",
  "Adobe analytics",
  "Adobe DTM",
  "Adobe Omniture",
  "AEM",
  "Aerospike",
  "AFNetworking",
  "Agent Based Simulation Model",
  "Akamai",
  "AKKA",
  "Alation",
  "Alexa",
  "Aliyun",
  "Amazon API Gateway",
  "Amazon Athena",
  "Amazon CloudFront",
  "Amazon CloudSearch",
  "Amazon CloudWatch",
  "Amazon DynamoDB Streams",
  "Amazon DynamoDB",
  "Amazon Elastic Compute Cloud - AWS EC2",
  "Amazon Elastic",
  "Amazon Fargate",
  "Amazon Glacier",
  "Amazon Glue",
  "Amazon Kinesis",
  "Amazon RDS",
  "Amazon S3",
  "Amazon Simple Email Service - AWS SES",
  "Amazon Simple Queue Service (SQS)",
  "Amazon Web Services",
  "Ammado",
  "AndEngine",
  "Android Studio",
  "Android",
  "Angular 2 (Typescript)",
  "Angular 6",
  "Angular Cli",
  "AngularJS",
  "Ansible",
  "Ant Design",
  "Apache Ambari",
  "Apache Camel",
  "Apache CXF",
  "Apache Drill",
  "Apache Http Client",
  "Apache Orc",
  "Apache Ranger",
  "Apache Zeppelin",
  "Apache Zookeeper",
  "Apache-Airflow",
  "Apache",
  "Apex Up",
  "API Gateway",
  "Apigee",
  "App Centre",
  "App Dynamics",
  "App Stack",
  "Appium",
  "Apple watch",
  "Apple",
  "Artifactory",
  "Askable",
  "ASP.net Core Web",
  "ASP.NET MVC 4.0",
  "ASP.NET",
  "AssertJ",
  "AstrixDB",
  "AsyncImageView",
  "Aurelia",
  "Aurora",
  "Auth0",
  "AuthN",
  "Authorisation Extension",
  "AuthZ",
  "AUTOscaling",
  "Avro",
  "AVS",
  "AWS ACM",
  "AWS API Gateway",
  "AWS CLI Imperative",
  "AWS Cloud",
  "AWS CloudFormation Declarative(ish)",
  "AWS CloudFront",
  "AWS Cognito",
  "AWS ECS",
  "AWS Elastic Beanstalk",
  "AWS Elastic Container Registry",
  "AWS Elastic Container Service",
  "AWS Elastic Kubernetes Service",
  "AWS Elastic Load Balancing",
  "AWS Elastic Map Reduce",
  "AWS Lambda",
  "AWS Lex",
  "AWS ML",
  "AWS S3",
  "AWS Simple Notification Service",
  "AWS Simple Queue Service",
  "AWS WAF",
  "AWS",
  "Axios",
  "Axon Framework",
  "Axon",
  "Azure API Management",
  "Azure Data Factory",
  "Azure DevOps",
  "Azure IoT Hub",
  "Azure Keyvault",
  "Azure Pipelines",
  "Azure Scheduler",
  "Azure Service Bus",
  "Azure Service Fabric",
  "Azure Synapse",
  "Azure",
  "Babel-JS",
  "Babel",
  "Babushka",
  "Backbone.js",
  "Backend",
  "Balsamiq",
  "Bamboo",
  "Bartender",
  "Bash",
  "Batect",
  "Bazel",
  "Behave",
  "Bitbucket",
  "Bitrise",
  "BlocksKit",
  "Boomi",
  "Bootstrap",
  "Boto3",
  "Box.com",
  "Braintree",
  "Browserify",
  "BrowserStack",
  "Buddy Build",
  "BugSnag iOS Crash Reporting",
  "Build & Deployment",
  "Build chain",
  "Buildkite",
  "Business Intelligence",
  "C",
  "C/C++",
  "C#",
  "CafeX",
  "Caffe",
  "Cake",
  "Calabash",
  "Calatrava",
  "Capistrano",
  "Capybara",
  "Cascading",
  "Cassandra",
  "CatBoost",
  "CD4ML",
  "Celery",
  "Centrify",
  "Certificate Manager",
  "Chai",
  "Chef Solo",
  "Chef",
  "ChromeHeadless",
  "Chronograf",
  "CircleCI",
  "Clojure",
  "Cloud Foundry",
  "Cloudera Distribution (CDH)",
  "CloudFormation",
  "CloudFront",
  "Cloudinary",
  "CloudInit",
  "CloudTrail",
  "CloudWatch for Logging",
  "CloudWatch",
  "CMake",
  "CMS",
  "Cocoapods",
  "cocos2d",
  "Codemagic",
  "CoffeeScript",
  "ColdFusion",
  "Collectd",
  "Compass",
  "Concourse CI",
  "Concourse",
  "Confluence",
  "Consul Template",
  "Consul",
  "Contentful",
  "Corda",
  "Cordova",
  "Couchbase",
  "CouchbaseLite",
  "CouchDB",
  "Coverity Scan",
  "Coverity",
  "Crashlytics",
  "Cross mobile platforms",
  "CSS",
  "CSS3",
  "Cucumber-jvm",
  "Cucumber",
  "CucumberJS",
  "Cypress",
  "D3",
  "Dash",
  "Data Driven Documents (D3.js)",
  "Data Pipeline",
  "Databricks",
  "Datadog",
  "Datastax Cassandra",
  "Datastax OpsCenter",
  "Db2",
  "DBDeploy",
  "Dbt",
  "DC/OC",
  "Debian Packaging",
  "Delphix",
  "Dep",
  "Derby Db",
  "Detekt",
  "Detox",
  "Developer Stack",
  "Devops",
  "DHIS2",
  "Dialogflow NLP engine",
  "Dialogflow",
  "Django Rest Framework",
  "Django",
  "Docker Lambda",
  "Docker",
  "Domain-specific PaaS",
  "Drools",
  "DropWizard",
  "Drupal CMS",
  "Duck-Angular",
  "DVA",
  "DVC",
  "Dynatrace",
  "EC2",
  "Eclipse",
  "ECMAScript 2015",
  "EFK",
  "Elasticsearch",
  "ELB",
  "Electron",
  "Elixir",
  "ELK",
  "Emacs",
  "EmberJS",
  "EMR",
  "Enketo",
  "Ensighten",
  "Envoy Proxy",
  "Enzyme",
  "ePlum",
  "Epsilon",
  "ERB",
  "ES6",
  "ES8",
  "ESLint",
  "ESP32",
  "Espresso",
  "Ethereum",
  "Event Hub",
  "Express",
  "Expresso",
  "External Integration",
  "F5",
  "Fabric",
  "Fastify",
  "Fastlane",
  "FastReport.net",
  "Feign",
  "Figma",
  "Filebeat",
  "Finacle",
  "Find Security Bugs",
  "FinnOne Neo Customer Acquisition System",
  "FinnOne Neo Loan Management System",
  "Fiona",
  "Firebase",
  "Firmware development",
  "Flask",
  "Flexible Data Ingestion Pipelines (Spring Cloud Data Flow)",
  "Flink",
  "Flow",
  "FluentD",
  "Flume",
  "Flutter",
  "Folium",
  "Formik",
  "Fortify",
  "FOSSA",
  "FreedomBox",
  "Freemarker Oviyam2 viewer",
  "Freemarker",
  "FSCheck",
  "Fusion",
  "Galen",
  "Gatling",
  "Gauge",
  "Gclient Tool Chain",
  "Genetic Algorithms",
  "Geopandas",
  "Gephi",
  "Geth client",
  "ggplot2",
  "Git",
  "Github",
  "GitLab CI",
  "Gitlab",
  "Gladson",
  "GlassFish JAXB",
  "GnuPG",
  "Go",
  "GoCD",
  "GoLang",
  "Gomatic",
  "Google Analytics",
  "Google App Engine",
  "Google Chat",
  "Google Chrome",
  "Google Cloud ML",
  "Google Cloud Platform",
  "Google Cloud SQL",
  "Google Cloud Storage",
  "Google Collections",
  "Google Go",
  "Google Home",
  "Google IoT core",
  "Google Maps",
  "Google Pub/ Sub",
  "GPS",
  "Gradle",
  "Grafana",
  "Grails",
  "Graphite",
  "GraphQL",
  "Gravitee.io",
  "Graylog2",
  "Gridster.js",
  "Groovy Shared Libraries",
  "Groovy",
  "gRPC",
  "Grunt",
  "Guava",
  "Guice",
  "Gulp-connect",
  "Gulp-rev",
  "Gulp",
  "H2",
  "H20",
  "Hadoop Distributed File System",
  "Hadoop HDFS",
  "Hadoop",
  "HAL",
  "Hamcrest",
  "HAML",
  "Handlebars.js",
  "HAproxy",
  "HATEOAS",
  "Hazelcast",
  "Hcl Config",
  "Helm",
  "Heroku",
  "Hibernate",
  "HipChat",
  "HIVE",
  "HockeyApp",
  "HortonWorks",
  "HTML Canvas",
  "HTML",
  "HTML5",
  "Hudson",
  "Hypersonic",
  "Hystrix",
  "IAM",
  "IBM AS/400",
  "Icinga/Nagios",
  "IFCOpenShell",
  "ImmutableJS",
  "Impala",
  "Imperva WAF",
  "IndexDB",
  "Industrial gateway",
  "influxDB",
  "Infrablocks",
  "Infrastructure and CI/CD: Gitlab",
  "Instart Logic CDN",
  "Intel Neural Compute",
  "IntelliJ",
  "InVision",
  "iOS XCTest",
  "iOS",
  "IoT PaaS",
  "iRi",
  "Isomorphic",
  "Istio",
  "Jackson",
  "Jade",
  "Jaegar",
  "Jasmine",
  "Jasper",
  "Java 7",
  "Java 8",
  "Java Apple Push Service",
  "Java Funk",
  "Java",
  "Java6",
  "Javalin",
  "JavaScript (ES6)",
  "JavaScript MVC",
  "Javascript Typed Arrays",
  "JavaScript",
  "JBoss",
  "Jenkins 2",
  "Jenkins",
  "Jersey",
  "Jest",
  "Jetpack",
  "Jetty",
  "JettyCent OS 7",
  "Jfrog",
  "Jira",
  "JMeter",
  "JMS",
  "JMXTrans",
  "JPA",
  "jQuery",
  "JS",
  "Json",
  "JSTestDriver",
  "JsYAML",
  "JUnit 5",
  "jUnit",
  "JUnit4",
  "Jupyter",
  "Juypter Notebook",
  "JVM Tuning",
  "k8s",
  "Kafka",
  "Kapacitor",
  "Karma",
  "Keepalived",
  "Keras",
  "Kerbero",
  "Key Management Service",
  "Keycloak",
  "Kibana",
  "KMS",
  "Knockout.js",
  "Knockout",
  "Kong",
  "Konvergence integration",
  "Kops",
  "Kotlin",
  "Ktlint",
  "Kubernetes",
  "Lambda",
  "LaunchDarkly",
  "Leaflet",
  "LESS",
  "Let's Encrypt",
  "LightGBM",
  "Linear Programming Solver Kit",
  "Linux Containers",
  "Liquibase",
  "LITMUS",
  "Locust",
  "Log Monitoring",
  "Logstash",
  "Lombok",
  "LoRaWAN",
  "Lua",
  "Lucene",
  "MagicalRecord",
  "MailguN",
  "Makefile",
  "Marathon",
  "Material UI",
  "Matplotlib",
  "Maven",
  "Mavic Drone",
  "Mechanical Turk",
  "Mesos ALM/CI Tools: Jira",
  "Metricbeat",
  "Micronaut",
  "Microsoft Active Directory",
  "Microsoft Azure Blob Storage",
  "Microsoft Lync",
  "Microsoft Teams",
  "Middleman",
  "Mingle",
  "Missingno",
  "MLFlow",
  "Mobile Boilerplate",
  "Mobx",
  "Mocha",
  "Mocha/Chai",
  "MochaPuppet",
  "Mockito",
  "MockServer",
  "Mod_wsgi",
  "MongoDB",
  "Mongoose OS",
  "Monit",
  "Mootools",
  "More AWS",
  "Mosquito",
  "Motech",
  "Mountebank",
  "Movidius USB",
  "MQTT",
  "MS Azure Cloud",
  "Munin",
  "MURAL",
  "Mustache.js",
  "MySQL on Azure",
  "MySQL",
  "Mystique",
  "Nagios",
  "Neo4j",
  "Netflix Eureka",
  "NetSuite",
  "Netty",
  "New Relic",
  "Next.js",
  "Nexus",
  "Nginx",
  "NgRx",
  "NGUnit",
  "Nightwatch",
  "Node JS",
  "Node",
  "NoSql",
  "NPM",
  "NUnit",
  "NuxtJS",
  "NYU Langone Medical Center",
  "OAuth",
  "Objective C",
  "Octopus deploy",
  "Okta",
  "One Password",
  "Oozie",
  "Open Data Kit",
  "Open MRS",
  "Open Resty",
  "Open source BI/ETL tools",
  "Open Source CLI Tooling",
  "Open source IaaS",
  "Open Source",
  "Open SUSE (SP12)",
  "OpenCV",
  "Openshift",
  "OpenStack",
  "OpenZeppelin",
  "Ops Stack",
  "OpsWorks",
  "Oracle Responsys",
  "Oracle SQL",
  "Oracle",
  "ORY Hydra",
  "Owasp zap",
  "OzLink",
  "Packer",
  "Pact (Consumer Driven Tests)",
  "Pact Broker",
  "Pact",
  "PACTflow",
  "PactJS",
  "PagerDuty",
  "Palo Alto",
  "Pandas",
  "Paper",
  "Parallels",
  "Parquet",
  "Pay",
  "PDFium",
  "PDFKit (PDF)",
  "Pearl",
  "Pencil",
  "PhantomJS",
  "Phoenix",
  "PHP",
  "PHPUnit",
  "Pico containers",
  "PIMCore",
  "Pivotal Cloud Foundry",
  "PIWIK",
  "PL/I",
  "Plaid",
  "Platform Stack",
  "Platforms",
  "Play Framework",
  "Plotly",
  "PlumHost",
  "PM2",
  "Polyglot Development Environments",
  "Post-it",
  "PostGIS",
  "Postgres RDS",
  "Postgres",
  "PostgreSQL",
  "Postman",
  "Powa",
  "PowerShell",
  "Precision Time Protocol (PTP)",
  "Prettier",
  "Prometheus",
  "Protecode",
  "Protractor",
  "Psake",
  "Pulsar",
  "Puppet",
  "PuppetDB",
  "Puppeteer",
  "Pushpin",
  "PxLoader",
  "Pydantic",
  "Python 3",
  "Python for apis and crypto",
  "Python Scripting",
  "Python",
  "PyTorch",
  "Qpid",
  "Quartz",
  "Quick & Nimble",
  "R (Programming Language)",
  "R markdown",
  "R Studio",
  "RabbitMQ",
  "Rackspace",
  "Rails 3.1",
  "Rails 3.2",
  "Rails 4",
  "Rails",
  "Rake",
  "Rancher",
  "Raphael for IE8 support",
  "Rasa",
  "Raspberry Pi",
  "RDBMS - Postgres Thymeleaf",
  "RDS",
  "React Native",
  "React Testing Library",
  "React",
  "ReactJS - SPA",
  "Reactstrap",
  "Reactvex",
  "Realm",
  "Recompose",
  "Redis cache",
  "Redis",
  "Redshift",
  "Redux Form",
  "Redux-Saga",
  "Redux",
  "Render Script",
  "Repmgr",
  "Rest Assured",
  "Rest Easy",
  "REST",
  "REST/Jersey",
  "Rest/SoapSpring Boot",
  "RESTful",
  "Retrofit",
  "Revit",
  "RHEL",
  "RightFax",
  "Roboguice",
  "Robolectric",
  "Rollbar",
  "Route 53",
  "RSpec",
  "Ruby on Rails",
  "Ruby",
  "Rundeck",
  "Runtime",
  "Rust",
  "RxJava",
  "RxJS",
  "Saga",
  "Sahi",
  "SAP HANA",
  "SAP",
  "SAS",
  "SASS",
  "Scala",
  "ScalaTest",
  "Scattergeo",
  "Scikit-learn",
  "Secrets Manager",
  "Selenium WebDriver",
  "Selenium",
  "Semaphore",
  "Sentry",
  "Serverless",
  "Serverspec",
  "SFTP",
  "Shell",
  "Sinatra",
  "Siri",
  "Sketch",
  "Skribble.io",
  "Slack",
  "Slim",
  "Sly.js",
  "Snap CI",
  "Snap",
  "Snyk",
  "SOAP",
  "Softlayer",
  "Solidity",
  "Solr",
  "Sonar Type",
  "Sonar",
  "SonarGroovy",
  "SonarQube",
  "spaCy",
  "Spark Cluster",
  "Spark Streaming",
  "Spark",
  "SpecFlow",
  "Spinnaker",
  "SPINS",
  "Splunk",
  "Spock",
  "Spree e-commerce framework",
  "Spring boot web app",
  "Spring Boot",
  "Spring Cloud Contract",
  "Spring Cloud Finchley.SR1",
  "Spring Contract Test",
  "spring data JPA",
  "Spring Frameworks",
  "Spring Integration",
  "Spring MVC",
  "Spring Security",
  "Spring Test",
  "Spring",
  "Sprintboot",
  "SQL Server",
  "SQL",
  "SQLite",
  "SqlServer",
  "Sqoop",
  "SSD MobileNet",
  "statsd",
  "Storm",
  "Storybook",
  "StringTemplate",
  "STs",
  "Stubby",
  "Styled Components",
  "SumoLogic",
  "Supervisor",
  "SVG",
  "SVN",
  "Swagger Hub",
  "Swagger",
  "Swift 1.2",
  "Swift 3",
  "Swift",
  "Symfony",
  "SyncGateway",
  "Syncwords",
  "Tableau",
  "Taiko",
  "Talend",
  "Talisman",
  "Team Foundation Server",
  "TeamCity",
  "Telegraf",
  "Tensorflow",
  "Teradata",
  "Terraform",
  "TerraGrunt",
  "TestCafe",
  "TestFlight iOS",
  "TestFlight",
  "TestNG",
  "Testrpc for Ethereum",
  "TFS",
  "Three.js",
  "Thumbor",
  "Thymeleaf",
  "Togglz",
  "Tomcat",
  "Tools",
  "Tox",
  "Transit VPC",
  "Translog",
  "Trello",
  "Truffle Framework",
  "Tslint",
  "TSQL",
  "Tsuru",
  "Twig",
  "Twilio",
  "Twist",
  "TypeScript",
  "Ubuntu",
  "Unbound DNS",
  "Underscore.js",
  "Unicorn",
  "Unity 3D",
  "Universal Windows Platform",
  "Unstructured Supplementary Service Data (USSD)",
  "uWSGI",
  "Vagrant",
  "Vanilla",
  "Varnish",
  "Vault",
  "Vaut",
  "Velocity.js",
  "VerneMQ",
  "Vert.X",
  "Vertica",
  "Virtual Box",
  "VM",
  "VMWare vCenter",
  "VMWare",
  "Vue test Utils (UI)",
  "Vue.js",
  "Wearable",
  "Web Services",
  "WebAdm",
  "Webdriver",
  "Webpack",
  "WebSocket",
  "WeChat API",
  "Wechat Mini APP",
  "WeChat",
  "Wiremock",
  "Wit.ai",
  "Wordpress",
  "Workbox",
  "Xamarin",
  "XGBoost",
  "xUnit.net",
  "Yarn",
  "Zappa",
  "Zeep",
  "Zeplin",
  "Zoom",
];
techStack.sort();
export default techStack;

import React from "react";
import useEditApplicationInformationController from "./controller";
import FormComponent, { INavigationButtonsProps } from "../../../../common/components/forms";
import { NavigationButtons } from "../../../../common/components/forms/navigation-buttons";
import "./index.scss";

export const EditApplicationInformation = () => {
  const controller = useEditApplicationInformationController();

  function renderNavigationButtons({ getFormData, doesFormHasErrors }: INavigationButtonsProps) {
    return (
      <NavigationButtons
        nextButtonLabel={controller.getSaveButtonLabel()}
        previousButtonLabel="Cancel"
        getFormData={getFormData}
        doesFormHasErrors={doesFormHasErrors}
        onClickNext={controller.onSave}
        onClickPrevious={controller.onCancel}
        isNextButtonDisabled={controller.isSaveButtonDisabled()}
        isPreviousButtonDisabled={controller.isCancelButtonDisabled()}
      />
    );
  }

  return (
    <div className="EditApplicationInformation">
      <FormComponent
        questions={controller.questions}
        data={controller.getEditApplicationInfo()}
        renderNavigationButtons={renderNavigationButtons}
      />
    </div>
  );
};

import React, { FunctionComponent } from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import "./index.scss";
import TableSortIcon from "../../../common/components/data-table-sort-icon";
import { AggregatedTaskStatusLabel, IRequest, ITask, RequestStatusLabel } from "../types/requests";
import { formatDate } from "../../../utils/date-format";
import _ from "lodash";
import { useSelectorTyped } from "../../../common/redux/store";

export type Props = {
  requests: IRequest[];
  onRowClickedHandler?: (row: IRequest, event: MouseEvent) => void;
};

const RequestsTable: FunctionComponent<Props> = (props: Props) => {
  const { labelMaps } = useSelectorTyped((state) => state.header.uiConfigsPEV.value);
  const { requestTypeLabelMap, taskTypeLabelMap } = labelMaps;

  function renderAggregatedTaskStatus(request: IRequest) {
    if (request.aggregatedTaskStatus) {
      return (
        <div className={`aggregated-status-${request.aggregatedTaskStatus}`}>
          {AggregatedTaskStatusLabel[request.aggregatedTaskStatus]}
        </div>
      );
    }
  }

  function renderRequestedFor(name: string, type: string) {
    return (
      <div>
        <div>
          {name}
        </div>
        <div className="requested-for-type">
          {type}
        </div>
      </div>
    );
  }

  function renderStatus(request: IRequest) {
    return (
      <div className="status-column">
        <div
          className={`request-status-${request.status}`}
          data-tag="allowRowEvents"
        >
          {RequestStatusLabel[request.status]}
        </div>
        {renderAggregatedTaskStatus(request)}
      </div>
    );
  }

  function renderResource(tasks: ITask[]) {
    const tasksWithUniqueTaskTypes = _.uniqBy(tasks, "taskType");
    if (tasks.length === 0) {
      return "-";
    }
    return (
      <div>
        {tasksWithUniqueTaskTypes.map((task, index) => (
          <div
            key={index}
            data-tag="allowRowEvents"
          >
            {taskTypeLabelMap[task.taskType]}
          </div>
        ))}
      </div>
    );
  }

  const columns: Array<IDataTableColumn<IRequest>> = [
    {
      name: "Request Type",
      selector: (row) => requestTypeLabelMap[row.type],
      sortable: true,
      sortFunction: (row1, row2) => requestTypeLabelMap[row1.type].localeCompare(requestTypeLabelMap[row2.type]),
    },
    {
      name: "Resource",
      selector: "resource",
      cell: (row) => renderResource(row.tasks),
      sortable: true,
      sortFunction: (row1, row2) => {
        // eslint-disable-next-line unicorn/prefer-spread
        const task1 = row1.tasks.reduce((result, b) => result.concat(b.taskType), "");
        // eslint-disable-next-line unicorn/prefer-spread
        const task2 = row2.tasks.reduce((result, b) => result.concat(b.taskType), "");
        return task1.localeCompare(task2);
      },
    },
    {
      name: "Requested for",
      selector: "requestedFor",
      cell: (row) => renderRequestedFor(row.associatedEntity.name, row.associatedEntity.type),
      sortable: true,
    },
    {
      name: "Requested Team",
      sortable: true,
      selector: (row) => row.requester.teamName,
    },
    {
      name: "Assigned to",
      sortable: true,
      selector: (row) => row.tasks[0]?.taskOwner.teamName ?? "-",
    },
    {
      name: "Requested on",
      sortable: true,
      selector: "requestedOn",
      cell: (row) => formatDate(row.raisedDateTime),
      sortFunction: (row1, row2) =>
        row1.raisedDateTime.localeCompare(row2.raisedDateTime),
    },
    {
      name: "Status",
      sortable: true,
      selector: "status",
      cell: (request) => renderStatus(request),
      sortFunction: (row1, row2) => {
        const getStatus = (request: IRequest) => `${RequestStatusLabel[request.status]} ${request.aggregatedTaskStatus ?? ""}`;
        return getStatus(row1).localeCompare(getStatus(row2));
      },
    },
  ];

  return (
    <DataTable
      className={"RequestsTable"}
      columns={columns}
      data={props.requests}
      noHeader={true}
      sortIcon={<TableSortIcon/>}
      persistTableHead={true}
      onRowClicked={props.onRowClickedHandler}
    />
  );
};

export default RequestsTable;


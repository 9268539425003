import { SubHeaderProps } from "../../../common/components/sub-header/controller";
import { RequestsPages } from "./pages";

const amethystPurpleColor = "#634F7D";

export const requestsSubHeaderProps: SubHeaderProps = {
  headerTitle: "Requests and Tasks",
  backgroundColor: amethystPurpleColor,
  menuItems: [
    {
      id: "myRequests",
      title: RequestsPages.MY_REQUESTS.title,
      route: RequestsPages.MY_REQUESTS.path,
      highlightTabsForAdditionalRoutes: [
        "/requests/:requestId",
      ],
    },
    {
      id: "myTasks",
      title: RequestsPages.MY_TASKS.title,
      route: RequestsPages.MY_TASKS.path,
      highlightTabsForAdditionalRoutes: [
        "/requests/:requestId/tasks",
      ],
    },
  ],
};

import React, { FunctionComponent } from "react";
import { useEditTeamDetailsController } from "./controller";
import PageTitle from "../../../common/components/page-title";
import FormComponent, { INavigationButtonsProps } from "../../../common/components/forms";
import { NavigationButtons } from "../../../common/components/forms/navigation-buttons";
import "./index.scss";
import Progress from "../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../common/components/error-new";

export const EditTeamDetails: FunctionComponent = () => {
  const controller = useEditTeamDetailsController();

  function renderNavigationButtons({ getFormData, doesFormHasErrors }: INavigationButtonsProps) {
    return (
      <NavigationButtons
        nextButtonLabel={controller.getSaveButtonLabel()}
        previousButtonLabel="Cancel"
        getFormData={getFormData}
        doesFormHasErrors={doesFormHasErrors}
        onClickNext={controller.onSave}
        onClickPrevious={controller.onDiscard}
        isNextButtonDisabled={controller.isEditTeamInProgress()}
        isPreviousButtonDisabled={controller.isEditTeamInProgress()}
      />
    );
  }

  const renderForm = () => {
    if (controller.shouldRenderForm()) {
      return (
        <FormComponent
          questions={controller.questions}
          data={controller.getFormData()}
          renderNavigationButtons={renderNavigationButtons}
        />
      );
    }
    return null;
  };

  const renderError = () => {
    if (controller.shouldRenderError()) {
      return (
        <Error
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          {...mapApplicationErrorInfoToErrorComponentProps(controller.getErrorMsg()!)}
        />
      );
    }
  };


  return (
    <div className={"EditTeamDetails"}>
      <PageTitle title="Edit Team"/>
      <hr/>
      {renderForm()}
      <Progress text={controller.getProgressMsg()}/>
      {renderError()}
    </div>
  );
};

import { useDispatch } from "react-redux";
import { useSelectorTyped } from "../../../common/redux/store";
import { getAddMemberQuestions } from "./data/questions";
import { getDropdownChoicesForMembers } from "../../../utils/teams/surveyjs-helper";
import { IAddTeamMembersForm, TeamDetailsPageActions } from "../redux";
import Analytics from "../../../common/services/analytics";
import { useState } from "react";
import castTo from "../../../utils/cast-to";
import { SurveyModel } from "survey-react";
import { ITeamCreateForm, TeamType } from "../../create-team/redux";
import { useSelectedNonTechOpsMembers } from "../../../common/hooks/teams/use-selected-non-techops-members";

export default function useAddTeamMembersController() {
  const addMembersModalShow = useSelectorTyped((state) => state.teamDetailsPage.showAddMembersModal);
  const addTeamMembersPE = useSelectorTyped((state) => state.teamDetailsPage.addTeamMembersPE);
  const potentialMembersPEV = useSelectorTyped((state) => state.teamsPage.potentialMembersPEV);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const team = useSelectorTyped((state) => state.teamDetailsPage.teamDetailsPEV.value!);
  const [selectedMembersToBeAddedToTeam, setSelectedMembersToBeAddedToTeam] = useState(castTo<string[]>([]));
  const selectedNonTechOpsMembers = useSelectedNonTechOpsMembers(selectedMembersToBeAddedToTeam);

  const dispatch = useDispatch();
  const isAddMembersInProgress = !!addTeamMembersPE.progress;

  const getAddButtonLabel = () => isAddMembersInProgress ? "Adding" : "Add";

  const onCancel = () => {
    setSelectedMembersToBeAddedToTeam([]);
    dispatch(TeamDetailsPageActions.displayAddMembersModal(false));
  };


  const getFormData = () => ({ managers: [], developers: [], "non-developers": [] });

  const shouldDifferentiateNonTechOpsMembers =
    team.teamType !== TeamType.EXPERIMENT_AND_INNOVATION && team.teamType !== TeamType.SOFTWARE_MANAGEMENT;

  const questions = getAddMemberQuestions(
    getDropdownChoicesForMembers(potentialMembersPEV.value, shouldDifferentiateNonTechOpsMembers),
    team.teamType,
  );

  const shouldRenderForm = () => potentialMembersPEV.value
    && !potentialMembersPEV.progress
    && !potentialMembersPEV.error;

  const shouldRenderProgress = () => potentialMembersPEV.progress
    && potentialMembersPEV.value.length === 0;

  const shouldRenderError = () => potentialMembersPEV.error
    && !potentialMembersPEV.progress;

  return {
    addMembersModalShow,
    isAddMembersInProgress,
    getAddButtonLabel,
    getFormData,
    onCancel,
    questions,
    onAddButtonClick: (teamMembers: IAddTeamMembersForm) => {
      Analytics.trackEventAddAndSaveTeamMembers(team);
      dispatch(TeamDetailsPageActions.addMembersToTheTeam({
        teamId: team.id,
        memberData: {
          managers: teamMembers.managers,
          developers: teamMembers.developers,
          "non-developers": teamMembers["non-developers"],
        },
      }));
      setSelectedMembersToBeAddedToTeam([]);
    },
    shouldRenderForm,
    shouldRenderProgress,
    shouldRenderError,
    shouldDifferentiateNonTechOpsMembers,
    NonTechOpsMembersAlertMessage: "Review your choices before you add members to the team!",
    handleFormValueChangedEvent: (form: SurveyModel) => {
      const formData = castTo<ITeamCreateForm>(form.data);
      const members = [
        ...(formData.managers ?? []),
        ...(formData.developers ?? []),
        ...(formData["non-developers"] ?? []),
      ];
      setSelectedMembersToBeAddedToTeam(members);
    },
    selectedNonTechOpsMembers,
    progressText: potentialMembersPEV.progress,
    errorText: potentialMembersPEV.error,
    shouldRenderNonTechOpsMembersAlert: () => shouldDifferentiateNonTechOpsMembers && (selectedNonTechOpsMembers.length > 0),
  };
}

import React from "react";
import Nav from "@impact-x/react-nav";
import "./index.scss";
import useSubHeaderController, { SubHeaderProps } from "./controller";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";


const SubHeader = createFunctionalComponentFromView<ReturnType<typeof useSubHeaderController>, SubHeaderProps>(
  useSubHeaderController,
  (controller) => (
    <div className="SubHeader">
      {// @ts-ignore
        <Nav
          menuItems={controller.menuItems}
          onMenuClick={controller.handleTabClick}
          activeMenuId={controller.activeMenuId}
          headerTitle={controller.headerTitle}
          styles={`height:120px;background-color:${controller.backgroundColor}`}
        />
      }
    </div>
  ));

export default SubHeader;

import React, { FunctionComponent } from "react";
import "./index.scss";
import { IPotentialMember } from "../../../pages/teams/redux";
import { iif } from "../../../utils/functions";

type Props = {
  selectedMembers: IPotentialMember[];
  message: string;
};

const NonTechOpsMembersAlert: FunctionComponent<Props> = (props: Props) => {
  const getMemberNamesAsCommaSeparatedValue = () => {
    const commaSeparatedMemberNames = props.selectedMembers
      .map((member) => `${member.firstName} ${member.lastName}`)
      .join(", ");
    return (iif(function replaceLastCommaWithAnd() {
      return commaSeparatedMemberNames.replace(/,([^,]*)$/, " and$1");
    }));
  };

  return (
    <div className="NonTechOpsMembersAlert">
      <div className={"alert-icon"}>
        <i className="ri-alert-line"/>
      </div>
      <div>
        <div className="alert-heading">Attention</div>
        <div className="alert-message">
          {
            `${getMemberNamesAsCommaSeparatedValue()} do not belong to TechOps.
             ${props.message}`
          }
        </div>
      </div>
    </div>
  );
};

export default NonTechOpsMembersAlert;

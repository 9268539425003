import React, { ReactNode } from "react";
import "./requested-infra-detail.scss";
import NeoLink from "../../../../common/components/neo-link";
import { IRequest } from "../../../requests/types/requests";

type Props = {
  requestId: IRequest["id"];
  label: string | ReactNode;
  showTaskDetailsPageUrl?: boolean;
};


const RequestedInfraDetail = (props: Props) => {
  const renderInfraDetailsLink = () => (
    <NeoLink
      link={props.showTaskDetailsPageUrl ? `/requests/${props.requestId}/tasks` : `/requests/${props.requestId}`}
      external
    >
      Requested
    </NeoLink>
  );

  return (
    <div className="RequestedInfraDetail">
      <span className={"label"}>{props.label}</span>
      {renderInfraDetailsLink()}
    </div>
  );
};

export default RequestedInfraDetail;

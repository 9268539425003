export const extractFieldValues = <ValueType, ReturnType>(fieldName: keyof ValueType, values: ValueType[]): ReturnType[] => {
  if (!values || values.length === 0) {
    return [];
  }
  return values.map((value) => (value[fieldName] as unknown as ReturnType));
};

const extractMultipleFieldsFromObject = <ValueType>(fieldNames: Array<keyof ValueType>, value: ValueType) => {
  const object: Partial<ValueType> = {};
  fieldNames.map((field) => {
    object[field] = value[field];
  });
  return object;
};

export const extractMultipleFieldValues = <ValueType>(fieldNames: Array<keyof ValueType>, values: ValueType[]): Array<Partial<ValueType>> =>
  values.map((value) => extractMultipleFieldsFromObject<ValueType>(fieldNames, value));

import { ApplicationBasicInfo, ApplicationDetailsPageActions } from "../redux";
import { Hash } from "../../../../utils/hash";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import Analytics from "../../../../common/services/analytics";
import { getYesNoChoiceFromBoolean } from "../../../../utils/forms";
import castTo from "../../../../utils/cast-to";
import { formatDate } from "../../../../utils/date-format";

export type Props = {
  application: ApplicationBasicInfo;
  isUserWithEditPrivilege: boolean;
};

export default function useApplicationInformationController(props: Props) {
  const dataClassificationFields: Array<keyof Partial<ApplicationBasicInfo>> = ["hasMnpi", "hasPii", "hasSensitivePii"];
  const generalDetailFields: Array<keyof Partial<ApplicationBasicInfo>> =
    ["description", "purpose", "endDate", "targetAudience", "businessFunction", "category", "techStack", "owner", "country", "project"];
  const headingsMap: Hash<string> = {
    description: "Description",
    purpose: "Purpose",
    targetAudience: "Target audience",
    businessFunction: "Business function",
    country: "Country",
    category: "Category",
    owner: "Owner",
    techStack: "Tech stack",
    hasMnpi: "Does it contain thoughtworks financial information?",
    hasPii: "Does it contain PII?",
    hasSensitivePii: "Does it contain sensitive PII?",
    endDate: "Lifespan",
  };
  const dispatch = useDispatch();

  useEffect(() => {
    Analytics.trackPageApplicationDetails();
  }, []);

  function isTechStackField(fieldName: string) {
    return fieldName === "techStack";
  }

  function isCategoryField(fieldName: string) {
    return fieldName === "category";
  }

  function isOwnerField(fieldName: string) {
    return fieldName === "owner";
  }

  function renderAsList(fieldName: string) {
    return isCategoryField(fieldName) || isTechStackField(fieldName) || isOwnerField(fieldName);
  }

  function shouldRenderDetailsList(detailsList: string[]) {
    return !!detailsList && detailsList.length > 0;
  }

  function isCountryField(fieldName: string) {
    return fieldName === "country";
  }

  function isEndDateField(fieldName: string) {
    return fieldName === "endDate";
  }

  function isProjectField(fieldName: string) {
    return fieldName === "project";
  }

  function isDataClassificationField(fieldName: keyof ApplicationBasicInfo) {
    return dataClassificationFields.includes(fieldName);
  }

  function getFieldValue(fieldName: keyof ApplicationBasicInfo) {
    if (isCountryField(fieldName)) {
      return `${props.application.countryName} - ${props.application.country}`;
    } else if (isEndDateField(fieldName)) {
      return formatDate(props.application.endDate);
    } else if (isDataClassificationField(fieldName)) {
      return getYesNoChoiceFromBoolean(castTo<boolean>(props.application[fieldName]));
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return props.application[fieldName];
  }

  function shouldRenderApplicationInformation() {
    return props.application && props.application.name;
  }

  function shouldRenderEditButton() {
    return props.isUserWithEditPrivilege;
  }

  function enableApplicationDetailsEdit() {
    dispatch(ApplicationDetailsPageActions.updateApplicationDetailsEditState(true));
  }

  return ({
    headingsMap,
    dataClassificationFields,
    informationHandlingDocumentLink:
      "https://docs.google.com/presentation/d/1jtvtamSG6_VmCO0Ra43k7A8un_1qfgwkvRCNjkaKsog/edit#slide=id.ge64adc33d6_0_86",
    getGeneralDetailsFields: () => generalDetailFields,
    getFieldValue,
    shouldRenderApplicationInformation,
    shouldRenderDetailsList,
    shouldRenderEditButton,
    onClickEditButton: enableApplicationDetailsEdit,
    onClickExtendOption: () => {
      Analytics.trackEventLifespanExtend();
      enableApplicationDetailsEdit();
    },
    isEndDateField,
    isProjectField,
    shouldRenderEndDateField: () => props.application.lifespan.canShowApplicationLifespan,
    getBillingProject: () => {
      const project = props.application.project;
      return project ? `${project.name} - ${project.accountProjectCode}` : undefined;
    },
    renderAsList,
    shouldRenderExtendButton: () => props.application.lifespan.canShowExtendOption && props.isUserWithEditPrivilege,
  });
}

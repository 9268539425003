import { useSelectorTyped } from "../../../common/redux/store";
import { useDispatch } from "react-redux";
import { ITeam, TeamDetailsPageActions } from "../redux";
import { teamTypeLabelMap } from "../../../common/data/team-types";
import { TeamType } from "../../create-team/redux";
import { useGetFeatureToggleState } from "../../../common/hooks/feature-toggles";
import { TeamStatus, TeamStatusLabel } from "../../get-started/redux";
import Analytics from "../../../common/services/analytics";
import { getEnvVars } from "../../../common/config/env";


export default function useTeamDetailsController() {
  const { value: teamDetails, error: teamDetailsError, progress: teamDetailsProgress } =
    useSelectorTyped((state) => state.teamDetailsPage.teamDetailsPEV);
  const getTeamDetailFields: Array<keyof ITeam> = ["description", "contactEmail", "supportEmail"];
  const getFieldValue = (fieldName: keyof ITeam) => teamDetails?.[fieldName];
  const projectAssociationToggleEnabled = useGetFeatureToggleState("Neo_Teams_AssociateStaffingProjects");
  const isApiUnificationToggleEnabled = useGetFeatureToggleState("Neo_ApiPlatform_Unification");

  const dispatch = useDispatch();
  const { unificationConfig } = getEnvVars();

  const shouldRenderTeamDetails = () =>
    !teamDetailsError
    && !teamDetailsProgress
    && teamDetails;

  const getInternalOpenSourceField = (): { value: string; description: string } => getFieldValue("isOpen")
    ? {
      value: "Yes",
      description: "All the applications created by this team will be internal open source and any " +
        "Thoughtworker can join this team and contribute to code.",
    }
    : {
      value: "No",
      description: "Only team members can work on the applications created by this team.",
    };

  const getTeamStatusDescription = () => (teamDetails?.status === TeamStatus.ACTIVE)
    ? "Team members can create applications and manage this team."
    : "Applications or clients cannot be created for this team and won't be available in the team catalogue.";

  const getTeamTypeText = () => teamDetails && teamTypeLabelMap[teamDetails.teamType];

  const isEditable = () => teamDetails?.isTeamDetailsEditable ?? false;
  const onClickEditButton = () => {
    Analytics.trackEventClickEditTeamDetails();
    dispatch(TeamDetailsPageActions.updateTeamDetailsEditableState(true));
  };

  return ({
    teamDetails,
    teamDetailsError,
    teamDetailsProgress,
    getTeamDetailFields,
    projectAssociationToggleEnabled,
    isApiUnificationToggleEnabled,
    environmentConfig: unificationConfig.environmentSpecificConfig,
    getFieldValue,
    shouldRenderTeamDetails,
    getTeamTypeText,
    getInternalOpenSourceField,
    shouldRenderInternalOpenSource: () =>
      getFieldValue("teamType") === TeamType.EXPERIMENT_AND_INNOVATION,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    getStatus: () => teamDetails !== null ? TeamStatusLabel[teamDetails.status] : "",
    getTeamStatusDescription,
    shouldRenderProjectAssociation: () =>
      (getFieldValue("teamType") === TeamType.REGIONAL_IT ||
        getFieldValue("teamType") === TeamType.TECHOPS) && projectAssociationToggleEnabled,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    getAssociatedProjects: () => teamDetails?.projects?.map((project) => project.name),
    shouldRenderNoProjectAssociationMessage: () => teamDetails?.projects === null || teamDetails?.projects?.length === 0,
    onClickEditButton,
    isEditable,
  });
}

import React, { ChangeEvent, FunctionComponent } from "react";
import "./index.scss";
import ImpactXIconButton from "../impactx-icon-button";

export type Props = {
  label: string;
  className?: string;
  placeholder?: string;
  searchText: string;
  onChangeSearchText: (event: ChangeEvent<HTMLInputElement>) => void;
  onClickClearSearchText: () => void;
};


const SearchBar: FunctionComponent<Props> = (props: Props) => (
  <span
    ix-styles="input"
    className={`SearchBar ${props.className || ""}`}
  >
    <i className="ri-search-2-line search-icon"/>
    <input
      type="text"
      aria-label={props.label}
      placeholder={props.placeholder ?? "Search"}
      value={props.searchText}
      onChange={props.onChangeSearchText}
    />
    <ImpactXIconButton
      ariaLabel="Clear search text"
      onClick={props.onClickClearSearchText}
      className={"clear-search-button"}
    >
      <i className="ri-close-fill cursor-pointer"/>
    </ImpactXIconButton>
  </span>
);

export default SearchBar;

import { useSelectorTyped } from "../../../../../common/redux/store";
import { getYesNoChoiceFromBoolean } from "../../../../../utils/forms";

export default function useBasicInfoReviewController() {
  const basicInfo = useSelectorTyped((state) => state.getStartedPage.regionalIT.basicInfo);

  return {
    basicInfo,
    dataClassificationDetails: {
      hasMnpi: getYesNoChoiceFromBoolean(basicInfo.hasMnpi),
      hasPii: getYesNoChoiceFromBoolean(basicInfo.hasPii),
      hasSensitivePii: getYesNoChoiceFromBoolean(basicInfo.hasSensitivePii),
    },
    shouldRenderBillingProject: () => basicInfo.billingProjectName,
  };
}


import * as Survey from "survey-react";
import React, { ChangeEvent } from "react";

const inputBox = {
  name: "inputbox",
  isFit: (question: Survey.IQuestion) => question.getType() === "text",
  render: function renderInputBox(question: Survey.QuestionTextModel) {
    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      question.value = e.target.value;
    };

    function renderPreText() {
      if (question.description?.startsWith("pre--")) {
        return (
          <span>{question.description.slice("pre--".length)}</span>
        );
      }
    }

    function renderPostText() {
      if (question.description?.startsWith("pos--")) {
        return (
          <span>{question.description.slice("pos--".length)}</span>
        );
      }
    }

    return (
      <span
        ix-styles="input"
        aria-disabled={question.readOnly}
      >
        {renderPreText()}
        <input
          type="text"
          aria-label={question.title}
          name={question.getValueName()}
          ix-styles="m"
          value={question.value as string || ""}
          onChange={onInputChange}
          disabled={question.readOnly}
        />
        {renderPostText()}
      </span>
    );
  },
};

export default inputBox;

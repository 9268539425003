import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as service from "./service";
import { PEV, ProgressErrorValue } from "../../utils/progress-error-value";
import { INeoErrorInfo, NeoError } from "../../utils/errors/neo-errors";
import { Nullable } from "../../utils/nullable";
import { createAsyncThunkWithRejectValue } from "../../common/redux/createAsyncThunkWithRejectValue";
import { IRequest, ITask } from "./types/requests";
import { PE, ProgressError } from "../../utils/progress-error";
import { identity } from "../../utils/cast-to";

export type RequestRaisedEventPayload = {
  request: Omit<IRequest, "tasks" | "closedDateTime">;
};

export type RequestInProgressEventPayload = {
  request: Omit<IRequest, "closedDateTime" | "tasks"> & {
    tasks: Array<Omit<ITask, "completedDateTime">>;
  };
};

export type TaskCreatedEventPayload = {
  request: Omit<IRequest, "tasks" | "closedDateTime">;
  task: Omit<ITask, "completedDateTime" | "disapprovalReason">;
};

export type TaskApprovedEventPayload = {
  request: Omit<IRequest, "tasks" | "closedDateTime">;
  task: Omit<ITask, "completedDateTime" | "disapprovalReason">;
};

export type TaskReTriggeredEventPayload = TaskApprovedEventPayload;

export type TaskDisapprovedEventPayload = {
  request: Omit<IRequest, "tasks" | "closedDateTime">;
  task: ITask;
};

export type TaskFailedEventPayload = {
  request: Omit<IRequest, "tasks" | "closedDateTime">;
  task: Omit<ITask, "completedDateTime" | "disapprovalReason">;
};

export type TaskCompletedEventPayload = {
  request: Omit<IRequest, "tasks" | "closedDateTime">;
  task: Omit<ITask, "disapprovalReason">;
};

export type RequestClosedEventPayload = {
  request: IRequest;
  tasks: ITask[];
};

export enum RequestEventTypeLabelMap {
  REQUEST_RAISED = "request-raised",
  REQUEST_IN_PROGRESS = "request-in-progress",
  REQUEST_CLOSED = "request-closed",
  TASK_CREATED = "task-created",
  TASK_APPROVED = "task-approved",
  TASK_COMPLETED = "task-completed",
  TASK_RETRIGGERED = "task-retriggered",
  TASK_DISAPPROVED = "task-disapproved",
  TASK_FAILED = "task-failed",
}

export type RequestEventPayload =
  RequestRaisedEventPayload
  | RequestInProgressEventPayload
  | RequestClosedEventPayload
  | TaskCreatedEventPayload
  | TaskApprovedEventPayload
  | TaskDisapprovedEventPayload
  | TaskFailedEventPayload
  | TaskCompletedEventPayload
  | TaskReTriggeredEventPayload;

export interface IRequestEvent {
  id: string;
  groupingKey: string;
  payload: RequestEventPayload;
  type: RequestEventTypeLabelMap;
  createdDateTime: string;
  updatedDateTime: string;
}

export type IDisapproveTaskRequest = {
  disapprovalReason: ITask["disapprovalReason"];
};

interface IRequestsPageState {
  requestEventsPEV: ProgressErrorValue<IRequestEvent[], Nullable<string>, Nullable<INeoErrorInfo>>;
  myRequestsPEV: ProgressErrorValue<IRequest[], Nullable<string>, Nullable<INeoErrorInfo>>;
  assignedRequestsPEV: ProgressErrorValue<IRequest[], Nullable<string>, Nullable<INeoErrorInfo>>;
  approveTaskPE: ProgressError<Nullable<string>, Nullable<INeoErrorInfo>>;
  reTriggerTaskPE: ProgressError<Nullable<string>, Nullable<INeoErrorInfo>>;
  disapproveTaskPE: ProgressError<Nullable<string>, Nullable<INeoErrorInfo>>;
  completeTaskPE: ProgressError<Nullable<string>, Nullable<INeoErrorInfo>>;
  showDisapproveTaskModal: boolean;
  idOfRequestBeingFetched: string;
}

const initialState: IRequestsPageState = {
  requestEventsPEV: PEV([]),
  myRequestsPEV: PEV([]),
  assignedRequestsPEV: PEV([]),
  approveTaskPE: PE(),
  reTriggerTaskPE: PE(),
  disapproveTaskPE: PE(),
  completeTaskPE: PE(),
  idOfRequestBeingFetched: "",
  showDisapproveTaskModal: false,
};

const fetchMyRequests = createAsyncThunkWithRejectValue<IRequest[]>(
  "my-requests/get",
  service.getMyRequests,
);

const fetchAssignedRequests = createAsyncThunkWithRejectValue<IRequest[]>(
  "assigned-requests/get",
  service.getAssignedRequests,
);

const approveTask = createAsyncThunkWithRejectValue<IRequest, { requestId: string; taskId: string }>(
  ":requestId/tasks/:taskId/approve",
  async (payload) => service.approveTask(payload.requestId, payload.taskId),
);

const reTriggerTask = createAsyncThunkWithRejectValue<IRequest, { requestId: string; taskId: string }>(
  ":requestId/tasks/:taskId/re-trigger",
  async (payload) => service.reTriggerTask(payload.requestId, payload.taskId),
);

const fetchRequestEvents = createAsyncThunkWithRejectValue<IRequestEvent[], { requestId: string }>(
  "request-events/get",
  async (payload) => service.getRequestEvents(payload.requestId),
);

const disapproveTask =
  createAsyncThunkWithRejectValue<IRequest, { requestId: string; taskId: string; requestBody: IDisapproveTaskRequest }>(
    "disapprove-task/post",
    async (payload) =>
      service.disapproveTask(payload.requestId, payload.taskId, payload.requestBody),
  );

const completeTask = createAsyncThunkWithRejectValue<IRequest, { requestId: string; taskId: string }>(
  ":requestId/tasks/:taskId/complete",
  async (payload) => service.completeTask(payload.requestId, payload.taskId),
);

const updateAssignedRequestsValue = (
  taskId: string,
  assignedRequests: IRequest[],
  updatedRequest: IRequest,
) => {
  const updatedTask = updatedRequest.tasks.find((task) => task.id === taskId);
  return assignedRequests.map((request) => {
    if (request.id === updatedRequest.id) {
      return identity<IRequest>({
        ...request,
        status: updatedRequest.status,
        aggregatedTaskStatus: updatedRequest.aggregatedTaskStatus,
        tasks: request.tasks.map((task) => {
          if (task.id === taskId) {
            return identity<ITask>({
              ...task,
              ...updatedTask,
            });
          }
          return task;
        }),
      });
    }
    return request;
  });
};

const isFetchingRequestActivitiesActionCompleted = (
  idOfRequestInProgress: string,
  action: PayloadAction<IRequestEvent[] | NeoError, string, { arg: { requestId: string } }>,
) => {
  const requestIdFromResponse = action.meta.arg.requestId;
  return idOfRequestInProgress === requestIdFromResponse;
};

export const requestsPageSlice = createSlice({
  name: "requestsPage",
  initialState,
  reducers: {
    updateRequestDetailsPage: (state: IRequestsPageState, action: PayloadAction<Partial<IRequestsPageState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: ((builder) => {
    builder
      .addCase(fetchMyRequests.pending, (state) => {
        state.myRequestsPEV = PEV(state.myRequestsPEV.value, "Fetching requests.");
      })
      .addCase(fetchMyRequests.fulfilled, (state, action) => {
        state.myRequestsPEV = PEV(action.payload, null, null);
      })
      .addCase(fetchMyRequests.rejected.type, (state, action: PayloadAction<NeoError>) => {
        state.myRequestsPEV = PEV([], null, action.payload.getErrorInfo());
      })
      .addCase(fetchAssignedRequests.pending, (state) => {
        state.assignedRequestsPEV = PEV(state.assignedRequestsPEV.value, "Fetching tasks.");
      })
      .addCase(fetchAssignedRequests.fulfilled, (state, action) => {
        state.assignedRequestsPEV = PEV(action.payload, null, null);
      })
      .addCase(fetchAssignedRequests.rejected.type, (state, action: PayloadAction<NeoError>) => {
        state.assignedRequestsPEV = PEV([], null, action.payload.getErrorInfo());
      })
      .addCase(approveTask.pending, (state) => {
        state.approveTaskPE = PE("Approving task.", null);
      })
      .addCase(approveTask.fulfilled, (state, action) => {
        const updatedAssignedRequests = updateAssignedRequestsValue(action.meta.arg.taskId, state.assignedRequestsPEV.value, action.payload);
        state.assignedRequestsPEV = PEV(updatedAssignedRequests, null, null);
        state.approveTaskPE = PE(null, null);
      })
      .addCase(approveTask.rejected.type, (state, action: PayloadAction<NeoError>) => {
        state.approveTaskPE = PEV(null, null, action.payload.getErrorInfo());
      })
      .addCase(disapproveTask.pending, (state) => {
        state.disapproveTaskPE = PE("Disapproving a task.");
      })
      .addCase(disapproveTask.fulfilled, (state, action) => {
        const updatedAssignedRequests = updateAssignedRequestsValue(action.meta.arg.taskId, state.assignedRequestsPEV.value, action.payload);
        state.assignedRequestsPEV = PEV(updatedAssignedRequests, null, null);
        state.disapproveTaskPE = PE(null, null);
        state.showDisapproveTaskModal = false;
      })
      .addCase(disapproveTask.rejected.type, (state, action: PayloadAction<NeoError>) => {
        state.disapproveTaskPE = PE(null, action.payload.getErrorInfo());
      })
      .addCase(completeTask.pending, (state) => {
        state.completeTaskPE = PE("Completing a task.");
      })
      .addCase(completeTask.fulfilled, (state, action) => {
        const updatedAssignedRequests = updateAssignedRequestsValue(action.meta.arg.taskId, state.assignedRequestsPEV.value, action.payload);
        state.assignedRequestsPEV = PEV(updatedAssignedRequests, null, null);
        state.completeTaskPE = PE(null, null);
      })
      .addCase(completeTask.rejected.type, (state, action: PayloadAction<NeoError>) => {
        state.completeTaskPE = PE(null, action.payload.getErrorInfo());
      })
      .addCase(reTriggerTask.pending, (state) => {
        state.reTriggerTaskPE = PE("Re-triggering task.", null);
      })
      .addCase(reTriggerTask.fulfilled, (state, action) => {
        const updatedAssignedRequests = updateAssignedRequestsValue(action.meta.arg.taskId, state.assignedRequestsPEV.value, action.payload);
        state.assignedRequestsPEV = PEV(updatedAssignedRequests, null, null);
        state.reTriggerTaskPE = PE(null, null);
      })
      .addCase(reTriggerTask.rejected.type, (state, action: PayloadAction<NeoError>) => {
        state.reTriggerTaskPE = PE(null, action.payload.getErrorInfo());
      })
      .addCase(fetchRequestEvents.pending, (state, action) => {
        function isCurrentFetchRequestActivitySameAsExistingActivity() {
          return action.meta.arg.requestId === state.requestEventsPEV?.value?.[0]?.groupingKey;
        }

        if (!isCurrentFetchRequestActivitySameAsExistingActivity()) {
          state.requestEventsPEV = PEV(state.requestEventsPEV.value, "Fetching request events.");
        }
        state.idOfRequestBeingFetched = action.meta.arg.requestId;
      })
      .addCase(fetchRequestEvents.fulfilled, (state, action) => {
        if (isFetchingRequestActivitiesActionCompleted(state.idOfRequestBeingFetched, action)) {
          state.requestEventsPEV = PEV(action.payload, null, null);
        }
      })
      .addCase(fetchRequestEvents.rejected.type, (state, action: PayloadAction<NeoError, string, { arg: { requestId: string } }>) => {
        if (isFetchingRequestActivitiesActionCompleted(state.idOfRequestBeingFetched, action)) {
          state.requestEventsPEV = PEV([], null, action.payload.getErrorInfo());
        }
      });
  }),
});

export const requestsPageReducer = requestsPageSlice.reducer;

export const RequestsPageActions = {
  updateRequestDetailsPageState: requestsPageSlice.actions.updateRequestDetailsPage,
  fetchMyRequests,
  fetchRequestEvents,
  fetchAssignedRequests,
  approveTask,
  disapproveTask,
  completeTask,
  reTriggerTask,
};

import { Hash } from "../../utils/hash";

export const teamTypeLabelMap: Hash<string> = {
  EXPERIMENT_AND_INNOVATION: "Experiment and Innovation",
  REGIONAL_IT: "Regional IT",
  TECHOPS: "TechOps",
  SOFTWARE_MANAGEMENT: "Software Management",
};

export const teamTypeDropDownOptions = [
  { label: teamTypeLabelMap.EXPERIMENT_AND_INNOVATION, value: "EXPERIMENT_AND_INNOVATION" },
  { label: teamTypeLabelMap.REGIONAL_IT, value: "REGIONAL_IT" },
  { label: teamTypeLabelMap.TECHOPS, value: "TECHOPS" },
  { label: teamTypeLabelMap.SOFTWARE_MANAGEMENT, value: "SOFTWARE_MANAGEMENT" },
];

import { PEV, ProgressErrorValue } from "../../../utils/progress-error-value";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { applicationStatusService } from "../../services/applications-status";
import { ApplicationStatus } from "../../data/application-status";
import { INeoErrorInfo, NeoError } from "../../../utils/errors/neo-errors";
import { Nullable } from "../../../utils/nullable";
import { createAsyncThunkWithRejectValue } from "../createAsyncThunkWithRejectValue";

export type IApplicationStatuses = ApplicationStatus[];

type IApplicationStatusState = ProgressErrorValue<IApplicationStatuses, Nullable<string>, Nullable<INeoErrorInfo>>;

const initialState: IApplicationStatusState = PEV([]);

const fetchApplicationStatusList = createAsyncThunkWithRejectValue<IApplicationStatuses>(
  "application-status",
  applicationStatusService.getApplicationsStatuses,
);

export const applicationStatusesSlice = createSlice({
  name: "applicationStatuses",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchApplicationStatusList.pending.type]: (state) => {
      state.progress = "Fetching application status";
      state.error = null;
      state.value = [];
    },
    [fetchApplicationStatusList.fulfilled.type]: (state, action: PayloadAction<IApplicationStatuses>) => {
      state.value = action.payload;
      state.progress = null;
      state.error = null;
    },
    [fetchApplicationStatusList.rejected.type]: (state: IApplicationStatusState, action: PayloadAction<NeoError>) => {
      state.error = action.payload.getErrorInfo();
      state.progress = null;
      state.value = [];
    },
  },
});

export const dispatchFetchApplicationStatusesIfEmpty = (
  dispatch: Dispatch<unknown>,
  applicationStatusPEV: IApplicationStatusState,
) => {
  if (applicationStatusPEV.value.length === 0 && !applicationStatusPEV.progress && !applicationStatusPEV.error) {
    dispatch(fetchApplicationStatusList());
  }
};

export const applicationStatusReducer = applicationStatusesSlice.reducer;

import React, { FunctionComponent } from "react";
import "./index.scss";
import { DropdownItem } from "../dropdown-menu-item";
import useDropdownMenuController from "./controller";
import Analytics from "../../../services/analytics";
import NeoLink from "../../neo-link";
import DropdownMenu from "../../dropdown-menu";

export type HeaderDropdownMenuProps = {
  label: string;
  url?: string;
  dropdownItems: DropdownItem[];
  width?: number;
  highlightUrl?: string;
};

const HeaderDropdownMenu: FunctionComponent<HeaderDropdownMenuProps> = (props: HeaderDropdownMenuProps) => {
  const controller = useDropdownMenuController(props);

  const renderArrowIcon = () => (<i className="ri-arrow-down-s-line"/>);

  const renderMenu = () => {
    if (props.url) {
      return (
        <NeoLink
          link={props.url}
          onClick={() => Analytics.trackEventClickOnHeaderItems(props.label)}
          clearDefaultStyles
        >
          {props.label}
        </NeoLink>
      );
    } else if (props.dropdownItems.length > 0) {
      return (
        <div className="drop-down-menu">
          <DropdownMenu
            dropdownItems={props.dropdownItems}
            ariaLabel="Header Menu Dropdown"
          >
            <div>
              {props.label}
              {renderArrowIcon()}
            </div>
          </DropdownMenu>
        </div>
      );
    } else {
      return props.label;
    }
  };

  const renderMenuTitle = () => (
    <div className="menu-title">
      <div>
        {renderMenu()}
      </div>
    </div>
  );

  return (
    <div className="HeaderDropdownMenu">
      <div className={`dropdown-button ${controller.getHighlightClassForCurrentPath()}`}>
        {renderMenuTitle()}
        <div className="hidden-menu-item">{renderMenuTitle()}</div>
      </div>
    </div>
  );
};

export default HeaderDropdownMenu;

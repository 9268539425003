import React from "react";
import { createFunctionalComponentFromView } from "../../../../../utils/view-controller";
import useRequestZendeskResourceFormController from "./controller";
import { NavigationButtons } from "../../../../../common/components/forms/navigation-buttons";
import FormComponent, { INavigationButtonsProps } from "../../../../../common/components/forms";

const RequestZendeskResource = createFunctionalComponentFromView(
  useRequestZendeskResourceFormController,
  (controller) => {
    const renderNavigationButtons = ({ getFormData, doesFormHasErrors }: INavigationButtonsProps) => (
      <NavigationButtons
        nextButtonLabel={controller.getSubmitButtonLabel()}
        previousButtonLabel="Cancel"
        getFormData={getFormData}
        doesFormHasErrors={doesFormHasErrors}
        onClickNext={controller.onSubmit}
        onClickPrevious={controller.onCancel}
        isNextButtonDisabled={controller.isFormButtonDisabled()}
        isPreviousButtonDisabled={controller.isFormButtonDisabled()}
      />
    );

    return (
      <div className={controller.resourceConfig.resourceType.toLowerCase()}>
        <FormComponent
          ref={controller.formRef}
          key={controller.formKey}
          questions={controller.getQuestions()}
          data={controller.getFormData()}
          renderNavigationButtons={renderNavigationButtons}
        />
      </div>
    );
  },
);

export default RequestZendeskResource;

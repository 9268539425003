import { getEnvVars } from "../../config/env";
import { IDToken, OktaAuth, Tokens } from "@okta/okta-auth-js";
import castTo from "../../../utils/cast-to";
import { invokeImmediately } from "../../../utils/functions";
import { timeInSeconds } from "../../../utils/date-time";
import { EncryptStorage } from "encrypt-storage";

const { oktaAuth: auth, disableOktaAuth: isOktaDisabled } = getEnvVars();

const getOktaTokenKey = () => {
  if (isOktaDisabled) {
    return "fc81uVzCq2";
  } else {
    if (auth.oktaTokenKey) {
      return auth.oktaTokenKey;
    } else {
      throw new Error("Okta auth initialization failed due to invalid token");
    }
  }
};

export const encryptedStorage = new EncryptStorage(getOktaTokenKey(), {
  storageType: "localStorage",
  encAlgorithm: "AES",
});

export const oktaTokenStorage: Pick<Storage, "getItem" | "setItem"> & { [name: string]: string | null | unknown } = {
  getItem(key) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return encryptedStorage.getItem(key) ?? null;
    } catch {
      return null;
    }
  },
  setItem(key, value) {
    encryptedStorage.setItem(key, JSON.stringify(value));
  },
};

// uncomment this if we want to use local storage to store okta tokens
// export const oktaTokenStorage = window.localStorage;

export const oktaAuthClient: OktaAuth = new OktaAuth({
  issuer: auth.issuer,
  clientId: auth.clientId,
  redirectUri: auth.redirectUrl,
  scopes: auth.scopes.split(/\s+/),
  pkce: true,
  tokenManager: {
    autoRenew: true,
    expireEarlySeconds: timeInSeconds(0, 5),
    storage: (oktaTokenStorage as unknown as string),
  },
  // @ts-ignore
  transactionManager: {
    saveNonceCookie: false,
    saveParamsCookie: false,
    saveStateCookie: false,
  },
});

function getOktaIdTokenObject() {
  const tokensObjectAsStr = oktaTokenStorage.getItem("okta-token-storage");
  return (tokensObjectAsStr && (castTo<{ idToken?: IDToken }>(JSON.parse(tokensObjectAsStr)))?.idToken) || null;
}

export function getOktaIdToken() {
  return getOktaIdTokenObject()?.idToken;
}

function getOktaAccessTokenObject() {
  const tokensObjectAsStr = oktaTokenStorage.getItem("okta-token-storage");
  return (tokensObjectAsStr && (castTo<{ accessToken?: Tokens }>(JSON.parse(tokensObjectAsStr)))?.accessToken) || null;
}

export function getOktaAccessToken() {
  return getOktaAccessTokenObject()?.accessToken;
}

window.getOktaIdToken = getOktaIdToken;
window.getOktaAccessToken = getOktaAccessToken;

export function hasOktaTokenExpired() {
  return (
    !getEnvVars().disableOktaAuth
    && invokeImmediately(function checkForTokenExpiry() {
      const oktaIdTokenObject = getOktaIdTokenObject();
      return oktaIdTokenObject ? oktaAuthClient.tokenManager.hasExpired(oktaIdTokenObject) : true;
    })
  );
}

import { useSelectorTyped } from "../../../../../common/redux/store";
import YesNoChoices from "../../../data/yes-no-choice";
import { getGcpProjectNames, prefixApplicationNameToGitHubRepos } from "../../../data/common-functions";

export default function useInfraConfigReviewController() {
  const infraConfig = useSelectorTyped((state) => state.getStartedPage.experimentsAndInnovations.infraConfig);
  const applicationName = useSelectorTyped((state) => state.getStartedPage.experimentsAndInnovations.basicInfo.projectName);

  const isGcpProjectRequested = () => infraConfig.isHostingServiceNeeded === YesNoChoices.YES;

  const getGithubRepoNames = () => prefixApplicationNameToGitHubRepos(infraConfig.githubRepos, applicationName);

  const isGithubRepoRequested = () => infraConfig.isCodeRepositoryNeeded === YesNoChoices.YES;

  const shouldRenderComments = () => infraConfig.isCodeRepositoryNeeded === YesNoChoices.NO
    && infraConfig.isHostingServiceNeeded === YesNoChoices.NO;

  const getGithubRepoRequestedStatus = () => isGithubRepoRequested() ? "Requested" : "Not requested";

  const getGcpProjectRequestedStatus = () => isGcpProjectRequested() ? "Requested" : "Not requested";

  const getCircleCiRequestedStatus = () => infraConfig.isCiCdPipelineNeeded === YesNoChoices.YES ? "Requested" : "Not requested";

  return {
    infraConfig,
    getGithubRepoNames,
    shouldRenderComments,
    isGcpProjectRequested,
    isGithubRepoRequested,
    getCircleCiRequestedStatus,
    getGithubRepoRequestedStatus,
    getGcpProjectRequestedStatus,
    getGcpProjectNames: () => getGcpProjectNames(infraConfig.gcpProjects),
  };
}


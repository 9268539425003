import React, { ReactNode } from "react";
import "./index.scss";
import { Link, LinkProps } from "react-router-dom";
import { omit } from "lodash";

type NeoLinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href" | "aria-label" | "key"> & {
  link: string | LinkProps["to"];
  label?: string;
  ariaLabel?: string;
  "data-testid"?: string;
  "ix-styles"?: string;
  external?: boolean;
  clearDefaultStyles?: boolean;
  children?: ReactNode;
};

const NeoLink = ({ children, ...restOfProps }: NeoLinkProps) => {
  const propsToBeExcluded = ["link", "label", "ariaLabel", "external", "clearDefaultStyles", "className"];

  function getClassName() {
    if (restOfProps.clearDefaultStyles) {
      return restOfProps.className;
    }
    return `NeoLink ${(restOfProps.className ? restOfProps.className : "")}`;
  }

  if (restOfProps.external) {
    return (
      <a
        className={getClassName()}
        href={restOfProps.link as string}
        aria-label={restOfProps.ariaLabel}
        title={restOfProps.ariaLabel}
        target="_blank"
        rel="noopener noreferrer"
        {...omit(restOfProps, ...propsToBeExcluded)}
      >
        {children ?? restOfProps.label ?? restOfProps.link}
      </a>
    );
  }
  return (
    <Link
      className={getClassName()}
      to={restOfProps.link}
      aria-label={restOfProps.ariaLabel}
      title={restOfProps.ariaLabel}
      {...omit(restOfProps, ...propsToBeExcluded)}
    >
      {children ?? restOfProps.label ?? restOfProps.link}
    </Link>
  );
};

export default NeoLink;

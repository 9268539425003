import React from "react";
import "./index.scss";
import SearchBar from "../../../../common/components/search-bar";
import { useApplicationSearchController } from "./controller";
import { createFunctionalComponentFromView } from "../../../../utils/view-controller";

const AllApplicationSearch = createFunctionalComponentFromView(useApplicationSearchController, (controller) => (
  <SearchBar
    label="All Applications"
    className="AllApplicationsSearch"
    placeholder="Search by keyword"
    searchText={controller.searchText}
    onChangeSearchText={controller.onChangeSearchText}
    onClickClearSearchText={controller.onClickClearSearchText}
  />
));

export default AllApplicationSearch;

import React, { FunctionComponent } from "react";
import NeoLink from "../../../../common/components/neo-link";

const ApplicationsLink: FunctionComponent = () => (
  <div className="apps-catalogue">
    <span>Before you start any new project/initiative, check the </span>
    <NeoLink
      className="apps-catalogue-link"
      link="/catalogue/applications"
    >
      Applications
    </NeoLink>
    <span> to avoid duplicated efforts.</span>
  </div>
);

export default ApplicationsLink;

import apiImage from "../assets/LandingPageApi.webp";
import eventsImage from "../assets/LandingPageEvents.webp";
import dataImage from "../assets/LandingPageData.webp";
import { ImageNavigationCardProps } from "../image-navigation-cards";

export const discoverCardsData: ImageNavigationCardProps[] = [
  {
    title: "API",
    description: "Explore APIs organised by domain aggregates that are available to consume.",
    link: "/api-gateway/api-list",
    image: apiImage,
    imageAltText: "",
  },
  {
    title: "Events",
    description: "Explore the events organised by domain aggregates that are available to subscribe to.",
    link: "/events",
    image: eventsImage,
    imageAltText: "",
  },
  {
    title: "Data",
    description: "Explore the list of Sisense dashboards and datasets from various TW applications available on the platform.",
    link: "/data-catalogue",
    image: dataImage,
    imageAltText: "",
  },
];

import React, {FunctionComponent} from "react";
import Nav from "@impact-x/react-nav";
import "./index.scss";
import {MENU_IDS, SUBHEADER_BACKGROUND_COLOR, SUBHEADER_MENU_ITEMS, SUBHEADER_TITLE} from "./data/constants";
import useEventsSubHeaderController from "./controller";
import {useGetFeatureToggleState} from "../../../common/hooks/feature-toggles";

const EventsSubHeader: FunctionComponent = () => {
  const controller = useEventsSubHeaderController();
  const isOverviewFeatureEnabled = useGetFeatureToggleState("Events_EventsDiscovery_Overview");

  return (
    <div className="EventsSubHeader">
      {// @ts-ignore
        <Nav
          menuItems={isOverviewFeatureEnabled ? [{id: MENU_IDS.EVENTS_OVERVIEW, title: "Overview"}, ...SUBHEADER_MENU_ITEMS] : SUBHEADER_MENU_ITEMS}
          onMenuClick={controller.handleEventTabClick}
          activeMenuId={controller.activeMenuId}
          headerTitle={SUBHEADER_TITLE}
          styles={`height:120px;background-color:${SUBHEADER_BACKGROUND_COLOR}`}
        />
      }
    </div>
  );
};

export default EventsSubHeader;

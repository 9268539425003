import React, { FunctionComponent } from "react";
import TechOpsInfo from "../../../common/components/project-classification-contents/techops-info";

const TechOpsTooltipInfo: FunctionComponent = () => (
  <div className="tooltip-content">
    <TechOpsInfo />
  </div>
);

export default TechOpsTooltipInfo;

import React from "react";
import { Props, useApplicationTableViewController } from "./controller";
import DataTable from "react-data-table-component";
import "./index.scss";
import TableSortIcon from "../data-table-sort-icon";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";

const ApplicationsListView = createFunctionalComponentFromView<ReturnType<typeof useApplicationTableViewController>, Props>(
  useApplicationTableViewController,
  (controller) => (
    <DataTable
      className={"ApplicationsListView"}
      columns={controller.columns}
      data={controller.applications}
      noHeader={true}
      sortIcon={<TableSortIcon/>}
      persistTableHead={true}
      onRowClicked={!controller.environmentConfig.isSandbox?controller.onRowClicked:undefined}
    />
  ),
);

export default ApplicationsListView;

import { useSelectorTyped } from "../../../../../common/redux/store";
import { getYesNoChoiceFromBoolean } from "../../../../../utils/forms";
import { formatDate } from "../../../../../utils/date-format";

export default function useBasicInfoReviewController() {
  const basicInfo = useSelectorTyped((state) => state.getStartedPage.experimentsAndInnovations.basicInfo);

  return {
    basicInfo,
    dataClassificationDetails: {
      hasMnpi: getYesNoChoiceFromBoolean(basicInfo.hasMnpi),
      hasPii: getYesNoChoiceFromBoolean(basicInfo.hasPii),
      hasSensitivePii: getYesNoChoiceFromBoolean(basicInfo.hasSensitivePii),
    },
    shouldRenderBillingProject: () => basicInfo.billingProjectName,
    getEndDate: () => formatDate(basicInfo.endDate),
  };
}

import React from "react";
import { createFunctionalComponentFromView } from "../../../../../utils/view-controller";
import useDecommissionApplicationFormController from "./controller";
import { NavigationButtons } from "../../../../../common/components/forms/navigation-buttons";
import FormComponent, { INavigationButtonsProps } from "../../../../../common/components/forms";
import NeoModalDialog from "../../../../../common/components/neo-modal-dialog";

const DecommissionApplication = createFunctionalComponentFromView(
  useDecommissionApplicationFormController,
  (controller) => {
    const renderNavigationButtons = ({ getFormData, doesFormHasErrors }: INavigationButtonsProps) => {
      if (controller.shouldRenderNavigationButtons()) {
        return (
          <NavigationButtons
            nextButtonLabel="Submit"
            previousButtonLabel="Cancel"
            getFormData={getFormData}
            doesFormHasErrors={doesFormHasErrors}
            onClickNext={controller.onSubmit}
            onClickPrevious={controller.onCancel}
            isNextButtonDisabled={controller.isFormButtonDisabled()}
            isPreviousButtonDisabled={controller.isFormButtonDisabled()}
          />
        );
      }
      return null;
    };

    const renderConfirmationModal = () => (
      <NeoModalDialog
        dialogTitle="Application Decommission"
        subTitle="Are you sure you want to decommission this application?"
        highlightSubTitle={true}
        bodyText={
          "All GCP Projects, GitHub team, CI/CD pipelines, Okta preview / Okta production chiclets and groups, " +
          "Clients will be deleted. GitHub repositories will be temporarily archived."
        }
        dialogClassName="decommission-confirmation-modal"
        dontAutoHideOnButtonClick={true}
        primaryButton={{
          show: true,
          label: controller.getDecommissionButtonLabel(),
          disabled: controller.isConfirmationModalButtonDisabled(),
          onClick: controller.onClickDecommissionInConfirmationModal,
        }}
        secondaryButton={{
          show: true,
          label: "Cancel",
          disabled: controller.isConfirmationModalButtonDisabled(),
          onClick: controller.onClickCancelInConfirmationModal,
        }}
        show={controller.showConfirmationModal}
        onHide={controller.onHideConfirmationModal}
      />
    );

    return (
      <div className="DecommissionApplication">
        <FormComponent
          ref={controller.formRef}
          key={controller.formKey}
          questions={controller.getQuestions()}
          data={controller.getFormData()}
          renderNavigationButtons={renderNavigationButtons}
        />
        {renderConfirmationModal()}
      </div>
    );
  },
);

export default DecommissionApplication;

import { callBffApi } from "../../common/services/axios";
import { ITeam } from "../team-details/redux";
import { IPotentialMember } from "./redux";

type FetchMyTeamResponse = {
  teams: ITeam[];
};

type FetchAllTeamResponse = {
  teams: ITeam[];
};

type FetchUsersResponse = {
  users: IPotentialMember[];
};

export const fetchMyTeams = async (): Promise<ITeam[]> => {
  const result = await callBffApi<FetchMyTeamResponse>("get", "/teams/my-teams");
  return result.teams;
};

// @ts-ignore
export const fetchAllPotentialMembers = async (): Promise<IPotentialMember[]> => {
  const result = await callBffApi<FetchUsersResponse>("get", "/teams/potential-members");
  return result.users;
};

export const fetchAllTeams = async (): Promise<ITeam[]> => {
  const result = await callBffApi<FetchAllTeamResponse>("get", "/teams");
  return result.teams;
};

import React from "react";
import "./index.scss";
import FilterDropDown from "../../../../common/components/filter-dropdown";
import { useApplicationFiltersController } from "./controller";
import TextButton from "../../../../common/components/text-button";
import CheckBox from "../../../../common/components/check-box";
import { createFunctionalComponentFromView } from "../../../../utils/view-controller";

const MyApplicationFilters = createFunctionalComponentFromView(useApplicationFiltersController, (controller) => (
  <div className="MyApplicationsFilters">
    <div className="title-and-reset">
      <span className="title">Filters</span>
      <TextButton
        label="Reset"
        className="reset"
        onClick={controller.onClickResetButton}
      />
    </div>
    <div className="display-inactive-applications">
      <CheckBox
        id="display-inactive-applications-checkbox"
        checked={controller.filters.displayInactiveApplications}
        onChange={controller.onChangeDisplayInactiveApplicationsCheckbox}
      />
      <label htmlFor="display-inactive-applications-checkbox">Display inactive applications</label>
    </div>
    <FilterDropDown
      options={controller.getApplicationStatusDropdownOptions()}
      title="Status"
      onChangeHandler={controller.onChangeStatusFilter}
      selectedOptions={controller.filters.statuses}
    />
    <FilterDropDown
      options={controller.getApplicationTypeDropdownOptions()}
      title="Type"
      onChangeHandler={controller.onChangeTeamTypeFilter}
      selectedOptions={controller.filters.teamTypes}
    />
    <FilterDropDown
      options={controller.getBusinessFunctionDropdownOptions()}
      title="Business function"
      onChangeHandler={controller.onChangeBusinessFilter}
      selectedOptions={controller.filters.businessFunctions}
    />
    <FilterDropDown
      options={controller.getApplicationCategoryDropdownOptions()}
      title="Application category"
      onChangeHandler={controller.onChangeCategoriesFilter}
      selectedOptions={controller.filters.categories}
    />
    <FilterDropDown
      options={controller.getCountryDropdownOptions()}
      title="Country"
      onChangeHandler={controller.onChangeCountryFilter}
      selectedOptions={controller.filters.countries}
    />
  </div>
));

export default MyApplicationFilters;

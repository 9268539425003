import React, { FunctionComponent } from "react";
import PageTitle from "../page-title";
import "./index.scss";

type Props = {
  title: string;
  subtitle?: string;
  subText?: string | React.ReactNode;
  rightColumnElement?: React.ReactElement;
};
export const PageTitleWithRightElement: FunctionComponent<Props> = (props: Props) => (
  <div className="PageTitleWithRightElement">
    <PageTitle
      title={props.title}
      subtitle={props.subtitle}
      subText={props.subText}
    />
    <div className={"right-element"}>
      {props.rightColumnElement ? props.rightColumnElement : null}
    </div>
  </div>);

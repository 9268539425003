import React, { FunctionComponent } from "react";
import PageTitle from "../../../../common/components/page-title";
import TeamListTable from "../../../../common/components/team-list-table";
import useTeamInformationController from "./controller";
import Progress from "../../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../../common/components/error-new";
import ImpactXButton from "../../../../common/components/impactx-button";
import "./index.scss";
import StepBar from "../../../../common/components/step-bar";
import RegionalITTeamUnAuthorizedMessage from "../../../../common/components/regional-it-team-unauthorized-message";

export const SelectTeam: FunctionComponent = () => {
  const controller = useTeamInformationController();

  const renderButton = (label: string, isSecondaryButton = true) => (
    <ImpactXButton
      className={"previous-button"}
      label={label}
      outline={isSecondaryButton}
      onClickHandler={controller.onPrevious}
    />
  );

  const renderContent = () => {
    if (controller.shouldRenderProgressMessage()) {
      return (<Progress text={controller.teamListProgress}/>);
    }
    if (controller.shouldRenderErrorMessage()) {
      return (
        <Error
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          {...mapApplicationErrorInfoToErrorComponentProps(controller.teamListError!)}
        />);
    }
    return (
      renderDetails()
    );
  };

  const renderSelectTeamPage = () => (
    <>
      <StepBar
        stepLabels={["Select Team", "Application Information", "Infrastructure Configuration", "Review and Submit"]}
        currentCompletedStep={1}
      />
      <PageTitle
        title="Regional IT"
        displayTitleLighter={true}
        subtitle={"Select Team"}
      />
      <div className={"content"}>
        <TeamListTable
          teams={controller.getTeamList()}
          onRowClickedHandler={controller.rowClickHandler}
          hideStatus={true}
        />
      </div>
      {renderButton("PREVIOUS")}
    </>
  );

  const renderUserNotAssociatedToTeam = () => (
    <div className={"user-not-associated-to-team"}>
      <PageTitle
        title="Get Started"
      />
      <RegionalITTeamUnAuthorizedMessage
        errorMessage={"Sorry, you are not associated to any Regional IT team!"}
      />
      <ImpactXButton
        className={"previous-button"}
        label="Go Back"
        outline={false}
        onClickHandler={controller.onPrevious}
      />
    </div>
  );

  const renderDetails = () => {
    if (controller.shouldRenderUserNotAssociatedToTeamMessage()) {
      return renderUserNotAssociatedToTeam();
    } else {
      return renderSelectTeamPage();
    }
  };

  return (
    <div className="SelectTeam">
      {renderContent()}
    </div>
  );
};

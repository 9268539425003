import { useDispatch } from "react-redux";
import { useSelectorTyped } from "../../../../common/redux/store";
import { ApplicationDetailsPageActions, EditApplicationInfoForm, UpdateApplicationData } from "../redux";
import applicationCategoryList from "./data/application-category-list";
import techStackList from "../../../get-started/data/tech-stack";
import { convertCSVToList, convertListToCSV } from "../../../../utils/string";
import editApplicationInformationQuestions from "./data/questions";
import Analytics from "../../../../common/services/analytics";
import { Nullable } from "../../../../utils/nullable";
import moment from "moment";
import { iif } from "../../../../utils/functions";
import _ from "lodash";
import { getEnvVars } from "../../../../common/config/env";

export default function useEditApplicationInformationController() {
  const countryRegionalItLeadsPEV = useSelectorTyped((state) => state.countryRegionalItLeadsPEV);
  const businessFunctionLeadsPEV = useSelectorTyped((state) => state.businessFunctionLeadsPEV);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const application = useSelectorTyped((state) => state.applicationDetailsPage.applicationBasicInfoPEV.value!);
  const editApplicationDetailsPE = useSelectorTyped((state) => state.applicationDetailsPage.editApplicationDetailsPE);
  const applicationPurposes = useSelectorTyped((state) => state.applicationPurposesPEV.value);
  const dispatch = useDispatch();
  const isApplicationLifespanQuestionEditable = application.lifespan.canEditApplicationLifespan;

  function getFieldValueIfUpdated<T>(formDataValue: T, existingFieldValue: T) {
    return existingFieldValue === formDataValue
      ? undefined
      : formDataValue;
  }

  function getFieldValueOfArraysIfUpdated(formDataValue: string[], existingFieldValue: string[]) {
    return (existingFieldValue.length === formDataValue.length &&
      existingFieldValue.every((val, index) => val === formDataValue[index]))
      ? undefined
      : formDataValue;
  }

  const onSave = (formData: EditApplicationInfoForm) => {
    const selectedCountry = countryRegionalItLeadsPEV.value.find((data) => data.name === formData.countryName);
    const applicationInformationFromForm: UpdateApplicationData = {
      description: getFieldValueIfUpdated(formData.description, application.description),
      country: getFieldValueIfUpdated(selectedCountry?.businessUnit, application.country),
      targetAudience: getFieldValueIfUpdated(formData.targetAudience, application.targetAudience),
      businessFunction: getFieldValueIfUpdated(formData.businessFunction ?? null, application.businessFunction),
      category: getFieldValueOfArraysIfUpdated(getArrayIfOthersSelected(formData.category, formData["category-Comment"]), application.category),
      owner: getFieldValueOfArraysIfUpdated(convertCSVToList(formData.owner as unknown as string), application.owner),
      techStack: getFieldValueOfArraysIfUpdated(getArrayIfOthersSelected(formData.techStack, formData["techStack-Comment"]), application.techStack),
      purpose: getFieldValueIfUpdated(formData.purpose, application.purpose),
      endDate: isApplicationLifespanQuestionEditable && formData.endDate
        ? getFieldValueIfUpdated(moment(formData.endDate).format("YYYY-MM-DD"), application.endDate)
        : undefined,
    };
    if (application.isDataClassificationEditable) {
      applicationInformationFromForm.hasMnpi = getFieldValueIfUpdated(formData.hasMnpi, application.hasMnpi);
      applicationInformationFromForm.hasPii = getFieldValueIfUpdated(formData.hasPii, application.hasPii);
      applicationInformationFromForm.hasSensitivePii = getFieldValueIfUpdated(formData.hasSensitivePii, application.hasSensitivePii);
    }

    const updatedApplicationInformation = _.omitBy(applicationInformationFromForm, _.isUndefined);
    dispatch(ApplicationDetailsPageActions.updateApplicationInformation({
      updatedApplicationInformation,
      applicationId: application.id,
      existingApplicationData: application,
    }));
    Analytics.trackEventEditApplicationBasicDetails();
  };

  const getCommentValuesForOtherOption = (selectedList: string[], availableList: string[]) => {
    let comment = "";
    const otherList = selectedList.filter((cat) => !availableList.includes(cat));
    selectedList = selectedList.filter((cat) => availableList.includes(cat));
    if (otherList.length > 0) {
      selectedList.push("other");
      comment = otherList.join(",");
    }
    return { selectedList, comment };
  };


  const getCountryHelpText = () => (
    isTechOpsApplication()
      ? "The country is where the application will be used"
      : "The country is where the application will be used and which will fund the costs of it"
  );

  const isTechOpsApplication = () => application.team.teamType === "TECHOPS";

  const getArrayIfOthersSelected = (selectedArray: Nullable<string[]>, comment: string): string[] => {
    if (selectedArray) {
      const indexOfOtherValue = selectedArray.findIndex((it) => it === "other" || it === "Others");
      if (indexOfOtherValue > -1) {
        selectedArray.splice(indexOfOtherValue, 1);
        return [...selectedArray, ...comment.trim().split(",")];
      }
      return selectedArray;
    }
    return [];
  };

  const getEditApplicationInfo = () => {
    const {
      selectedList: category,
      comment: categoryComment,
    } = getCommentValuesForOtherOption(application.category, applicationCategoryList);
    const {
      selectedList: techStack,
      comment: techStackComment,
    } = getCommentValuesForOtherOption(application.techStack, techStackList);
    return {
      ...application,
      category,
      techStack,
      purpose: application?.purpose,
      "category-Comment": categoryComment,
      "techStack-Comment": techStackComment,
      owner: convertListToCSV(application.owner),
      endDate: application.endDate
        ? iif(function setApplicationEndDateToMinimumAllowedValueIfItIsBelowTheMinimumDate() {
          const minDate = moment().add(7, "days");
          return minDate.diff(moment(application.endDate), "days") > 0
            ? minDate.format("MMM DD, YYYY")
            : moment(application.endDate).format("MMM DD, YYYY");
        })
        : undefined,
      project: application.project
        ? `${application.project.name} - ${application.project.accountProjectCode}`
        : undefined,
    };
  };

  function onCancel() {
    dispatch(ApplicationDetailsPageActions.updateApplicationDetailsEditState(false));
  }

  function getSaveButtonLabel() {
    return !editApplicationDetailsPE.progress ? "Save" : "Saving";
  }

  function isSaveButtonDisabled() {
    return !!editApplicationDetailsPE.progress;
  }

  function isCancelButtonDisabled() {
    return !!editApplicationDetailsPE.progress;
  }

  return {
    questions: editApplicationInformationQuestions({
      countryNames: countryRegionalItLeadsPEV.value.map(({ name }) => name),
      businessFunctions: businessFunctionLeadsPEV.value.map(({ businessFunction }) => businessFunction),
      countryHelpText: getCountryHelpText(),
      applicationPurposes,
      isDataClassificationEditable: application.isDataClassificationEditable,
      shouldRenderLifespan: application.lifespan.canShowApplicationLifespanInEditPage,
      isApplicationLifespanQuestionEditable,
      neoSupportEmail: getEnvVars().feedbackEmailId,
    }),
    onSave,
    getEditApplicationInfo,
    onCancel,
    getSaveButtonLabel,
    isSaveButtonDisabled,
    isCancelButtonDisabled,
  };
}

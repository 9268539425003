import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { teamTypeLabelMap } from "../../data/team-types";
import React from "react";
import "./index.scss";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import useApplicationCardController from "./controller";


const ApplicationCard = createFunctionalComponentFromView(useApplicationCardController, (controller) => {
  const renderApplicationInfo = () => (
    <Card.Body>
      <div className="team-type">{teamTypeLabelMap[controller.application.team.teamType]}</div>
      <h6
        className="card-title"
        title={controller.application.name}
      >
        {controller.application.name}
      </h6>
      <div className="description">{controller.application.description}</div>
    </Card.Body>
  );

  const renderApplicationStatus = () => (
    <div className="application-status">{controller.application.status}</div>
  );

  return (
    <Link
      to={{
        pathname: controller.redirectionPathname,
        state: { from: controller.currentPathname },
      }}
      className={"ApplicationCard"}
      onClick={controller.onClick}
    >
      <Card>
        {renderApplicationInfo()}
        {renderApplicationStatus()}
      </Card>
    </Link>
  );
});

export default ApplicationCard;

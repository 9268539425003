import { useSelectorTyped } from "../../redux/store";
import Analytics from "../../services/analytics";
import { CataloguePages } from "../../../pages/catalogue/data/pages";
import { TEAMS_TAB_EVENT_KEY, TeamsPageActions } from "../../../pages/teams/redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getEnvVars } from "../../config/env";
import { useGetFeatureToggleState } from "../../hooks/feature-toggles";


export default function useHeaderController() {
  const dispatch = useDispatch();
  const loggedInUserNamePEV = useSelectorTyped((state) => state.header.userInfo.name);
  const { unificationConfig, env } = getEnvVars();
  const helpDocumentLink = useSelectorTyped((state) => state.header.uiConfigsPEV.value.externalLinks.helpDocumentLink);
  const isApiUnificationToggleEnabled = useGetFeatureToggleState("Neo_ApiPlatform_Unification");
  const location = useLocation();
  const getManageMenuItems = () => {
    let manageMenuItems = [
      {
        label: CataloguePages.MY_APPS_AND_SERVICES.title,
        url: CataloguePages.MY_APPS_AND_SERVICES.path,
      },
      {
        label: "My Teams",
        url: "/teams",
      },
      {
        label: "My Requests",
        url: "/requests/my-requests",
      },
      {
        label: "My Tasks",
        url: "/requests/my-tasks",
      },
    ];
    if(isApiUnificationToggleEnabled && unificationConfig.environmentSpecificConfig.isSandbox){
      manageMenuItems  = [
        {
          label: "My Teams",
          url: "/teams",
        },
        {
          label: "My Requests",
          url: "/requests/my-requests",
        },
        {
          label: "My Tasks",
          url: "/requests/my-tasks",
        },
      ];
    }

    return manageMenuItems.map((menuItem) => ({
      ...menuItem,
      onClick: () => {
        if (menuItem.label === "My Teams") {
          dispatch(TeamsPageActions.setActiveTab(TEAMS_TAB_EVENT_KEY.MY_TEAMS));
        }
        Analytics.trackEventClickOnManageMenuItems(menuItem.label);
      },
    }));
  };

  const loggedInUserName = loggedInUserNamePEV.value;

  const getUserNameInitials = () => {
    const getFirstTwoInitialsOfName = (initials: string[]) => initials.slice(0, 2).join("").toUpperCase();
    const getFirstLettersOfEachNames = (names: string[]) => names.map((n) => n[0]);
    const splitUserNameBySpace = (userName: string) => userName.split(" ");
    return getFirstTwoInitialsOfName(getFirstLettersOfEachNames(splitUserNameBySpace(loggedInUserName)));
  };

  const onToggleClick = () => {
    Analytics.trackEventClickOnToggleButton();
    window.open(unificationConfig.toggleUrl, "_self");
  };

  console.log("Location", location.pathname);

  return {
    isApiUnificationToggleEnabled,
    helpDocumentLink,
    message: `You are in the ${env} environment`,
    getManageMenuItems,
    getUserNameInitials,
    currentPath: location.pathname,
    onToggleClick,
    unificationConfig,
    environmentConfig: { ...unificationConfig.environmentSpecificConfig },
  };
}

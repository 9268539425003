import { combineReducers } from "redux";
import { eventsDiscoveryPageReducer } from "./events-discovery/redux";
import { eventDetailsPageReducer } from "./event-details/redux";
import {eventsOverviewPageReducer} from "./events-overview/redux";

export const eventsReducer = combineReducers({
  eventsDiscoveryPage: eventsDiscoveryPageReducer,
  eventDetailsPage: eventDetailsPageReducer,
  eventsOverviewPage: eventsOverviewPageReducer,
});

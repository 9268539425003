import React, { FunctionComponent, ReactElement } from "react";
import "./index.scss";

type Props = {
  titleText?: string;
  titleInfo?: ReactElement;
  content: ReactElement;
};

const Panel: FunctionComponent<Props> = (props: Props) => (
  <div className="Panel">
    { (props.titleText || props.titleInfo)?
      (
        <div className="panel-title">
          { props.titleText?
            (<h5>{props.titleText}</h5>):""}
          <div className="title-info">{props.titleInfo}</div>
        </div>
      ):"" }
    {props.content}
  </div>
);

export default Panel;

import React, { FunctionComponent, ReactNode } from "react";
import ToolTipWrapped from "../../../common/components/tooltip";

interface Props {
  headerColumnText: string;
  toolTipContent: ReactNode;
}

const HeaderColumnElement: FunctionComponent<Props> = ({ headerColumnText, toolTipContent }: Props) => (
  <th
    rowSpan={2}
    className="header-column"
  >
    <div className="element-container">
      <div>{headerColumnText}</div>
      <div>
        <ToolTipWrapped
          id="infra-offerings-tooltip"
          content={toolTipContent}
          placement="right"
        >
          <i className="ri-question-line tooltip-icon"/>
        </ToolTipWrapped>
      </div>
    </div>
  </th>
);

export default HeaderColumnElement;

import React, { FunctionComponent, PropsWithChildren } from "react";
import { Route, Switch } from "react-router-dom";
import { getEnvVars } from "../../config/env";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../error-new";
import useAuthenticationController from "./controller";
import { oktaAuthClient } from "./data";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";

export const Authentication: FunctionComponent = ({ children }: PropsWithChildren<unknown>) => {
  const controller = useAuthenticationController(oktaAuthClient);

  if (getEnvVars().disableOktaAuth) {
    return (
      <>
        {children}
      </>
    );
  }
  return controller.isSessionTimedOut
    ? (
      <Error
        {...mapApplicationErrorInfoToErrorComponentProps(controller.getSessionTimeOutErrorInfo())}
        onClick={controller.onClickLoginButton}
        renderAsFullPage={true}
      />
    )
    : (
      <Security
        oktaAuth={oktaAuthClient}
        restoreOriginalUri={controller.restoreOriginalUri}
      >
        <Switch>
          <Route
            path={controller.callbackPath}
            component={LoginCallback}
          />
          <SecureRoute path="/">
            {children}
          </SecureRoute>
        </Switch>
      </Security>
    );
};

import { useSelectorTyped } from "../../../common/redux/store";
import { ACTIVITY_HISTORY_TIME_FORMAT, formatDate } from "../../../utils/date-format";
import { useGetFeatureToggleState } from "../../../common/hooks/feature-toggles";

export default function useTeamActivitiesController() {
  const activitiesPEV = useSelectorTyped((state) => state.teamDetailsPage.activitiesPEV);
  const activities = [...activitiesPEV.value].sort((row1, row2) =>
    new Date(row2.occurredAt).getTime() - new Date(row1.occurredAt).getTime(),
  );
  const teamDetailsPEV = useSelectorTyped((state) => state.teamDetailsPage.teamDetailsPEV);

  const getActivities = () => activitiesPEV.value;

  const shouldRenderProgressMessage = () => activitiesPEV.progress !== null;

  const shouldRenderErrorMessage = () => !!activitiesPEV.error;

  const isApplicationListEmpty = () => activitiesPEV.value?.length === 0;

  const projectAssociationToggleEnabled = useGetFeatureToggleState("Neo_Teams_AssociateStaffingProjects");


  const shouldRenderNoActivitiesMessage = () => isApplicationListEmpty();

  const getExportCSVData = () => {
    const escapeDoubleQuote = (textWithDoubleQuote: string) =>
      textWithDoubleQuote.replace(/"/g, "\"\"");

    return activities.map((activityHistory) => ({
      Date: formatDate(activityHistory.occurredAt, ACTIVITY_HISTORY_TIME_FORMAT),
      Description: escapeDoubleQuote(activityHistory.action),
      User: activityHistory.performedBy ? escapeDoubleQuote(activityHistory.performedBy) : "",
    }));
  };

  const getPaginationRowsPerPageOptions = () => {
    const rowsPerPage: number[] = [];
    const maxRow = getActivities().length;
    if (maxRow > 10) {
      rowsPerPage.push(10);
    }
    let multiple = 25;
    while (multiple < maxRow) {
      rowsPerPage.push(multiple);
      multiple *= multiple === 100 ? 5 : 2;
    }
    rowsPerPage.push(maxRow);
    return rowsPerPage;
  };

  return ({
    getActivities,
    shouldRenderProgressMessage,
    shouldRenderErrorMessage,
    shouldRenderNoActivitiesMessage,
    projectAssociationToggleEnabled,
    progressMessage: activitiesPEV.progress,
    errorMessage: activitiesPEV.error,
    getCSVFileName: () => `Activity_History_${teamDetailsPEV.value ? teamDetailsPEV.value.name : ""}`,
    getExportCSVData,
    getPaginationRowsPerPageOptions,
    shouldRenderExportCSVLink: () => !isApplicationListEmpty(),
  });

}

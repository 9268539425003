import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EventDetailsPageService } from "./service";
import { PEV, ProgressErrorValue } from "../../../utils/progress-error-value";
import { Nullable } from "../../../utils/nullable";
import { FETCH_EVENT_DETAILS_ERROR_TEXT, FETCHING_EVENT_DETAILS_TEXT } from "./data/constants";
import { Metadata } from "showdown";

interface EventDetailsRequestParams {
  namespaceName: string;
  eventName: string;
}

const fetchEventDetails = createAsyncThunk("fetch-event-details", async (eventDetailsParams: EventDetailsRequestParams) =>
  EventDetailsPageService.fetchEventDetails(eventDetailsParams.namespaceName, eventDetailsParams.eventName),
);

export interface IEventDetails {
  name: string;
  description: string;
  schema: Record<string, unknown>;
  namespaceName: string;
  namespaceDescription: string;
  teamEmail: string;
  team: TeamDetails;
  tags: string[];
  category: string;
}

interface TeamDetails {
  id: string;
  name: string;
}

interface IEventsDetailsPageState {
  eventDetailsPEV: ProgressErrorValue<Nullable<IEventDetails>>;
}

const initialState: IEventsDetailsPageState = {
  eventDetailsPEV: PEV(null),
};

const eventDetailsPageSlice = createSlice({
  name: "eventDetailsPage",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchEventDetails.fulfilled.type]: (state: IEventsDetailsPageState, action: PayloadAction<IEventDetails>) => {
      state.eventDetailsPEV = PEV(action.payload, null, null);
    },
    [fetchEventDetails.pending.type]: (state: IEventsDetailsPageState) => {
      state.eventDetailsPEV = PEV(null, FETCHING_EVENT_DETAILS_TEXT, null);
    },
    [fetchEventDetails.rejected.type]: (state: IEventsDetailsPageState, action: PayloadAction<never, never, Metadata, Error>) => {
      if (!action.error.message || action.error.message === "Unknown Error") {
        state.eventDetailsPEV = PEV(null, null, FETCH_EVENT_DETAILS_ERROR_TEXT);
      } else {
        state.eventDetailsPEV = PEV(null, null, action.error.message);
      }
    },
  },
});

export const eventDetailsPageReducer = eventDetailsPageSlice.reducer;

export const eventDetailsPageActions = {
  fetchEventDetails,
};

import React from "react";
import useDeveloperToolInformationController, { Props } from "./controller";
import "./index.scss";
import { createFunctionalComponentFromView } from "../../../../../utils/view-controller";
import castTo from "../../../../../utils/cast-to";
import { DeveloperTool } from "../../redux";
import Details from "../../../../applications/application-details/details";
import DetailsList from "../../../../applications/application-details/details-list";
import Markdown from "../../../../../common/components/markdown";


const DeveloperToolInformation = createFunctionalComponentFromView<ReturnType<typeof useDeveloperToolInformationController>, Props>(
  useDeveloperToolInformationController,
  (controller) => {

    function renderReadMe() {
      return (
        <div className="Details">
          <div className="info-title">README.md</div>
          <div className="markdown">
            <Markdown content={controller.developerTool.readmeFileContent}/>
          </div>
        </div>
      );
    }

    function renderFields(fields: Array<keyof DeveloperTool>) {
      return (
        <>
          {
            fields.map((field) => {
              if (controller.renderAsList(field)) {
                return (
                  <DetailsList
                    key={field}
                    fieldName={controller.headingsMap[field]}
                    detailsList={castTo<string[]>(controller.getFieldValue(field))}
                  />
                );
              } else {
                return (
                  <Details
                    key={field}
                    fieldName={controller.headingsMap[field]}
                    value={castTo<string>(controller.getFieldValue(field))}
                  />
                );
              }
            })
          }
          {/* TODO why renderReadMe() is inside renderFields? */}
          {renderReadMe()}
        </>
      );
    }

    return (
      <div className="DeveloperToolInformation">
        {renderFields(controller.getDeveloperToolFields())}
      </div>
    );
  },
);

export default DeveloperToolInformation;

import { ITeam } from "../../../team-details/redux";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { GetStartedPageActions } from "../../redux";
import { TeamCreationPageActions, TeamType } from "../../../create-team/redux";
import { experimentsAndInnovationsCategoryActions } from "../redux";
import Analytics from "../../../../common/services/analytics";
import { dispatchFetchAllPotentialMembersIfEmpty } from "../../../teams/redux";
import { useSelectorTyped } from "../../../../common/redux/store";

export default function useTeamInformationController() {
  const dispatch = useDispatch();
  const selectedProjectClassification = useSelectorTyped((state) => state.getStartedPage.selectedProjectClassification);
  const teamListPEV = useSelectorTyped((state) => state.getStartedPage.teamListForSelectedCategoryPEV);
  const potentialMembersPEV = useSelectorTyped((state) => state.teamsPage.potentialMembersPEV);
  const createTeamPEV = useSelectorTyped((state) => state.teamCreationPage.createTeamPEV);
  const [createTeamModalShow, setCreateTeamModalShow] = useState(false);
  const { value: teamList, error: teamListError, progress: teamListProgress } = teamListPEV;

  useEffect(() => {
    dispatch(GetStartedPageActions.fetchMyActiveTeamsWithCategory(selectedProjectClassification));
    dispatchFetchAllPotentialMembersIfEmpty(dispatch, potentialMembersPEV);
  }, []);

  useEffect(
    function resetReduxStateAndNavigateToApplicationBasicInfoPageWhenTeamIsCreated() {
      if (createTeamPEV.value) {
        dispatch(GetStartedPageActions.updateCurrentStep("BASIC_INFO"));
        dispatch(experimentsAndInnovationsCategoryActions.updateBasicInfo({
          teamId: createTeamPEV.value.id,
          teamName: createTeamPEV.value.name,
        }));
        dispatch(TeamCreationPageActions.resetState());
      }
    },
    [createTeamPEV.value],
  );

  const rowClickHandler = (row: ITeam) => {
    dispatch(GetStartedPageActions.updateCurrentStep("BASIC_INFO"));
    dispatch(experimentsAndInnovationsCategoryActions.updateBasicInfo({
      teamId: row.id,
      teamName: row.name,
    }));
  };

  const shouldRenderNoTeamsMessage = () => teamList.length === 0;

  const shouldRenderProgressMessage = () => teamListProgress !== null && teamList.length === 0;

  const shouldRenderErrorMessage = () => !!teamListError;

  const setTeamProjectClassificationAndShowCreateTeamModal = () => {
    dispatch(TeamCreationPageActions.updateTeamSelection(TeamType.EXPERIMENT_AND_INNOVATION));
    setCreateTeamModalShow(true);
  };

  const onPrevious = () => {
    dispatch(GetStartedPageActions.updateCurrentStep("PROJECT_CLASSIFICATION"));
    dispatch(TeamCreationPageActions.resetState());
  };

  const onCancel = () => {
    setCreateTeamModalShow(false);
  };

  const onClickCreateButton = () => {
    Analytics.trackEventClickOnCreateTeamButton("From Get Started flow");
    setCreateTeamModalShow(true);
  };

  const getTeamList = () => teamList ?? [];
  return {
    getTeamList,
    rowClickHandler,
    shouldRenderNoTeamsMessage,
    teamListError,
    teamListProgress,
    shouldRenderProgressMessage,
    shouldRenderErrorMessage,
    createTeamModalShow,
    setCreateTeamModalShow,
    onClickCreateButton,
    setTeamProjectClassificationAndShowCreateTeamModal,
    onPrevious,
    onCancel,
  };
}

import { TeamType } from "../../create-team/redux";
import Progress from "../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../common/components/error-new";
import PageTitle from "../../../common/components/page-title";
import React from "react";
import ImpactXButton from "../../../common/components/impactx-button";
import { Nullable } from "../../../utils/nullable";
import "./index.scss";
import { INeoErrorInfo } from "../../../utils/errors/neo-errors";
import NeoLink from "../../../common/components/neo-link";

type Props = {
  showProgress: boolean;
  showError: boolean;
  progressMsg: Nullable<string>;
  errorMsg: Nullable<INeoErrorInfo>;
  teamType: TeamType.REGIONAL_IT | TeamType.TECHOPS;
  onClickGoBackBtn: () => void;
};

const UserNotAssociatedToTeam = (props: Props) => {
  const neoSupportMail = "engineering-platform-support@thoughtworks.com";
  const getTeamTypeLabel = (teamType: TeamType) => teamType === TeamType.REGIONAL_IT ? "Regional IT" : "TechOps";

  const renderNoTeamsMessage = () => {
    if (props.showProgress) {
      return (<Progress text={props.progressMsg}/>);
    }
    if (props.showError) {
      return (
        <Error
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          {...mapApplicationErrorInfoToErrorComponentProps(props.errorMsg!)}
        />);
    }
    return (
      <div className={"UserNotAssociatedToTeam"}>
        <PageTitle
          title="Get Started"
        />
        <div className={"not-associated-to-team"}>
          <div className={"message"}>
            <div>Sorry, you are not associated to any {getTeamTypeLabel(props.teamType)} team!</div>
            <div>Please send your request to&nbsp;
              <NeoLink
                link={`mailto:${neoSupportMail}`}
                className={"contact-email"}
                external
              >
                {neoSupportMail}
              </NeoLink>
              &nbsp;if you need to create a new {getTeamTypeLabel(props.teamType)} team.
            </div>
          </div>
          <ImpactXButton
            className={"previous-button"}
            label="Go Back"
            outline={false}
            onClickHandler={props.onClickGoBackBtn}
          />
        </div>
      </div>
    );
  };

  return renderNoTeamsMessage();

};
export default UserNotAssociatedToTeam;

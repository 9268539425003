import { ActionCreatorWithPayload, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "../../../utils/nullable";

export interface IBasicInfo {
  projectName: string;
  projectDescription: string;
  purpose: string;
  country: string;
  countryCode: string;
  targetAudience: string;
  businessFunction: string;
  projectCategory: string[];
  "projectCategory-Comment": string;
  projectOwner: string[];
  techStack: string[];
  "techStack-Comment": string;
  teamId: string;
  teamName: string;
  hasMnpi: Nullable<boolean>;
  hasPii: Nullable<boolean>;
  hasSensitivePii: Nullable<boolean>;
  billingProjectId: Nullable<string>;
  billingProjectName: Nullable<string>;
}

export interface IInfraConfig {
  isCodeRepositoryNeeded: string;
  githubUsername: string;
  githubOrganisation: string;
  isCiCdPipelineNeeded: string;
  isHostingServiceNeeded: string;
  gcpProjectName: string;
  gcpProjects: Array<{ name: string }>;
  comment: string;
}

export type ITechOpsFormData = {
  basicInfo: IBasicInfo;
  infraConfig: IInfraConfig;
};

export const techOpsInitialState: ITechOpsFormData = {
  basicInfo: {
    projectName: "",
    projectDescription: "",
    country: "",
    countryCode: "",
    targetAudience: "",
    businessFunction: "",
    projectCategory: [],
    "projectCategory-Comment": "",
    projectOwner: [],
    techStack: [],
    "techStack-Comment": "",
    teamId: "",
    teamName: "",
    purpose: "",
    hasMnpi: null,
    hasPii: null,
    hasSensitivePii: null,
    billingProjectName: null,
    billingProjectId: null,
  },
  infraConfig: {
    isCodeRepositoryNeeded: "",
    githubUsername: "",
    githubOrganisation: "",
    isCiCdPipelineNeeded: "",
    isHostingServiceNeeded: "",
    gcpProjectName: "",
    gcpProjects: [],
    comment: "",
  },
};


const techOpsSlice = createSlice({
  name: "getStartedPage/techOps",
  initialState: techOpsInitialState,
  reducers: {
    updateInfraConfig: (state: ITechOpsFormData, action: PayloadAction<Partial<IInfraConfig>>) => {
      state.infraConfig = {
        ...state.infraConfig,
        ...action.payload,
      };
    },
    updateBasicInfo: (state: ITechOpsFormData, action: PayloadAction<Partial<IBasicInfo>>) => {
      state.basicInfo = {
        ...state.basicInfo,
        ...action.payload,
      };
    },
  },
});

export const techOpsReducer = techOpsSlice.reducer;

const updateInfraConfig: ActionCreatorWithPayload<Partial<IInfraConfig>> =
  techOpsSlice.actions.updateInfraConfig;

const updateBasicInfo: ActionCreatorWithPayload<Partial<IBasicInfo>> =
  techOpsSlice.actions.updateBasicInfo;

export const TechOpsCategoryActions = {
  updateInfraConfig,
  updateBasicInfo,
};

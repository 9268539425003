import { callBffApi } from "../../../common/services/axios";
import {
  ApplicationBasicInfo,
  ApplicationInformation,
  IApplicationEvent,
  IDecommissionApplicationResponse,
  InfrastructureDetails,
  IRequestedAdditionalSupport,
  IRequestZendeskResourceResponse, OidcOktaChicletRequest,
  OktaChicletRequest,
  OktaChicletType,
  RequestAdditionalInfrastructureData,
  RequestDecommissionApplicationData,
  RequestZendeskResourceData,
  SamlOktaChicletRequest, SpaOktaChicletRequest,
  UpdateApplicationData,
} from "./redux";
import { toast } from "react-toastify";
import { iif } from "../../../utils/functions";
import { NeoError } from "../../../utils/errors/neo-errors";
import castTo, { identity } from "../../../utils/cast-to";

export const fetchApplication = async (applicationId: string) =>
  callBffApi<ApplicationBasicInfo>("get", `/applications/${applicationId}/basic-info`);

export const fetchInfrastructure = async (applicationId: string) =>
  callBffApi<InfrastructureDetails>("get", `/applications/${applicationId}/infra-info`);

export const fetchActivitiesOfApplication = async (applicationId: string) => {
  const response = await callBffApi<{ data: IApplicationEvent[] }>("get", `/applications/${applicationId}/events`);
  return response.data;
};

export const fetchRequestedAdditionalSupportForApplication = async (applicationId: string, requestTypes?: string[]) =>
  callBffApi<IRequestedAdditionalSupport>(
    "get",
    `/applications/${applicationId}/additional-support`,
    undefined,
    {
      requestTypes,
    },
  );


export const updateApplicationInformation = async (
  applicationInformation: UpdateApplicationData,
  applicationId: string,
) => {
  try {
    const result = await callBffApi<ApplicationInformation>("patch", `/applications/${applicationId}`, applicationInformation);
    toast.success("Application details updated successfully");
    return result;
  } catch (error) {
    toast.error(iif(function getErrorMessageForToast() {
      return ((error as NeoError).errorType === "UNPROCESSABLE_ENTITY"
        ? (error as NeoError).message
        : "Error occurred in updating the details! Please try again.");
    }));
    throw error;
  }
};

export const triggerGCPProjectCreation = async (applicationId: string) => {
  try {
    const result = await callBffApi(
      "post",
      `/applications/${applicationId}/infrastructure/gcp-project`,
    );
    toast.success("GCP project creation triggered successfully");
    return result;
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    toast.error(`Triggering GCP project creation failed due to ${error}`);
    throw error;
  }
};

export const triggerGithubRepositoryCreation = async (applicationId: string) => {
  try {
    const result = await callBffApi(
      "post",
      `/applications/${applicationId}/infrastructure/github-repository`,
    );
    toast.success("Github repository creation triggered successfully");
    return result;
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    toast.error(`Triggering github repository creation failed due to ${error}`);
    throw error;
  }
};

export const requestAdditionalInfra = async (applicationId: string, data: RequestAdditionalInfrastructureData) => {
  try {
    const result = await callBffApi(
      "patch",
      `/applications/${applicationId}/infrastructure`,
      identity<RequestAdditionalInfrastructureData>({
        ...data,
        oktaChiclet: iif(function formatOktaChicletRequestData() {
          const map: Record<OktaChicletType, (oktaChiclet: OktaChicletRequest) => OktaChicletRequest> = {
            [OktaChicletType.SAML]: (oktaChiclet: OktaChicletRequest) => {
              const { chicletName, chicletType, ssoUrl, audienceUrl } = castTo<SamlOktaChicletRequest>(oktaChiclet);
              return identity<SamlOktaChicletRequest>({ chicletName, chicletType, audienceUrl, ssoUrl });
            },
            [OktaChicletType.OIDC]: (oktaChiclet: OktaChicletRequest) => {
              const { chicletName, chicletType, signInRedirectUrl, signOutRedirectUrl, initiateLoginUrl } =
                castTo<OidcOktaChicletRequest>(oktaChiclet);
              return identity<OidcOktaChicletRequest>({ chicletName, chicletType, signInRedirectUrl, signOutRedirectUrl, initiateLoginUrl });
            },
            [OktaChicletType.SPA]: (oktaChiclet: OktaChicletRequest) => {
              const { chicletName, chicletType, signInRedirectUrl, signOutRedirectUrl, initiateLoginUrl } =
                castTo<SpaOktaChicletRequest>(oktaChiclet);
              return identity<SpaOktaChicletRequest>({ chicletName, chicletType, signInRedirectUrl, signOutRedirectUrl, initiateLoginUrl });
            },
          };
          return data.oktaChiclet && map[data.oktaChiclet.chicletType](data.oktaChiclet);
        }),
      }),
    );
    toast.success("Infrastructure requested successfully");
    return result;
  } catch (error) {
    toast.error("Something went wrong. Please contact the NEO support team if it persists.");
    throw error;
  }
};

export const requestDecommissionApplication = async (applicationId: string, data: RequestDecommissionApplicationData) => {
  try {
    const result = await callBffApi<IDecommissionApplicationResponse>(
      "post",
      `/applications/${applicationId}/decommission`,
      data,
    );
    toast.success("Decommission request raised successfully");
    return result;
  } catch (error) {
    toast.error("Something went wrong. Please contact the NEO support team if it persists.");
    throw error;
  }
};

export const requestZendeskResource = async (applicationId: string, data: RequestZendeskResourceData) => {
  try {
    const result = await callBffApi<IRequestZendeskResourceResponse>(
      "post",
      `/applications/${applicationId}/request-zendesk-resource`,
      data,
    );
    toast.success("Request for zendesk resource raised successfully");
    return result;
  } catch (error) {
    toast.error("Something went wrong. Please contact the NEO support team if it persists.");
    throw error;
  }
};

import React from "react";
import Progress from "../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../common/components/error-new";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import useMyTeamsController, { Props } from "./controller";
import NoTeamsMessage from "./no-teams-message";
import "./index.scss";
import TeamListTable from "../../../common/components/team-list-table";
import FilterDropDown from "../../../common/components/filter-dropdown";
import SearchBar from "../../../common/components/search-bar";
import ExportTeams from "../export-teams";
import Analytics from "../../../common/services/analytics";
import TextButton from "../../../common/components/text-button";


const MyTeams = createFunctionalComponentFromView<ReturnType<typeof useMyTeamsController>, Props>(
  useMyTeamsController,
  (controller) => {
    const renderContent = () => {
      if (controller.shouldRenderProgressMessage()) {
        return (<Progress text={controller.getProgressMsg()}/>);
      } else if (controller.shouldRenderErrorMessage()) {
        return (
          <Error
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            {...mapApplicationErrorInfoToErrorComponentProps(controller.getErrorMsg()!)}
            onClick={controller.onClickRetryFetchMyTeams}
          />
        );
      } else if (controller.shouldRenderNoAssociatedTeamsMessage()) {
        return (
          <NoTeamsMessage helpDocumentLink={controller.helpDocumentLink}/>
        );
      } else {
        return (
          <TeamListTable
            teams={controller.getTeamList()}
            onRowClickedHandler={controller.onTableRowClickedHandler}
          />
        );
      }
    };

    const renderFilterAndSearch = () => {
      if (controller.shouldRenderFiltersAndCount()) {
        return (
          <div className="filter-and-search">
            <FilterDropDown
              className="my-team-filter"
              options={controller.getTeamTypeDropdownOptions()}
              title="Type"
              onChangeHandler={controller.onChangeTeamTypeFilter}
              selectedOptions={controller.getSelectedTeamType()}
              width={440}
            />
            <FilterDropDown
              className="my-team-filter"
              options={controller.getInternalOpenSourceDropdownOptions()}
              title="Internal open source"
              onChangeHandler={controller.onChangeInternalOpenSourceFilter}
              selectedOptions={controller.getInternalOpenSourceSelectedValue()}
            />
            <FilterDropDown
              className="my-team-filter"
              options={controller.getTeamStatusDropdownOptions()}
              title="Status"
              onChangeHandler={controller.onChangeTeamStatusFilter}
              selectedOptions={controller.getTeamStatusSelectedValue()}
            />
            <TextButton
              label="Reset"
              className="reset-my-team-filter"
              onClick={controller.onClickResetButton}
            />
            <SearchBar
              label="My teams"
              className="my-team-search"
              searchText={controller.searchText}
              onChangeSearchText={controller.onChangeSearchText}
              onClickClearSearchText={controller.onClickClearSearchText}
            />
          </div>
        );
      }
    };

    const renderMyTeamsCount = () => {
      if (controller.shouldRenderFiltersAndCount()) {
        return (
          <div className="teams-count">
            {controller.getTeamsCountMessage()}
          </div>
        );
      }
      return null;
    };

    const renderExportMyTeamsLink = () => {
      if (controller.shouldRenderExportMyTeamsLink()) {
        return (
          <ExportTeams
            title={"My_Teams"}
            teams={controller.getTeamList()}
            trackAnalytics={() => Analytics.trackEventExportMyTeamsToCSVFromMyTeamsPage()}
          />
        );
      }
      return null;
    };

    return (
      <div className={"MyTeams"}>
        {renderFilterAndSearch()}
        <div className="teams-count-and-export">
          {renderMyTeamsCount()}
          {renderExportMyTeamsLink()}
        </div>
        {renderContent()}
      </div>
    );
  },
);

export default MyTeams;

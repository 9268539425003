import React, { FunctionComponent } from "react";
import useNavigationButtonsController from "./controller";
import { NavigationButtons as FormNavigationButtons } from "../../../common/components/forms/navigation-buttons";
import { INavigationButtonsProps } from "../../../common/components/forms";

export interface IProps extends INavigationButtonsProps {
  /**
   * User clicked on the Next/Submit button.
   * Check if all data is correct and return `true` to automatically go to next step.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  shouldGoToNextStep: (formData: any) => boolean;
  /**
   * User clicked on the Previous button.
   * Save all the user entered data into redux store and return `true` to automatically go to previous step.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  shouldGoToPreviousStep: (formData: any) => boolean;
}

const NavigationButtons: FunctionComponent<IProps> = (props) => {
  const controller = useNavigationButtonsController(props);
  return (
    <FormNavigationButtons
      onClickNext={controller.onClickNext}
      onClickPrevious={controller.onClickPrevious}
      doesFormHasErrors={controller.doesFormHasErrors}
      getFormData={controller.getFormData}
    />
  );
};

export default NavigationButtons;

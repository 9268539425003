import React, {FunctionComponent} from "react";
import {Route, Switch} from "react-router-dom";
import {EventsPages} from "./data/pages";
import EventsSubHeader from "./events-subheader";
import ApplicationError from "./common/component/application-error";
import FOUR_ZERO_FOUR_IMAGE from "./event-details/assets/404.png";
import {NOT_FOUND_ERROR_CODE} from "./data/constants";

const Events: FunctionComponent = () =>
  <>
    <EventsSubHeader/>
    <Switch>
      <Route
        exact
        path={EventsPages.EVENTS_USER_GUIDE.path}
        component={EventsPages.EVENTS_USER_GUIDE.component}
      />
      <Route
        exact
        path={EventsPages.EVENT_DETAILS.path}
        component={EventsPages.EVENT_DETAILS.component}
      />
      <Route
        exact
        path={EventsPages.EVENTS_NAMESPACES_VIEW.path}
        component={EventsPages.EVENTS_NAMESPACES_VIEW.component}
      />
      <Route
        exact
        path={EventsPages.EVENTS_DISCOVERY.path}
        component={EventsPages.EVENTS_DISCOVERY.component}
      />
      <Route
        exact
        path={EventsPages.EVENTS_OVERVIEW.path}
        component={EventsPages.EVENTS_OVERVIEW.component}
      />
      <Route
        component={() => (
          <ApplicationError
            message={"We can't seem to find the page you are looking for."}
            retryLink="/events"
            image={FOUR_ZERO_FOUR_IMAGE}
            code={NOT_FOUND_ERROR_CODE}
          />)}
      />
    </Switch>
  </>;


export default Events;

import React, { ReactNode } from "react";
import { Typography } from "antd";
import "./index.scss";
import { ParagraphProps } from "antd/lib/typography/Paragraph";

const { Paragraph } = Typography;

type CopyableItemProps = ParagraphProps & {
  copyText: string;
  iconBeforeCopy: string | ReactNode;
  iconAfterCopy: string | ReactNode;
  label?: string;
  toolTip?: boolean;
  onCopy?: () => void;
};

const CopyableItem = ({
  copyText,
  iconBeforeCopy,
  iconAfterCopy,
  toolTip = true,
  label,
  onCopy,
}: CopyableItemProps) => (
  <Paragraph
    className="copyable-item"
    copyable={
      {
        text: copyText,
        icon: [iconBeforeCopy, iconAfterCopy],
        tooltips: toolTip,
        onCopy,
      }
    }
  >
    {label}
  </Paragraph>
);

export default CopyableItem;

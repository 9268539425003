import { createFunctionalComponentFromView } from "../../../../utils/view-controller";
import NeoModalDialog from "../../../../common/components/neo-modal-dialog";
import useDisapproveTaskController from "./controller";
import FormComponent, { INavigationButtonsProps } from "../../../../common/components/forms";
import { NavigationButtons } from "../../../../common/components/forms/navigation-buttons";
import React from "react";
import "./index.scss";

export const DisapproveTaskModal = createFunctionalComponentFromView(
  useDisapproveTaskController,
  (controller) => {
    function renderNavigationButtons({ getFormData, doesFormHasErrors }: INavigationButtonsProps) {
      return (
        <NavigationButtons
          nextButtonLabel={controller.getDisapproveButtonLabel()}
          previousButtonLabel="Cancel"
          getFormData={getFormData}
          doesFormHasErrors={doesFormHasErrors}
          onClickNext={controller.onClickDisapproveButton}
          onClickPrevious={controller.onCancel}
          isNextButtonDisabled={controller.isDisapprovingTaskInProgress}
          isPreviousButtonDisabled={controller.isDisapprovingTaskInProgress}
        />
      );
    }

    return (
      <NeoModalDialog
        dialogClassName="DisapproveTaskModal"
        dialogTitle="Disapprove a Task"
        show={controller.showModal}
        onHide={controller.onCancel}
      >
        <FormComponent
          questions={controller.questions()}
          data={controller.getInitialFormData()}
          renderNavigationButtons={renderNavigationButtons}
        />
      </NeoModalDialog>
    );
  },
);

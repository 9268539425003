import { RouteComponentProps } from "react-router-dom";
import { useSelectorTyped } from "../../../../common/redux/store";
import { useDispatch } from "react-redux";
import { DeveloperToolsPageActions } from "../redux";
import { NeoError, NeoErrorType } from "../../../../utils/errors/neo-errors";
import { useEffect } from "react";
import Analytics from "../../../../common/services/analytics";

export type DeveloperToolDetailsProps = RouteComponentProps<{
  developerToolId: string;
}>;

export default function useDeveloperToolDetailsController(props: DeveloperToolDetailsProps) {
  const dispatch = useDispatch();
  const {
    error: fetchDeveloperToolsError,
    value: developerTools,
    progress: fetchDeveloperToolsProgress,
  } = useSelectorTyped((state) => state.developerToolsPage.developerToolsPEV);
  const selectedDeveloperTool = developerTools.find((developerTool) => developerTool.id === props.match.params.developerToolId);

  useEffect(() => {
    Analytics.trackPageDeveloperToolDetails();
    dispatch(DeveloperToolsPageActions.fetchDeveloperTools());
  }, []);

  const isSelectedDeveloperToolDetailsPresent = () => !!selectedDeveloperTool;

  const isDeveloperToolNotFound = () => !isSelectedDeveloperToolDetailsPresent() && !fetchDeveloperToolsProgress;

  const retryFetchDeveloperTools = () => dispatch(DeveloperToolsPageActions.fetchDeveloperTools());

  return ({
    selectedDeveloperTool,
    fetchDeveloperToolsError,
    shouldRenderProgressMessage: () => fetchDeveloperToolsProgress !== null && !isSelectedDeveloperToolDetailsPresent(),
    fetchDeveloperToolDetailsProgressText: "Fetching developer tool details.",
    shouldRenderErrorMessage: () => !!fetchDeveloperToolsError || isDeveloperToolNotFound(),
    getErrorMessage: () =>
      fetchDeveloperToolsError
        ? fetchDeveloperToolsError
        : new NeoError(NeoErrorType.RESOURCE_NOT_FOUND, "Developer tool details not found").getErrorInfo(),
    handleOnClick: () => {
      if (fetchDeveloperToolsError) {
        retryFetchDeveloperTools();
      }
    },
  });
}

import React, { FunctionComponent } from "react";
import { Card } from "react-bootstrap";
import "./index.scss";
import ImpactXLinkButton from "../../../common/components/impactx-link-button";

export type NavigationCardProps = {
  heading: string;
  title: string;
  description: string;
  buttonName: string;
  link: string;
  isExternalLink?: boolean;
  onTrackAnalytics?: () => void;
};

const NavigationCard: FunctionComponent<NavigationCardProps> = (props: NavigationCardProps) => {
  const renderCard = () => (
    <div>
      <h4>{props.heading}</h4>
      <Card>
        <Card.Body>
          <Card.Title>
            <h6>{props.title}</h6>
          </Card.Title>
          <Card.Text>
            {props.description}
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <ImpactXLinkButton
            href={props.link}
            onClick={props.onTrackAnalytics}
            isInternalLink={!props.isExternalLink}
          >
            {props.buttonName}
          </ImpactXLinkButton>
        </Card.Footer>
      </Card>
    </div>
  );

  return (
    <div className="NavigationCard">
      {renderCard()}
    </div>
  );
};

export default NavigationCard;

import React, { ReactElement } from "react";
import "./index.scss";
import { Nullable } from "../../../../utils/nullable";

type RequestFieldProps = {
  fieldName: string;
  value?: Nullable<string>;
  children?: ReactElement;
};

export const RequestDetailField = ({ value, fieldName, children }: RequestFieldProps) => {
  function renderFieldValue() {
    if (children) {
      return children;
    } else if (value) {
      return value;
    } else {
      return "---";
    }
  }

  return (
    <span>
      <span className={"fields"}>{`${fieldName}:`}</span>
      {renderFieldValue()}
    </span>
  );
};

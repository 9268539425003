import { useSelectorTyped } from "../../../../common/redux/store";
import { useGetFeatureToggleState } from "../../../../common/hooks/feature-toggles";
import { useDispatch } from "react-redux";
import { Role } from "../../../team-details/redux";
import { useEffect } from "react";
import { ApplicationDetailsPageActions } from "../redux";
import { getUserEmployeeIdFromUserEmail } from "../../../../utils/teams/surveyjs-helper";

export function useApplicationPageTitleControllerProvider() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const applicationBasicInfo = useSelectorTyped((state) => state.applicationDetailsPage.applicationBasicInfoPEV.value!);
  const joinTeamPE = useSelectorTyped((state) => state.applicationDetailsPage.joinTeamPE);
  const showJoinTeamModal = useSelectorTyped((state) => state.applicationDetailsPage.showJoinTeamModal);
  const loggedInUserEmail = useSelectorTyped((state) => state.header.userInfo.email.value);
  const isAuthZAccessRequestToggleEnabled = useGetFeatureToggleState("Neo_AuthZ_AccessRequest");
  const exitTeamPE =
    useSelectorTyped((state) => state.applicationDetailsPage.exitTeamPE);
  const potentialMembers = useSelectorTyped((state) => state.teamsPage.potentialMembersPEV.value);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ApplicationDetailsPageActions.displayJoinTeamModal(false));
  }, []);

  return ({
    applicationBasicInfo,
    onClickJoinButton: () => dispatch(ApplicationDetailsPageActions.displayJoinTeamModal(true)),
    shouldRenderJoinTeamButton: () => applicationBasicInfo.team.canJoinTeam,
    shouldRenderAuthZRequestAccessButton: () => isAuthZAccessRequestToggleEnabled,
    onCloseJoinTeamModel: () => dispatch(ApplicationDetailsPageActions.displayJoinTeamModal(false)),
    isJoinTeamInProgress: !!joinTeamPE.progress,
    showJoinTeamModal,
    onClickJoinTeamButtonInModel: (employeeId: string, role: Role) => {
      dispatch(ApplicationDetailsPageActions.joinTeam({
        teamId: applicationBasicInfo.team.id,
        memberData: {
          employeeId,
          role,
        },
        applicationId: applicationBasicInfo.id,
      }));
    },
    shouldRenderExitTeamButton: () => applicationBasicInfo.team.canExitTeam,
    loggedInUserEmail,
    exitTeamPE,
    onClickConfirmExitTeam: () => {
      const loggedInUserEmployeeId = getUserEmployeeIdFromUserEmail(loggedInUserEmail, potentialMembers);
      dispatch(ApplicationDetailsPageActions.exitTeam(
        {
          teamId: applicationBasicInfo.team.id,
          employeeId: loggedInUserEmployeeId,
          applicationId: applicationBasicInfo.id,
        },
      ));
    },
  });
}

import React, { FunctionComponent } from "react";
import { convertSpaceToUnderscore } from "../../../utils/string";
import NeoLink from "../neo-link";

type Props = {
  id?: string;
  name?: string;
};
const TeamLink: FunctionComponent<Props> = (props: Props) => {
  if (props.name && props.id) {
    return (
      <div>
        Team:&nbsp;
        <NeoLink
          link={`/teams/${props.id}/${convertSpaceToUnderscore(props.name)}`}
          data-testid="team-link"
        >
          {props.name}
        </NeoLink>
      </div>
    );
  }
  return null;
};

export default TeamLink;

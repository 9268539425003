import React from "react";
import ToolTipWrapped from "../tooltip";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import useDropdownMenuWithTooltipController, { Props } from "./controller";
import DropdownMenu from "../dropdown-menu";

const DropdownMenuWithToolTip = createFunctionalComponentFromView<ReturnType<typeof useDropdownMenuWithTooltipController>, Props>(
  useDropdownMenuWithTooltipController,
  (controller, children) => (
    <DropdownMenu
      dropdownItems={controller.dropdownItems}
      onToggle={controller.toggleDropdownOpen}
      ariaLabel={controller.ariaLabel}
    >
      <ToolTipWrapped
        id="dropdown-menu-tooltip"
        content={controller.tooltipContent}
        placement="bottom"
        enabled={controller.isDropdownOpen}
      >
        {children}
      </ToolTipWrapped>
    </DropdownMenu>
  ),
);

export default DropdownMenuWithToolTip;

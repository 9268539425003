import { Nullable } from "../../../utils/nullable";

export interface IRequest {
  id: string;
  status: RequestStatus;
  aggregatedTaskStatus: Nullable<AggregatedTaskStatus>;
  requester: IRequester;
  associatedEntity: IAssociatedEntity;
  type: string;
  domain: RequestDomain;
  resources?: Resource[];
  eta?: string;
  raisedDateTime: string;
  closedDateTime?: string;
  tasks: ITask[];
  metadata?: IMetadata;
}

export type Resource = Record<string, unknown>;

interface ITaskOwner {
  employeeId: string;
  employeeName: string;
  employeeEmail: string;
  teamId: string;
  teamName: string;
  teamSupportEmail: string;
}

export interface ITask {
  id: string;
  status: TaskStatus;
  requestId: string;
  hasAutomation: boolean;
  isApprovalRequired: boolean;
  taskOwner: ITaskOwner;
  resource: Resource;
  taskType: string;
  createdDateTime: string;
  updatedDateTime: string;
  completedDateTime?: string;
  disapprovalReason?: string;
}

interface IRequester {
  employeeId: string;
  employeeName: string;
  employeeEmail: string;
  teamId: string;
  teamName: string;
  teamSupportEmail: string;
}

export enum AssociatedEntityType {
  APPLICATION = "Application",
  CLIENT = "Client",
  TEAM = "Team",
  API = "API"
}

interface IAssociatedEntity {
  type: AssociatedEntityType;
  id: string;
  name: string;
}

export type IMetadata = Record<string, string>;

export enum TaskStatusLabel {
  PENDING_APPROVAL = "Pending",
  APPROVED = "Approved",
  FAILED = "Failed",
  DISAPPROVED = "Disapproved",
  COMPLETED = "Completed",
}

export enum RequestStatusLabel {
  PENDING_VALIDATION = "Pending",
  OPEN = "Pending",
  IN_PROGRESS = "In Progress",
  CLOSED = "Closed"
}

export enum AggregatedTaskStatusLabel {
  APPROVED = "Approved",
  PARTIALLY_APPROVED = "Partially Approved",
  DISAPPROVED = "Disapproved",
}

export enum TaskStatus {
  PENDING_APPROVAL = "PENDING_APPROVAL",
  APPROVED = "APPROVED",
  FAILED = "FAILED",
  DISAPPROVED = "DISAPPROVED",
  COMPLETED = "COMPLETED",
}

export enum RequestStatus {
  PENDING_VALIDATION = "PENDING_VALIDATION",
  OPEN = "OPEN",
  IN_PROGRESS = "IN_PROGRESS",
  CLOSED = "CLOSED"
}

export enum RequestDomain {
  API = "API",
  INFRASTRUCTURE = "INFRASTRUCTURE",
  EVENTS = "EVENTS",
  SOFTWARE_SUPPORT = "SOFTWARE_SUPPORT"
}

export enum AggregatedTaskStatus {
  APPROVED = "APPROVED",
  PARTIALLY_APPROVED = "PARTIALLY_APPROVED",
  DISAPPROVED = "DISAPPROVED",
}

import React from "react";
import "./index.scss";
import useTeamActivitiesController from "./controller";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import TableSortIcon from "../../../common/components/data-table-sort-icon";
import Progress from "../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../common/components/error-new";
import { ActivityHistory } from "../redux";
import { ExportCSVLink } from "../../../common/components/export-csv";
import Analytics from "../../../common/services/analytics";
import { ACTIVITY_HISTORY_TIME_FORMAT, formatDate } from "../../../utils/date-format";
import Panel from "../../../common/components/panel";

export const TeamActivitiesTable = () => {
  const controller = useTeamActivitiesController();

  const columns: Array<IDataTableColumn<ActivityHistory>> = [
    {
      name: "Date",
      selector: "occurredAt",
      cell: (row) => formatDate(row.occurredAt, ACTIVITY_HISTORY_TIME_FORMAT),
      sortable: true,
      sortFunction: (row1, row2) => new Date(row1.occurredAt).getTime() - new Date(row2.occurredAt).getTime(),
      grow: 1,
    },
    {
      name: "Description",
      selector: "action",
      sortable: true,
      grow: 3,
    },
    {
      name: "User",
      selector: "performedBy",
      cell: (row) => row.performedBy ?? "--",
      sortable: true,
      grow: 1,
    },
  ];

  const renderActivityHistoryTable = () => (
    <DataTable
      className={"TeamActivitiesTable"}
      columns={columns}
      data={controller.getActivities()}
      noHeader={true}
      sortIcon={<TableSortIcon/>}
      persistTableHead={true}
      defaultSortField="occurredAt"
      defaultSortAsc={false}
      pagination={true}
      paginationPerPage={10}
      paginationRowsPerPageOptions={controller.getPaginationRowsPerPageOptions()}
    />
  );

  function renderExportCSVLink() {
    if (controller.shouldRenderExportCSVLink()) {
      return (
        <ExportCSVLink
          data={controller.getExportCSVData()}
          filename={controller.getCSVFileName()}
          onClick={() => Analytics.trackEventExportCSVActivityHistoryOfTeamFromTeamDetailsPage()}
        />
      );
    }
  }

  const renderContent = () => {
    if (controller.shouldRenderProgressMessage()) {
      return (
        <Progress text={controller.progressMessage}/>
      );
    }
    if (controller.shouldRenderErrorMessage()) {
      return (
        <Error
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          {...mapApplicationErrorInfoToErrorComponentProps(controller.errorMessage!)}
        />
      );
    }
    if (controller.shouldRenderNoActivitiesMessage()) {
      return (
        <span>
          There is no activity performed on the team.
        </span>
      );
    }
    return (
      <div>
        {renderActivityHistoryTable()}
      </div>
    );
  };

  return (
    <div className="TeamActivities">
      {
        controller.projectAssociationToggleEnabled
          ?
          <Panel
            content={renderContent()}
            titleInfo={renderExportCSVLink()}
          />
          :
          <>
            <div className="Heading">
              <div className="title">Activity History</div>
              <div className={"export-csv"}>
                {renderExportCSVLink()}
              </div>
            </div>
            {renderContent()}
          </>
      }
    </div>
  );
};

import * as React from "react";
import ImpactXButton from "../impactx-button";
import "./index.scss";
import { INeoErrorInfo, NeoErrorType } from "../../../utils/errors/neo-errors";
import { identity } from "../../../utils/cast-to";
import { IErrorComponentProps, useErrorController } from "./controller";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import serverError from "./error-images/server_error.svg";
import { Nullable } from "../../../utils/nullable";


export function mapApplicationErrorInfoToErrorComponentProps(info: INeoErrorInfo) {
  return identity<Pick<IErrorComponentProps, "title" | "subTitle" | "buttonLabel" | "errorType" | "shouldDisplayErrorImage">>({
    title: info.title,
    subTitle: info.subTitle,
    buttonLabel: info.actionLabel,
    errorType: info.errorType,
    shouldDisplayErrorImage: info.shouldDisplayErrorImage,
  });
}

const errorTypeToErrorImageMap: Record<NeoErrorType, Nullable<{ image: string; message: string }>> = {
  [NeoErrorType.SERVER_ERROR]: {
    image: serverError,
    message: "Something went wrong, please try again later.",
  },
  [NeoErrorType.INTERNET_ERROR]: null,
  [NeoErrorType.AUTH_SESSION_EXPIRED]: null,
  [NeoErrorType.RESOURCE_NOT_FOUND]: null,
  [NeoErrorType.UNPROCESSABLE_ENTITY]: null,
  [NeoErrorType.UNHANDLED_EXCEPTION]: null,
  [NeoErrorType.REQUEST_TIMEOUT]: null,
};

const Error = createFunctionalComponentFromView<ReturnType<typeof useErrorController>, IErrorComponentProps>
(useErrorController, (controller) => {

  function renderErrorMessage() {
    return (
      <>
        <div className="error-title">{controller.title}</div>
        <div className="error-subtitle">
          {controller.subTitle.split("\n").map((str) =>
            <div key={str}>{str}<br/></div>)}
        </div>
      </>
    );
  }

  function renderErrorMessageWithImage() {
    return (
      <>
        <img
          src={errorTypeToErrorImageMap[controller.errorType]?.image}
          alt="server-error"
          width={550}
          height={400}

        />
        <div className="neo-error-message">
          {errorTypeToErrorImageMap[controller.errorType]?.message}
        </div>
      </>
    );
  }

  return (
    <div
      className={`Error ${controller.renderAsFullPage ? " ErrorFullPage" : ""}`}
      style={{
        minHeight: controller.minHeight,
        height: controller.height && (controller.height > controller.minHeight)
          && controller.height || undefined,
      }}
    >
      <div className="error-container">
        {
          controller.shouldDisplayErrorImage && controller.isApiUnificationToggleEnabled
            ? renderErrorMessageWithImage()
            : renderErrorMessage()
        }
        <ImpactXButton
          label={controller.buttonLabel}
          onClickHandler={controller.onClick}
        />
      </div>
    </div>
  );
});


export default Error;

import React from "react";
import { createFunctionalComponentFromView } from "../../../../utils/view-controller";
import useRequestDetailsHeaderController, { RequestDetailsHeaderProps } from "./controller";
import "./index.scss";
import { RequestDetailField } from "../fields";
import NeoLink from "../../../../common/components/neo-link";

const RequestDetailsHeader = createFunctionalComponentFromView<ReturnType<typeof useRequestDetailsHeaderController>, RequestDetailsHeaderProps>(
  useRequestDetailsHeaderController,
  (controller) => {
    function renderAggregatedTaskStatus() {
      if (controller.getAggregatedTaskStatusLabel()) {
        return (
          <div className={controller.getClassNameForAggregatedStatus()}>
            {controller.getAggregatedTaskStatusLabel()}
          </div>
        );
      }
    }

    function renderStatus() {
      return (
        <div className={"status"}>
          <div className={controller.getClassNameForRequestStatus()}>
            {controller.getRequestStatusLabel()}
          </div>
          {renderAggregatedTaskStatus()}
        </div>
      );
    }

    function renderRequestTypeAndStatus() {
      return (
        <div className={"type-and-status-info"}>
          <div>
            <h3>
              {controller.getRequestTypeLabel()}
            </h3>
          </div>
          <div>
            <RequestDetailField
              fieldName={"ID"}
              value={controller.requestDetails.id}
            />
          </div>
          {renderStatus()}
        </div>
      );
    }

    function renderLink(url: string, name: string) {
      return (
        <NeoLink link={url}>
          {name}
        </NeoLink>
      );
    }

    function renderTeamDetails(team: { name: string; link: string }) {
      return (
        <>
          {/* eslint-disable-next-line security/detect-non-literal-fs-filename */}
          {renderLink(team.link, team.name)} team
        </>
      );
    }

    function renderRequestedTeamAndApplication() {
      const associatedEntityDetails = controller.getAssociatedEntityDetails();
      return (
        <RequestDetailField
          fieldName="Requested for"
        >
          <span>
            {/* eslint-disable-next-line security/detect-non-literal-fs-filename */}
            {renderLink(associatedEntityDetails.link, associatedEntityDetails.name)} {associatedEntityDetails.type}, &nbsp;
            {renderTeamDetails(controller.getRequesterTeamDetails())}
          </span>
        </RequestDetailField>
      );
    }

    function renderRequesterDetails() {
      return (
        <RequestDetailField
          fieldName="Requested on"
          value={`${controller.getRequestRaisedDateTime()} | ${controller.requestDetails.requester.employeeName}`}
        />
      );
    }

    function renderAssignedTo() {
      const taskOwnerTeam = controller.getTaskOwnerTeamDetails();
      return (
        <RequestDetailField
          fieldName="Assigned to"
        >
          {taskOwnerTeam ? renderTeamDetails(taskOwnerTeam) : undefined}
        </RequestDetailField>
      );
    }

    return (
      <div className={"RequestDetailsHeader"}>
        {renderRequestTypeAndStatus()}
        {renderRequesterDetails()}
        {renderRequestedTeamAndApplication()}
        {renderAssignedTo()}
      </div>
    );
  },
);

export default RequestDetailsHeader;

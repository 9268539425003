const applicationCategories = [
  "Web Application",
  "Mobile Application",
  "Static web page or site",
  "Chatbot",
  "Script",
  "Worker process",
  "Microservice",
  "Generative AI",
];

export default applicationCategories;

import React from "react";
import "./index.scss";
import ContainerBox from "../../../common/components/container-box";
import useApplicationsController from "./controller";
import Progress from "../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../common/components/error-new";
import { Col, Row } from "react-bootstrap";
import ApplicationCard from "../../../common/components/application-card";
import { ExportCSVLink } from "../../../common/components/export-csv";
import Analytics from "../../../common/services/analytics";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import ApplicationFilters from "./filters";
import AllApplicationSearch from "./search";

const Applications = createFunctionalComponentFromView(useApplicationsController, (controller) => {
  const renderFilteredApplications = () => {
    if (controller.shouldRenderNoRecordsToDisplayMessage()) {
      return (
        <span>No records to display.</span>
      );
    }
    return (
      <Row className="filtered-applications">
        {controller
          .filteredApplications
          .map((application, index) => (
            <Col
              className="application-column"
              key={index}
              xs={4}
              md={4}
            >
              <ApplicationCard
                application={application}
                onClick={() => Analytics.trackEventClickOnApplicationCardFromApplicationsPage(application.name)}
              />
            </Col>
          ))}
      </Row>
    );
  };

  const renderExportCsvLink = () => {
    if (controller.shouldRenderExportCsvLink()) {
      return (
        <div className="export-all-applications">
          <ExportCSVLink
            data={controller.getCSVDataForDownload()}
            filename={controller.getCSVFileName()}
            onClick={() => Analytics.trackEventExportApplicationsToCSVFromApplicationsPage()}
          />
        </div>
      );
    }
  };

  const renderApplicationsList = () => {
    if (controller.shouldRenderProgressMessage()) {
      return <Progress text={controller.fetchAllApplicationsProgress}/>;
    }
    if (controller.shouldRenderErrorMessage()) {
      return (
        <Error
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          {...mapApplicationErrorInfoToErrorComponentProps(controller.fetchAllApplicationsError!)}
          onClick={controller.retryFetchApplicationsCatalogue}
        />
      );
    }
    if (controller.shouldRenderNoApplicationsInCatalogueMessage()) {
      return (
        <span>
        There are no applications created yet. Click <b>Get Started</b> option to create a new application and request for infrastructure.
        </span>
      );
    }
    return (
      <>
        <div className="application-count-and-export-csv">
          <div className="application-count">
            Showing {controller.filteredApplicationsCount} of {controller.totalApplicationsCount} applications
          </div>
          {renderExportCsvLink()}
        </div>
        {renderFilteredApplications()}
      </>
    );
  };

  const renderAllApplications = () => (
    <div className="all-applications-content">
      <div className="search-and-filters">
        <AllApplicationSearch/>
        <ApplicationFilters/>
      </div>
      <div className="applications-list">
        {renderApplicationsList()}
      </div>
    </div>
  );

  return (
    <div className="ApplicationsCatalogue">
      <ContainerBox>
        <div className="all-applications">
          <h4 className="all-applications-title">All applications</h4>
          {renderAllApplications()}
        </div>
      </ContainerBox>
    </div>
  );
});

export default Applications;

import React, { FunctionComponent } from "react";
import useTeamMembershipController from "./controller";
import "./index.scss";
import Panel from "../../../common/components/panel";
import TeamMembersTable from "../team-members-table";
import { ProjectSelection } from "./project-selection";
import NeoModalDialog from "../../../common/components/neo-modal-dialog";
import ImpactXIconButton from "../../../common/components/impactx-icon-button";
import { IProjectDetails } from "../redux";
import ImpactXButton from "../../../common/components/impactx-button";

export const TeamMembership: FunctionComponent = () => {
  const controller = useTeamMembershipController();

  const renderDissociateButton = (project: IProjectDetails) => {
    if (controller.shouldRenderDissociateProjectButton()) {
      return (
        <ImpactXIconButton
          onClick={() => controller.onClickDissociateIcon(project)}
          className={"dissociate-project-button"}
          ariaLabel={`Dissociate project-${project.name}`}
          disabled={controller.isDissociateProjectIconDisabled}
        >
          <i
            className="ri-delete-bin-6-line ri-l"
          />
        </ImpactXIconButton>);
    }
  };

  const renderModalBodyText = () => (
    <div>
      People from <b>{controller.selectedProject.name}</b> project will no longer be added or removed from this team based on the staffing
      assignments. Managers need to add/remove them manually.
      <br/>Dissociating this project will not remove existing team members.
    </div>
  );

  const renderProjectDissociationModal = () => (
    <NeoModalDialog
      dialogClassName="DissociateProjectModal"
      dialogTitle="Staffing Project Dissociation"
      bodyText={renderModalBodyText()}
      show={controller.showDissociateProjectModal}
      dontAutoHideOnButtonClick={true}
      onHide={controller.onClickCancelButton}
      primaryButton={{
        show: true,
        label: controller.getPrimaryButtonLabel,
        disabled: controller.isDissociateProjectInProgress,
        onClick: controller.onClickDissociateButton,
      }}
      secondaryButton={{
        show: true,
        label: "Cancel",
        disabled: controller.isDissociateProjectInProgress,
        onClick: controller.onClickCancelButton,
      }}
    />
  );

  function renderAssociateProjectButton() {
    if (controller.shouldRenderAssociateProjectButton()) {
      return (
        <ImpactXButton
          label="Associate Project"
          disabled={controller.isAssociateProjectButtonDisabled()}
          onClickHandler={controller.onClickAssociateProjectButton}
        />
      );
    }
    return null;
  }

  const renderProjectAssociation = () => {
    if (controller.shouldRenderProjectAssociation()) {
      return (
        <div className="project-association">
          <div className="heading">
            <div className="title">Staffing project association</div>
            <div className="member-actions">
              {renderAssociateProjectButton()}
            </div>
          </div>
          <div>
            <span className="project-association-description">
                Staffing project association helps in managing your team by automatically adding and removing members based on assignments.
            </span>
            <div className="project-association-detail">
              {controller.shouldRenderNoProjectAssociationMessage()
                ? controller.noProjectAssociationMessage
                : controller.teamDetails?.projects?.map((project) => (
                  <div
                    key={project.id}
                    className="project-associations"
                  >
                    {project.name}
                    {renderDissociateButton(project)}
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      );
    }
  };

  function renderContent() {
    return (
      <>
        {renderProjectAssociation()}
        {<ProjectSelection/>}
        {<TeamMembersTable/>}
      </>
    );
  }

  return (
    <div className={"TeamMembership"}>
      {
        controller.projectAssociationToggleEnabled
          ?
          <Panel
            content={renderContent()}
          />
          :
          renderContent()
      }
      {renderProjectDissociationModal()}
    </div>
  );
};


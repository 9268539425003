export type FilterDropdownProps = {
  title: string;
  options: Array<{ label: string; value: string; disabled?: boolean }>;
  onChangeHandler: (selectedOptions: string[]) => void;
  selectedOptions: string[];
  width?: number;
  className?: string;
};

export default function useMultiSelectFilterController(props: FilterDropdownProps) {
  function getSelectedData() {
    const options = props.options.map((option) => option.value);
    return options.filter((option) => props.selectedOptions.includes(option));
  }

  function onChange(selectedOptions: string[]) {
    props.onChangeHandler(selectedOptions);
  }

  return ({
    ...props,
    getSelectedData,
    onChange,
  });
}

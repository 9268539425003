import React, { FunctionComponent } from "react";
import useProjectSelectionController from "./controller";
import "./index.scss";
import NeoModalDialog from "../../../../common/components/neo-modal-dialog";
import FormComponent, { INavigationButtonsProps } from "../../../../common/components/forms";
import { NavigationButtons } from "../../../../common/components/forms/navigation-buttons";

// TODO why didnt use ViewController:createFunctionalComponentFromView()?
export const ProjectSelection: FunctionComponent = () => {
  const controller = useProjectSelectionController();

  const renderNavigationButtons = ({ getFormData, doesFormHasErrors }: INavigationButtonsProps) => (
    <NavigationButtons
      nextButtonLabel="Associate"
      previousButtonLabel="Cancel"
      getFormData={getFormData}
      doesFormHasErrors={doesFormHasErrors}
      onClickNext={controller.onClickAssociateButton}
      onClickPrevious={controller.onClickCancel}
    />
  );

  const renderProjectSelection = () => {
    if (controller.showProjectSelection) {
      return (
        <div className="project-selection-form">
          <FormComponent
            questions={controller.questions}
            renderNavigationButtons={renderNavigationButtons}
          />
        </div>
      );
    }
  };

  const renderModalBodyText = () => (
    <div>
      <div className={"selected-projects"}>
        Projects: <b>{controller.getSelectedProjects()}</b>
      </div>
      People from these projects will be automatically added or removed from this team based on the staffing assignments.
    </div>
  );

  const renderProjectAssociationModal = () => (
    <NeoModalDialog
      dialogClassName="AssociateProjectsModal"
      dialogTitle="Staffing Project Association"
      bodyText={renderModalBodyText()}
      show={controller.showAssociateProjectsModal}
      dontAutoHideOnButtonClick={true}
      onHide={controller.onCancel}
      primaryButton={{
        show: true,
        label: controller.getPrimaryButtonLabel,
        onClick: controller.onClickModalAssociateButton,
        disabled: controller.isAssociateProjectsInProgress,
      }}
      secondaryButton={{
        show: true,
        label: "Cancel",
        onClick: controller.onCancel,
        disabled: controller.isAssociateProjectsInProgress,
      }}
    />
  );

  return (
    <div className="ProjectSelection">
      {renderProjectSelection()}
      {renderProjectAssociationModal()}
    </div>
  );
};


import React, { FunctionComponent } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  name: string;
  maxLength: number;
  itemNameClassName: string;
};

const renderFullItemName = (fullItemName: string) => (
  <Tooltip id="button-tooltip">
    {fullItemName}
  </Tooltip>
);

const getTruncatedItemName = (itemName: string, maxNameLength: number) => {
  if (itemName.length > maxNameLength) {
    return `${itemName.slice(0, maxNameLength)} ...`;
  }
  return itemName;
};

const ItemName: FunctionComponent<Props> = (props: Props) => {
  const itemName = props.name;
  const maxItemNameLength = props.maxLength;

  const renderShortName = () => {
    if (itemName.length <= maxItemNameLength) {
      return (
        <div className={props.itemNameClassName}>
          {getTruncatedItemName(itemName, maxItemNameLength)}
        </div>
      );
    }
  };

  const renderLongName = () => {
    if (itemName.length > maxItemNameLength) {
      return (
        <OverlayTrigger
          placement="auto"
          delay={{ show: 250, hide: 300 }}
          overlay={renderFullItemName(itemName)}
        >
          <div className={props.itemNameClassName}>
            {getTruncatedItemName(itemName, maxItemNameLength)}
          </div>
        </OverlayTrigger>
      );
    }
  };

  return (
    <>
      {renderShortName()}
      {renderLongName()}
    </>
  );
};

export default ItemName;


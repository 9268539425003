export const SHOW_TOOLTIP_DELAY = 250;
export const HIDE_TOOLTIP_DELAY = 300;
export const EVENT_CARD_NAME_MAX_LENGTH = 22;
export const EVENT_CARD_DESCRIPTION_MAX_LENGTH = 90;
export const EVENT_CARD_DESCRIPTION_MAX_LENGTH_2LINES = 60;
export const NAMESPACE_DESCRIPTION_MAX_LENGTH = 50;
export const NAMESPACE_NAME_MAX_LENGTH = 22;
export const NO_EVENTS_PRESENT_MESSAGE = "There are no events registered yet for this namespace";
export const NOT_FOUND_ERROR_CODE = 404;
export const ERROR_TITLE = (errorCode: number) => `${errorCode}`;

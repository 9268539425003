import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { dispatchFetchAllPotentialMembersIfEmpty, TEAMS_TAB_EVENT_KEY, TeamsPageActions } from "./redux";
import Analytics from "../../common/services/analytics";
import { useSelectorTyped } from "../../common/redux/store";
import { useGetFeatureToggleState } from "../../common/hooks/feature-toggles";
import { getEnvVars } from "../../common/config/env";

export default function useTeamsController() {
  const dispatch = useDispatch();
  const activeTab = useSelectorTyped((state) => state.teamsPage.activeTab);
  const potentialMembersPEV = useSelectorTyped((state) => state.teamsPage.potentialMembersPEV);
  const isApiUnificationToggleEnabled = useGetFeatureToggleState("Neo_ApiPlatform_Unification");
  const { unificationConfig } = getEnvVars();

  useEffect(() => {
    Analytics.trackPageTeams();
    dispatchFetchAllPotentialMembersIfEmpty(dispatch, potentialMembersPEV);
    dispatch(TeamsPageActions.fetchAllTeams());
    dispatch(TeamsPageActions.fetchMyTeams());
  }, []);

  const onClickCreateButton = () => {
    dispatch(TeamsPageActions.updateShowChooseTeamTypeForTeamCreationModal(true));
  };

  const onClickAllTeamsTab = () => {
    Analytics.trackPageAllTeams();
  };

  const onClickMyTeamsTab = () => {
    Analytics.trackPageMyTeams();
  };

  const setActiveTab = (tab: TEAMS_TAB_EVENT_KEY) => {
    dispatch(TeamsPageActions.setActiveTab(tab));
  };

  return ({
    isApiUnificationToggleEnabled,
    environmentConfig: unificationConfig.environmentSpecificConfig,
    onClickCreateButton,
    activeTab,
    setActiveTab,
    onClickAllTeamsTab,
    onClickMyTeamsTab,
  });
}

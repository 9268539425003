import { useOktaAuth } from "@okta/okta-react";
import { getEnvVars } from "../../config/env";
import { iif } from "../../../utils/functions";


let setUserInfo: (_userInfo: IUserInfo) => void;
const userInfoPromise = new Promise<IUserInfo>((resolve) => {
  setUserInfo = resolve;
});
window.getUserInfo = () => userInfoPromise;


export const mockUser: IUserInfo = {
  name: "Mock User",
  email: "mockUser@thoughtworks.com",
};

export function useAuthUserInfo() {
  const oktaContext = useOktaAuth();
  return {
    async getUser() {
      let userInfo: IUserInfo = mockUser;
      if (!getEnvVars().disableOktaAuth) {
        const { name, email } = await oktaContext.oktaAuth.getUser();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        userInfo = { name: name!, email: email! };
      }

      iif(function setUserInfoIntoWindowObject() {
        if (!window.userInfo) {
          window.userInfo = userInfo;
          window.dispatchEvent(new CustomEvent(
            "UserInfoAvailable",
            { detail: userInfo },
          ));
          setUserInfo?.(userInfo);
        }
      });

      return userInfo;
    },
  };
}

import React, { FunctionComponent } from "react";
import { ICategory, IEventRegistration, IEventRegistrationV3, INamespace } from "./redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { InView } from "react-intersection-observer";
import EventCard from "./event-card";
import Progress from "../../../common/components/progress";

type Props = {
  itemMetadata: ICategory[] | INamespace[];
  eventRegistrations: { [key: string]: IEventRegistrationV3[] | IEventRegistration[] };
  fetchEventRegistrations: (category: string) => void;
  selectedItem: string;
  highlightedItem: string;
  hasMore: { [key: string]: boolean };
  isLoading: { [key: string]: boolean };
  handleSectionTitleInView: (category: string, isInView: boolean) => void;
  sectionHeights: { [key: string]: number };
  onEventTileClick: (namespaceName: string, eventName: string) => void;
  shouldShowEventTags: () => boolean;
  filterTags: (tags: string[]) => string[] | undefined;
  isNamespaceViewActive: boolean;
};

const EventsPanel: FunctionComponent<Props> = (props: Props) => {
  const renderItemTitle = (title: string, item: ICategory | INamespace) =>
    (
      <>
        <h3 className={props.highlightedItem === item.name ? "section-title-selected" : "section-title"}>
          {title}
        </h3>
        <div
          id={title}
          style={{ height: 200, position: "absolute", top: -100 }}
        />
      </>
    );

  const renderNamespaceDescription = (item: INamespace) => (
    <div
      id={item.name}
      className="namespace-description"
    >
      {item.description}
    </div>

  );

  const renderLoadingIcon = (message: string) => (
    <div style={{ width: "100%" }}>
      <div className="loading-icon">
        <Progress text={message}/>
      </div>
    </div>);

  const renderEventsProgress = (category: string) => {
    if (props.isLoading[category]) {
      return renderLoadingIcon("Fetching event registrations.");
    }
  };

  const renderEvents = (itemName: string) => {
    if (props.eventRegistrations[itemName]) {
      return (
        <div>
          <div className={"event-registrations"}>
            {props.eventRegistrations[itemName].map((item, index) => (
              <EventCard
                key={`${item.name}_${index}`}
                event={item}
                resourceDescriptionClassName={"event-registration-desc"}
                onEventTileClick={props.onEventTileClick}
                shouldShowEventTags={props.shouldShowEventTags}
                filterTags={props.filterTags}
              />
            ))}
            {renderEventsProgress(itemName)}
          </div>
        </div>);
    }
  };

  const hasMoreItem = (itemName: string): boolean => {
    if (!props.isNamespaceViewActive) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return props.selectedItem === itemName && props.hasMore[itemName];
    }
    return false;
  };

  return (
    <div>
      {
        (!props.isNamespaceViewActive) ?
          props.itemMetadata?.map((item, itemIdx) => {
            const itemName = props.itemMetadata[itemIdx].name;
            return (
              // eslint-disable-next-line react/jsx-key
              <InfiniteScroll
                // eslint-disable-next-line unicorn/explicit-length-check
                dataLength={props.eventRegistrations[itemName] && props.eventRegistrations[itemName].length || 0}
                next={() => props.fetchEventRegistrations(itemName)}
                hasMore={hasMoreItem(itemName)}
                loader={""}
                scrollThreshold={0.5}
                onScroll={() => props.fetchEventRegistrations(itemName)}
              >
                <InView
                  // threshold={THRESHOLD}
                  wrap={"div"}
                  rootMargin={"248px 0px -248px 0px"}
                  onChange={(isInView) => {
                    props.handleSectionTitleInView(itemName, isInView);
                  }}
                >
                  <div
                    onScroll={() => props.fetchEventRegistrations(itemName)}
                    style={{
                      position: "relative",
                      minHeight: `${props.sectionHeights[itemName]}px`,
                      marginTop: `${itemIdx === 0 ? 0 : 36}px`,
                    }}
                    className={"events"}
                  >
                    {renderItemTitle(itemName, item)}
                    {item.totalEventRegistrations === 0 ? (
                      <div className={"no-events-message"}>
                        No event registrations available.
                      </div>
                    ) : (
                      renderEvents(itemName)
                    )}
                  </div>
                </InView>
              </InfiniteScroll>
            );
          }) :
          <>
            {
              props.isNamespaceViewActive ?
                props.itemMetadata?.map((item, itemIdx) => {
                  const itemName = props.itemMetadata[itemIdx].name;
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <InView
                      // threshold={[1]}
                      // key={itemName}
                      wrap={"div"}
                      rootMargin={"248px 0px -248px 0px"}
                      onChange={(isInView) => {
                        props.handleSectionTitleInView(itemName, isInView);
                      }}
                      onScroll={() => props.fetchEventRegistrations(itemName)}
                    >
                      <div
                        // key={itemName}
                        // onScroll={() => props.fetchEventRegistrations(itemName)}
                        style={{
                          position: "relative",
                          minHeight: `${props.sectionHeights[itemName]}px`,
                          marginTop: `${itemIdx === 0 ? 0 : 36}px`,
                        }}
                        className={"events"}
                      >
                        {renderItemTitle(itemName, item)}
                        {renderNamespaceDescription(item as INamespace)}
                        {item.totalEventRegistrations === 0 ? (
                          <div className={"no-events-message"}>
                            No event registrations available.
                          </div>
                        ) : (
                          renderEvents(itemName)
                        )}
                      </div>
                    </InView>);
                }) : ""
            }
          </>
      }
    </div>
  );
};

export default EventsPanel;

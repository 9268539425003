import {
  getGcpProjectNameValidatorConfig,
  getGcpValidatorConfig,
  getGithubRepoNameValidatorConfig,
  getGithubReposValidatorConfig,
} from "../../../../get-started/utils/validator";
import { TeamType } from "../../../../create-team/redux";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { ApplicationBasicInfo, InfrastructureDetails, OktaChicletType } from "../../redux";
import DetailsList from "../../details-list";
import { stringUtils } from "../../../../../utils/string-utils";
import { replaceInvalidGitHubRepoNameCharactersWithHyphens } from "../../../../get-started/utils/process-fields";
import InfraDetails from "../infra-details";
import OktaChicletsInfoOld from "../okta-chiclets-info-old";
import CircleCiInfo from "../circle-ci-info";
import { getEnumValues } from "../../../../../utils/enum";
import { getOktaChicletNameValidatorConfig, getUrlValidatorConfig } from "../../../../../utils/forms/validator";
import OktaChicletsInfo from "../okta-chiclets-info";
import { iif } from "../../../../../utils/functions";

const getGithubRepositoriesQuestion = (options: {
  teamType: TeamType;
  applicationName: ApplicationBasicInfo["name"];
  canRequestAdditionalInfra?: boolean;
  requestedInfra?: InfrastructureDetails["requestedInfra"];
  repositories?: string[];
}) => (
  options.teamType === TeamType.EXPERIMENT_AND_INNOVATION
    ? [
      {
        type: "html",
        name: "existingAndRequestedGithubRepos",
        html: ReactDOMServer.renderToString(
          <InfraDetails
            title={"Github repositories"}
            provisionedInfra={options.repositories ?? []}
            requestedInfra={options.requestedInfra?.repositories ?? []}
            showTaskDetailsPageUrl={options.requestedInfra?.showTaskDetailsPageUrl}
          />,
        ),
      },
      !!options.canRequestAdditionalInfra && {
        titleLocation: "hidden",
        type: "matrixdynamic",
        minRowCount: 0,
        maxRowCount: 5,
        rowCount: 0,
        width: "max-content",
        name: "githubRepos",
        addRowText: "+ Add Repo",
        removeRowText: "\t",
        columns: [
          {
            name: "name",
            width: 600,
            description: `pre--${replaceInvalidGitHubRepoNameCharactersWithHyphens(options.applicationName)}-`,
            cellType: "text",
            validators: getGithubRepoNameValidatorConfig(),
          },
          {
            name: "dummy-field",
            description: "This is needed to show submit button when user clicks on Add project",
            defaultValue: "dummy-value",
            visible: "disabled",
          },
        ],
        validators: getGithubReposValidatorConfig(),
      },
    ]
    : []
);

const getGithubOrganisationQuestion = (teamType: TeamType, githubOrg?: string) => (
  teamType === TeamType.REGIONAL_IT || teamType === TeamType.TECHOPS
    ? [{
      type: "html",
      html: ReactDOMServer.renderToString(
        <DetailsList
          fieldName={"GitHub organisation"}
          detailsList={stringUtils.convertStringToListIfNewLineCharExists(githubOrg ?? "")}
          renderUrlsAsHyperLink={true}
        />,
      ),
    }]
    : []
);

const getCloudProjectsQuestion = (options: {
  canRequestAdditionalInfra?: boolean;
  applicationName: ApplicationBasicInfo["name"];
  requestedInfra: InfrastructureDetails["requestedInfra"];
  gcpProjects?: string[];
  isApplicationCreatedAfterProductionGCPDisablingDate: boolean;
  isDisplayGCPBudgetAlertToggleEnabled: boolean;
  shouldDisplayBudgetAlert: boolean;
}) => ([
  {
    type: "html",
    name: "existingAndRequestedGcpProjects",
    html: ReactDOMServer.renderToString(
      <InfraDetails
        title="GCP projects"
        provisionedInfra={options.gcpProjects ?? []}
        requestedInfra={options.requestedInfra?.gcpProjects ?? []}
        showTaskDetailsPageUrl={options.requestedInfra?.showTaskDetailsPageUrl}
        isApplicationCreatedAfterProductionGCPDisablingDate={options.isApplicationCreatedAfterProductionGCPDisablingDate}
        shouldDisplayBudgetAlert={options.shouldDisplayBudgetAlert}
        isDisplayGCPBudgetAlertToggleEnabled={options.isDisplayGCPBudgetAlertToggleEnabled}
      />,
    ),
  },
  !!options.canRequestAdditionalInfra && {
    titleLocation: "hidden",
    type: "matrixdynamic",
    minRowCount: 0,
    maxRowCount: 5,
    rowCount: 0,
    width: "max-content",
    name: "gcpProjects",
    description: "Use the word \"prod\" if the project is for a production " + // TODO Check if description is required
      "environment. E.g. app-name-prod.",
    addRowText: "+ Add Project",
    removeRowText: "\t",
    columns: [
      {
        name: "name",
        width: 600,
        cellType: "text",
        defaultValue: options.applicationName,
        isRequired: true,
        requiredErrorText: "Required",
        validators: getGcpProjectNameValidatorConfig(),
      },
      {
        name: "dummy-field",
        description: "This is needed to show submit button when user clicks on Add project",
        defaultValue: "dummy-value",
        visible: "disabled",
      },
    ],
    validators: getGcpValidatorConfig(),
  },
]);

const getCircleCiDetails = (options: {
  teamType: TeamType;
  ciCdProvider: InfrastructureDetails["ciCdProvider"];
  pipelines: InfrastructureDetails["pipelines"];
  githubOrg: InfrastructureDetails["githubOrg"];
}) => ({
  type: "html",
  html: ReactDOMServer.renderToString(<CircleCiInfo{...options}/>),
});

const getOktaDetailsWithoutRequestOktaPreviewQuestions = (
  oktaChiclets: InfrastructureDetails["oktaChiclets"],
) => ({
  type: "html",
  html: ReactDOMServer.renderToString(<OktaChicletsInfoOld oktaChiclets={oktaChiclets}/>),
});

const getOktaPreviewQuestions = (options: {
  oktaChicletHelpDocumentLink: string;
  diSupportEmail: string;
  providedOktaChiclets?: InfrastructureDetails["oktaChiclets"];
  requestedOktaChiclets?: InfrastructureDetails["requestedInfra"]["oktaChiclets"];
  showTaskDetailsPageUrl?: boolean;
  canRequestAdditionalInfra?: boolean;
}) => (
  [
    {
      type: "html",
      html: ReactDOMServer.renderToString(
        <OktaChicletsInfo
          providedOktaChiclets={options.providedOktaChiclets}
          requestedOktaChiclets={options.requestedOktaChiclets}
          showTaskDetailsPageUrl={options.showTaskDetailsPageUrl}
          oktaChicletHelpDocumentLink={options.oktaChicletHelpDocumentLink}
          diSupportEmail={options.diSupportEmail}
        />,
      ),
    },
    iif(function shouldDisplayRequestOktaPreviewForm() {
      return !!options.canRequestAdditionalInfra
        && !options.providedOktaChiclets?.length
        && !options.requestedOktaChiclets?.length;
    }) && {
      type: "paneldynamic",
      name: "oktaChiclets",
      allowRemovePanel: false,
      titleLocation: "hidden",
      templateElements: [
        {
          type: "text",
          name: "chicletName",
          title: "Chiclet name",
          titleLocation: "top",
          description: "Accepts 3 to 85 characters",
          descriptionLocation: "underTitle",
          isRequired: true,
          validators: getOktaChicletNameValidatorConfig(),
        },
        {
          name: "chicletType",
          title: "Sign-In method",
          titleLocation: "top",
          type: "dropdown",
          choices: getEnumValues(OktaChicletType),
          isRequired: true,
        },
        {
          type: "text",
          name: "ssoUrl",
          title: "Single SignOn URL",
          titleLocation: "top",
          description: "The location where the SAML assertion is sent with a HTTP POST. " +
            "This is often referred to as the SAML Assertion Consumer Service (ACS) URL for your application.",
          descriptionLocation: "underTitle",
          isRequired: true,
          visibleIf: `{panel.chicletType}='${OktaChicletType.SAML}'`,
          validators: getUrlValidatorConfig("panel.ssoUrl"),
        },
        {
          type: "text",
          name: "audienceUrl",
          title: "Audience restriction URL",
          titleLocation: "top",
          description: "The application-defined unique identifier that is the intended audience of the SAML assertion. " +
            "This is most often the SP entity ID of your application.",
          descriptionLocation: "underTitle",
          isRequired: true,
          visibleIf: `{panel.chicletType}='${OktaChicletType.SAML}'`,
          validators: getUrlValidatorConfig("panel.audienceUrl"),
        },
        {
          type: "text",
          name: "initiateLoginUrl",
          title: "Initiate login URL",
          titleLocation: "top",
          description: "Include an URL to have Okta initiate to the sign in flow. " +
            "When Okta redirects to this endpoint, the client is triggered to send an authorize request",
          descriptionLocation: "underTitle",
          isRequired: true,
          visibleIf: `{panel.chicletType}='${OktaChicletType.OIDC}' or {panel.chicletType}='${OktaChicletType.SPA}'`,
          validators: getUrlValidatorConfig("panel.initiateLoginUrl"),
        },
        {
          type: "text",
          name: "signInRedirectUrl",
          title: "Sign-in redirect URL",
          titleLocation: "top",
          description: "Okta sends the authentication response and ID token for the user's sign in request to these URL",
          descriptionLocation: "underTitle",
          isRequired: true,
          visibleIf: `{panel.chicletType}='${OktaChicletType.OIDC}' or {panel.chicletType}='${OktaChicletType.SPA}'`,
          validators: getUrlValidatorConfig("panel.signInRedirectUrl"),
        },
        {
          type: "text",
          name: "signOutRedirectUrl",
          title: "Sign-out redirect URL",
          titleLocation: "top",
          description: "After your application contacts Okta to close the user section, Okta redirect user to one of these URL",
          descriptionLocation: "underTitle",
          isRequired: true,
          visibleIf: `{panel.chicletType}='${OktaChicletType.OIDC}' or {panel.chicletType}='${OktaChicletType.SPA}'`,
          validators: getUrlValidatorConfig("panel.signOutRedirectUrl"),
        },
      ],
      noEntriesText: "",
      panelCount: 0,
      maxPanelCount: 1,
      panelAddText: "+ Add Okta Preview",
      renderMode: "list",
    },
  ]);

const getManageInfraQuestions = (options: {
  infrastructureInfo: InfrastructureDetails;
  teamType: TeamType;
  applicationName: ApplicationBasicInfo["name"];
  isRequestOktaPreviewToggleEnabled: boolean;
  oktaChicletHelpDocumentLink: string;
  diSupportEmail: string;
  isApplicationCreatedAfterProductionGCPDisablingDate: boolean;
  isDisplayGCPBudgetAlertToggleEnabled: boolean;
  shouldDisplayBudgetAlert: boolean;
}) => options.isRequestOktaPreviewToggleEnabled
  ? [
    {
      type: "panel",
      title: "Code Repository",
      elements: [
        ...getGithubRepositoriesQuestion({
          teamType: options.teamType,
          applicationName: options.applicationName,
          canRequestAdditionalInfra: options.infrastructureInfo?.canRequestAdditionalInfra,
          requestedInfra: options.infrastructureInfo?.requestedInfra,
          repositories: options.infrastructureInfo?.githubRepositories,
        }),
        ...getGithubOrganisationQuestion(
          options.teamType,
          options.infrastructureInfo?.githubOrg,
        ),
        getCircleCiDetails({
          teamType: options.teamType,
          ciCdProvider: options.infrastructureInfo?.ciCdProvider,
          pipelines: options.infrastructureInfo?.pipelines,
          githubOrg: options.infrastructureInfo?.githubOrg,
        }),
      ],
    },
    {
      type: "panel",
      title: "Cloud Provider",
      elements: getCloudProjectsQuestion({
        canRequestAdditionalInfra: options.infrastructureInfo?.canRequestAdditionalInfra,
        applicationName: options.applicationName,
        requestedInfra: options.infrastructureInfo?.requestedInfra,
        gcpProjects: options.infrastructureInfo?.cloudAccount,
        isApplicationCreatedAfterProductionGCPDisablingDate: options.isApplicationCreatedAfterProductionGCPDisablingDate,
        isDisplayGCPBudgetAlertToggleEnabled: options.isDisplayGCPBudgetAlertToggleEnabled,
        shouldDisplayBudgetAlert: options.shouldDisplayBudgetAlert,
      }),
    },
    {
      type: "panel",
      title: "Single Sign-On",
      elements: getOktaPreviewQuestions({
        oktaChicletHelpDocumentLink: options.oktaChicletHelpDocumentLink,
        diSupportEmail: options.diSupportEmail,
        providedOktaChiclets: options.infrastructureInfo?.oktaChiclets,
        requestedOktaChiclets: options.infrastructureInfo?.requestedInfra?.oktaChiclets,
        showTaskDetailsPageUrl: options.infrastructureInfo?.requestedInfra?.showTaskDetailsPageUrl,
        canRequestAdditionalInfra: options.infrastructureInfo?.canRequestAdditionalInfra,
      }),
    },
  ]
  : [
    ...getGithubRepositoriesQuestion({
      teamType: options.teamType,
      applicationName: options.applicationName,
      canRequestAdditionalInfra: options.infrastructureInfo?.canRequestAdditionalInfra,
      requestedInfra: options.infrastructureInfo?.requestedInfra,
      repositories: options.infrastructureInfo?.githubRepositories,
    }),
    ...getGithubOrganisationQuestion(
      options.teamType,
      options.infrastructureInfo?.githubOrg,
    ),
    ...getCloudProjectsQuestion({
      canRequestAdditionalInfra: options.infrastructureInfo?.canRequestAdditionalInfra,
      applicationName: options.applicationName,
      requestedInfra: options.infrastructureInfo?.requestedInfra,
      gcpProjects: options.infrastructureInfo?.cloudAccount,
      isApplicationCreatedAfterProductionGCPDisablingDate: options.isApplicationCreatedAfterProductionGCPDisablingDate,
      isDisplayGCPBudgetAlertToggleEnabled: options.isDisplayGCPBudgetAlertToggleEnabled,
      shouldDisplayBudgetAlert: options.shouldDisplayBudgetAlert,
    }),
    getCircleCiDetails({
      teamType: options.teamType,
      ciCdProvider: options.infrastructureInfo?.ciCdProvider,
      pipelines: options.infrastructureInfo?.pipelines,
      githubOrg: options.infrastructureInfo?.githubOrg,
    }),
    getOktaDetailsWithoutRequestOktaPreviewQuestions(
      options.infrastructureInfo?.oktaChiclets,
    ),
  ];

export default getManageInfraQuestions;

import React, { FunctionComponent } from "react";
import ProjectClassification from "./project-classifications";
import "./index.scss";
import useProjectClassificationController from "./controller";
import ExperimentsAndInnovations from "./experiments-and-innovations";
import RegionalIT from "./regional-it";
import TechOps from "./techops";
import ContainerBox from "../../common/components/container-box";

const GetStarted: FunctionComponent = () => {
  const controller = useProjectClassificationController();

  function renderCurrentStep() {
    if (controller.shouldRenderProjectClassification()) {
      return (
        <ProjectClassification/>
      );
    }
    if (controller.shouldRenderExperimentsAndInnovations()) {
      return (
        <ExperimentsAndInnovations/>
      );
    }
    if (controller.shouldRenderRegionalIt()) {
      return (
        <RegionalIT/>
      );
    }
    if (controller.shouldRenderTechOps()) {
      return (
        <TechOps/>
      );
    }
    return null;
  }

  return (
    <div className="GetStarted">
      <ContainerBox>
        {renderCurrentStep()}
      </ContainerBox>
    </div>
  );
};

export default GetStarted;

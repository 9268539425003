import { DEVELOPER_QUESTION_NAME, getCreateTeamQuestions, MANAGER_QUESTION_NAME, NON_DEVELOPER_QUESTION_NAME, PROJECTS_NAME } from "./data/questions";
import { ICreateTeam, ITeamCreateForm, TeamCreationPageActions, TeamType } from "../../../pages/create-team/redux";
import { useDispatch } from "react-redux";
import { useSelectorTyped } from "../../redux/store";
import { getDropdownChoicesForMembers, getUserEmployeeIdFromUserEmail } from "../../../utils/teams/surveyjs-helper";
import { ReactElement, useEffect, useState } from "react";
import Analytics from "../../services/analytics";
import { extractFieldValues } from "../../../utils/extractor";
import { ITeam } from "../../../pages/team-details/redux";
import { getTeamTypeReadableLabel } from "../../../pages/teams/team-types/team-types-label";
import castTo, { identity } from "../../../utils/cast-to";
import { SurveyModel } from "survey-react";
import { iif } from "../../../utils/functions";
import { useSelectedNonTechOpsMembers } from "../../hooks/teams/use-selected-non-techops-members";
import { getCollaborationType } from "../../../utils/teams/functions";
import { OnValueChangedOptions } from "../forms";
import { useGetFeatureToggleState } from "../../hooks/feature-toggles";
import { IProject } from "../../redux/slices/projects";
import { Choice } from "../custom-form-controls/paginated-dropdown-control";
import ReactDOMServer from "react-dom/server";

export type CreateTeamProps = {
  onCancel: () => void;
  showLoggedInUserBePartOfTeamErrorWithinForm?: boolean;
  teamType: TeamType;
};

export function useCreateTeamFormController(props: CreateTeamProps) {
  const dispatch = useDispatch();
  const userAdditionalInfoPEV = useSelectorTyped((state) => state.header.userInfo.additionalInfo);
  const potentialMembersPEV = useSelectorTyped((state) => state.teamsPage.potentialMembersPEV);
  const createTeamPEV = useSelectorTyped((state) => state.teamCreationPage.createTeamPEV);
  const projectsPEV = useSelectorTyped((state) => state.teamCreationPage.projectsPEV);
  const loggedInUserEmailIdPEV = useSelectorTyped((state) => state.header.userInfo.email);
  const loggedInUserEmployeeId = getUserEmployeeIdFromUserEmail(loggedInUserEmailIdPEV.value, potentialMembersPEV.value);
  const teamListPEV = useSelectorTyped((state) => state.teamsPage.teamsListPEV);
  const [showCreateTeamConfirmationModal, setShowCreateTeamWithoutLoggedInUserNotMemberConfirmationModal] = useState(false);
  const [teamInformation, setTeamInformation] = useState(castTo<ICreateTeam>(null));
  const [showLoggedInUserNotPartOfTheTeamError, setShowLoggedInUserNotPartOfTheTeamError] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState(castTo<string[]>([]));
  const selectedNonTechOpsMembers = useSelectedNonTechOpsMembers(selectedMembers);
  const [formKey, setFormKey] = useState(0);
  const projectAssociationToggleEnabled = useGetFeatureToggleState("Neo_Teams_AssociateStaffingProjects");
  const [currentProjectIds, setCurrentProjectIds] = useState(castTo<string[]>([]));


  useEffect(() => {
    setFormKey(formKey + 1);
  }, [projectsPEV]);

  useEffect(() => {
    Analytics.trackPageCreateTeam();
  }, []);

  const isLoggedInUserPartOfTheTeam = (createTeamInformation: ITeamCreateForm) =>
    createTeamInformation.managers?.includes(loggedInUserEmployeeId)
    || createTeamInformation.developers?.includes(loggedInUserEmployeeId)
    || createTeamInformation["non-developers"]?.includes(loggedInUserEmployeeId) || false;

  const validateLoggedInUserPartOfTheTeam = (createTeamInformation: ITeamCreateForm) => {
    if (props.showLoggedInUserBePartOfTeamErrorWithinForm && !isLoggedInUserPartOfTheTeam(createTeamInformation)) {
      setShowLoggedInUserNotPartOfTheTeamError(true);
      return false;
    }
    return true;
  };

  const onSave = (createTeamInformation: ITeamCreateForm) => {
    const teamDetails = iif(function getTeamDetails() {
      const supportEmail = createTeamInformation.isSupportEmailSameAsContact ?
        createTeamInformation.contactEmail?.trim() : createTeamInformation.supportEmail?.trim();

      return identity<ICreateTeam>({
        name: createTeamInformation.name,
        description: createTeamInformation.description,
        contactEmail: `${createTeamInformation.contactEmail?.trim()}@thoughtworks.com`,
        supportEmail: `${supportEmail}@thoughtworks.com`,
        teamType: props.teamType,
        managers: createTeamInformation.managers,
        developers: createTeamInformation.developers ?? [],
        "non-developers": createTeamInformation["non-developers"] ?? [],
        projectIds: createTeamInformation.projectIds?.length ? createTeamInformation.projectIds : undefined,
        isOpen: createTeamInformation.isOpen,
      });
    });
    setTeamInformation(teamDetails);

    if (isLoggedInUserPartOfTheTeam(createTeamInformation)) {
      dispatch(TeamCreationPageActions.createTeam(teamDetails));
      Analytics.trackEventCreateTeam(getTeamTypeReadableLabel(props.teamType), getCollaborationType(createTeamInformation.isOpen));
    } else {
      setShowCreateTeamWithoutLoggedInUserNotMemberConfirmationModal(true);
    }
  };

  const onClickCreateInConfirmationModal = () => {
    dispatch(TeamCreationPageActions.createTeam(teamInformation));
    Analytics.trackEventCreateTeam(getTeamTypeReadableLabel(props.teamType), getCollaborationType(teamInformation.isOpen));
  };

  const onClickCancelInConfirmationModal = () => {
    setShowCreateTeamWithoutLoggedInUserNotMemberConfirmationModal(false);
  };

  const onHideConfirmationModal = () => {
    onClickCancelInConfirmationModal();
  };

  const getInitialFormData = () => identity<ITeamCreateForm>({
    name: "",
    description: "",
    contactEmail: "",
    supportEmail: "",
    teamType: props.teamType,
    isSupportEmailSameAsContact: ["Use the same as contact"],
    managers: [],
    developers: [],
    "non-developers": [],
    projectIds: [],
  });

  const shouldRenderForm = () => (!potentialMembersPEV.progress && !potentialMembersPEV.error)
    && (!userAdditionalInfoPEV.progress && !userAdditionalInfoPEV.error)
    && (!teamListPEV.progress && !teamListPEV.error)
    && (!loggedInUserEmailIdPEV.progress && !loggedInUserEmailIdPEV.error)
    && (!projectsPEV.progress && !projectsPEV.error);

  const shouldDifferentiateNonTechOpsMembers =
    props.teamType !== TeamType.EXPERIMENT_AND_INNOVATION;

  const questions = getCreateTeamQuestions({
    potentialMembers: getDropdownChoicesForMembers(potentialMembersPEV.value, shouldDifferentiateNonTechOpsMembers),
    teamNames: extractFieldValues<ITeam, string>("name", teamListPEV.value),
    teamType: props.teamType,
    projects: projectsPEV.value.map((project) => identity<Choice>({
      value: project.id,
      text: project.name,
    })),
    projectAssociationToggleEnabled,
  });

  const getProgressMsg = () => {
    const isApiCallInProgress = potentialMembersPEV.progress !== null
      || userAdditionalInfoPEV.progress != null
      || teamListPEV.progress !== null
      || loggedInUserEmailIdPEV.progress !== null
      || projectsPEV.progress !== null;
    return (isApiCallInProgress && !getErrorMsg()) ? "Fetching details" : null;
  };

  const getErrorMsg = () =>
    potentialMembersPEV.error
    || userAdditionalInfoPEV.error
    || teamListPEV.error
    || loggedInUserEmailIdPEV.error
    || projectsPEV.error;

  const shouldRenderErrorMsg = () => !!getErrorMsg();

  const isTeamCreationInProgress = () => !!createTeamPEV.progress;

  const getCreateButtonLabel = () => isTeamCreationInProgress() ? "Creating" : "Create";

  const getEmployeeById = (employeeId: EmployeeId) =>
    potentialMembersPEV.value.find(
      (potentialMember) => potentialMember.employeeId === employeeId,
    );

  const updateTheUIToShowTheSelectedMembers = (elementId: string, selectedMembersInForm: string[]) => {
    const option: HTMLOptionElement[] = [];
    selectedMembersInForm?.map((member) => {
      const selectedMemberComponentWithTechOpsFlag = castTo<ReactElement>(getDropdownChoicesForMembers(
        potentialMembersPEV.value.filter((potentialMember) => potentialMember.employeeId === member),
        shouldDifferentiateNonTechOpsMembers,
      )[0].component);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const titleWithoutTechOpsFlag = selectedMemberComponentWithTechOpsFlag.props.children[0] as string;
      const optionWithCorrectTitle = new Option(
        ReactDOMServer.renderToString(selectedMemberComponentWithTechOpsFlag),
        member,
        true,
        true,
      );
      optionWithCorrectTitle.title = titleWithoutTechOpsFlag;
      option.push(optionWithCorrectTitle);
    });
    $(`#${elementId} select`).empty().append(option).trigger("change");
  };

  return ({
    questions,
    onSave,
    shouldDifferentiateNonTechOpsMembers,
    selectedNonTechOpsMembers,
    showLoggedInUserNotPartOfTheTeamError,
    onClickCreateInConfirmationModal,
    showCreateTeamConfirmationModal,
    onClickCancelInConfirmationModal,
    getInitialFormData,
    shouldRenderForm,
    getProgressMsg,
    getErrorMsg,
    shouldRenderErrorMsg,
    getCreateButtonLabel,
    isTeamCreationInProgress,
    onCancel: props.onCancel,
    onHideConfirmationModal,
    handleFormValueChangedEvent: (form: SurveyModel, options: OnValueChangedOptions) => {
      const isMemberFieldsValueUpdated = () => options.name === MANAGER_QUESTION_NAME
        || options.name === DEVELOPER_QUESTION_NAME
        || options.name === NON_DEVELOPER_QUESTION_NAME;
      if (isMemberFieldsValueUpdated()) {
        const formData = castTo<ITeamCreateForm>(form.data);
        if (showLoggedInUserNotPartOfTheTeamError && isLoggedInUserPartOfTheTeam(formData)) {
          setShowLoggedInUserNotPartOfTheTeamError(false);
        }
        const members = [
          ...(formData.managers ?? []),
          ...(formData.developers ?? []),
          ...(formData["non-developers"] ?? []),
        ];
        setSelectedMembers(members);
        if (options.name === "developers") {
          updateTheUIToShowTheSelectedMembers(options.question.id, form.getValue("developers"));
        }
        if (options.name === "non-developers") {
          updateTheUIToShowTheSelectedMembers(options.question.id, form.getValue("non-developers"));
        }
      }
      const isProjectsFieldUpdated = () => options.name === PROJECTS_NAME;
      if (isProjectsFieldUpdated()) {
        const formData = castTo<ITeamCreateForm>(form.data);
        const newProjectIds = formData.projectIds ?? [];
        const getProjectById = (projectId: IProject["id"]) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          projectsPEV.value.find((project) => project.id === projectId)!;
        const getAdditionalProject = (projectIds1: string[], projectIds2: string[]) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const additionalProjectId = projectIds1.find((projectId) => !projectIds2.includes(projectId))!;
          return getProjectById(additionalProjectId);
        };
        if (currentProjectIds.length > newProjectIds.length) {
          iif(function processProjectRemoved() {
            const removedProject = getAdditionalProject(currentProjectIds, newProjectIds);
            const otherProjects = newProjectIds.map(getProjectById);
            let membersAssociatedToOtherProjects: EmployeeId[] = [];
            otherProjects.forEach((project) => {
              membersAssociatedToOtherProjects = [...membersAssociatedToOtherProjects, ...project.employeeIds];
            });
            const membersToBeRemoved = removedProject.employeeIds.filter((employeeId) => !membersAssociatedToOtherProjects.includes(employeeId));
            const pruneMembers = (existingMembers: EmployeeId[]) =>
              existingMembers?.filter((employeeId) => !membersToBeRemoved.includes(employeeId)) ?? [];
            form.setValue("managers", pruneMembers(formData.managers));
            form.setValue("developers", pruneMembers(formData.developers));
            form.setValue("non-developers", pruneMembers(formData["non-developers"]));
          });
        } else {
          iif(function processProjectAdded() {
            const processedEmployeeIds: EmployeeId[] = [
              ...(formData.developers ?? []),
              ...(formData["non-developers"] ?? []),
              ...(formData.managers ?? []),
            ];
            const developerEmployeeIds: string[] = [];
            const nonDeveloperEmployeeIds: string[] = [];
            getAdditionalProject(newProjectIds, currentProjectIds).employeeIds.forEach((employeeId) => {
              if (!processedEmployeeIds.includes(employeeId)) {
                const employee = getEmployeeById(employeeId);
                if (employee) {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  if (employee.thoughtworksRole!.toLocaleLowerCase().includes("developer")) {
                    developerEmployeeIds.push(employeeId);
                  } else {
                    nonDeveloperEmployeeIds.push(employeeId);
                  }
                }
                processedEmployeeIds.push(employeeId);
              }
            });
            form.setValue("developers", [
              ...formData.developers ?? [],
              ...developerEmployeeIds,
            ]);
            form.setValue("non-developers", [
              ...formData["non-developers"] ?? [],
              ...nonDeveloperEmployeeIds,
            ]);
          });
        }
        setCurrentProjectIds(newProjectIds);
      }
    },
    validateLoggedInUserPartOfTheTeam,
    shouldRenderNonTechOpsMembersAlert: () => shouldDifferentiateNonTechOpsMembers && (selectedNonTechOpsMembers.length > 0),
    NonTechOpsMembersAlertMessage: "Review your choices before you create the team!",
    formKey,
  });
}

import { ApplicationBasicInfo } from "../pages/applications/application-details/redux";
import { convertSpaceToUnderscore, removeSpaces } from "./string";
import { ITeam } from "../pages/team-details/redux";
import { AssociatedEntityType, IRequest } from "../pages/requests/types/requests";

export const buildApplicationDetailsPageLink = (application: Pick<ApplicationBasicInfo, "id" | "name">) =>
  encodeURI(`/catalogue/${application.id}/${convertSpaceToUnderscore(application.name)}`);

export const buildTeamDetailsPageLink = (team: Pick<ITeam, "id" | "name">) =>
  encodeURI(`/teams/${team.id}/${convertSpaceToUnderscore(team.name)}`);

export const buildClientDetailsPageLink = (client: { id: string; name: string }) =>
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  encodeURI(`/api-gateway/api-clients/${client.id}/${removeSpaces(client.name)}`);

export const buildApiDetailsPageLink = (client: { id: string; name: string }) =>
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  encodeURI(`/api-gateway/apis/name/${removeSpaces(client.name)}`);

export const buildAssociatedEntityLink = (associatedEntity: IRequest["associatedEntity"]) => {
  const associatedEntityLinkBuilderMap: Record<AssociatedEntityType, () => string> = {
    [AssociatedEntityType.TEAM]: () => buildTeamDetailsPageLink(associatedEntity),
    [AssociatedEntityType.APPLICATION]: () => buildApplicationDetailsPageLink(associatedEntity),
    [AssociatedEntityType.CLIENT]: () => buildClientDetailsPageLink(associatedEntity),
    [AssociatedEntityType.API]: () => buildApiDetailsPageLink(associatedEntity),
  };
  return associatedEntityLinkBuilderMap[associatedEntity.type]();
};

import { CataloguePages } from "./data/pages";
import { identity } from "../../utils/cast-to";
import { MenuItemProps } from "../../common/components/sub-header/controller";

export default function useCatalogueController() {
  return ({
    getMenuItems: () => identity<MenuItemProps[]>([
      {
        id: "Applications",
        title: CataloguePages.APPLICATIONS.title,
        route: CataloguePages.APPLICATIONS.path,
      },
      {
        id: "My Apps & Services",
        title: CataloguePages.MY_APPS_AND_SERVICES.title,
        route: CataloguePages.MY_APPS_AND_SERVICES.path,
      },
      {
        id: "Developer Tools",
        title: CataloguePages.DEVELOPER_TOOLS.title,
        route: CataloguePages.DEVELOPER_TOOLS.path,
        highlightTabsForAdditionalRoutes: [
          CataloguePages.DEVELOPER_TOOL_DETAILS.path,
        ],
      },
    ]),
  });
}


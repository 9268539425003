import { callBffApi } from "../../../common/services/axios";
import { ApplicationBasicInfo } from "../../applications/application-details/redux";

type ApplicationsResponse = {
  data: ApplicationBasicInfo[];
};

export const getAllApplications = async (): Promise<ApplicationBasicInfo[]> => {
  const response = await callBffApi<ApplicationsResponse>("get", "/applications");
  return response.data;
};

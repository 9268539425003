import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelectorTyped } from "../../../common/redux/store";
import { RequestsPageActions } from "../redux";
import Analytics from "../../../common/services/analytics";
import { useHistory } from "react-router-dom";
import { IRequest } from "../types/requests";

export type IMyTasksController = ReturnType<typeof useMyTasksController>;

export default function useMyTasksController() {
  const assignedRequestsPEV = useSelectorTyped(((state) => state.requestsPage.assignedRequestsPEV));
  const assignedRequests = assignedRequestsPEV.value;
  const fetchAssignedRequestsError = assignedRequestsPEV.error;
  const fetchAssignedRequestsProgress = assignedRequestsPEV.progress;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    Analytics.trackPageMyTasks();
    dispatch(RequestsPageActions.fetchAssignedRequests());
  }, []);

  function shouldRenderNoRequestsMessage(): boolean {
    return (!fetchAssignedRequestsError) && (!fetchAssignedRequestsProgress) && (!userHasApplications());
  }

  function shouldRenderError(): boolean {
    return !!fetchAssignedRequestsError;
  }

  function userHasApplications(): boolean {
    return (assignedRequests.length > 0);
  }

  function onClickRetryButton() {
    dispatch(RequestsPageActions.fetchAssignedRequests());
  }

  function shouldRenderProgressMessage() {
    return fetchAssignedRequestsProgress !== null && assignedRequests.length === 0;
  }

  const onTableRowClickedHandler = (row: IRequest, event: MouseEvent) => {
    if (event.metaKey || event.ctrlKey) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(`/requests/${row.id}/tasks`, "_blank");
    } else {
      history.push(`/requests/${row.id}/tasks`);
    }
  };

  return ({
    assignedRequests,
    shouldRenderNoRequestsMessage,
    fetchAssignedRequestsProgress,
    fetchAssignedRequestsError,
    shouldRenderError,
    onClickRetryButton,
    shouldRenderProgressMessage,
    onTableRowClickedHandler,
  });
}

import { useDispatch } from "react-redux";
import { useSelectorTyped } from "../../../../common/redux/store";
import { ChangeEvent } from "react";
import Analytics from "../../../../common/services/analytics";
import { ApplicationsCataloguePageActions } from "../redux";

export function useApplicationSearchController() {
  const dispatch = useDispatch();
  const { searchText } = useSelectorTyped((state) => state.applicationsCataloguePage.filters);
  const activeApplicationStatuses = useSelectorTyped((state) => state.header.uiConfigsPEV.value.activeApplicationStatuses);

  return ({
    searchText,
    onChangeSearchText: (event: ChangeEvent<HTMLInputElement>) => {
      Analytics.trackEventSearchApplicationsFromApplicationsPage();
      dispatch(ApplicationsCataloguePageActions.applySearch(event.target.value, activeApplicationStatuses));
    },
    onClickClearSearchText: () => {
      dispatch(ApplicationsCataloguePageActions.applySearch("", activeApplicationStatuses));
    },
  });
}

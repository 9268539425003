import { ApplicationBasicInfo } from "../../../pages/applications/application-details/redux";
import { useHistory } from "react-router-dom";
import { convertSpaceToUnderscore } from "../../../utils/string";

type Props = {
  application: ApplicationBasicInfo;
  onClick: () => void;
};

export default function useApplicationCardController(props: Props) {
  const history = useHistory();

  return {
    ...props,
    currentPathname: history.location.pathname,
    redirectionPathname: `/catalogue/${props.application.id}/${convertSpaceToUnderscore(props.application.name)}`,
  };
}

import React, { FunctionComponent } from "react";
import "./index.scss";
import PageTitle from "../../../../common/components/page-title";
import useBasicInformationController from "./controller";
import Progress from "../../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../../common/components/error-new";
import NavigationButtons from "../../navigation-buttons";
import FormComponent, { INavigationButtonsProps } from "../../../../common/components/forms";
import StepBar from "../../../../common/components/step-bar";
import TeamLink from "../../../../common/components/team-link";


const BasicInformation: FunctionComponent = () => {
  const controller = useBasicInformationController();

  function renderNavigationButtons({ doesFormHasErrors, getFormData }: INavigationButtonsProps) {
    return (
      <NavigationButtons
        doesFormHasErrors={doesFormHasErrors}
        getFormData={getFormData}
        shouldGoToNextStep={controller.shouldGoToNextStep}
        shouldGoToPreviousStep={controller.shouldGoToPreviousStep}
      />
    );
  }

  const renderForm = () => {
    if (controller.shouldRenderForm()) {
      return (
        <FormComponent
          questions={controller.questions}
          data={controller.getBasicInfoDataForForm()}
          renderNavigationButtons={renderNavigationButtons}
          handleOnAfterRenderQuestion={controller.onAfterQuestionRendered}
        />
      );
    }
    return null;
  };

  const renderStepBar = () => (
    <StepBar
      stepLabels={controller.getStepLabelsForPage()}
      currentCompletedStep={2}
    />
  );

  const renderPageTitle = () => (
    <PageTitle
      title="TechOps"
      subText={
        <TeamLink
          id={controller.teamDetails.teamId}
          name={controller.teamDetails.teamName}
        />}
      subtitle="Application Information"
      displayTitleLighter={true}
    />
  );

  const renderError = () => {
    if (controller.shouldRenderError()) {
      return (
        <Error
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          {...mapApplicationErrorInfoToErrorComponentProps(controller.getErrorMsg()!)}
        />
      );
    }
  };

  return (
    <div className="BasicInformation">
      {renderStepBar()}
      {renderPageTitle()}
      {renderForm()}
      <Progress text={controller.getProgressMsg()}/>
      {renderError()}
    </div>
  );
};

export default BasicInformation;

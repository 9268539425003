import { ITeam } from "../../../team-details/redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { GetStartedPageActions } from "../../redux";
import { useSelectorTyped } from "../../../../common/redux/store";
import { TeamCreationPageActions } from "../../../create-team/redux";
import { RegionalITCategoryActions } from "../redux";

export default function useTeamInformationController() {
  const dispatch = useDispatch();
  const teamListPEV = useSelectorTyped((state) => state.getStartedPage.teamListForRegionalITPEV);
  const { value: teamList, error: teamListError, progress: teamListProgress } = teamListPEV;

  useEffect(() => {
    dispatch(GetStartedPageActions.fetchMyActiveTeamsForRegionalIT());
  }, []);

  const rowClickHandler = (row: ITeam) => {
    dispatch(GetStartedPageActions.updateCurrentStep("BASIC_INFO"));
    dispatch(RegionalITCategoryActions.updateBasicInfo({
      teamId: row.id,
      teamName: row.name,
    }));
  };

  const shouldRenderUserNotAssociatedToTeamMessage = () => teamList.length === 0;

  const shouldRenderProgressMessage = () => teamListProgress !== null && teamList.length === 0;

  const shouldRenderErrorMessage = () => !!teamListError;

  const onPrevious = () => {
    dispatch(GetStartedPageActions.updateCurrentStep("PROJECT_CLASSIFICATION"));
    dispatch(TeamCreationPageActions.resetState());
  };

  const getTeamList = () => teamList ?? [];

  return {
    getTeamList,
    rowClickHandler,
    shouldRenderUserNotAssociatedToTeamMessage,
    teamListError,
    teamListProgress,
    shouldRenderProgressMessage,
    shouldRenderErrorMessage,
    onPrevious,
  };
}

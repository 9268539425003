import React from "react";
import { Route, Switch } from "react-router-dom";
import { Pages } from "./pages";
import Header from "../header";
import useAppController from "./controller";
import { MicroFrontend } from "../../../pages/micro-frontend";
import "./index.scss";
import { Footer } from "../footer";
import FeedbackButton from "../feedback-button";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import Qualaroo from "../qualaroo";
import Progress from "../progress";

const App = createFunctionalComponentFromView(useAppController, (controller) => {
  window.env = controller.env;

  function renderMffRoutes() {
    return controller.microFrontends.map((microFrontend) => {
      const path = microFrontend.getHeaderMenus()?.flatMap(
        (menu) => menu.url
          || menu.dropdownItems?.map((menuItems) => menuItems.url)
          || [],
      ).filter((url) => !!url) || [];
      if (!path || path.length === 0) {
        return null;
      }
      return (
        <Route
          key={microFrontend.id}
          path={path}
          render={() => <MicroFrontend microFrontedData={microFrontend}/>}
        />
      );
    });
  }

  function renderInfoPage() {
    return (
      <div className="sandbox-hidden-page-info">
        <div className="bold-text">We can&#39;t seem to find the page you are looking for.</div>
        <div className="info">Access NEO production for uninterrupted service.</div>
      </div>
    );
  }

  function renderFeedbackButton() {
    return (<FeedbackButton/>);
  }

  function renderRoutes() {
    return (
      <Switch>
        <Route
          path={Pages.PROJECT_CATEGORIES.path}
          component={Pages.PROJECT_CATEGORIES.component}
        />
        <Route
          path={Pages.INFRA_OFFERINGS.path}
          component={Pages.INFRA_OFFERINGS.component}
        />
        <Route
          path={Pages.CATALOGUE.path}
          component={Pages.CATALOGUE.component}
        />
        <Route
          path={Pages.EVENTS.path}
          component={Pages.EVENTS.component}
        />
        <Route
          path={Pages.TEAM_DETAILS.path}
          component={Pages.TEAM_DETAILS.component}
        />
        <Route
          path={Pages.TEAMS.path}
          component={Pages.TEAMS.component}
        />
        <Route
          path={Pages.CREATE_TEAM.path}
          component={Pages.CREATE_TEAM.component}
        />
        <Route
          path={Pages.REQUESTS_PAGE.path}
          component={Pages.REQUESTS_PAGE.component}
        />
        {renderMffRoutes()}
        <Route
          path={Pages.HOME_PAGE.path}
          component={Pages.HOME_PAGE.component}
        />
      </Switch>
    );
  }

  function renderRoutesForSandbox() {
    return (
      <Switch>
        <Route
          path={Pages.TEAM_DETAILS.path}
          component={Pages.TEAM_DETAILS.component}
        />
        <Route
          path={Pages.TEAMS.path}
          component={Pages.TEAMS.component}
        />
        <Route
          path={Pages.CREATE_TEAM.path}
          component={Pages.CREATE_TEAM.component}
        />
        <Route
          path={Pages.REQUESTS_PAGE.path}
          component={Pages.REQUESTS_PAGE.component}
        />
        {renderMffRoutes()}
        <Route
          path={"/api-gateway/*"}
          exact
        >
          <Progress text={"Loading"}/>
        </Route>
        <Route>
          {renderInfoPage()}
        </Route>
      </Switch>
    );
  }

  return (
    <div
      className="App"
      style={{ background: controller.isApiUnificationToggleEnabled ? controller.environmentConfig.backgroundColor : "#FFFFFF" }}
    >
      <Header/>
      {(controller.isApiUnificationToggleEnabled && controller.environmentConfig.isSandbox && controller.isDisableRoutesToggleEnabled)
        ? renderRoutesForSandbox()
        : renderRoutes()}
      <Qualaroo/>
      <Footer/>
      {!(controller.isApiUnificationToggleEnabled && controller.environmentConfig.isSandbox) && renderFeedbackButton()}
    </div>
  );
});

export default App;

import { callBffEventsAPI } from "../../../common/services/axios";
import { IEventDetails } from "./redux";

interface EventDetailsResponse {
  eventDetails: IEventDetails;
}

const fetchEventDetails = async (namespaceName: string, eventName: string): Promise<IEventDetails> => {
  const eventDetailsResponse = await callBffEventsAPI<EventDetailsResponse>("get", `/namespaces/${namespaceName}/event-registrations/${eventName}`);
  return eventDetailsResponse.eventDetails;
};

export const EventDetailsPageService = {
  fetchEventDetails,
};

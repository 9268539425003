import React, { FunctionComponent, ReactNode } from "react";
import "./index.scss";

type Props = {
  label?: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  children?: ReactNode;
};

const TextButton: FunctionComponent<Props> = (props: Props) => (
  <button
    className={`text-button ${props.className || ""}`}
    onClick={props.onClick}
    disabled={props.disabled}
    type="button"
    ix-styles={"outline"}
  >
    {props.label ?? props.children}
  </button>
);

export default TextButton;

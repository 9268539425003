import { useSelectorTyped } from "../../../common/redux/store";
import castTo from "../../../utils/cast-to";
import { ActivityHistory } from "../../team-details/redux";
import {
  IRequestEvent,
  RequestClosedEventPayload,
  RequestEventTypeLabelMap,
  RequestInProgressEventPayload,
  RequestRaisedEventPayload,
  TaskApprovedEventPayload,
  TaskCompletedEventPayload,
  TaskCreatedEventPayload,
  TaskDisapprovedEventPayload,
  TaskFailedEventPayload, TaskReTriggeredEventPayload,
} from "../redux";
import { IRequest, ITask } from "../types/requests";
import { ACTIVITY_HISTORY_TIME_FORMAT, formatDate } from "../../../utils/date-format";

export default function useRequestActivitiesController(props: { requestDetails: IRequest }) {
  const { requestEventsPEV } = useSelectorTyped((state) => state.requestsPage);
  const { labelMaps } = useSelectorTyped((state) => state.header.uiConfigsPEV.value);
  const { taskTypeLabelMap } = labelMaps;

  const getLatestUpdatedTask = (tasks: ITask[]) => [...tasks].sort((task1, task2) =>
    new Date(task2.updatedDateTime).getTime() - new Date(task1.updatedDateTime).getTime(),
  )[0];

  const buildRequestActivityHistory = (events: IRequestEvent[]) => events.reduce((result: ActivityHistory[], event) => {
    if (event.type === RequestEventTypeLabelMap.REQUEST_RAISED) {
      const payload = castTo<RequestRaisedEventPayload>(event.payload);
      result.push(
        {
          action: "Request has been raised",
          eventType: event.type,
          occurredAt: event.createdDateTime,
          performedBy: payload.request.requester.employeeName,
        },
      );
    } else if (event.type === RequestEventTypeLabelMap.REQUEST_IN_PROGRESS) {
      const payload = castTo<RequestInProgressEventPayload>(event.payload);
      result.push(
        {
          action: "Request is in progress",
          eventType: event.type,
          occurredAt: event.createdDateTime,
          performedBy: getLatestUpdatedTask(payload.request.tasks)?.taskOwner?.employeeName ?? "System",
        },
      );
    } else if (event.type === RequestEventTypeLabelMap.REQUEST_CLOSED) {
      const payload = (event.payload as RequestClosedEventPayload);
      result.push(
        {
          action: "Request has been closed",
          eventType: event.type,
          occurredAt: event.createdDateTime,
          performedBy: getLatestUpdatedTask(payload.request.tasks)?.taskOwner?.employeeName ?? "System",
        },
      );
    } else if (event.type === RequestEventTypeLabelMap.TASK_CREATED) {
      const { task } = castTo<TaskCreatedEventPayload>(event.payload);
      result.push(
        {
          action: `Task "${taskTypeLabelMap[task.taskType]} (${task.id})" created and assigned to "${task.taskOwner.teamName}" team`,
          eventType: event.type,
          occurredAt: event.createdDateTime,
          performedBy: "System",
        },
      );
    } else if (event.type === RequestEventTypeLabelMap.TASK_APPROVED) {
      const { task } = castTo<TaskApprovedEventPayload>(event.payload);
      result.push(
        {
          action: `Task "${taskTypeLabelMap[task.taskType]} (${task.id})" has been approved`,
          eventType: event.type,
          occurredAt: event.createdDateTime,
          performedBy: task.taskOwner.employeeName ?? "System",
        },
      );
    } else if (event.type === RequestEventTypeLabelMap.TASK_DISAPPROVED) {
      const { task } = castTo<TaskDisapprovedEventPayload>(event.payload);
      result.push(
        {
          action: `Task "${taskTypeLabelMap[task.taskType]} (${task.id})" has been disapproved`,
          eventType: event.type,
          occurredAt: event.createdDateTime,
          performedBy: task.taskOwner.employeeName,
        },
      );
    } else if (event.type === RequestEventTypeLabelMap.TASK_COMPLETED) {
      const { task } = castTo<TaskCompletedEventPayload>(event.payload);
      result.push(
        {
          action: `Task "${taskTypeLabelMap[task.taskType]} (${task.id})" has been completed`,
          eventType: event.type,
          occurredAt: event.createdDateTime,
          performedBy: task.hasAutomation
            ? "System"
            : task.taskOwner.employeeName,
        },
      );
    } else if (event.type === RequestEventTypeLabelMap.TASK_FAILED) {
      const { task } = castTo<TaskFailedEventPayload>(event.payload);
      result.push(
        {
          action: `Task "${taskTypeLabelMap[task.taskType]} (${task.id})" has been failed`,
          eventType: event.type,
          occurredAt: event.createdDateTime,
          performedBy: "System",
        },
      );
    } else if (event.type === RequestEventTypeLabelMap.TASK_RETRIGGERED) {
      const { task } = castTo<TaskReTriggeredEventPayload>(event.payload);
      result.push(
        {
          action: `Task "${taskTypeLabelMap[task.taskType]} (${task.id})" has been re-triggered`,
          eventType: event.type,
          occurredAt: event.createdDateTime,
          performedBy: task.taskOwner.employeeName,
        },
      );
    }
    return result;
  }, []);
  let activities: ActivityHistory[];
  if (requestEventsPEV.value) {
    activities = buildRequestActivityHistory(requestEventsPEV.value).sort((row1, row2) =>
      new Date(row2.occurredAt).getTime() - new Date(row1.occurredAt).getTime(),
    );
  }
  const getActivities = () => activities;

  const shouldRenderProgressMessage = () => requestEventsPEV.progress !== null;

  const shouldRenderErrorMessage = () => !!requestEventsPEV.error;

  const isApplicationListEmpty = () => requestEventsPEV.value?.length === 0;

  const shouldRenderNoActivitiesMessage = () => isApplicationListEmpty();

  const getExportCSVData = () =>
    getActivities().map((activityHistory) => ({
      Date: formatDate(activityHistory.occurredAt, ACTIVITY_HISTORY_TIME_FORMAT),
      Description: activityHistory.action,
      User: activityHistory.performedBy ? activityHistory.performedBy : "",
    }));

  const getPaginationRowsPerPageOptions = () => {
    const rowsPerPage: number[] = [];
    let init = 10;
    const maxRow = getActivities().length;
    let multiple = 1;
    while (init < maxRow) {
      rowsPerPage.push(init);
      init = 25 * multiple++;
    }
    rowsPerPage.push(maxRow);
    return rowsPerPage;
  };

  return ({
    getActivities,
    shouldRenderProgressMessage,
    shouldRenderErrorMessage,
    shouldRenderNoActivitiesMessage,
    progressMessage: requestEventsPEV.progress,
    errorMessage: requestEventsPEV.error,
    getCSVFileName: () =>
      `Request_Activity_History_${props.requestDetails.associatedEntity.name}`,
    getExportCSVData,
    getPaginationRowsPerPageOptions,
    shouldRenderExportCSVLink: () => !isApplicationListEmpty(),
  });
}

import React, { FunctionComponent } from "react";
import { Col, Row } from "react-bootstrap";
import TeamTypesInfo from "./team-types-info";
import ExperimentAndInnovationInfo from "../../../common/components/project-classification-contents/experiment-innovation-info";
import { TeamType } from "../../create-team/redux";
import RegionalITInfo from "../../../common/components/project-classification-contents/regional-it-info";
import TechOpsInfo from "../../../common/components/project-classification-contents/techops-info";

const TeamTypesButtons: FunctionComponent = () => (
  <Row>
    <Col>
      <TeamTypesInfo
        heading="Experiment and Innovation"
        content={<ExperimentAndInnovationInfo hideApplicationSpecificInfo={true}/>}
        teamType={TeamType.EXPERIMENT_AND_INNOVATION}
        id="experiment-and-innovation-btn"
      />
    </Col>
    <Col>
      <TeamTypesInfo
        heading="Regional IT"
        content={<RegionalITInfo hideApplicationSpecificInfo={true}/>}
        teamType={TeamType.REGIONAL_IT}
        id="regional-it-btn"
      />
    </Col>
    <Col>
      <TeamTypesInfo
        heading="TechOps"
        content={<TechOpsInfo hideApplicationSpecificInfo={true}/>}
        teamType={TeamType.TECHOPS}
        id="techops-info-btn"
      />
    </Col>
  </Row>
);

export default TeamTypesButtons;

import React, { FunctionComponent } from "react";
import "./index.scss";

type Props = {
  value: string;
  shouldDifferentiateNonTechOpsMembers: boolean;
};

export const HighlightValueAsNonTechOps: FunctionComponent<Props> = (props: Props) => {
  const { value, shouldDifferentiateNonTechOpsMembers } = props;

  const renderHighlightNonTechOpsMember = () => (
    shouldDifferentiateNonTechOpsMembers
    && <span className={"non-techops-member-highlight"}>**</span>
  );

  return (
    <span className={"PotentialMemberTagBoxOption"}>
      {value}{renderHighlightNonTechOpsMember()}
    </span>
  );
};

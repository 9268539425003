import React, { FunctionComponent, ReactNode } from "react";
import HeaderColumnElement from "./header-column-element";

export enum OfferAvailabilityStatus {
  AVAILABLE,
  NOT_AVAILABLE
}

enum Columns {
  VERSION_CONTROL_SYSTEM,
  CLOUD_PROVIDERS_GCP,
  CI_CD_TOOL,
  SINGLE_SIGN_ON,
  MONITORING_TOOL,
  ANALYTICS_TOOL
}

export type OfferAvailabilities = Array<{
  available: OfferAvailabilityStatus;
  text?: string;
}>;

interface Props {
  headerColumnText: string;
  tooltipContent: ReactNode;
  offerAvailabilities: OfferAvailabilities;
}

const getSubTextStyle = (column: Columns, hasSubText: boolean) => {
  if (column === Columns.CLOUD_PROVIDERS_GCP && hasSubText) {
    return "split-cell-bottom split-cell-right";
  }
  return "split-cell-bottom";
};

const renderSubText = (offerAvailabilities: OfferAvailabilities) => offerAvailabilities.map((offerAvailability, idx) => (offerAvailability.text
  ? (
    <td
      key={offerAvailability.text}
      className={getSubTextStyle(idx, !!offerAvailability.text)}
    >
      {offerAvailability.text}
    </td>
  )
  : null));


const Row: FunctionComponent<Props> = (props: Props) => {
  const renderOfferAvailability = (isAvailable: OfferAvailabilityStatus) => (isAvailable === OfferAvailabilityStatus.AVAILABLE
    ? <i className="ri-check-line offering-available"/>
    : <i className="ri-close-line offering-not-available"/>);

  const getRowSpan = (hasText: boolean) => (
    hasText ? 1 : 2
  );

  const getStyle = (column: Columns, hasSubText: boolean) => {
    if (column === Columns.CLOUD_PROVIDERS_GCP) {
      return hasSubText ? "split-cell-top split-cell-right" : "no-split-cell split-cell-right";
    }
    if (column === Columns.VERSION_CONTROL_SYSTEM || column === Columns.CI_CD_TOOL) {
      return "split-cell-top";
    }
    return "no-split-cell";
  };


  return (
    <>
      <tr>
        <HeaderColumnElement
          headerColumnText={props.headerColumnText}
          toolTipContent={props.tooltipContent}
        />
        <td
          className={getStyle(Columns.VERSION_CONTROL_SYSTEM, !!props.offerAvailabilities[Columns.VERSION_CONTROL_SYSTEM].text)}
          rowSpan={getRowSpan(!!props.offerAvailabilities[Columns.VERSION_CONTROL_SYSTEM].text)}
        >
          {renderOfferAvailability(props.offerAvailabilities[Columns.VERSION_CONTROL_SYSTEM].available)}
        </td>
        <td
          className={getStyle(Columns.CLOUD_PROVIDERS_GCP, !!props.offerAvailabilities[Columns.CLOUD_PROVIDERS_GCP].text)}
          rowSpan={getRowSpan(!!props.offerAvailabilities[Columns.CLOUD_PROVIDERS_GCP].text)}
        >
          {renderOfferAvailability(props.offerAvailabilities[Columns.CLOUD_PROVIDERS_GCP].available)}
        </td>
        <td
          className={getStyle(Columns.CI_CD_TOOL, !!props.offerAvailabilities[Columns.CI_CD_TOOL].text)}
          rowSpan={getRowSpan(!!props.offerAvailabilities[Columns.CI_CD_TOOL].text)}
        >
          {renderOfferAvailability(props.offerAvailabilities[Columns.CI_CD_TOOL].available)}
        </td>
        <td
          className={getStyle(Columns.SINGLE_SIGN_ON, !!props.offerAvailabilities[Columns.SINGLE_SIGN_ON].text)}
          rowSpan={getRowSpan(!!props.offerAvailabilities[Columns.SINGLE_SIGN_ON].text)}
        >
          {renderOfferAvailability(props.offerAvailabilities[Columns.SINGLE_SIGN_ON].available)}
        </td>
        <td
          className={getStyle(Columns.MONITORING_TOOL, !!props.offerAvailabilities[Columns.MONITORING_TOOL].text)}
          rowSpan={getRowSpan(!!props.offerAvailabilities[Columns.MONITORING_TOOL].text)}
        >
          {renderOfferAvailability(props.offerAvailabilities[Columns.MONITORING_TOOL].available)}
        </td>
        <td
          className={getStyle(Columns.ANALYTICS_TOOL, !!props.offerAvailabilities[Columns.ANALYTICS_TOOL].text)}
          rowSpan={getRowSpan(!!props.offerAvailabilities[Columns.ANALYTICS_TOOL].text)}
        >
          {renderOfferAvailability(props.offerAvailabilities[Columns.ANALYTICS_TOOL].available)}
        </td>
      </tr>
      <tr>
        {renderSubText(props.offerAvailabilities)}
      </tr>
    </>
  );
};

export default Row;

import React from "react";
import ContainerBox from "../../../common/components/container-box";
import Progress from "../../../common/components/progress";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../common/components/error-new";
import useMyRequestsController, { IMyRequestsController } from "./controller";
import "./index.scss";
import RequestsTable from "../requests-table";
import { createFunctionalComponentFromView } from "../../../utils/view-controller";
import NoRequestsAvailable from "../asserts/image/NoRequestsAvailable.png";

const MyRequests = createFunctionalComponentFromView<IMyRequestsController>(
  useMyRequestsController,
  (controller) => {
    const renderContent = () => {
      if (controller.shouldRenderProgressMessage()) {
        return <Progress text={controller.fetchMyRequestsProgress}/>;
      }
      if (controller.shouldRenderError()) {
        return (
          <Error
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            {...mapApplicationErrorInfoToErrorComponentProps(controller.fetchMyRequestsError!)}
            onClick={controller.onClickRetryButton}
          />);
      }
      if (controller.shouldRenderNoRequestsMessage()) {
        return renderNoRequestsMessage();
      }

      return renderRequests();
    };

    const renderRequests = () => (
      <RequestsTable
        requests={controller.myRequests}
        onRowClickedHandler={controller.onTableRowClickedHandler}
      />
    );

    const renderNoRequestsMessage = () => (
      <div className="no-requests-available">
        <img
          alt="no-requests-available"
          src={NoRequestsAvailable}
        />
        <div>It appears there are no requests from your team(s).</div>
      </div>
    );

    return (
      <div className="MyRequests">
        <ContainerBox>
          <div className="requests-container">
            {renderContent()}
          </div>
        </ContainerBox>
      </div>
    );
  });

export default MyRequests;

import { useDispatch } from "react-redux";
import { useSelectorTyped } from "../../../common/redux/store";
import { ITeamMember, Role, TeamDetailsPageActions } from "../redux";
import Analytics from "../../../common/services/analytics";
import { TeamType } from "../../create-team/redux";
import { ChangeEvent, useEffect, useState } from "react";
import { TeamStatus } from "../../get-started/redux";
import { getUserEmployeeIdFromUserEmail } from "../../../utils/teams/surveyjs-helper";
import { useGetFeatureToggleState } from "../../../common/hooks/feature-toggles";
import moment from "moment";
import { capitalize } from "lodash";
import { getEnvVars } from "../../../common/config/env";

export default function useTeamMembersTableController() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const teamDetails = useSelectorTyped((state) => state.teamDetailsPage.teamDetailsPEV.value!);
  const updateTeamMemberRoleProgress =
    useSelectorTyped((state) => state.teamDetailsPage.updateTeamMemberRolePE.progress);
  const exitTeamPE =
    useSelectorTyped((state) => state.teamDetailsPage.exitTeamPE);
  const joinTeamPE = useSelectorTyped((state) => state.teamDetailsPage.joinTeamPE);
  const showJoinTeamModal = useSelectorTyped((state) => state.teamDetailsPage.showJoinTeamModal);
  const [selectedMembers, setSelectedMembers] = useState<ITeamMember[]>([]);
  const [toggleClearSelectedMembers, setToggleClearSelectedMembers] = useState<boolean>(false);
  const [showRemoveMembersWarningModal, setShowRemoveMembersWarningModal] = useState<boolean>(false);
  const [showRemoveLastManagerWarningModal, setShowRemoveLastManagerWarningModal] = useState<boolean>(false);
  const potentialMembers = useSelectorTyped((state) => state.teamsPage.potentialMembersPEV.value);
  const loggedInUserEmail = useSelectorTyped((state) => state.header.userInfo.email.value);
  const removeMultipleMemberPES = useSelectorTyped((state) => state.teamDetailsPage.removeMultipleMembersPES);
  const projectAssociationToggleEnabled = useGetFeatureToggleState("Neo_Teams_AssociateStaffingProjects");
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState<string>("");
  const [filteredMembers, setFilteredMembers] = useState<ITeamMember[]>([]);
  const isApiUnificationToggleEnabled = useGetFeatureToggleState("Neo_ApiPlatform_Unification");
  const { unificationConfig } = getEnvVars();
  const environmentConfig = unificationConfig.environmentSpecificConfig;

  useEffect(() => {
    dispatch(TeamDetailsPageActions.displayJoinTeamModal(false));
  }, []);

  useEffect(() => {
    setFilteredMembers(teamDetails.members.filter((member) => {
      const caseInsensitiveSearchText = searchText.toLowerCase();
      const memberName = `${member.firstName} ${member.lastName}`;
      const location = `${member.country} - ${member.homeOffice}`;
      return memberName.toLowerCase().includes(caseInsensitiveSearchText)
        || member.emailId.toLowerCase().includes(caseInsensitiveSearchText)
        || member.departmentName.toLowerCase().includes(caseInsensitiveSearchText)
        || member.role.toLowerCase().includes(caseInsensitiveSearchText)
        || location.toLowerCase().includes(caseInsensitiveSearchText)
        || member.associatedProjects.some((project) =>
          project.name.toLowerCase().includes(caseInsensitiveSearchText));
    }));
  }, [teamDetails, searchText]);

  useEffect(() => {
    setToggleClearSelectedMembers(!toggleClearSelectedMembers);
  }, [teamDetails.members]);

  useEffect(() => {
    if (removeMultipleMemberPES.success) {
      setShowRemoveMembersWarningModal(false);
      dispatch(TeamDetailsPageActions.resetRemoveMultipleMembersPES());
    }
  }, [removeMultipleMemberPES]);

  const onClickAddMembersButton = () => {
    Analytics.trackEventClickAddTeamMembers();
    dispatch(TeamDetailsPageActions.displayAddMembersModal(true));
  };

  const onClickRemoveMembersButton = () => {
    const isSelectedMembersContainsAllManagers = () => {
      const filterManagers = (members: ITeamMember[]) =>
        members.filter((member) => member.role === "manager");
      const selectedManagersEmployeeIds = filterManagers(selectedMembers)
        .map((member) => member.employeeId);
      return filterManagers(teamDetails.members)
        .every((member) => selectedManagersEmployeeIds.includes(member.employeeId));
    };
    if (isSelectedMembersContainsAllManagers()) {
      setShowRemoveLastManagerWarningModal(true);
    } else {
      setShowRemoveMembersWarningModal(true);
    }
  };

  const onClickJoinTeamButton = () => {
    dispatch(TeamDetailsPageActions.displayJoinTeamModal(true));
  };

  const shouldRenderUpdateMemberProgress = () => !!updateTeamMemberRoleProgress;


  const canManageMembers = () => shouldDisableManageTeamOptions()?false:teamDetails?.canManageMembers;

  const shouldRenderJoinTeamButton = () => shouldDisableManageTeamOptions()?false:!!teamDetails?.canJoinTeam;

  const shouldRenderExitTeamButton = () => shouldDisableManageTeamOptions()?false:!!teamDetails?.canExitTeam;

  const shouldDisableManageTeamOptions = () => isApiUnificationToggleEnabled && environmentConfig.isSandbox;

  const shouldDifferentiateNonTechopsMembers =
    (teamDetails?.teamType !== TeamType.EXPERIMENT_AND_INNOVATION) && (teamDetails?.teamType !== TeamType.SOFTWARE_MANAGEMENT);

  const shouldShowMultiSelectOption = () => canManageMembers();

  const isTeamActive = () => teamDetails.status === TeamStatus.ACTIVE;

  const isSelectedMembersContainsLoggedInUser = () =>
    selectedMembers.some((member) => member.emailId === loggedInUserEmail);

  const showProjectAssociation = () =>
    (teamDetails.teamType === TeamType.REGIONAL_IT ||
      teamDetails.teamType === TeamType.TECHOPS) && projectAssociationToggleEnabled;

  const getCSVDataForDownload = () => initialSortTeamMembersBasedOnRoleAndName(filteredMembers).map((member) => ({
    Name: `${member.firstName} ${member.lastName}`,
    "Email Address": member.emailId,
    "Home Office": `${member.country} - ${member.homeOffice}`,
    "Working Office": `${member.workingCountry} - ${member.workingOffice}`,
    "Member Type": capitalize(member.role),
    Department: member.departmentName,
    "Project Name": member.projects.map((project) => project.name).join(", "),
    "Project Code": member.projects.map((project) => project.code).join(", "),
    "Account Project Code": member.projects.map((project) => project.accountProjectCode).join(", "),
    "Effort %": member.projects.map((project) => project.effort).join(", "),
  }));

  const initialSortTeamMembersBasedOnRoleAndName = (teamMembers: ITeamMember[]) =>
    [...teamMembers].sort((member1, member2) => {
      enum sortOrder {
        manager,
        developer,
        "non-developer",
        member
      }

      const sortOrderOfMember1 = sortOrder[member1.role];
      const sortOrderOfMember2 = sortOrder[member2.role];
      if (sortOrderOfMember1 === sortOrderOfMember2) {
        return member1.firstName.localeCompare(member2.firstName);
      }
      return sortOrderOfMember1 < sortOrderOfMember2 ? -1 : 1;
    });

  const getCSVFileName = () => `${teamDetails.name}_${moment().format("YYYY-MM-DDTHH-mm-ss")}`;

  const shouldRenderTeamMembersTable = () => isTeamActive() && teamDetails.members.length > 0;

  return ({
    teamDetails,
    filteredMembers,
    toggleClearSelectedMembers,
    showRemoveLastManagerWarningModal,
    showRemoveMembersWarningModal,
    shouldShowMultiSelectOption,
    isRemoveMembersButtonDisabled: () => shouldRenderUpdateMemberProgress() || selectedMembers.length === 0,
    isAddMembersButtonDisabled: shouldRenderUpdateMemberProgress,
    isJoinTeamButtonDisabled: shouldRenderUpdateMemberProgress,
    initialSortTeamMembersBasedOnRoleAndName,
    onClickAddMembersButton,
    onClickRemoveMembersButton,
    shouldRenderUpdateMemberProgress,
    canManageMembers,
    shouldRenderJoinTeamButton,
    shouldRenderRemoveMembersButton: shouldShowMultiSelectOption,
    onClickJoinTeamButton,
    shouldRenderNonTechOpsLegend: shouldDifferentiateNonTechopsMembers,
    shouldDifferentiateNonTechopsDepartment: (member: ITeamMember) => {
      if (shouldDifferentiateNonTechopsMembers) {
        return !(member.isTechOpsMember || member.isRegionalITMember);
      }
      return false;
    },
    onCloseJoinTeamModel: () => dispatch(TeamDetailsPageActions.displayJoinTeamModal(false)),
    isJoinTeamInProgress: !!joinTeamPE.progress,
    showJoinTeamModal,
    onClickJoinTeamButtonInModel: (employeeId: string, role: Role) => {
      dispatch(TeamDetailsPageActions.joinTeam({
        teamId: teamDetails.id,
        memberData: {
          employeeId,
          role,
        },
      }));
    },
    onClickConfirmRemoveMembers: () => {
      dispatch(TeamDetailsPageActions.removeMultipleMembersFromTeam({
        teamId: teamDetails.id,
        employeeIds: selectedMembers.map((member) => member.employeeId),
      }));
      Analytics.trackEventClickRemoveMultipleTeamMembers(teamDetails);
    },
    handleSelectedRowsChange: ({ selectedRows }: { selectedRows: ITeamMember[] }) => {
      setSelectedMembers(selectedRows);
    },
    getRemoveMembersWarningModalConfirmationQuestion: () => {
      if (isSelectedMembersContainsLoggedInUser()) {
        return selectedMembers.length === 1
          ? "Are you sure you want to remove yourself?"
          // eslint-disable-next-line max-len
          : `Are you sure you want to remove ${selectedMembers.length} members (including yourself)?`;
      }
      return `Are you sure you want to remove ${selectedMembers.length} members?`;
    },
    getRemoveMembersWarningModalAdditionalInfo: () => isSelectedMembersContainsLoggedInUser() && selectedMembers.length === 1
      ? "You will no longer be a member and will lose access to this team."
      : "They'll no longer be a member and will lose access to this team.",
    onHideRemoveMembersWarningModal: () => {
      setShowRemoveMembersWarningModal(false);
    },
    onHideRemoveLastManagerWarningModal: () => {
      setShowRemoveLastManagerWarningModal(false);
    },
    getMemberCount: () => {
      const teamMemberCount = teamDetails.members.length;
      const selectedMemberCount = selectedMembers.length;
      if (selectedMemberCount === 0) {
        return teamMemberCount;
      }
      return `${selectedMemberCount} selected out of ${teamMemberCount}`;
    },
    getMemberTypeCounts: () => {
      const memberTypeCounts: Record<Role, number> = { manager: 0, developer: 0, "non-developer": 0 };
      filteredMembers.forEach((member) =>
        memberTypeCounts[member.role] = memberTypeCounts[member.role] + 1,
      );
      return memberTypeCounts;
    },
    shouldRenderTeamMembersTable,
    shouldRenderExitTeamButton,
    onClickConfirmExitTeam: () => {
      const loggedInUserEmployeeId = getUserEmployeeIdFromUserEmail(loggedInUserEmail, potentialMembers);
      dispatch(TeamDetailsPageActions.exitTeam({ teamId: teamDetails.id, employeeId: loggedInUserEmployeeId }));
    },
    getRemoveButtonLabelInRemoveMembersWarningModal: () => removeMultipleMemberPES.progress ? "Removing" : "Remove",
    shouldDisableButtonsInRemoveMembersWarningModal: () => !!removeMultipleMemberPES.progress,
    exitTeamPE,
    loggedInUserEmail,
    showProjectAssociation,
    getCSVDataForDownload,
    getCSVFileName,
    shouldRenderExportCSVLink: shouldRenderTeamMembersTable(),
    searchText,
    onClickClearSearchText: () => {
      setSearchText("");
    },
    onChangeSearchText: (event: ChangeEvent<HTMLInputElement>) => {
      Analytics.trackEventSearchTeamMembers();
      setSearchText(event.target.value);
    },
  });
}

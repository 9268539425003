import { ApplicationDetailsPageActions, RequestZendeskResourceData } from "../../redux";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { FormRef } from "../../../../../common/components/forms";
import { useSelectorTyped } from "../../../../../common/redux/store";
import { getRequestResourceQuestions } from "./data/questions";
import { IResourceConfig } from "../../../../../common/components/header/redux";
import castTo from "../../../../../utils/cast-to";

export default function useRequestZendeskResourceFormController(props: { resourceConfig: IResourceConfig; onClose: () => void }) {
  const { requestZendeskResourcePES, requestedAdditionalSupportPEV } = useSelectorTyped((state) => state.applicationDetailsPage);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const application = useSelectorTyped((state) => state.applicationDetailsPage.applicationBasicInfoPEV.value!);
  const dispatch = useDispatch();
  const formRef = useRef<FormRef>(null);
  const [formKey, setFormKey] = useState(0);
  const zendeskResourceRequest =
    // eslint-disable-next-line max-len
    requestedAdditionalSupportPEV.value?.zendeskResourceRequests.find((data) => data.tasks[0].taskType === props.resourceConfig.resourceType);

  useEffect(() => {
    if (requestZendeskResourcePES.success) {
      closeAccordionAndResetFormData();
    }
  }, [requestZendeskResourcePES]);

  useEffect(() => {
    setFormKey((value) => value + 1);
  }, [application, zendeskResourceRequest]);

  const closeAccordionAndResetFormData = () => {
    props.onClose();
    formRef.current?.reset();
    dispatch(ApplicationDetailsPageActions.resetRequestZendeskResourcePES());
  };

  const isRequestResourceInProgress = () => !!requestZendeskResourcePES.progress;

  const isFormButtonDisabled = () => !!zendeskResourceRequest || isRequestResourceInProgress();

  return {
    formRef,
    formKey,
    resourceConfig: props.resourceConfig,
    getQuestions: () => getRequestResourceQuestions(props.resourceConfig),
    getFormData: () => (zendeskResourceRequest?.tasks[0].resource),
    getSubmitButtonLabel: () => isRequestResourceInProgress() ? "Submitting" : "Submit",
    isFormButtonDisabled,
    onSubmit: (formData: Record<string, string>) => {
      const resourceDetail = props.resourceConfig.resourceDetails.map((resource) => (
        {
          [`${resource.fieldName}`]: formData[resource.fieldName],
        }
      ));
      dispatch(ApplicationDetailsPageActions.requestZendeskResource({
        applicationId: application.id,
        data: {
          resourceType: props.resourceConfig.resourceType,
          resourceDetails: castTo<RequestZendeskResourceData["resourceDetails"]>(Object.assign({}, ...resourceDetail)),
        },
      }));
    },
    onCancel: closeAccordionAndResetFormData,
  };
}

import React from "react";
import FormComponent, { INavigationButtonsProps } from "../../../../common/components/forms";
import "./index.scss";
import useManageInfrastructureController from "./controller";
import { createFunctionalComponentFromView } from "../../../../utils/view-controller";
import Progress from "../../../../common/components/progress";
import { NavigationButtons } from "../../../../common/components/forms/navigation-buttons";
import Error, { mapApplicationErrorInfoToErrorComponentProps } from "../../../../common/components/error-new";
import Alert from "react-bootstrap/Alert";

const ManageInfrastructure = createFunctionalComponentFromView(
  useManageInfrastructureController,
  (controller) => {
    const renderNavigationButtons = ({ getFormData, doesFormHasErrors }: INavigationButtonsProps) => {
      if (controller.shouldRenderNavigationButtons()) {
        return (
          <NavigationButtons
            nextButtonLabel={controller.getSubmitButtonLabel()}
            previousButtonLabel="Cancel"
            getFormData={getFormData}
            doesFormHasErrors={doesFormHasErrors}
            onClickNext={controller.onSubmit}
            onClickPrevious={controller.onCancel}
            isNextButtonDisabled={controller.isRequestAdditionalInfraInProgress()}
            isPreviousButtonDisabled={controller.isRequestAdditionalInfraInProgress()}
          />
        );
      }
      return null;
    };

    const renderError = () => {
      if (controller.shouldRenderError()) {
        return (
          <Error
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            {...mapApplicationErrorInfoToErrorComponentProps(controller.infrastructureDetailError!)}
            height={280}
          />
        );
      }
    };

    const renderBillingProjectNotAssociatedAlert = () => {
      if (controller.shouldRenderBillingProjectNotAssociatedAlert()) {
        return (
          <Alert className="billing-project-not-associated-alert">
            Billing project is not associated with this application, so you cannot request for infrastructure.
            Please contact {controller.neoSupportEmail} for further help.
          </Alert>
        );
      }
    };

    const renderManageInfraForm = () => {
      if (controller.shouldRenderForm()) {
        return (
          <FormComponent
            ref={controller.formRef}
            questions={controller.getQuestions()}
            renderNavigationButtons={renderNavigationButtons}
            handleValueChanged={controller.onFormValueChanged}
            // FormKey is used to re-render the form when infraDetails is updated
            // as provisionedInfraDetails or requestedInfraDetails is part of form
            // and form needs to re-render when the above-mentioned details change
            key={controller.formKey}
          />
        );
      }
    };

    return (
      <div className="ManageInfrastructure">
        {renderBillingProjectNotAssociatedAlert()}
        {renderManageInfraForm()}
        <Progress text={controller.infrastructureDetailProgress}/>
        {renderError()}
      </div>
    );
  },
);

export default ManageInfrastructure;

export enum NeoErrorType {
  SERVER_ERROR = "SERVER_ERROR",
  AUTH_SESSION_EXPIRED = "AUTH_SESSION_EXPIRED",
  REQUEST_TIMEOUT = "REQUEST_TIMEOUT",
  RESOURCE_NOT_FOUND = "RESOURCE_NOT_FOUND",
  INTERNET_ERROR = "INTERNET_ERROR",
  UNHANDLED_EXCEPTION = "UNHANDLED_EXCEPTION",
  UNPROCESSABLE_ENTITY = "UNPROCESSABLE_ENTITY",
}

export enum ErrorAction {
  RETRY = "Retry",
  GO_BACK = "Back",
  LOGIN = "Login",
}

export type INeoErrorInfo = {
  title: string;
  subTitle: string;
  actionLabel: ErrorAction;
  errorType: NeoErrorType;
  shouldDisplayErrorImage?: boolean;
};

const errorTypeToErrorInfoMap: Record<NeoErrorType, INeoErrorInfo> = {
  [NeoErrorType.INTERNET_ERROR]: {
    errorType: NeoErrorType.INTERNET_ERROR,
    title: "There is no internet connection.",
    subTitle: "Please check your internet connection and try again.",
    actionLabel: ErrorAction.RETRY,
  },
  [NeoErrorType.AUTH_SESSION_EXPIRED]: {
    errorType: NeoErrorType.AUTH_SESSION_EXPIRED,
    title: "Your session has expired.",
    subTitle: "Please login and try again.",
    actionLabel: ErrorAction.LOGIN,
  },
  [NeoErrorType.RESOURCE_NOT_FOUND]: {
    errorType: NeoErrorType.RESOURCE_NOT_FOUND,
    title: "Sorry!",
    subTitle: "We can't seem to find the page you are looking for.",
    actionLabel: ErrorAction.GO_BACK,
  },
  [NeoErrorType.SERVER_ERROR]: {
    errorType: NeoErrorType.SERVER_ERROR,
    title: "Sorry! Something went wrong.",
    subTitle: "Please try again. If the problem persists, please get in touch with us\n" +
      "\"engineering-platform-support@thoughtworks.com\"",
    shouldDisplayErrorImage: true,
    actionLabel: ErrorAction.RETRY,
  },
  [NeoErrorType.UNPROCESSABLE_ENTITY]: {
    errorType: NeoErrorType.UNPROCESSABLE_ENTITY,
    title: "Sorry! Something went wrong.",
    subTitle: "Please try again. If the problem persists, please get in touch with us\n" +
      "\"engineering-platform-support@thoughtworks.com\"",
    actionLabel: ErrorAction.RETRY,
  },
  [NeoErrorType.UNHANDLED_EXCEPTION]: {
    errorType: NeoErrorType.UNHANDLED_EXCEPTION,
    title: "Sorry! Something went wrong.",
    subTitle: "Please try again.",
    actionLabel: ErrorAction.GO_BACK,
  },
  [NeoErrorType.REQUEST_TIMEOUT]: {
    errorType: NeoErrorType.REQUEST_TIMEOUT,
    title: "Request timeout.",
    subTitle: "The request took too long to respond. Please try again or contact us\n" +
      "\"engineering-platform-support@thoughtworks.com\".",
    actionLabel: ErrorAction.RETRY,
  },
};


export class NeoError extends Error {
  public errorType: NeoErrorType;

  public constructor(errorType: NeoErrorType, originalErrorMessage: string) {
    super(originalErrorMessage);
    this.errorType = errorType;
  }

  public getErrorInfo(): INeoErrorInfo {
    return errorTypeToErrorInfoMap[this.errorType];
  }

}

import React from "react";

const TableSortIcon = () => (
  <svg
    width="24"
    height="34"
    viewBox="0 0 24 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 11.828L9.17196 14.657L7.75696 13.243L12 9L16.243 13.243L14.828 14.657L12 11.828Z"
      fill="#BDBEC0"
    />
    <path
      d="M12 22.172L9.17196 19.343L7.75696 20.757L12 25L16.243 20.757L14.828 19.343L12 22.172Z"
      fill="#414343"
    />
  </svg>
);

export default TableSortIcon;
